<template>
  <div class="manage-container">
    <el-card class="filter-card"
             shadow="hover">
      <!-- 数据筛选表单 -->
      <el-form ref="voucher-form"
               :model="voucherform"
               label-width="80px"
               :rules="formRulessearch">
        <el-form-item label="名称"
                      prop="id"
                      class="form-items">
          <el-select v-model="voucherform.id"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="searchData">
            <el-option v-for="item in customerdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:-80px ;"
                      class="form-items">
          <el-button size="primary"
                     @click="dialogFormCustomer = true"
                     icon="el-icon-plus">新增客户</el-button>
        </el-form-item>
        <el-form-item style="margin-left:-80px ;"
                      class="form-items">
          <el-button size="primary"
                     type="success"
                     icon="el-icon-plus"
                     @click="dialogFormProject = true">新增项目分析</el-button>
        </el-form-item>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>
    <el-card class="box-card"
             shadow="hover">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="layui-elem-quote"> 基本信息</div>
          </template>
          <el-row>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">名称：</span>{{customerinfoss.name}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">简称：</span>{{customerinfoss.abbreviation}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">曾用名：</span>{{customerinfoss.beforename}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">关联企业：</span>{{customerinfoss.associated}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">公司类型：</span>{{customerinfoss.type}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">员工规模：</span>{{customerinfoss.scale}} 人</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">所属部门：</span>{{customerinfoss.deptname}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">客户经理：</span>{{customerinfoss.manager}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">六六：</span>{{customerinfoss.liuliu}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">钢小二：</span>{{customerinfoss.gangxiaoer}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">营业执照：</span>
                <el-link v-if="customerinfoss.business_license_url==null">未上传文件</el-link>
                <el-link type="primary"
                         v-else
                         @click="businessPdf(customerinfoss.business_license_url)">查看文件</el-link>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">开票信息：</span>
                <el-link v-if="customerinfoss.billing_information_url==null">未上传文件</el-link>
                <el-link type="primary"
                         v-else
                         @click="billingPdf(customerinfoss.billing_information_url)">查看文件</el-link>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">收款账号：</span>
                <el-link v-if="customerinfoss.collection_account_url==null">未上传文件</el-link>
                <el-link type="primary"
                         v-else
                         @click="collectionPdf(customerinfoss.collection_account_url)">查看文件</el-link>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content "><span class="grid-content-text">办公地址：</span>{{customerinfoss.addr}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content ">
                <el-button type="warning"
                           size="mini"
                           plain
                           @click="customeredit(customerinfoss.id)">修改</el-button>
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <div class="layui-elem-quote"> 经营状况</div>
          </template>
          <div>
            <el-row>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">业务种类：</span>{{businessinfoa.btype}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">业务联系人：</span>{{businessinfoa.business_contact}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">业务联系方式：</span>{{businessinfoa.btel}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">主要车队：</span>{{businessinfoa.main_fleet}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">开票联系人：</span>{{businessinfoa.billing_contact}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">开票联系方式：</span>{{businessinfoa.bctel}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">业务规模：</span>{{businessinfoa.business_scale}} 万吨/年</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">自有资金规模：</span>{{businessinfoa.ownfunds_scale}} 万元</div>
              </el-col>
              <el-row>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="grid-content bg-purple"><span class="grid-content-text">主要项目：</span>{{businessinfoa.main_terminal}}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="grid-content"><span class="grid-content-text">主要供应商：</span>{{businessinfoa.main_supplier_name}}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="grid-content"><span class="grid-content-text">市场敏感度：</span>{{businessinfoa.market_sensitivity}}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="grid-content bg-purple"><span class="grid-content-text">成交量分析：</span>{{businessinfoa.volume_analysis}}</div>
                </el-col>
              </el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple"><span class="grid-content-text">走访记录：</span>{{businessinfoa.interview_record}}</div>
              </el-col>
              <el-col :span="24">
                <div class="grid-content bg-purple"><span class="grid-content-text">备注：</span>{{businessinfoa.rank}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-button type="warning"
                             size="mini"
                             plain
                             @click="businessedit(businessinfoa.id)">修改</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title">
            <div class="layui-elem-quote"> 项目分析</div>
          </template>
          <div class="demo-block"
               v-for="(item,index) in projectdata"
               :key="index">
            <el-row>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">（{{index+1}}）项目名：</span>{{item.project_name}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">业主单位：</span>{{item.owner_unit}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content "><span class="grid-content-text">承建商：</span>{{item.contractor}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content "><span class="grid-content-text">包工头：</span>{{item.jobwork}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content "><span class="grid-content-text">收货人：</span>{{item.consignee}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">联系方式：</span>{{item.tel}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">计量方式：</span>{{item.metering_mode}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">收货要求：</span>{{item.demand}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">供货周期：</span>{{item.settlement}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">付款周期：</span>{{item.payment_cycle}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">合同量：</span>{{item.contract_number}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">建设工期：</span>{{item.construction_period}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">主供品牌：</span>{{item.brand}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">施工状态：</span><span class="red"
                        v-if="item.status=='1'">未完工</span> <span v-else>已完工</span></div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content"><span class="grid-content-text">品牌范围：</span>{{item.using_brand}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content"><span class="grid-content-text">项目地址：</span>{{item.addr}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple"><span class="grid-content-text">备注：</span>{{item.rank}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-button type="warning"
                             size="mini"
                             plain
                             @click="projectedit(item.id)">修改</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <!-- 添加修改基本信息 -->
    <el-dialog title="新增基本信息"
               :visible.sync="dialogFormCustomer"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="customerinfo-form"
               :model="customerinfo"
               label-width="120px"
               :rules="formRules">
        <el-form-item label="名称"
                      prop="name">
          <el-input v-model="customerinfo.name"></el-input>
        </el-form-item>
        <el-form-item label="所在部门"
                      prop="deptid">
          <el-select v-model="customerinfo.deptid"
                     placeholder="请选择"
                     clearable
                     filterable>
            <el-option v-for="item in deptoptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型"
                      prop="mid">
          <el-select v-model="customerinfo.mid"
                     placeholder="请选择"
                     multiple
                     filterable
                     remote
                     reserve-keyword>
            <el-option v-for="item in mertypeoptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简称"
                      prop="abbreviation">
          <el-input v-model="customerinfo.abbreviation"></el-input>
        </el-form-item>
        <el-form-item label="曾用名"
                      prop="beforename">
          <el-input v-model="customerinfo.beforename"></el-input>
        </el-form-item>
        <el-form-item label="办公地址"
                      prop="addr">
          <el-input v-model="customerinfo.addr"></el-input>
        </el-form-item>
        <el-form-item label="关联企业"
                      prop="associated">
          <el-select v-model="customerinfo.associatedid"
                     filterable
                     multiple
                     remote
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in customerdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司类型"
                      prop="type">
          <el-select v-model="customerinfo.type"
                     placeholder="请选择">
            <el-option v-for="item in typeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工规模"
                      prop="scale">
          <el-select v-model="customerinfo.scale"
                     placeholder="请选择">
            <el-option v-for="item in scaleoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select> 人
        </el-form-item>
        <el-form-item label="六六"
                      prop="liuliu">
          <el-select v-model="customerinfo.liuliu"
                     placeholder="请选择">
            <el-option v-for="item in liuliuoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="钢小二"
                      prop="gangxiaoer">
          <el-select v-model="customerinfo.gangxiaoer"
                     placeholder="请选择">
            <el-option v-for="item in gangxiaoeroptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户经理"
                      prop="username">
          <el-select v-model="customerinfo.userid"
                     placeholder="请选择"
                     clearable
                     filterable>
            <el-option v-for="item in useroptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="customerinfo.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="营业执照"
                      prop="business_license">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="businessRemove"
                     :on-success="filesuccess"
                     multiple
                     :file-list="business"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="开票信息"
                      prop="billing_information">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="billingRemove"
                     :on-success="billingsuccess"
                     multiple
                     :file-list="billing"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="收款账号"
                      prop="collection_account">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="collectionRemove"
                     :on-success="collectionsuccess"
                     multiple
                     :file-list="collection"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateCustomer">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改基本信息"
               :visible.sync="dialogFormCustomers"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="customerinfos-form"
               :model="customerinfos"
               label-width="120px"
               :rules="formRuless">
        <el-form-item label="客户名称"
                      prop="name">
          <el-input v-model="customerinfos.name"></el-input>
        </el-form-item>
        <el-form-item label="所在部门"
                      prop="deptid">
          <el-select v-model="customerinfos.deptid"
                     placeholder="请选择"
                     clearable
                     filterable>
            <el-option v-for="item in deptoptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型"
                      prop="mid">
          <el-select v-model="customerinfos.mid"
                     placeholder="请选择"
                     multiple
                     filterable
                     remote
                     reserve-keyword>
            <el-option v-for="item in mertypeoptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简称"
                      prop="abbreviation">
          <el-input v-model="customerinfos.abbreviation"></el-input>
        </el-form-item>
        <el-form-item label="曾用名"
                      prop="beforename">
          <el-input v-model="customerinfos.beforename"></el-input>
        </el-form-item>
        <el-form-item label="办公地址"
                      prop="addr">
          <el-input v-model="customerinfos.addr"></el-input>
        </el-form-item>
        <el-form-item label="关联企业"
                      prop="associated">
          <el-select v-model="customerinfos.associatedid"
                     filterable
                     multiple
                     remote
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in customerdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司类型"
                      prop="type">
          <el-select v-model="customerinfos.type"
                     placeholder="请选择">
            <el-option v-for="item in typeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工规模"
                      prop="scale">
          <el-select v-model="customerinfos.scale"
                     placeholder="请选择">
            <el-option v-for="item in scaleoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select> 人
        </el-form-item>
        <el-form-item label="六六"
                      prop="liuliu">
          <el-select v-model="customerinfos.liuliu"
                     placeholder="请选择">
            <el-option v-for="item in liuliuoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="钢小二"
                      prop="gangxiaoer">
          <el-select v-model="customerinfos.gangxiaoer"
                     placeholder="请选择">
            <el-option v-for="item in gangxiaoeroptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户经理"
                      prop="username">
          <el-select v-model="customerinfos.userid"
                     placeholder="请选择"
                     clearable
                     filterable>
            <el-option v-for="item in useroptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="customerinfos.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="营业执照"
                      prop="business_license">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :limit='1'
                     :headers="myHeaders"
                     :on-remove="businessaRemove"
                     :on-success="fileasuccess"
                     :file-list="businessa"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="开票信息"
                      prop="billing_information">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :limit='1'
                     :headers="myHeaders"
                     :on-remove="billingaRemove"
                     :on-success="billingasuccess"
                     :file-list="billinga"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="收款账号"
                      prop="collection_accoun">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :limit='1'
                     :headers="myHeaders"
                     :on-remove="collectionaRemove"
                     :on-success="collectionasuccess"
                     :file-list="collectiona"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateCustomers">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 添加修改经营状况 -->
    <el-dialog title="新增经营状况"
               :visible.sync="dialogFormBusiness"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="businessinfo-form"
               :model="businessinfo"
               label-width="120px"
               :rules="formRules">
        <el-form-item label="客户名称"
                      prop="nid">
          <el-select v-model="businessinfo.nid"
                     filterable
                     placeholder="请选择">
            <el-option v-for="item in customerdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务种类"
                      prop="btype">
          <el-select v-model="businessinfo.btype"
                     placeholder="请选择">
            <el-option v-for="item in btypeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务规模"
                      prop="business_scale">
          <el-input v-model="businessinfo.business_scale"></el-input>
        </el-form-item>
        <el-form-item label="业务规模"
                      prop="business_scale">
          <el-input v-model="businessinfo.business_scale"
                    style="width: 230px;"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"></el-input> 万吨/年
        </el-form-item>
        <el-form-item label="自有资金规模"
                      prop="business_scale">
          <el-input v-model="businessinfo.ownfunds_scale"
                    style="width: 230px;"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"></el-input> 万元
        </el-form-item>
        <el-form-item label="联系方式"
                      prop="btel"
                      class="el-form-items">
          <el-input v-model="businessinfo.btel"></el-input>
        </el-form-item>
        <el-form-item label="开票联系人"
                      prop="billing_contact"
                      class="el-form-items">
          <el-input v-model="businessinfo.billing_contact"></el-input>
        </el-form-item>
        <el-form-item label="联系方式"
                      prop="bctel"
                      class="el-form-items">
          <el-input v-model="businessinfo.bctel"></el-input>
        </el-form-item>
        <el-form-item label="市场敏感度"
                      prop="market_sensitivity">
          <el-input type="textarea"
                    v-model="businessinfo.market_sensitivity"></el-input>
        </el-form-item>
        <el-form-item label="主要供应商"
                      prop="main_supplier">
          <el-select v-model="businessinfo.main_supplier"
                     multiple
                     filterable
                     remote
                     placeholder="请输入主要供应商"
                     :loading="loading"
                     style="width:100%">
            <el-option v-for="item in customerdata"
                       :key="item.id"
                       :label="item.abbreviation"
                       :value="item.abbreviation">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主要车队"
                      prop="main_fleet">
          <el-input v-model="businessinfo.main_fleet"></el-input>
        </el-form-item>
        <el-form-item label="成交量分析"
                      prop="rank">
          <el-input v-model="businessinfo.volume_analysis"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="走访记录"
                      prop="interview_record">
          <el-input v-model="businessinfo.interview_record"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="businessinfo.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateBusiness">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改经营状况"
               :visible.sync="dialogFormBusinesss"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="businessinfos-form"
               :model="businessinfos"
               label-width="120px"
               :rules="formRuless">
        <el-form-item label="业务种类"
                      prop="btype">
          <el-select v-model="businessinfos.btype"
                     placeholder="请选择">
            <el-option v-for="item in btypeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务联系人"
                      prop="business_contact"
                      class="el-form-items">
          <el-input v-model="businessinfos.business_contact"></el-input>
        </el-form-item>
        <el-form-item label="业务联系方式"
                      prop="btel"
                      class="el-form-items">
          <el-input v-model="businessinfos.btel"></el-input>
        </el-form-item>
        <el-form-item label="业务规模"
                      prop="business_scale">
          <el-input v-model="businessinfos.business_scale"
                    style="width: 230px;"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"></el-input> 万吨/年
        </el-form-item>
        <el-form-item label="自有资金规模"
                      prop="business_scale">
          <el-input v-model="businessinfos.ownfunds_scale"
                    style="width: 230px;"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"></el-input> 万元
        </el-form-item>

        <el-form-item label="开票联系人"
                      prop="billing_contact"
                      class="el-form-items">
          <el-input v-model="businessinfos.billing_contact"></el-input>
        </el-form-item>
        <el-form-item label="开票联系方式"
                      prop="bctel"
                      class="el-form-items">
          <el-input v-model="businessinfos.bctel"></el-input>
        </el-form-item>
        <el-form-item label="市场敏感度"
                      prop="market_sensitivity">
          <el-input type="textarea"
                    v-model="businessinfos.market_sensitivity"></el-input>
        </el-form-item>
        <el-form-item label="主要供应商"
                      prop="main_supplier">
          <el-select v-model="businessinfos.main_supplier"
                     multiple
                     filterable
                     remote
                     placeholder="请输入主要供应商"
                     :loading="loading"
                     style="width:100%">
            <el-option v-for="item in customeralldata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主要车队"
                      prop="main_fleet">
          <el-input v-model="businessinfos.main_fleet"></el-input>
        </el-form-item>
        <el-form-item label="成交量分析"
                      prop="rank">
          <el-input v-model="businessinfos.volume_analysis"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="走访记录"
                      prop="interview_record">
          <el-input v-model="businessinfos.interview_record"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="businessinfos.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateBusinesss">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 添加修改项目分析 -->
    <el-dialog title="新增项目分析"
               :visible.sync="dialogFormProject"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="projectinfo-form"
               :model="projectinfo"
               label-width="120px"
               :rules="formRulesproject">
        <el-form-item label="客户名称"
                      prop="nid">
          <el-select v-model="projectinfo.nid"
                     filterable
                     placeholder="请选择">
            <el-option v-for="item in customerdata"
                       :key="item.ide"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名"
                      prop="project_name">
          <el-input v-model="projectinfo.project_name"></el-input>
        </el-form-item>
        <el-form-item label="业主单位"
                      prop="owner_unit">
          <el-input v-model="projectinfo.owner_unit"></el-input>
        </el-form-item>
        <el-form-item label="项目地址"
                      prop="addr">
          <el-input v-model="projectinfo.addr"></el-input>
        </el-form-item>
        <el-form-item label="承建商"
                      prop="contractor"
                      class="el-form-items">
          <el-input v-model="projectinfo.contractor"></el-input>
        </el-form-item>
        <el-form-item label="包工头"
                      prop="jobwork"
                      class="el-form-items">
          <el-input v-model="projectinfo.jobwork"></el-input>
        </el-form-item>
        <el-form-item label="收货人"
                      prop="consignee"
                      class="el-form-items">
          <el-input v-model="projectinfo.consignee"></el-input>
        </el-form-item>
        <el-form-item label="联系方式"
                      prop="tel"
                      class="el-form-items">
          <el-input v-model="projectinfo.tel"></el-input>
        </el-form-item>
        <el-form-item label="主供品牌"
                      prop="brand"
                      class="el-form-itemss">
          <el-select v-model="projectinfo.brand"
                     multiple
                     filterable
                     remote
                     placeholder="请输入品牌"
                     :loading="loading"
                     style="width:100%">
            <el-option v-for="item in brandlist"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌范围"
                      prop="tel"
                      class="el-form-items">
          <el-input v-model="projectinfo.using_brand"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="计量方式"
                      prop="metering_mode">
          <el-select v-model="projectinfo.metering_mode"
                     placeholder="请选择">
            <el-option v-for="item in metering_modeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收货要求"
                      prop="demand">
          <el-input v-model="projectinfo.demand"></el-input>
        </el-form-item>
        <el-form-item label="供货周期"
                      prop="settlement">
          <el-input v-model="projectinfo.settlement"></el-input>
        </el-form-item>
        <el-form-item label="付款周期"
                      prop="payment_cycle">
          <el-input v-model="projectinfo.payment_cycle"></el-input>
        </el-form-item>
        <el-form-item label="建设工期"
                      prop="construction_period">
          <el-date-picker v-model="projectinfo.period1"
                          type="date"
                          placeholder="选择开工日期"
                          value-format="yyyy-MM-dd">
          </el-date-picker> - <el-date-picker v-model="projectinfo.period2"
                          type="date"
                          placeholder="选择完工日期"
                          value-format="yyyy-MM-dd"
                          @change="perioda">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="施工状态"
                      prop="status">
          <el-select v-model="projectinfo.status"
                     placeholder="请选择">
            <el-option v-for="item in statusoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同量"
                      prop="contract_number">
          <el-input v-model="projectinfo.contract_number"
                    style="width:230px"></el-input> 吨
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="projectinfo.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateProject">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改项目分析"
               :visible.sync="dialogFormProjects"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="projectinfos-form"
               :model="projectinfos"
               label-width="120px"
               :rules="formRulesprojects">
        <el-form-item label="项目名"
                      prop="project_name">
          <el-input v-model="projectinfos.project_name"></el-input>
        </el-form-item>
        <el-form-item label="业主单位"
                      prop="owner_unit">
          <el-input v-model="projectinfos.owner_unit"></el-input>
        </el-form-item>
        <el-form-item label="项目地址"
                      prop="addr">
          <el-input v-model="projectinfos.addr"></el-input>
        </el-form-item>
        <el-form-item label="承建商"
                      prop="contractor"
                      class="el-form-items">
          <el-input v-model="projectinfos.contractor"></el-input>
        </el-form-item>
        <el-form-item label="包工头"
                      prop="jobwork"
                      class="el-form-items">
          <el-input v-model="projectinfos.jobwork"></el-input>
        </el-form-item>
        <el-form-item label="收货人"
                      prop="consignee"
                      class="el-form-items">
          <el-input v-model="projectinfos.consignee"></el-input>
        </el-form-item>
        <el-form-item label="联系方式"
                      prop="tel"
                      class="el-form-items">
          <el-input v-model="projectinfos.tel"></el-input>
        </el-form-item>
        <el-form-item label="主供品牌"
                      prop="brand"
                      class="el-form-itemss">
          <el-select v-model="projectinfos.brand"
                     multiple
                     filterable
                     remote
                     placeholder="请输入品牌"
                     :loading="loading"
                     style="width:100%">
            <el-option v-for="item in brandlist"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌范围"
                      prop="tel"
                      class="el-form-items">
          <el-input v-model="projectinfos.using_brand"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="计量方式"
                      prop="metering_mode">
          <el-select v-model="projectinfos.metering_mode"
                     placeholder="请选择">
            <el-option v-for="item in metering_modeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收货要求"
                      prop="demand">
          <el-input v-model="projectinfos.demand"></el-input>
        </el-form-item>
        <el-form-item label="供货周期"
                      prop="settlement">
          <el-input v-model="projectinfos.settlement"></el-input>
        </el-form-item>
        <el-form-item label="付款周期"
                      prop="payment_cycle">
          <el-input v-model="projectinfos.payment_cycle"></el-input>
        </el-form-item>
        <el-form-item label="建设工期"
                      prop="construction_period">
          <el-date-picker v-model="projectinfos.period1"
                          type="date"
                          placeholder="选择开工日期"
                          value-format="yyyy-MM-dd">
          </el-date-picker> - <el-date-picker v-model="projectinfos.period2"
                          type="date"
                          placeholder="选择完工日期"
                          value-format="yyyy-MM-dd"
                          @change="periodb">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="施工状态"
                      prop="status">
          <el-select v-model="projectinfos.status"
                     placeholder="请选择">
            <el-option v-for="item in statusoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同量"
                      prop="contract_number">
          <el-input v-model="projectinfos.contract_number"
                    style="width:230px"></el-input> 吨
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="projectinfos.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateProjects">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 添加修改数据筛选 -->
  </div>
</template>

<script>
import { getCustomerList, getEditCustomer, getEditBusiness, getProjectList, upDataCustomer, addNewsCustomer, addNewsBusiness, upDataBusiness, addNewsProject, upDataProject, getEditProject, getDelBusiness, getDelProject, getdelCustomer, getDepartmentList, getMerchantsType, getCustomerListAll } from '@/api/global'
import { getUserList } from '@/api/user'
import globalBus from '@/utils/global-bus'
import axios from 'axios'
const Base64 = require('js-base64').Base64

export default {
  name: 'CustomerIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      activeNames: ['1'],
      voucherform: {
        id: 175
      },
      formRulessearch: {
        id: [{ required: true, message: '请输选择客户名', trigger: 'blur' }]
      },
      top: '15px',
      business: [],
      billing: [],
      businessa: [],
      billinga: [],
      collection: [],
      collectiona: [],
      projectdata: [],
      businessinfoa: '',
      myHeaders: '',
      updateProfileLoading: false, // 更新基本信息的 loading 状态
      customerdata: [],
      customeralldata: [],
      search: '',
      deptoptions: [],
      useroptions: [],
      mertypeoptions: [],
      customerinfoss: '',
      dialogFormCustomer: false,
      dialogFormCustomers: false,
      customerformRules: {
        id: [{ required: true, message: '请输选择公司名', trigger: 'blur' }]
      },
      formRules: {
        name: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      formRuless: {
        name: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      liuliuoptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }],
      gangxiaoeroptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }],
      customerinfo: {
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        associatedid: '',
        type: '',
        addr: '',
        scale: '',
        business_scale: '',
        liuliu: '',
        gangxiaoer: '',
        business_license: '',
        business_license_url: '',
        billing_information: '',
        billing_information_url: '',
        collection_account: '',
        collection_account_url: '',
        rank: ''
      },
      customerinfos: {
        id: '',
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        associatedid: '',
        type: '',
        addr: '',
        scale: '',
        business_scale: '',
        liuliu: '',
        business_license: '',
        business_license_url: '',
        billing_information: '',
        billing_information_url: '',
        collection_account: '',
        collection_account_url: '',
        gangxiaoer: '',
        rank: ''
      },
      businessinfo: {
        id: '',
        nid: '',
        type: '',
        btype: '',
        settlement_clause: '',
        business_contact: '',
        btel: '',
        billing_contact: '',
        bctel: '',
        business_scale: '',
        ownfunds_scale: '',
        market_sensitivity: '',
        main_supplier: '',
        main_fleet: '',
        main_terminal: '',
        volume_analysi: '',
        interview_record: '',
        rank: ''
      },
      businessinfos: {
        id: '',
        nid: '',
        type: '',
        btype: '',
        settlement_clause: '',
        business_contact: '',
        btel: '',
        billing_contact: '',
        bctel: '',
        business_scale: '',
        ownfunds_scale: '',
        market_sensitivity: '',
        main_supplier: '',
        main_fleet: '',
        main_terminal: '',
        volume_analysi: '',
        interview_record: '',
        rank: ''
      },
      btypeoptions: [{
        value: '工程',
        label: '工程'
      }, {
        value: '账期',
        label: '账期'
      }, {
        value: '先货后款',
        label: '先货后款'
      }, {
        value: '款到发货',
        label: '款到发货'
      }],
      dialogFormBusiness: false,
      dialogFormBusinesss: false,
      dialogFormProject: false,
      projectinfo: {
        id: '',
        nid: '',
        project_name: '',
        owner_unit: '',
        addr: '',
        consignee: '',
        tel: '',
        brand: '',
        using_brand: '',
        payment_cycle: '',
        metering_mode: '',
        demand: '',
        settlement: '',
        contract_number: '',
        period1: '',
        period2: '',
        contractor: '',
        jobwork: '',
        rank: ''
      },
      projectinfos: {
        id: '',
        nid: '',
        project_name: '',
        owner_unit: '',
        addr: '',
        consignee: '',
        tel: '',
        brand: '',
        using_brand: '',
        payment_cycle: '',
        metering_mode: '',
        demand: '',
        settlement: '',
        contract_number: '',
        period1: '',
        period2: '',
        contractor: '',
        jobwork: '',
        rank: ''
      },
      formRulesproject: {
        nid: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ]
      },
      formRulesprojects: {
        nid: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ]
      },
      statusoptions: [{
        value: 1,
        label: '未完工'
      }, {
        value: 2,
        label: '已完工'
      }],
      brandlist: [],
      dialogFormProjects: false,
      scaleoptions: [{
        value: '0-10',
        label: '0-10'
      }, {
        value: '10-50',
        label: '10-50'
      }, {
        value: '50以上',
        label: '50以上'
      }],
      typeoptions: [{
        value: '民营企业',
        label: '民营企业'
      }, {
        value: '国有企业',
        label: '国有企业'
      }],
      metering_modeoptions: [{
        value: '理计',
        label: '理计'
      }, {
        value: '磅计',
        label: '磅计'
      }]
    }
  },
  created () {
    this.loadtoken()
    this.loadnav()
    this.loadcustomerList()
    this.loadcustomerListAll()
    this.loadcustomerinfo()
    this.loaddept()
    this.loadmerchantstype()
    this.loaduser()
    this.loadbrand()
  },
  mounted () {
  },
  methods: {
    loadbrand () {
      const _self = this
      axios.post('https://api.66gangtie.com/api/bmGetBrand').then(function (response) {
        if (response.data.status === 'success') {
          _self.brandlist = response.data.data
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loaduser () {
      getUserList().then(res => {
        if (res.data.status === 'success') {
          this.useroptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loaddept () {
      getDepartmentList().then(res => {
        if (res.data.status === 'success') {
          this.deptoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadmerchantstype () {
      getMerchantsType().then(res => {
        if (res.data.status === 'success') {
          this.mertypeoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'customerinfo') {
        this.topname = '客商管理'
        this.navnames = '客户分析'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadcustomerList () {
      getCustomerList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.customerdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    loadcustomerListAll () {
      getCustomerListAll().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.customeralldata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    loadcustomerinfo () {
      getEditCustomer({ id: this.voucherform.id }).then(res => {
        this.customerinfoss = res.data.data
        this.businessa = res.data.dataa
        this.billinga = res.data.datab
        this.collectiona = res.data.datac
        this.loadbusiness()
        this.loadprojectList()
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    searchData () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['voucher-form'].validate(valid => {
        if (!valid) {
          return false
        }
        getEditCustomer({ id: this.voucherform.id }).then(res => {
          this.businessinfos.nid = this.voucherform.id
          this.businessinfo.nid = this.voucherform.id
          this.customerinfoss = res.data.data
          this.businessa = res.data.dataa
          this.billinga = res.data.datab
          this.loadbusiness()
          this.loadprojectList()
        }).catch(err => {
          console.log('修改失败', err)
        })
      })
    },
    loadbusiness () {
      getEditBusiness({ nid: this.voucherform.id }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.businessinfoa = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    loadprojectList () {
      getProjectList({ nid: this.voucherform.id }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.projectdata = res.data.data
          this.projectinfo.nid = this.voucherform.id
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    businessPdf (url) {
      if (!url && typeof (url) !== 'undefined' && url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        window.open('https://file.gangerp.com/onlinePreview?url=' + Base64.encode(url), '_blank')
      }
    },
    billingPdf (url) {
      if (!url && typeof (url) !== 'undefined' && url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        window.open('https://file.gangerp.com/onlinePreview?url=' + Base64.encode(url), '_blank')
      }
    },
    collectionPdf (url) {
      if (!url && typeof (url) !== 'undefined' && url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        window.open('https://file.gangerp.com/onlinePreview?url=' + Base64.encode(url), '_blank')
      }
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.customerinfo.business_license = res.data.name
        this.customerinfo.business_license_url = res.data.url
        this.business = this.business.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessRemove (file) {
      this.business.some((item, i) => {
        if (item.url === file.url) {
          this.business.splice(i, 1)
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    billingsuccess (res) {
      if (res.status === 'success') {
        this.customerinfo.billing_information = res.data.name
        this.customerinfo.billing_information_url = res.data.url
        this.billing = this.billing.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    billingRemove (file) {
      this.billing.some((item, i) => {
        if (item.url === file.url) {
          this.billing.splice(i, 1)
          return true
        }
      })
    },
    fileasuccess (res) {
      if (res.status === 'success') {
        this.customerinfos.business_license = res.data.name
        this.customerinfos.business_license_url = res.data.url
        this.businessa = this.businessa.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessaRemove (file) {
      this.businessa.some((item, i) => {
        if (item.url === file.url) {
          this.businessa.splice(i, 1)
          return true
        }
      })
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    billingasuccess (res) {
      if (res.status === 'success') {
        this.customerinfos.billing_information = res.data.name
        this.customerinfos.billing_information_url = res.data.url
        this.billinga = this.billinga.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    billingaRemove (file) {
      this.billinga.some((item, i) => {
        if (item.url === file.url) {
          this.billinga.splice(i, 1)
          return true
        }
      })
    },
    collectionsuccess (res) {
      if (res.status === 'success') {
        this.customerinfo.collection_account = res.data.name
        this.customerinfo.collection_account_url = res.data.url
        this.collection = this.collection.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    collectionRemove (file) {
      this.collection.some((item, i) => {
        if (item.url === file.url) {
          this.collection.splice(i, 1)
          return true
        }
      })
    },
    collectionasuccess (res) {
      if (res.status === 'success') {
        this.customerinfos.collection_account = res.data.name
        this.customerinfos.collection_account_url = res.data.url
        this.collectiona = this.collectiona.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    collectionaRemove (file) {
      this.collectiona.some((item, i) => {
        if (item.url === file.url) {
          this.collectiona.splice(i, 1)
          return true
        }
      })
    },
    beforeUpload (file) {
    },
    customeredit (id) {
      getEditCustomer({ id: id }).then(res => {
        this.dialogFormCustomers = true
        this.customerinfos = res.data.data
        this.businessa = res.data.dataa
        this.billinga = res.data.datab
        this.collectiona = res.data.datac
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    onUpdateCustomer () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['customerinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addNewsCustomer(this.customerinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormCustomer = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.customerdata = res.data.data
          this.voucherform.id = res.data.id
          this.customerinfo.name = ''
          this.customerinfo.deptid = ''
          this.customerinfo.userid = ''
          this.loadbusiness()
          this.loadprojectList()
          this.loadcustomerinfo()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateCustomers () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['customerinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        if (this.collectiona.length === 0) {
          this.customerinfos.collection_account = null
          this.customerinfos.collection_account_url = null
        }
        if (this.businessa.length === 0) {
          this.customerinfos.business_license = null
          this.customerinfos.business_license_url = null
        }
        if (this.billinga.length === 0) {
          this.customerinfos.billing_information = null
          this.customerinfos.billing_information_url = null
        }
        this.upDataCustomer()
      })
    },
    upDataCustomer () {
      this.updatePhotoLoading = true
      upDataCustomer(this.customerinfos).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormCustomers = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.customerinfos.name = ''
          this.customerinfos.deptid = ''
          this.customerinfos.userid = ''
          this.loadbusiness()
          this.loadprojectList()
          this.loadcustomerinfo()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    // 经营状况
    businessedit () {
      getEditBusiness({ nid: this.voucherform.id }).then(res => {
        this.dialogFormBusinesss = true
        this.businessinfos = res.data.data
        this.businessinfos.nid = this.voucherform.id
        this.businessinfo.nid = this.voucherform.id
        this.businessinfos.main_supplier = res.data.main_supplier
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    onUpdateBusiness () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['businessinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendBusinessinfo()
      })
    },
    sendBusinessinfo () {
      this.updatePhotoLoading = true
      addNewsBusiness(this.businessinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormBusiness = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadbusiness()
          this.loadprojectList()
          this.loadcustomerinfo()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateBusinesss () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['businessinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataBusiness(this.businessinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormBusinesss = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadbusiness()
            this.loadprojectList()
            this.loadcustomerinfo()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    // 项目分析
    projectedit (id) {
      getEditProject({ id: id }).then(res => {
        this.dialogFormProjects = true
        this.projectinfos = res.data.data
        this.projectinfos.brand = res.data.brand
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    onUpdateProject () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['projectinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfoProject()
      })
    },
    sendinfoProject () {
      this.updatePhotoLoading = true
      addNewsProject(this.projectinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormProject = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadbusiness()
          this.loadprojectList()
          this.loadcustomerinfo()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateProjects () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['projectinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataProject(this.projectinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormProjects = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadbusiness()
            this.loadprojectList()
            this.loadcustomerinfo()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    onDelete (id) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getdelCustomer({ id: id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
        getDelBusiness({ id: id }).then(res => {
        }).catch(err => {
          console.log('删除失败', err)
        })
        getDelProject({ id: id }).then(res => {

        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    perioda () {
      this.projectinfo.construction_period = this.projectinfo.period1 + '-' + this.projectinfo.period2
    },
    periodb () {
      this.projectinfos.construction_period = this.projectinfos.period1 + '-' + this.projectinfos.period2
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
.form-items {
  float: left;
  margin-right: 5px;
}
.red {
  color: red;
  font-weight: 700;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
.grid-content {
  font-size: 14px;
  padding: 10px 0px;
}
.layui-elem-quote {
  padding: 3px;
  line-height: 10px;
  border-left: 3px solid #4596fc;
  border-radius: 0 2px 2px 0;
  font-weight: 700 !important;
}
/deep/.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0px 0;
}
/deep/.el-collapse-item__header {
  line-height: 48px;
  color: #303133;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.demo-block {
  border-bottom: 1px solid #ebebeb;
  transition: 0.2s;
}
.grid-content-text {
  font-weight: 600;
}
</style>
