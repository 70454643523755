import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/'
import Home from '@/views/home/index.vue'
import Layout from '@/views/layout/'
import Collection from '@/views/collection/'
import Deptcollection from '@/views/collection/deptcollection'
import Personalcollection from '@/views/collection/personalcollection'
import Payment from '@/views/payment/'
import Setting from '@/views/setting/'
import Department from '@/views/manage/department'
import User from '@/views/manage/user'
import Role from '@/views/manage/role'
import Purchasecontacts from '@/views/gangerp/purchasecontacts'
import Collectionlist from '@/views/paymentgoods/collectionlist'
import Paymentlist from '@/views/paymentgoods/paymentlist'
import Voucher from '@/views/finance/voucher'
import Customer from '@/views/customer/customer'
import Business from '@/views/customer/business'
import Project from '@/views/customer/project'
import Supplier from '@/views/customer/supplier'
import Contract from '@/views/customer/contract'
import Pdf from '@/views/customer/pdf'
import Customerinfo from '@/views/customer/customerinfo'
import Supplierinfo from '@/views/customer/supplierinfo'
import Merchants from '@/views/customer/merchants'
import Brands from '@/views/customer/brands'
import Chequnar from '@/views/chequnar'
import Contractword from '@/views/customer/contractword'
import Contractframeless from '@/views/customer/contractframeless'
import Contractexpiration from '@/views/customer/contractexpiration'
import Jobwork from '@/views/customer/jobwork.vue'
import Projects from '@/views/customer/projects.vue'
import Orderinfo from '@/views/order/orderinfo.vue'
import Orderanalysis from '@/views/order/orderanalysis.vue'
import Offeritem from '@/views/project/offeritem.vue'
import Offermanage from '@/views/project/offermanage.vue'
import Businessanalysis from '@/views/dataanalysis/businessanalysis.vue'
import Businessgroupanalysis from '@/views/dataanalysis/businessgroupanalysis.vue'
import Projectmanagement from '@/views/dataanalysis/projectmanagement.vue'
import Salesman from '@/views/dataanalysis/salesman.vue'
import Datamanagement from '@/views/dataanalysis/datamanagement.vue'
import Reportforms from '@/views/dataanalysis/reportforms.vue'
import Sellpurchaselist from '@/views/sellpurchase/sellpurchaselist.vue'
import Sellpurchaseweilanlist from '@/views/sellpurchase/sellpurchaseweilanlist.vue'
import Examinelist from '@/views/sellpurchase/examinelist.vue'
import Electronic from '@/views/sellpurchase/electronic.vue'
import Selllist from '@/views/sellpurchase/selllist.vue'
import Purchaselist from '@/views/sellpurchase/purchaselist.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: Pdf
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '', // path 为空，会作为默认子路由渲染
        name: 'home',
        component: Home
      },
      {
        path: '/collection',
        name: 'collection',
        component: Collection
      },
      {
        path: '/personalcollection',
        name: 'personalcollection',
        component: Personalcollection
      },
      {
        path: '/deptcollection',
        name: 'deptcollection',
        component: Deptcollection
      },
      {
        path: '/payment',
        name: 'payment',
        component: Payment
      },
      {
        path: '/setting',
        name: 'setting',
        component: Setting
      },
      {
        path: '/department',
        name: 'department',
        component: Department
      },
      {
        path: '/customer',
        name: 'customer',
        component: Customer
      },
      {
        path: '/user',
        name: 'user',
        component: User
      },
      {
        path: '/role',
        name: 'role',
        component: Role
      },
      {
        path: '/purchasecontacts',
        name: 'purchasecontacts',
        component: Purchasecontacts
      },
      {
        path: '/collectionlist',
        name: 'collectionlist',
        component: Collectionlist
      },
      {
        path: '/paymentlist',
        name: 'paymentlist',
        component: Paymentlist
      },
      {
        path: '/voucher',
        name: 'voucher',
        component: Voucher
      },
      {
        path: '/business',
        name: 'business',
        component: Business
      },
      {
        path: '/project',
        name: 'project',
        component: Project
      },
      {
        path: '/supplier',
        name: 'supplier',
        component: Supplier
      },
      {
        path: '/supplierinfo',
        name: 'supplierinfo',
        component: Supplierinfo
      },
      {
        path: '/contract',
        name: 'contract',
        component: Contract
      },
      {
        path: '/customerinfo',
        name: 'customerinfo',
        component: Customerinfo
      },
      {
        path: '/merchants',
        name: 'merchants',
        component: Merchants
      },
      {
        path: '/brands',
        name: 'brands',
        component: Brands
      },
      {
        path: '/chequnar',
        name: 'chequnar',
        component: Chequnar
      },
      {
        path: '/contractword',
        name: 'contractword',
        component: Contractword
      },
      {
        path: '/contractframeless',
        name: 'contractframeless',
        component: Contractframeless
      },
      {
        path: '/contractexpiration',
        name: 'contractexpiration',
        component: Contractexpiration
      },
      {
        path: '/jobwork',
        name: 'jobwork',
        component: Jobwork
      },
      {
        path: 'projects',
        name: 'projects',
        component: Projects
      },
      {
        path: 'orderinfo',
        name: 'orderinfo',
        component: Orderinfo
      },
      {
        path: 'orderanalysis',
        name: 'orderanalysis',
        component: Orderanalysis
      },
      {
        path: 'offeritem',
        name: 'offeritem',
        component: Offeritem
      },
      {
        path: 'offermanage',
        name: 'offermanage',
        component: Offermanage
      },
      {
        path: 'businessanalysis',
        name: 'businessanalysis',
        component: Businessanalysis
      },
      {
        path: 'businessgroupanalysis',
        name: 'businessgroupanalysis',
        component: Businessgroupanalysis
      },
      {
        path: 'projectmanagement',
        name: 'projectmanagement',
        component: Projectmanagement
      },
      {
        path: 'salesman',
        name: 'salesman',
        component: Salesman
      },
      {
        path: 'datamanagement',
        name: 'datamanagement',
        component: Datamanagement
      },
      {
        path: 'reportforms',
        name: 'reportforms',
        component: Reportforms
      },
      {
        path: 'sellpurchaselist',
        name: 'sellpurchaselist',
        component: Sellpurchaselist
      },
      {
        path: 'sellpurchaseweilanlist',
        name: 'sellpurchaseweilanlist',
        component: Sellpurchaseweilanlist
      },
      {
        path: 'examinelist',
        name: 'examinelist',
        component: Examinelist
      },
      {
        path: 'electronic',
        name: 'electronic',
        component: Electronic
      },
      {
        path: 'selllist',
        name: 'selllist',
        component: Selllist
      },
      {
        path: 'purchaselist',
        name: 'purchaselist',
        component: Purchaselist
      }
    ]
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(window.localStorage.getItem('user'))
  if (
    to.path !== '/login' &&
    to.path !== '/register' &&
    to.path !== '/findpassword'
  ) {
    if (user) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
