<template>
  <div class="manage-container">
    <el-card class="filter-card"
             shadow="hover">
      <!-- 数据筛选表单 -->
      <el-form ref="supplier-form"
               :model="supplierform"
               label-width="80px"
               :rules="supplierformRules">
        <el-form-item label="公司名"
                      prop="id"
                      class="el-form-items">
          <el-select v-model="supplierform.id"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in supplierdatasearch"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:-60px ;"
                      class="el-form-items">
          <el-button size="primary"
                     @click="searchData"
                     icon="el-icon-search"
                     :loading="updateLoading">搜索</el-button>
          <el-button type="primary"
                     icon="el-icon-plus"
                     @click="dialogFormVisible = true">新建客户</el-button>
        </el-form-item>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>
    <el-card class="box-card"
             shadow="hover">
      <el-table :data="supplierdata"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 180px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading">
        <el-table-column label="序号"
                         type="index"
                         align="center"
                         width="50">
        </el-table-column>
        <el-table-column label="客户名称"
                         prop="name"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="简称"
                         prop="abbreviation"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="曾用名"
                         prop="beforename"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="关联企业"
                         prop="associated"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="主营品种"
                         prop="varieties"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="主营品牌"
                         prop="brand"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="优势资源"
                         prop="resources"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="跟进人"
                         prop="follow_person"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="内勤跟进"
                         prop="office_follow"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <!-- <el-table-column label="对接人及联系方式"
                         prop="face_contact"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
       <el-table-column label="办公地址"
                         prop="addr"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单样式要求"
                         prop="order_style"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款类型"
                         prop="payment_type"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款注意事项"
                         prop="payment_notes"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="实提数注意事项"
                         prop="actual_number_notes"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="对账注意事项"
                         prop="reconciliation_notes"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="开票时间节点"
                         prop="billing_time"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="开票需提供的资料"
                         prop="billing_info"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>-->
        <el-table-column label="六六"
                         prop="liuliu"
                         align="center"
                         :filters="[{ text: '是', value: '是' }, { text: '否', value: '否' }]"
                         :filter-method="liuliufilterTags"
                         filter-placement="bottom-end"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="钢小二"
                         prop="gangxiaoer"
                         align="center"
                         :filters="[{ text: '是', value: '是' }, { text: '否', value: '否' }]"
                         :filter-method="gangxiaoerfilterTags"
                         filter-placement="bottom-end"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="类型"
                         prop="mername"
                         align="center"
                         :filters="[{ text: '客户', value: '客户' }, { text: '客户,供应商', value: '客户,供应商' }]"
                         :filter-method="filterTags"
                         filter-placement="bottom-end"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="营业执照"
                         prop="business_license_url"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="success"
                       plain
                       @click="businessPdf(scope.row)"
                       icon="el-icon-view">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         width="180"
                         label="操作">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="新增供应商"
                 :visible.sync="dialogFormVisible"
                 append-to-body>
        <el-form ref="supplierinfo-form"
                 :model="supplierinfo"
                 label-width="120px"
                 :rules="formRules">
          <el-form-item label="客户名称"
                        prop="name">
            <el-input v-model="supplierinfo.name"></el-input>
          </el-form-item>
          <el-form-item label="所在部门"
                        prop="deptid">
            <el-select v-model="supplierinfo.deptid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in deptoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型"
                        prop="mid">
            <el-select v-model="supplierinfo.mid"
                       placeholder="请选择"
                       multiple
                       filterable
                       remote
                       reserve-keyword>
              <el-option v-for="item in mertypeoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="简称"
                        prop="abbreviation">
            <el-input v-model="supplierinfo.abbreviation"></el-input>
          </el-form-item>
          <el-form-item label="曾用名"
                        prop="beforename">
            <el-input v-model="supplierinfo.beforename"></el-input>
          </el-form-item>
          <el-form-item label="关联企业"
                        prop="associated">
            <el-select v-model="supplierinfo.associated"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in supplierdatasearch"
                         :key="item.id"
                         :label="item.name"
                         :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主营品种"
                        prop="varieties">
            <el-input v-model="supplierinfo.varieties"></el-input>
          </el-form-item>
          <el-form-item label="主营品牌"
                        prop="brand">
            <el-select v-model="supplierinfo.brand"
                       multiple
                       filterable
                       remote
                       placeholder="请输入品牌"
                       :loading="loading"
                       style="width:100%">
              <el-option v-for="item in brandlist"
                         :key="item.id"
                         :label="item.brand"
                         :value="item.brand">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优势资源"
                        prop="resources">
            <el-input v-model="supplierinfo.resources"></el-input>
          </el-form-item>
          <el-form-item label="跟进人"
                        prop="follow_person">
            <el-input v-model="supplierinfo.follow_person"></el-input>
          </el-form-item>
          <el-form-item label="内勤跟进"
                        prop="office_follow">
            <el-input v-model="supplierinfo.office_follow"></el-input>
          </el-form-item>
          <el-form-item label="对接人及联系方式"
                        prop="face_contact">
            <el-input v-model="supplierinfo.face_contact"></el-input>
          </el-form-item>
          <el-form-item label="办公地址"
                        prop="addr">
            <el-input v-model="supplierinfo.addr"></el-input>
          </el-form-item>
          <el-form-item label="订单样式要求"
                        prop="order_style">
            <el-input v-model="supplierinfo.order_style"></el-input>
          </el-form-item>
          <el-form-item label="付款类型"
                        prop="payment_type">
            <el-input v-model="supplierinfo.payment_type"></el-input>
          </el-form-item>
          <el-form-item label="付款注意事项"
                        prop="payment_notes">
            <el-input v-model="supplierinfo.payment_notes"></el-input>
          </el-form-item>
          <el-form-item label="实提数注意事项"
                        prop="actual_number_notes">
            <el-input v-model="supplierinfo.actual_number_notes"></el-input>
          </el-form-item>
          <el-form-item label="对账注意事项"
                        prop="reconciliation_notes">
            <el-input v-model="supplierinfo.reconciliation_notes"></el-input>
          </el-form-item>
          <el-form-item label="开票时间节点"
                        prop="billing_time">
            <el-input v-model="supplierinfo.billing_time"></el-input>
          </el-form-item>
          <el-form-item label="实提数注意事项"
                        prop="billing_info">
            <el-input v-model="supplierinfo.billing_info"></el-input>
          </el-form-item>
          <el-form-item label="六六"
                        prop="liuliu">
            <el-select v-model="supplierinfo.liuliu"
                       placeholder="请选择">
              <el-option v-for="item in liuliuoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="钢小二"
                        prop="gangxiaoer">
            <el-select v-model="supplierinfo.gangxiaoer"
                       placeholder="请选择">
              <el-option v-for="item in gangxiaoeroptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注"
                        prop="rank">
            <el-input v-model="supplierinfo.rank"
                      type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="营业执照"
                        prop="business_license">
            <el-upload class="upload"
                       action="https://bmapi.gangerp.com/api/upFile"
                       :headers="myHeaders"
                       :on-remove="businessRemove"
                       :on-success="filesuccess"
                       multiple
                       :file-list="business"
                       :before-upload="beforeUpload">
              <el-button size="small"
                         type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateCustomer">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改供应商"
                 :visible.sync="dialogFormVisibles"
                 append-to-body>
        <el-form ref="supplierinfos-form"
                 :model="supplierinfos"
                 label-width="120px"
                 :rules="formRuless">
          <el-form-item label="客户名称"
                        prop="name">
            <el-input v-model="supplierinfos.name"></el-input>
          </el-form-item>
          <el-form-item label="所在部门"
                        prop="deptid">
            <el-select v-model="supplierinfos.deptid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in deptoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型"
                        prop="mid">
            <el-select v-model="supplierinfos.mid"
                       placeholder="请选择"
                       multiple
                       filterable
                       remote
                       reserve-keyword>
              <el-option v-for="item in mertypeoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="简称"
                        prop="abbreviation">
            <el-input v-model="supplierinfos.abbreviation"></el-input>
          </el-form-item>
          <el-form-item label="曾用名"
                        prop="beforename">
            <el-input v-model="supplierinfos.beforename"></el-input>
          </el-form-item>
          <el-form-item label="关联企业"
                        prop="associated">
            <el-select v-model="supplierinfos.associated"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in supplierdatasearch"
                         :key="item.id"
                         :label="item.name"
                         :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主营品种"
                        prop="varieties">
            <el-input v-model="supplierinfos.varieties"></el-input>
          </el-form-item>
          <el-form-item label="主营品牌"
                        prop="brand">
            <el-select v-model="supplierinfos.brand"
                       multiple
                       filterable
                       remote
                       placeholder="请输入品牌"
                       :loading="loading"
                       style="width:100%">
              <el-option v-for="item in brandlist"
                         :key="item.id"
                         :label="item.brand"
                         :value="item.brand">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优势资源"
                        prop="resources">
            <el-input v-model="supplierinfos.resources"></el-input>
          </el-form-item>
          <el-form-item label="跟进人"
                        prop="follow_person">
            <el-input v-model="supplierinfos.follow_person"></el-input>
          </el-form-item>
          <el-form-item label="内勤跟进"
                        prop="office_follow">
            <el-input v-model="supplierinfos.office_follow"></el-input>
          </el-form-item>
          <el-form-item label="对接人及联系方式"
                        prop="face_contact">
            <el-input v-model="supplierinfos.face_contact"></el-input>
          </el-form-item>
          <el-form-item label="办公地址"
                        prop="addr">
            <el-input v-model="supplierinfos.addr"></el-input>
          </el-form-item>
          <el-form-item label="订单样式要求"
                        prop="order_style">
            <el-input v-model="supplierinfos.order_style"></el-input>
          </el-form-item>
          <el-form-item label="付款类型"
                        prop="payment_type">
            <el-input v-model="supplierinfos.payment_type"></el-input>
          </el-form-item>
          <el-form-item label="付款注意事项"
                        prop="payment_notes">
            <el-input v-model="supplierinfos.payment_notes"></el-input>
          </el-form-item>
          <el-form-item label="实提数注意事项"
                        prop="actual_number_notes">
            <el-input v-model="supplierinfos.actual_number_notes"></el-input>
          </el-form-item>
          <el-form-item label="对账注意事项"
                        prop="reconciliation_notes">
            <el-input v-model="supplierinfos.reconciliation_notes"></el-input>
          </el-form-item>
          <el-form-item label="开票时间节点"
                        prop="billing_time">
            <el-input v-model="supplierinfos.billing_time"></el-input>
          </el-form-item>
          <el-form-item label="实提数注意事项"
                        prop="billing_info">
            <el-input v-model="supplierinfos.billing_info"></el-input>
          </el-form-item>
          <el-form-item label="六六"
                        prop="liuliu">
            <el-select v-model="supplierinfos.liuliu"
                       placeholder="请选择">
              <el-option v-for="item in liuliuoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="钢小二"
                        prop="gangxiaoer">
            <el-select v-model="supplierinfos.gangxiaoer"
                       placeholder="请选择">
              <el-option v-for="item in gangxiaoeroptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注"
                        prop="rank">
            <el-input v-model="supplierinfos.rank"
                      type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="营业执照"
                        prop="business_license">
            <el-upload class="upload"
                       action="https://bmapi.gangerp.com/api/upFile"
                       :limit='1'
                       :headers="myHeaders"
                       :on-remove="businessaRemove"
                       :on-success="fileasuccess"
                       :file-list="businessa"
                       :before-upload="beforeUpload">
              <el-button size="small"
                         type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateCustomers">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getSupplierList, addNewsSupplier, getDelSupplier, getEditSupplier, upDataSupplier, getDepartmentList, getSupplierSearch, getMerchantsType } from '@/api/global'
import { getUserList } from '@/api/user'
import globalBus from '@/utils/global-bus'
import axios from 'axios'
const Base64 = require('js-base64').Base64

export default {
  name: 'SupplierIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      supplierdatasearch: [],
      supplierform: {
        id: ''
      },
      supplierinfo: {
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        varieties: '',
        brand: '',
        resources: '',
        follow_person: '',
        office_follow: '',
        face_contact: '',
        addr: '',
        order_style: '',
        payment_type: '',
        payment_notes: '',
        actual_number_notes: '',
        reconciliation_notes: '',
        billing_time: '',
        billing_info: '',
        liuliu: '',
        gangxiaoer: '',
        business_license: '',
        business_license_url: '',
        rank: ''
      },
      myHeaders: '',
      business: [],
      billing: [],
      businessa: [],
      billinga: [],
      brandlist: [],
      updateProfileLoading: false,
      updateLoading: false,
      supplierformRules: {
        id: [{ required: true, message: '请输选择公司名', trigger: 'blur' }]
      },
      formRules: {
        name: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      supplierdata: [],
      search: '',
      dialogFormVisible: false,
      dialogFormVisibles: false,
      supplierinfos: {
        id: '',
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        varieties: '',
        brand: '',
        resources: '',
        follow_person: '',
        office_follow: '',
        face_contact: '',
        addr: '',
        order_style: '',
        payment_type: '',
        payment_notes: '',
        actual_number_notes: '',
        reconciliation_notes: '',
        billing_time: '',
        billing_info: '',
        liuliu: '',
        gangxiaoer: '',
        business_license: '',
        business_license_url: '',
        rank: ''
      },
      formRuless: {
        name: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      deptoptions: [],
      useroptions: [],
      mertypeoptions: [],
      liuliuoptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }],
      gangxiaoeroptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }]
    }
  },
  created () {
    this.loadtoken()
    this.loadnav()
    this.loaddept()
    this.loadmerchantstype()
    this.loaduser()
    this.loadsupplierList()
    this.loadbrand()
  },
  mounted () {
  },
  methods: {
    loadbrand () {
      const _self = this
      axios.post('https://api.66gangtie.com/api/bmGetBrand').then(function (response) {
        if (response.data.status === 'success') {
          _self.brandlist = response.data.data
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loaduser () {
      getUserList().then(res => {
        if (res.data.status === 'success') {
          this.useroptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'customer') {
        this.topname = '客商管理'
        this.navnames = '客户管理'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loaddept () {
      getDepartmentList().then(res => {
        if (res.data.status === 'success') {
          this.deptoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadmerchantstype () {
      getMerchantsType().then(res => {
        if (res.data.status === 'success') {
          this.mertypeoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadsupplierList () {
      getSupplierList().then(res => {
        if (res.data.status === 'success') {
          this.supplierdata = res.data.data
          this.supplierdatasearch = res.data.data
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    searchData () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['supplier-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updateLoading = true
        getSupplierSearch(this.supplierform).then(res => {
          this.loading = false
          this.updateLoading = false
          this.supplierdata = res.data.data
        }).catch(err => {
          this.updateLoading = false
          this.loading = false
          console.log('失获取数据败', err)
        })
      })
    },
    onUpdateCustomer () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['supplierinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addNewsSupplier(this.supplierinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.supplierinfo.name = ''
          this.supplierinfo.deptid = ''
          this.supplierinfo.userid = ''
          this.loadsupplierList()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateCustomers () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['supplierinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataSupplier(this.supplierinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisibles = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.supplierinfos.name = ''
            this.supplierinfos.deptid = ''
            this.supplierinfos.userid = ''
            this.loadsupplierList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    handleEdit (row) {
      getEditSupplier({ id: row.id }).then(res => {
        this.dialogFormVisibles = true
        this.supplierinfos = res.data.data
        this.supplierinfos.brand = res.data.brand
        this.businessa = res.data.dataa
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDelSupplier({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadsupplierList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    filterTag (value, row) {
      return row.deptname === value
    },
    filterTags (value, row) {
      return row.mername === value
    },
    liuliufilterTags (value, row) {
      return row.liuliu === value
    },
    gangxiaoerfilterTags (value, row) {
      return row.gangxiaoer === value
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.supplierinfo.business_license = res.data.name
        this.supplierinfo.business_license_url = res.data.url
        this.business = this.business.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessRemove (file) {
      this.business.some((item, i) => {
        if (item.url === file.url) {
          this.business.splice(i, 1)
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    fileasuccess (res) {
      if (res.status === 'success') {
        this.supplierinfos.business_license = res.data.name
        this.supplierinfos.business_license_url = res.data.url
        this.businessa = this.businessa.concat(res.data)
        console.log(this.businessa)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessaRemove (file) {
      this.businessa.some((item, i) => {
        if (item.url === file.url) {
          this.businessa.splice(i, 1)
          return true
        }
      })
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeUpload (file) {
    },
    businessPdf (row) {
      if (!row.business_license_url && typeof (row.business_license_url) !== 'undefined' && row.url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        const routeData = this.$router.resolve({
          name: 'pdf',
          query: {
            url: Base64.encode(row.business_license_url)
          }
        })
        window.open(routeData.href, '_blank')
      }
    }
  }
}
</script>

<style scoped lang="less">
.filter-card {
  margin-bottom: 10px;
}
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
</style>
