<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover">
      <div class="addbutton">
        <el-button type="primary"
                   size="small"
                   icon="el-icon-plus"
                   @click="dialogFormVisible = true">新增角色</el-button>
      </div>
      <el-table :data="deptdata.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 160px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading">
        <el-table-column label="角色名称"
                         prop="name"
                         align="center">
        </el-table-column>
        <el-table-column label="角色描述"
                         prop="explain"
                         align="center">
        </el-table-column>
        <el-table-column align="center"
                         label="操作">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="warning"
                       @click="handleAuth(scope.row)"
                       icon="el-icon-key">分配权限</el-button>
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="分配权限"
                 :visible.sync="dialogAuthVisible"
                 append-to-body
                 destroy-on-close
                 @close="arthclose">
        <el-tree :data="authdata"
                 :props="authprops"
                 show-checkbox
                 node-key="id"
                 :default-checked-keys="authid"
                 ref="treeRef">
        </el-tree>
        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
                     @click="authdetermine"
                     :loading="updatePhotoLoading"> 确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="新增角色"
                 :visible.sync="dialogFormVisible"
                 append-to-body>
        <el-form ref="deptinfo-form"
                 :model="deptinfo"
                 label-width="120px"
                 :rules="formRules">
          <el-form-item label="角色名称"
                        prop="name">
            <el-input v-model="deptinfo.name"></el-input>
          </el-form-item>
          <el-form-item label="角色描述"
                        prop="explain">
            <el-input v-model="deptinfo.explain"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updatePhotoLoading"
                       @click="onUpdateDepartment">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改角色"
                 :visible.sync="dialogFormVisibles"
                 append-to-body>
        <el-form ref="deptinfos-form"
                 :model="deptinfos"
                 label-width="120px"
                 :rules="formRuless">
          <el-form-item label="角色名称"
                        prop="name">
            <el-input v-model="deptinfos.name"></el-input>
          </el-form-item>
          <el-form-item label="角色描述"
                        prop="explain">
            <el-input v-model="deptinfos.explain"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updatePhotoLoading"
                       @click="onUpdateDepartments">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getRoleList, addNewsRole, getEditRole, getDelRole, upDataRole, getAuthList, getSelectAuth, upSelectAuth } from '@/api/global'
import globalBus from '@/utils/global-bus'

export default {
  name: 'Authrole',
  components: {},
  data () {
    return {
      loading: true,
      deptinfo: {
        name: '',
        explain: ''
      }, // 用户资料
      headimgurl: '',
      dialogVisible: false, // 控制上传图片裁切预览的显示状态
      previewImage: '', // 预览图片
      cropper: null, // 裁切器示例
      updatePhotoLoading: false, // 更新用户头像 loading 状态
      formRules: {
        name: [
          { required: true, message: '请输入角色', trigger: 'blur' }
        ]
      },
      deptdata: [],
      search: '',
      dialogFormVisible: false,
      dialogFormVisibles: false,
      deptinfos: {
        id: '',
        name: '',
        explain: ''
      },
      formRuless: {
        name: [
          { required: true, message: '请输入角色', trigger: 'blur' }
        ]
      },
      dialogAuthVisible: false,
      authdata: [],
      authprops: {
        label: 'name',
        children: 'children'
      },
      authid: [],
      rulesid: ''
    }
  },
  mounted () {
    this.loadnav()
    this.loadRoleList()
    this.loadAutList()
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'role') {
        this.topname = '权限管理'
        this.navnames = '角色管理'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadRoleList () {
      getRoleList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.deptdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    loadAutList () {
      getAuthList().then(res => {
        if (res.data.status === 'success') {
          this.authdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateDepartment () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['deptinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addNewsRole(this.deptinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadRoleList()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateDepartments () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['deptinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataRole(this.deptinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisibles = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadRoleList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    handleAuth (row) {
      getSelectAuth({ id: row.id }).then(res => {
        this.dialogAuthVisible = true
        this.authid = res.data.data.map(Number)
        this.rulesid = row.id
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleEdit (row) {
      getEditRole({ id: row.id }).then(res => {
        this.dialogFormVisibles = true
        this.deptinfos.name = res.data.data.name
        this.deptinfos.explain = res.data.data.explain
        this.deptinfos.id = row.id
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDelRole({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loaddepartmentList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    arthclose () {
      this.authid = []
    },
    authdetermine () {
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys()
      ]
      upSelectAuth({ id: this.rulesid, keys: keys }).then(res => {
        if (res.data.status === 'success') {
          this.dialogAuthVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-card__body {
  padding: 10px 10px 10px 10px;
}
/deep/ .el-button--mini,
.el-button--mini.is-round {
  padding: 3px 10px;
}
.el-form-items {
  margin-bottom: 5px;
}
/deep/ .el-form-items .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  border-top-color: rgb(220, 223, 230);
  border-right-color: rgb(220, 223, 230);
  border-bottom-color: rgb(220, 223, 230);
  border-left-color: rgb(220, 223, 230);
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 35px;
  line-height: 35px;
  outline: 0;
  padding: 0 15px;
  padding-right: 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .el-form-items .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.search {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.clear {
  clear: both;
}
/deep/.el-table th {
  padding: 5px 0;
}
/deep/.el-table td {
  padding: 5px 0;
  font-size: 12px;
}
</style>
