<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">

      <el-tabs v-model="activeName"
               @tab-click="handleClick">
        <el-tab-pane label="品牌采购报表"
                     name="first">

          <el-row>
            <el-form :model="form"
                     ref="customer-form">
              <el-form-item label="日期"
                            class="el-form-items">
                <el-date-picker v-model="form.date"
                                value-format="yyyy-MM-dd"
                                type="date"
                                class="input-with-select"
                                style="width: 140px;float: left;">
                </el-date-picker>
              </el-form-item>
              <div style="float: left;margin-top:5px">
                <el-button icon="el-icon-search"
                           type="primary"
                           size="mini"
                           :loading="updateLoading"
                           @click="search"
                           style="padding: 6px 10px;"></el-button>
              </div>
              <div style="float: left;margin-top:5px; margin-left: 20px;">
                <el-button type="primary"
                           size="mini"
                           @click="searchimgData"
                           style="padding: 6px 10px;">获取截图</el-button>
              </div>
              <div style="float:right;margin-top:5px">
                <el-button type="primary"
                           icon="iconfont icon-jia"
                           size="mini"
                           style="margin: 0 10px 10px 0;padding: 6px 12px;"
                           @click="importweilandata"> 同步蔚蓝数据</el-button>
              </div>
              <div style="float:right;margin-top:5px">
                <el-button type="success"
                           icon="iconfont icon-jia"
                           size="mini"
                           style="margin: 0 10px 10px 0;padding: 6px 12px;"
                           @click="importdata"> 同步泊明数据</el-button>
              </div>
              <div style="float:right;margin-top:5px">
                <el-button type="primary"
                           icon="iconfont icon-jia"
                           size="mini"
                           style="margin: 0 10px 10px 0;padding: 6px 12px;"
                           @click="importorder"> 添加不统计</el-button>
              </div>
            </el-form>
          </el-row>
          <el-row>
            <table align="center"
                   style="width:100%;height:100%;"
                   cellspacing="0"
                   cellpadding="0"
                   border="0"
                   class="xwyc-table">
              <thead>
                <tr style="background-color: #f4f4f5;">
                  <th align="center"
                      style="width: 4%;"><span style="font-weight: bold;">序号</span></th>
                  <th align="center"><span style="font-weight: bold;">品牌</span></th>
                  <th align="center"><span style="font-weight: bold;">今日采购</span></th>
                  <th align="center"><span style="font-weight: bold;">今日工程</span></th>
                  <th align="center"><span style="font-weight: bold;">今日大宗</span></th>
                  <th align="center"><span style="font-weight: bold;">本周</span></th>
                  <th align="center"><span style="font-weight: bold;">本月</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in orderlist"
                    :key="index">
                  <td align="center">{{index+1}}</td>
                  <td align="center">{{item.brand}}</td>
                  <td align="center">{{item.TransferedQuantity}}</td>
                  <td align="center">{{item.TransferedQuantity_project}}</td>
                  <td align="center">{{item.TransferedQuantity_bulk}}</td>
                  <td align="center">{{item.TransferedQuantity_week}}</td>
                  <td align="center">{{item.TransferedQuantity_month}}</td>
                </tr>
                <tr>
                  <td colspan="2"
                      align="center">合计：</td>
                  <td align="center">{{TransferedQuantity_total}}</td>
                  <td align="center">{{TransferedQuantity_project_total}}</td>
                  <td align="center">{{TransferedQuantity_bulk_total}}</td>
                  <td align="center">{{TransferedQuantity_week_total}}</td>
                  <td align="center">{{TransferedQuantity_month_total}}</td>
                </tr>
              </tbody>
            </table>
          </el-row>

        </el-tab-pane>
        <el-tab-pane label="工程客户销售报表"
                     name="second">
          <el-row>
            <el-form :model="form"
                     ref="customer-form">
              <el-form-item label="日期"
                            class="el-form-items">
                <el-date-picker v-model="form.date"
                                value-format="yyyy-MM-dd"
                                type="date"
                                class="input-with-select"
                                style="width: 140px;float: left;">
                </el-date-picker>
              </el-form-item>
              <div style="float: left;margin-top:5px">
                <el-button icon="el-icon-search"
                           type="primary"
                           size="mini"
                           :loading="updateLoading"
                           @click="searchCustomerSalesReport"
                           style="padding: 6px 10px;"></el-button>
              </div>
              <div style="float: left;margin-top:5px; margin-left: 20px;">
                <el-button type="primary"
                           size="mini"
                           @click="searchimgpData"
                           style="padding: 6px 10px;">获取截图</el-button>
              </div>
            </el-form>
          </el-row>
          <el-row>
            <table align="center"
                   style="width:100%;height:100%;"
                   cellspacing="0"
                   cellpadding="0"
                   border="0"
                   class="xwyc-table">
              <thead>
                <tr style="background-color: #f4f4f5;">
                  <th align="center"
                      style="width: 4%;"><span style="font-weight: bold;">序号</span></th>
                  <th align="center"><span style="font-weight: bold;">工程客户</span></th>
                  <th align="center"><span style="font-weight: bold;">工程</span></th>
                  <th align="center"><span style="font-weight: bold;">今日销售</span></th>
                  <th align="center"><span style="font-weight: bold;">本周累计</span></th>
                  <th align="center"><span style="font-weight: bold;">本月累计</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in Customerorderlist"
                    :key="index">
                  <td align="center">{{index+1}}</td>
                  <td align="center">{{item.CustomerShortName}}</td>
                  <td align="center">{{item.ProjectName}}</td>
                  <td align="center">{{item.AuditQuantity}}</td>
                  <td align="center">{{item.AuditQuantity_week}}</td>
                  <td align="center">{{item.AuditQuantity_month}}</td>
                </tr>
                <tr>
                  <td colspan="3"
                      align="center">工程合计：</td>
                  <td align="center">{{AuditQuantity_total}}</td>
                  <td align="center">{{AuditQuantity_week_total}}</td>
                  <td align="center">{{AuditQuantity_month_total}}</td>
                </tr>
                <tr>
                  <td colspan="3"
                      align="center">短账期工程合计：</td>
                  <td align="center">{{ShortAuditQuantity_total}}</td>
                  <td align="center">{{ShortAuditQuantity_week_total}}</td>
                  <td align="center">{{ShortAuditQuantity_month_total}}</td>
                </tr>
                <tr>
                  <td colspan="3"
                      align="center">长账期工程合计：</td>
                  <td align="center">{{longAuditQuantity_total}}</td>
                  <td align="center">{{longAuditQuantity_week_total}}</td>
                  <td align="center">{{longAuditQuantity_month_total}}</td>
                </tr>
              </tbody>
            </table>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="非工程客户销售报表"
                     name="third">
          <el-row>
            <el-form :model="form"
                     ref="customer-form">
              <el-form-item label="日期"
                            class="el-form-items">
                <el-date-picker v-model="form.date"
                                value-format="yyyy-MM-dd"
                                type="date"
                                class="input-with-select"
                                style="width: 140px;float: left;">
                </el-date-picker>
              </el-form-item>
              <div style="float: left;margin-top:5px">
                <el-button icon="el-icon-search"
                           type="primary"
                           size="mini"
                           :loading="updateLoading"
                           @click="searchNonCustomerSalesReport"
                           style="padding: 6px 10px;"></el-button>
              </div>
              <div style="float: left;margin-top:5px; margin-left: 20px;">
                <el-button type="primary"
                           size="mini"
                           @click="searchimgNonData"
                           style="padding: 6px 10px;">获取截图</el-button>
              </div>
            </el-form>
          </el-row>
          <el-row>
            <table align="center"
                   style="width:100%;height:100%;"
                   cellspacing="0"
                   cellpadding="0"
                   border="0"
                   class="xwyc-table">
              <thead>
                <tr style="background-color: #f4f4f5;">
                  <th align="center"
                      style="width: 4%;"><span style="font-weight: bold;">序号</span></th>
                  <th align="center"><span style="font-weight: bold;">非工程客户</span></th>
                  <th align="center"><span style="font-weight: bold;">今日销售</span></th>
                  <th align="center"><span style="font-weight: bold;">本周累计</span></th>
                  <th align="center"><span style="font-weight: bold;">本月累计</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in NonCustomerorderlist"
                    :key="index">
                  <td align="center">{{index+1}}</td>
                  <td align="center">{{item.CustomerShortName}}</td>
                  <td align="center">{{item.AuditQuantity}}</td>
                  <td align="center">{{item.AuditQuantity_week}}</td>
                  <td align="center">{{item.AuditQuantity_month}}</td>
                </tr>
                <tr>
                  <td colspan="2"
                      align="center">合计：</td>
                  <td align="center">{{NonAuditQuantity_total}}</td>
                  <td align="center">{{NonAuditQuantity_week_total}}</td>
                  <td align="center">{{NonAuditQuantity_month_total}}</td>
                </tr>
              </tbody>
            </table>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog title="品牌采购报表截图"
               append-to-body
               width="40%"
               :visible.sync="dialogTableVisibletheory">
      <div class="fr"
           style="margin-bottom: 35px;">
        <el-button type="primary"
                   @click="getImg">保存截图</el-button>
      </div>
      <div ref="mynode"
           class="bgs">
        <div class="titles">品牌采购报表 {{form.date}}</div>
        <table align="center"
               style="width:100%;height:100%;"
               cellspacing="0"
               cellpadding="0"
               border="0"
               class="xwyc-table">
          <thead>
            <tr style="background-color: #f4f4f5;">
              <th align="center"
                  style="width: 8%;"><span style="font-weight: bold;">序号</span></th>
              <th align="center"><span style="font-weight: bold;">品牌</span></th>
              <th align="center"><span style="font-weight: bold;">今日采购</span></th>
              <th align="center"><span style="font-weight: bold;">今日工程</span></th>
              <th align="center"><span style="font-weight: bold;">今日大宗</span></th>
              <th align="center"><span style="font-weight: bold;">本周</span></th>
              <th align="center"><span style="font-weight: bold;">本月</span></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in orderlist"
                :key="index">
              <td align="center">{{index+1}}</td>
              <td align="center">{{item.brand}}</td>
              <td align="center">{{item.TransferedQuantity}}</td>
              <td align="center">{{item.TransferedQuantity_project}}</td>
              <td align="center">{{item.TransferedQuantity_bulk}}</td>
              <td align="center">{{item.TransferedQuantity_week}}</td>
              <td align="center">{{item.TransferedQuantity_month}}</td>
            </tr>
            <tr>
              <td colspan="2"
                  align="center">合计：</td>
              <td align="center">{{TransferedQuantity_total}}</td>
              <td align="center">{{TransferedQuantity_project_total}}</td>
              <td align="center">{{TransferedQuantity_bulk_total}}</td>
              <td align="center">{{TransferedQuantity_week_total}}</td>
              <td align="center">{{TransferedQuantity_month_total}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>

    <el-dialog title="工程客户销售报表截图"
               append-to-body
               width="40%"
               :visible.sync="dialogTableVisiblethp">
      <div class="fr"
           style="margin-bottom: 35px;">
        <el-button type="primary"
                   @click="getImg">保存截图</el-button>
      </div>
      <div ref="mynode"
           class="bgs">
        <div class="titles">工程客户销售报表 {{form.date}}</div>
        <table align="center"
               style="width:100%;height:100%;"
               cellspacing="0"
               cellpadding="0"
               border="0"
               class="xwyc-table">
          <thead>
            <tr style="background-color: #f4f4f5;">
              <th align="center"
                  style="width: 4%;"><span style="font-weight: bold;">序号</span></th>
              <th align="center"><span style="font-weight: bold;">工程客户</span></th>
              <th align="center"><span style="font-weight: bold;">工程</span></th>
              <th align="center"><span style="font-weight: bold;">今日销售</span></th>
              <th align="center"><span style="font-weight: bold;">本周累计</span></th>
              <th align="center"><span style="font-weight: bold;">本月累计</span></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in Customerorderlist"
                :key="index">
              <td align="center">{{index+1}}</td>
              <td align="center">{{item.CustomerShortName}}</td>
              <td align="center">{{item.ProjectName}}</td>
              <td align="center">{{item.AuditQuantity}}</td>
              <td align="center">{{item.AuditQuantity_week}}</td>
              <td align="center">{{item.AuditQuantity_month}}</td>
            </tr>
            <tr>
              <td colspan="3"
                  align="center">工程合计：</td>
              <td align="center">{{AuditQuantity_total}}</td>
              <td align="center">{{AuditQuantity_week_total}}</td>
              <td align="center">{{AuditQuantity_month_total}}</td>
            </tr>
            <tr>
              <td colspan="3"
                  align="center">短账期工程合计：</td>
              <td align="center">{{ShortAuditQuantity_total}}</td>
              <td align="center">{{ShortAuditQuantity_week_total}}</td>
              <td align="center">{{ShortAuditQuantity_month_total}}</td>
            </tr>
            <tr>
              <td colspan="3"
                  align="center">长账期工程合计：</td>
              <td align="center">{{longAuditQuantity_total}}</td>
              <td align="center">{{longAuditQuantity_week_total}}</td>
              <td align="center">{{longAuditQuantity_month_total}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>

    <el-dialog title="非工程客户销售报表截图"
               append-to-body
               width="40%"
               :visible.sync="dialogTableVisiblethnon">
      <div class="fr"
           style="margin-bottom: 35px;">
        <el-button type="primary"
                   @click="getImg">保存截图</el-button>
      </div>
      <div ref="mynode"
           class="bgs">
        <div class="titles">非工程客户销售报表 {{form.date}}</div>
        <table align="center"
               style="width:100%;height:100%;"
               cellspacing="0"
               cellpadding="0"
               border="0"
               class="xwyc-table">
          <thead>
            <tr style="background-color: #f4f4f5;">
              <th align="center"
                  style="width: 4%;"><span style="font-weight: bold;">序号</span></th>
              <th align="center"><span style="font-weight: bold;">非工程客户</span></th>
              <th align="center"><span style="font-weight: bold;">今日销售</span></th>
              <th align="center"><span style="font-weight: bold;">本周累计</span></th>
              <th align="center"><span style="font-weight: bold;">本月累计</span></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in NonCustomerorderlist"
                :key="index">
              <td align="center">{{index+1}}</td>
              <td align="center">{{item.CustomerShortName}}</td>
              <td align="center">{{item.AuditQuantity}}</td>
              <td align="center">{{item.AuditQuantity_week}}</td>
              <td align="center">{{item.AuditQuantity_month}}</td>
            </tr>
            <tr>
              <td colspan="2"
                  align="center">合计：</td>
              <td align="center">{{NonAuditQuantity_total}}</td>
              <td align="center">{{NonAuditQuantity_week_total}}</td>
              <td align="center">{{NonAuditQuantity_month_total}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
    <el-dialog title="不统计客户"
               :visible.sync="dialogVisibles"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formorder"
               ref="salesman-form">
        <el-form-item label="客户名"
                      prop="CustomerShortName">
          <el-select v-model="formorder.CustomerShortName"
                     filterable
                     clearable
                     multiple
                     placeholder="请选择客户名"
                     class="input-with-select"
                     style="width: 300px;">
            <el-option v-for="(item,index) in customerenumlist"
                       :key="index"
                       :label="item.sn"
                       :value="item.sn">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="margin-left: 50px; margin-top: 20px;">
        <el-button type="primary"
                   @click="importFile()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="同步泊明数据"
               :visible.sync="dialogVisiblesdata"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formdata"
               ref="salesman-form">
        <el-form-item label="类型">
          <el-select v-model="formdata.type"
                     filterable
                     clearable
                     placeholder="请选择客户名"
                     class="input-with-select"
                     style="width: 200px;">
            <el-option v-for="(item,index) in typelist"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单日期"
                      v-if="formdata.type==1">
          <el-date-picker v-model="formdata.date"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 200px;float: left;">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="多日期"
                      v-else-if="formdata.type==2">
          <el-date-picker v-model="formdata.dates"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="margin-left: 50px; margin-top: 20px;">
        <el-button type="primary"
                   :loading="dataLoading"
                   @click="subdata()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="同步蔚蓝数据"
               :visible.sync="dialogVisiblesweilandata"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formdata"
               ref="salesman-form">
        <el-form-item label="类型">
          <el-select v-model="formdata.type"
                     filterable
                     clearable
                     placeholder="请选择客户名"
                     class="input-with-select"
                     style="width: 200px;">
            <el-option v-for="(item,index) in typelist"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单日期"
                      v-if="formdata.type==1">
          <el-date-picker v-model="formdata.date"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 200px;float: left;">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="多日期"
                      v-else-if="formdata.type==2">
          <el-date-picker v-model="formdata.dates"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="margin-left: 50px; margin-top: 20px;">
        <el-button type="primary"
                   :loading="dataLoading"
                   @click="subweilandata()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getBrandPurchaseList, getCustomerSalesReport, getNonCustomerSalesReport, CustomerEnumList, addPurchaseSellListIsolation, getPurchaseSellListIsolation, getPurchaseSellContacts, getWeiLanPurchaseSellContacts } from '@/api/global'
import globalBus from '@/utils/global-bus'
import domtoimage from 'dom-to-image'
export default {
  name: 'ReportformsIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dataLoading: false,
      dialogVisibles: false,
      dialogVisiblesweilandata: false,
      dialogVisiblesdata: false,
      dialogTableVisibletheory: false,
      dialogTableVisiblethp: false,
      dialogTableVisiblethnon: false,
      orderlist: [],
      TransferedQuantity_total: null,
      TransferedQuantity_project_total: null,
      TransferedQuantity_business_total: null,
      TransferedQuantity_bulk_total: null,
      TransferedQuantity_week_total: null,
      TransferedQuantity_month_total: null,
      form: {
        date: ''
      },
      formdata: {
        date: '',
        type: 1,
        dates: []
      },
      typelist: [{ id: 1, name: '单日期' }, { id: 2, name: '多日期' }],
      Customerorderlist: [],
      AuditQuantity_week_total: null,
      AuditQuantity_month_total: null,
      AuditQuantity_total: null,
      ShortAuditQuantity_total: null,
      longAuditQuantity_total: null,
      ShortAuditQuantity_week_total: null,
      longAuditQuantity_week_total: null,
      ShortAuditQuantity_month_total: null,
      longAuditQuantity_month_total: null,
      activeName: 'first',
      NonCustomerorderlist: [],
      NonAuditQuantity_total: null,
      NonAuditQuantity_week_total: null,
      NonAuditQuantity_month_total: null,
      filename: null,
      customerenumlist: [],
      formorder: {
        CustomerShortName: null
      },
      pickerOptions: {
        disabledDate (time) {
          const curDate = (new Date()).getTime()
          const three = 36 * 30 * 24 * 3600 * 1000
          const threeMonths = curDate - three
          return time.getTime() > Date.now() || time.getTime() < threeMonths
        }
      }
    }
  },
  created () {
    this.loadnav()
    this.loadgetdate()
    this.loadgetCustomerEnumList()
    this.loadgetPurchaseSellListIsolation()
    this.search()
    this.searchCustomerSalesReport()
    this.searchNonCustomerSalesReport()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'reportforms') {
        this.topname = '数据分析'
        this.navnames = '报表分析'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetdate () {
      this.form.date = this.getDay(0)
      this.formdata.date = this.getDay(0)
      this.formdata.dates[0] = this.getDay(-7)
      this.formdata.dates[1] = this.getDay(0)
    },
    loadgetPurchaseSellListIsolation () {
      getPurchaseSellListIsolation().then(res => {
        if (res.data.status === 'success') {
          this.formorder.CustomerShortName = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetCustomerEnumList () {
      CustomerEnumList().then(res => {
        if (res.data.status === 'success') {
          this.customerenumlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    importorder () {
      this.dialogVisibles = true
    },
    importFile () {
      addPurchaseSellListIsolation({ data: this.formorder.CustomerShortName }).then(res => {
        if (res.data.status === 'success') {
          this.dialogVisibles = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    importdata () {
      this.dialogVisiblesdata = true
    },
    subdata () {
      this.dataLoading = true
      const loading = this.$loading({
        lock: true,
        text: '正在同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      getPurchaseSellContacts({ date: this.formdata.date, dates: this.formdata.dates, type: this.formdata.type }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          loading.close()
          this.search()
          this.searchCustomerSalesReport()
          this.searchNonCustomerSalesReport()
          this.dataLoading = false
          this.dialogVisiblesdata = false
        } else {
          loading.close()
          this.$message.error(res.data.message)
          this.dataLoading = false
        }
      }).catch(err => {
        loading.close()
        this.dataLoading = false
        console.log('保存失败', err)
      })
    },
    importweilandata () {
      this.dialogVisiblesweilandata = true
    },
    subweilandata () {
      this.dataLoading = true
      const loading = this.$loading({
        lock: true,
        text: '正在同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      getWeiLanPurchaseSellContacts({ date: this.formdata.date, dates: this.formdata.dates, type: this.formdata.type }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          loading.close()
          this.search()
          this.searchCustomerSalesReport()
          this.searchNonCustomerSalesReport()
          this.dataLoading = false
          this.dialogVisiblesweilandata = false
        } else {
          loading.close()
          this.$message.error(res.data.message)
          this.dataLoading = false
        }
      }).catch(err => {
        loading.close()
        this.dataLoading = false
        console.log('保存失败', err)
      })
    },
    search () {
      this.updateLoading = true
      this.loading = true
      getBrandPurchaseList({ date: this.form.date }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.TransferedQuantity_total = res.data.TransferedQuantity_total
          this.TransferedQuantity_project_total = res.data.TransferedQuantity_project_total
          this.TransferedQuantity_business_total = res.data.TransferedQuantity_business_total
          this.TransferedQuantity_bulk_total = res.data.TransferedQuantity_bulk_total
          this.TransferedQuantity_week_total = res.data.TransferedQuantity_week_total
          this.TransferedQuantity_month_total = res.data.TransferedQuantity_month_total
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    searchCustomerSalesReport () {
      this.updateLoading = true
      this.loading = true
      getCustomerSalesReport({ date: this.form.date }).then(res => {
        if (res.data.status === 'success') {
          this.Customerorderlist = res.data.data
          this.AuditQuantity_week_total = res.data.AuditQuantity_week_total
          this.AuditQuantity_month_total = res.data.AuditQuantity_month_total
          this.AuditQuantity_total = res.data.AuditQuantity_total
          this.ShortAuditQuantity_total = res.data.ShortAuditQuantity_total
          this.longAuditQuantity_total = res.data.longAuditQuantity_total
          this.ShortAuditQuantity_week_total = res.data.ShortAuditQuantity_week_total
          this.longAuditQuantity_week_total = res.data.longAuditQuantity_week_total
          this.ShortAuditQuantity_month_total = res.data.ShortAuditQuantity_month_total
          this.longAuditQuantity_month_total = res.data.longAuditQuantity_month_total
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    searchNonCustomerSalesReport () {
      this.updateLoading = true
      this.loading = true
      getNonCustomerSalesReport({ date: this.form.date }).then(res => {
        if (res.data.status === 'success') {
          this.NonCustomerorderlist = res.data.data
          this.NonAuditQuantity_week_total = res.data.AuditQuantity_week_total
          this.NonAuditQuantity_month_total = res.data.AuditQuantity_month_total
          this.NonAuditQuantity_total = res.data.AuditQuantity_total
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    searchimgData () {
      this.filename = '-brand-procure.png'
      this.dialogTableVisibletheory = true
    },
    searchimgpData () {
      this.filename = '-engineering-customer.png'
      this.dialogTableVisiblethp = true
    },
    searchimgNonData () {
      this.filename = '-no-engineering-customer.png'
      this.dialogTableVisiblethnon = true
    },
    handleClick () { },
    // 获取截图
    getImg () {
      const loading = this.$loading({
        lock: true,
        text: '正在截图中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      var node = this.$refs.mynode
      var seft = this
      domtoimage.toPng(node).then(function (dataUrl) {
        var img = new Image()
        img.src = dataUrl
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = seft.form.date + seft.filename || '下载图片名称'
        a.href = dataUrl
        a.dispatchEvent(event)
        loading.close()
      }).catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
    },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay (day) {
      var today = new Date()
      var targetdaymilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targetdaymilliseconds)
      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth (month) {
      var m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 18px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  width: 70px;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 14px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 14px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 3px 0;
}
/deep/.el-table th {
  padding: 3px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #ebeef5;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
  height: 25px;
  line-height: 25px;
}
.xwyc-table {
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 14px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
.fr {
  float: right;
}
.bgs {
  clear: both;
  background-color: #ffffff;
}
.bgs .titles {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: #000;
  padding-bottom: 20px;
}
</style>
