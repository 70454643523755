<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover">
      <div class="addbutton">
        <el-button type="primary"
                   size="small"
                   icon="el-icon-plus"
                   @click="dialogFormVisible = true">新增客户项目</el-button>
      </div>
      <el-table :data="projectdata.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 180px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading">
        <el-table-column label="序号"
                         type="index"
                         align="center"
                         width="50">
        </el-table-column>
        <el-table-column label="客户名称"
                         prop="name"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="项目名"
                         prop="project_name"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="业主单位"
                         prop="owner_unit"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="项目地址"
                         prop="addr"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货人"
                         prop="consignee"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="联系方式"
                         prop="tel"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="使用品牌"
                         prop="brand"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="计量方式"
                         prop="metering_mode"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货要求"
                         prop="demand"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="结算工期"
                         prop="settlement"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="合同量"
                         prop="contract_number"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="备注"
                         prop="rank"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="right"
                         width="180">
          <template slot="header">
            <el-input v-model="search"
                      size="mini"
                      placeholder="输入关键字搜索" />
          </template>
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="新增客户项目"
                 :visible.sync="dialogFormVisible"
                 append-to-body>
        <el-form ref="projectinfo-form"
                 :model="projectinfo"
                 label-width="120px"
                 :rules="formRules">
          <el-form-item label="客户名称"
                        prop="nid">
            <el-select v-model="projectinfo.nid"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in customerdata"
                         :key="item.ide"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名"
                        prop="project_name">
            <el-input v-model="projectinfo.project_name"></el-input>
          </el-form-item>
          <el-form-item label="业主单位"
                        prop="owner_unit">
            <el-input v-model="projectinfo.owner_unit"></el-input>
          </el-form-item>
          <el-form-item label="项目地址"
                        prop="addr">
            <el-input v-model="projectinfo.addr"></el-input>
          </el-form-item>
          <el-form-item label="收货人"
                        prop="consignee"
                        class="el-form-items">
            <el-input v-model="projectinfo.consignee"></el-input>
          </el-form-item>
          <el-form-item label="联系方式"
                        prop="tel"
                        class="el-form-items">
            <el-input v-model="projectinfo.tel"></el-input>
          </el-form-item>
          <el-form-item label="使用品牌"
                        prop="brand"
                        class="el-form-itemss">
            <el-select v-model="projectinfo.brand"
                       multiple
                       filterable
                       remote
                       placeholder="请输入品牌"
                       :loading="loading"
                       style="width:100%">
              <el-option v-for="item in brandlist"
                         :key="item.id"
                         :label="item.brand"
                         :value="item.brand">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计量方式"
                        prop="metering_mode">
            <el-input v-model="projectinfo.metering_mode"></el-input>
          </el-form-item>
          <el-form-item label="收货要求"
                        prop="demand">
            <el-input v-model="projectinfo.demand"></el-input>
          </el-form-item>
          <el-form-item label="结算工期"
                        prop="settlement">
            <el-input v-model="projectinfo.settlement"></el-input>
          </el-form-item>
          <el-form-item label="建设工期"
                        prop="construction_period">
            <el-input v-model="projectinfo.construction_period"></el-input>
          </el-form-item>
          <el-form-item label="施工状态"
                        prop="status">
            <el-select v-model="projectinfo.status"
                       placeholder="请选择">
              <el-option v-for="item in statusoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同量"
                        prop="contract_number">
            <el-input v-model="projectinfo.contract_number"></el-input>
          </el-form-item>
          <el-form-item label="备注"
                        prop="rank">
            <el-input v-model="projectinfo.rank"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateProject">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改客户项目"
                 :visible.sync="dialogFormVisibles"
                 append-to-body>
        <el-form ref="projectinfos-form"
                 :model="projectinfos"
                 label-width="120px"
                 :rules="formRules">
          <el-form-item label="客户名称"
                        prop="nid">
            <el-select v-model="projectinfos.nid"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in customerdata"
                         :key="item.ide"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名"
                        prop="project_name">
            <el-input v-model="projectinfos.project_name"></el-input>
          </el-form-item>
          <el-form-item label="业主单位"
                        prop="owner_unit">
            <el-input v-model="projectinfos.owner_unit"></el-input>
          </el-form-item>
          <el-form-item label="项目地址"
                        prop="addr">
            <el-input v-model="projectinfos.addr"></el-input>
          </el-form-item>
          <el-form-item label="收货人"
                        prop="consignee"
                        class="el-form-items">
            <el-input v-model="projectinfos.consignee"></el-input>
          </el-form-item>
          <el-form-item label="联系方式"
                        prop="tel"
                        class="el-form-items">
            <el-input v-model="projectinfos.tel"></el-input>
          </el-form-item>
          <el-form-item label="使用品牌"
                        prop="brand"
                        class="el-form-itemss">
            <el-select v-model="projectinfos.brand"
                       multiple
                       filterable
                       remote
                       placeholder="请输入品牌"
                       :loading="loading"
                       style="width:100%">
              <el-option v-for="item in brandlist"
                         :key="item.id"
                         :label="item.brand"
                         :value="item.brand">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计量方式"
                        prop="metering_mode">
            <el-input v-model="projectinfos.metering_mode"></el-input>
          </el-form-item>
          <el-form-item label="收货要求"
                        prop="demand">
            <el-input v-model="projectinfos.demand"></el-input>
          </el-form-item>
          <el-form-item label="结算周期"
                        prop="settlement">
            <el-input v-model="projectinfos.settlement"></el-input>
          </el-form-item>
          <el-form-item label="建设工期"
                        prop="construction_period">
            <el-input v-model="projectinfos.construction_period"></el-input>
          </el-form-item>
          <el-form-item label="施工状态"
                        prop="status">
            <el-select v-model="projectinfos.status"
                       placeholder="请选择">
              <el-option v-for="item in statusoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同量"
                        prop="contract_number">
            <el-input v-model="projectinfos.contract_number"></el-input>
          </el-form-item>
          <el-form-item label="备注"
                        prop="rank">
            <el-input v-model="projectinfos.rank"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateProjects">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import { getProjectList, addNewsProject, getEditProject, upDataProject, getDelProject, getCustomerList } from '@/api/global'
import globalBus from '@/utils/global-bus'
import axios from 'axios'

export default {
  name: 'ProjectIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      projectinfo: {
        id: '',
        nid: '',
        project_name: '',
        owner_unit: '',
        addr: '',
        consignee: '',
        tel: '',
        brand: '',
        metering_mode: '',
        demand: '',
        settlement: '',
        contract_number: '',
        rank: ''
      },
      updatePhotoLoading: false, // 更新用户头像 loading 状态
      updateProfileLoading: false, // 更新基本信息的 loading 状态
      formRules: {
        nid: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ]
      },
      brandlist: [],
      projectdata: [],
      dialogFormVisible: false,
      dialogFormVisibles: false,
      projectinfos: {
        id: '',
        nid: '',
        project_name: '',
        owner_unit: '',
        addr: '',
        consignee: '',
        tel: '',
        brand: '',
        metering_mode: '',
        demand: '',
        settlement: '',
        contract_number: '',
        rank: ''
      },
      formRuless: {
        nid: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ]
      },
      statusoptions: [{
        value: 1,
        label: '未完工'
      }, {
        value: 2,
        label: '已完工'
      }],
      search: '',
      customerdata: []
    }
  },
  created () {
    this.loadnav()
    this.loadbrand()
    this.loadcustomerList()
    this.loadprojectList()
  },
  mounted () {
  },
  methods: {
    loadbrand () {
      const _self = this
      axios.post('https://api.66gangtie.com/api/bmGetBrand').then(function (response) {
        if (response.data.status === 'success') {
          _self.brandlist = response.data.data
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'project') {
        this.topname = '客商管理'
        this.navnames = '客户项目'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadprojectList () {
      getProjectList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.projectdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    loadcustomerList () {
      getCustomerList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.customerdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    onUpdateProject () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['projectinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addNewsProject(this.projectinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadprojectList()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateProjects () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['projectinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataProject(this.projectinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisibles = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadprojectList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    handleEdit (row) {
      getEditProject({ id: row.id }).then(res => {
        this.dialogFormVisibles = true
        this.projectinfos = res.data.data
        this.projectinfos.brand = res.data.brand
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？' + row.name, '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDelProject({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadprojectList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    selectedbands () { },
    filterTag (value, row) {
      return row.deptname === value
    },
    filterTags (value, row) {
      return row.mername === value
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
.el-form-itemss {
  clear: both;
}
</style>
