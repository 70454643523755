<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover"
             style="margin-top:10px;">
      <el-table ref="multipleTable"
                :data="contractdata"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 160px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading"
                @selection-change="handleSelectionChange"
                @select="handleSelect"
                @select-all="handleSelectAll"
                :row-key="getRowKeys">
        <el-table-column type="selection"
                         width="48"
                         :reserve-selection="true">
        </el-table-column>
        <el-table-column label="序号"
                         type="index"
                         align="center"
                         width="50">
        </el-table-column>
        <el-table-column label="管理序号"
                         prop="ordernumber"
                         align="center"
                         width="120">
        </el-table-column>
        <el-table-column label="我方抬头"
                         prop="our_title"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="对方抬头"
                         prop="other_title"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="合同编号"
                         prop="number"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="合同类型"
                         prop="type"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="工程名"
                         prop="project"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="到期时间"
                         prop="expire_time"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="effective_status"
                         label="生效状态"
                         align="center"
                         width="100px">
          <template slot-scope="scope">
            <div v-if="scope.row.effective_status==='生效中'"><span class="active-column">{{scope.row.effective_status}}</span> </div>
            <div v-else-if="scope.row.effective_status==='顺延中'"><span class="active-column3">{{scope.row.effective_status}}</span> </div>
            <div v-else-if="scope.row.effective_status==='未生效'"><span class="active-column3">{{scope.row.effective_status}}</span> </div>
            <div v-else><span class="active-column2">{{scope.row.effective_status}}</span> </div>
          </template>
        </el-table-column>
        <el-table-column label="合同负责人"
                         prop="responsible"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column label="备注"
                         prop="rank"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column label="扫描件"
                         prop="url"
                         align="center"
                         width="90"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="primary"
                       plain
                       @click="loadingPdf(scope.row)"
                       icon="el-icon-view"
                       v-if="scope.row.url!==null">查看</el-button>
            <el-button type="info"
                       size="mini"
                       plain
                       icon="el-icon-view"
                       v-else>查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="其他附件"
                         prop="enclosure"
                         align="center"
                         width="90"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="success"
                       plain
                       @click="enclosuresee(scope.row)"
                       icon="el-icon-view"
                       v-if="scope.row.otherfile>0">查看</el-button>
            <el-button type="info"
                       size="mini"
                       plain
                       icon="el-icon-view"
                       v-else>查看</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         label="操作"
                         width="230">
          <template slot-scope="scope">
            <el-button size="mini"
                       plain
                       type="success"
                       @click="handleWords(scope.row)"
                       icon="el-icon-view">浏览</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;"
           v-show="total!=0">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-sizes="[21,30, 50, 100]"
                       :page-size="21"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :title="title"
               append-to-body
               :visible.sync="dialogenclosureVisible">
      <el-table :data="enclosureData"
                v-loading="enclosureloading">
        <el-table-column property="name"
                         label="名称">
        </el-table-column>
        <el-table-column property="url"
                         label="操作">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="primary"
                       plain
                       @click="loadingPdf(scope.row)"
                       icon="el-icon-view">在线浏览</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="详细信息"
               :visible.sync="dialogForminfo"
               append-to-body
               top="10px"
               width="60%">
      <div class="demo-block">
        <el-row>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">我方抬头：</span>{{contractinfos.our_title}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">对方抬头：</span>{{contractinfos.other_title}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">合同名称：</span>{{contractinfos.name}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">工程名：</span>{{contractinfos.project}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">合同编号：</span>{{contractinfos.number}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">合同负责人：</span>{{contractinfos.responsible}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">合同类型：</span>{{contractinfos.type}}</div>
          </el-col>

          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">签订时间：</span>{{contractinfos.signing_time}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">到期时间：</span>{{contractinfos.expire_time}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">顺延期限：</span>{{contractinfos.postpone_term}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">合同份数：</span>{{contractinfos.contract_number}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">是否原件：</span>{{contractinfos.original}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">具体账期：</span>{{contractinfos.specificperiod}}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"><span class="grid-content-text">形式要件：</span>{{contractinfos.document}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="grid-content"><span class="grid-content-text">备注：</span>{{contractinfos.rank}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="grid-content"><span class="grid-content-text">扫描件：</span>
              <el-table :data="scanning"
                        v-loading="enclosureloading">
                <el-table-column property="name"
                                 label="名称">
                </el-table-column>
                <el-table-column property="url"
                                 label="操作"
                                 :width="180">
                  <template slot-scope="scope">
                    <el-button size="mini"
                               type="primary"
                               plain
                               @click="loadingPdf(scope.row)"
                               icon="el-icon-view">在线浏览</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="grid-content"><span class="grid-content-text">其他附件：</span>
              <el-table :data="enclosure"
                        v-loading="enclosureloading">
                <el-table-column property="name"
                                 label="名称">
                </el-table-column>
                <el-table-column property="url"
                                 label="操作"
                                 :width="180">
                  <template slot-scope="scope">
                    <el-button size="mini"
                               type="primary"
                               plain
                               @click="loadingPdf(scope.row)"
                               icon="el-icon-view">在线浏览</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getTimeOffCustomer, getEditContract, getDelContract, delContractFile, getEnclosureList, searchContract } from '@/api/global'
import globalBus from '@/utils/global-bus'
const Base64 = require('js-base64').Base64

export default {
  name: 'ContractIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      enclosureloading: true,
      currentPage: 1,
      total: 0,
      perpage: 21,
      submissiondate: '',
      updatePhotoLoading: false, // 更新用户头像 loading 状态
      updateProfileLoading: false, // 更新基本信息的 loading 状态
      contractdata: [],
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogenclosureVisible: false,
      dialogForminfo: false,
      title: '',
      enclosureData: [],
      search: '',
      customerdata: [],
      enclosure: [],
      scanning: [],
      enclosurea: [],
      scanninga: [],
      contracttypeData: [],
      contracttypesData: [],
      contractlistData: [],
      dialogFormTemplate: false,
      dialogVisiblesubmission: false,
      contractinfos: {
        id: '',
        our_title: '',
        other_title: '',
        name: '',
        project: '',
        number: '',
        signing_time: '',
        expire_time: '',
        postpone_term: '',
        responsible: '',
        settlement_clause: '',
        overdue_clause: '',
        quota: '',
        document: '',
        type: '',
        contract_number: '',
        original: '',
        specificperiod: '',
        confirm_submission: '',
        rank: '',
        otherfile: ''
      }
    }
  },
  created () {
    this.loadnav()
    this.loadcontractList()
  },
  mounted () {
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'contractexpiration') {
        this.topname = '合同管理'
        this.navnames = '合同过期'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadcontractList () {
      getTimeOffCustomer({ page: 1, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.currentPage = res.data.data.page
          this.total = res.data.data.total
          this.contractdata = res.data.data.page_items
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleSelectionChange (val) {
      // this.form.id = []
      // this.contractlistData = []
      this.contractlistData = val
      // for (var i = 0; i < val.length; i++) {
      // this.form.id[i] = val[i].ID
      // }
    },
    // 手动点击勾选框触发的事件
    handleSelect (selection, row) {
      // selection,row  传递两个参数，row直接是对象
      if (row.className === 'normal') {
        row.className = 'tableSelectedRowBgColor'
      } else {
        row.className = 'normal'
      }
    },

    // select-all 手动点击全选触发的事件
    handleSelectAll (selection) {
      if (selection.length > 0) {
        selection.forEach((item) => {
          if (item.className === 'normal') {
            item.className = 'tableSelectedRowBgColor'
          }
        })
      } else {
        // 空数组初始化className
        this.contractdata.forEach((item) => {
          if (item.className === 'tableSelectedRowBgColor') {
            item.className = 'normal'
          }
        })
      }
    },
    // 选中背景色
    tableRowClassNamesss ({ row, rowIndex }) {
      return row.className
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    // 点击行触发，切换复选框状态
    handleRowClick (row, column, event) {
      if (row.className === 'normal') {
        row.className = 'tableSelectedRowBgColor'
        this.$refs.multipleTable.toggleRowSelection(row, true)
      } else {
        row.className = 'normal'
        this.$refs.multipleTable.toggleRowSelection(row, false)
      }
    },
    getRowKeys (row) {
      return row.id
    },
    handleEdit (row) {
      getEditContract({
        id: row.id
      }).then(res => {
        this.dialogFormVisibles = true
        this.contractinfos = res.data.data
        this.scanning = res.data.scanning
        this.enclosure = res.data.enclosure
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？' + row.name, '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDelContract({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadcontractList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleWords (row) {
      getEditContract({
        id: row.id
      }).then(res => {
        this.enclosureloading = false
        this.dialogForminfo = true
        this.contractinfos = res.data.data
        this.scanning = res.data.scanning
        this.enclosure = res.data.enclosure
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleWord (row) {
      /* this.$router.push({
        path: '/contractword',
        name: 'contractword',
        params: {
          url: row.wordurl,
          name: row.signing_time + row.name
        }
      }) */
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + row.wordurl, '_blank')
    },
    filterTag (value, row) {
      return row.deptname === value
    },
    filterTags (value, row) {
      return row.mername === value
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.enclosure = this.enclosure.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    handleRemove (file) {
      this.enclosure.some((item, i) => {
        if (item.url === file.url) {
          this.enclosure.splice(i, 1)
          delContractFile({ uid: file.uid, sid: 1 }).then(res => {
            if (res.data.status === 'success') {
              this.$message({
                message: res.data.message,
                type: 'success'
              })
            } else {
              this.$message.error('删除成功')
            }
          }).catch(err => {
            console.log('删除失败', err)
          })
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    scanningsuccess (res) {
      if (res.status === 'success') {
        this.scanning = this.scanning.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    scanningRemove (file) {
      this.scanning.some((item, i) => {
        if (item.url === file.url) {
          this.scanning.splice(i, 1)
          delContractFile({ uid: file.uid, sid: 2 }).then(res => {
            if (res.data.status === 'success') {
              this.$message({
                message: res.data.message,
                type: 'success'
              })
            } else {
              this.$message.error('删除成功')
            }
          }).catch(err => {
            console.log('删除失败', err)
          })
          return true
        }
      })
    },
    fileasuccess (res) {
      if (res.status === 'success') {
        this.enclosurea = this.enclosurea.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    handleaRemove (file) {
      this.enclosurea.some((item, i) => {
        if (item.url === file.url) {
          this.enclosurea.splice(i, 1)
          return true
        }
      })
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    scanningasuccess (res) {
      if (res.status === 'success') {
        this.scanninga = this.scanninga.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    scanningaRemove (file) {
      this.scanninga.some((item, i) => {
        if (item.url === file.url) {
          this.scanninga.splice(i, 1)
          return true
        }
      })
    },
    beforeUpload (file) {
      /* var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'pdf'
      if (!extension) {
        this.$message({
          message: '上传文件只能是pdf格式!',
          type: 'warning'
        })
        return false
      }
      return extension */
    },
    enclosuresee (row) {
      this.enclosureloading = true
      this.dialogenclosureVisible = true
      this.title = row.name + '的其他附件'
      getEnclosureList({ id: row.id, status: 2 }).then(res => {
        if (res.data.status === 'success') {
          this.enclosureloading = false
          this.enclosureData = res.data.data
        } else {
          this.enclosureloading = false
        }
      }).catch(err => {
        this.enclosureloading = false
        console.log('修改失败', err)
      })
    },
    loadingPdf (row) {
      if (!row.url && typeof (row.url) !== 'undefined' && row.url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        window.open('https://file.gangerp.com/onlinePreview?url=' + Base64.encode(row.url), '_blank')
      }
    },
    fileasuccessa (res) {
      if (res.status === 'success') {
        this.guarantee = this.guarantee.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessaRemove (file) {
      this.guarantee.some((item, i) => {
        if (item.url === file.url) {
          this.guarantee.splice(i, 1)
          return true
        }
      })
    },
    filterTagstatus (value, row) {
      return row.effective_status === value
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      if (this.issearch === 1) {
        searchContract({ data: this.contractsearchform, per: this.perpage }).then(res => {
          if (res.data.status === 'success') {
            this.contractdata = res.data.data.page_items
            this.currentPage = res.data.data.page
            this.total = res.data.data.total
            this.loading = false
          } else {
            this.$message.error(res.data.message)
            this.loading = false
          }
        }).catch(err => {
          this.loading = false
          console.log('保存失败', err)
        })
      } else {
        getTimeOffCustomer({ per: this.perpage }).then(res => {
          if (res.data.status === 'success') {
            this.loading = false
            this.currentPage = res.data.data.page
            this.total = res.data.data.total
            this.contractdata = res.data.data.page_items
          }
        }).catch(err => {
          this.loading = false
          console.log('保存失败', err)
        })
      }
    },
    querySearch (queryString, cb) {
      var restaurants = this.oppAccountNameData
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleCurrentChange (val) {
      this.loading = true
      if (this.issearch === 1) {
        searchContract({ data: this.contractsearchform, page: val, per: this.perpage }).then(res => {
          if (res.data.status === 'success') {
            this.contractdata = res.data.data.page_items
            this.currentPage = res.data.data.page
            this.total = res.data.data.total
            this.loading = false
          } else {
            this.$message.error(res.data.message)
            this.loading = false
          }
        }).catch(err => {
          this.loading = false
          console.log('保存失败', err)
        })
      } else {
        getTimeOffCustomer({ page: val, per: this.perpage }).then(res => {
          if (res.data.status === 'success') {
            this.loading = false
            this.currentPage = res.data.data.page
            this.total = res.data.data.total
            this.contractdata = res.data.data.page_items
          }
        }).catch(err => {
          this.loading = false
          console.log('保存失败', err)
        })
      }
    },
    contracttypea (val) {
      var date = new Date()
      var year = date.getFullYear()
      if (val === '终端') {
        this.contractinfo.postpone_term = '2099-12-31'
        this.contractinfo.expire_time = '2099-12-31'
      } else {
        this.contractinfo.postpone_term = year + 2 + '-12-31'
        this.contractinfo.expire_time = year + 1 + '-12-31'
      }
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.active-column {
  color: #06b42c;
  font-weight: 600;
}
.active-column2 {
  color: #d30c0c;
  font-weight: 600;
}
.active-column3 {
  color: #e6a23c;
  font-weight: 600;
}
/deep/ .tableSelectedRowBgColor td {
  background-color: rgb(253, 246, 236) !important;
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
.grid-content {
  font-size: 14px;
  padding: 10px 0px;
}
.demo-block {
  width: 90%;
  transition: 0.2s;
  margin: 0 auto;
}
.grid-content-text {
  font-weight: 600;
}
/deep/.el-card__body {
  padding: 10px 10px 10px 10px;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
.el-form-itemss {
  clear: both;
}
.el-form-itemsa {
  margin-bottom: 0px;
}
/deep/ .el-button--mini,
.el-button--mini.is-round {
  padding: 3px 10px;
}
/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #606266;
  cursor: not-allowed;
  border-bottom-color: #e4e7ed;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #606266;
  cursor: not-allowed;
  border-bottom-color: #e4e7ed;
}
.el-form-items {
  margin-bottom: 5px;
}
/deep/ .el-form-items .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  border-top-color: rgb(220, 223, 230);
  border-right-color: rgb(220, 223, 230);
  border-bottom-color: rgb(220, 223, 230);
  border-left-color: rgb(220, 223, 230);
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 35px;
  line-height: 35px;
  outline: 0;
  padding: 0 15px;
  padding-right: 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .el-form-items .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.search {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.clear {
  clear: both;
}
/deep/.el-table th {
  padding: 5px 0;
}
/deep/.el-table td {
  padding: 5px 0;
  font-size: 12px;
}
</style>
