<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <el-form :model="form"
                 ref="customer-form">
          <el-form-item label="单号"
                        class="el-form-items">
            <el-input placeholder="请输入销售单号"
                      v-model="form.number"
                      clearable
                      class="input-with-select"
                      style="width: 150px;">
            </el-input>

          </el-form-item>
          <el-form-item label="客户"
                        class="el-form-items">
            <el-select v-model="form.CustomerName"
                       filterable
                       clearable
                       placeholder="请选择客户"
                       class="input-with-select"
                       style="width: 150px;">
              <el-option v-for="(item,index) in customerenumlist"
                         :key="index"
                         :label="item.sn"
                         :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工程"
                        class="el-form-items">
            <el-select v-model="form.ProjectName"
                       filterable
                       clearable
                       placeholder="请选择工程"
                       class="input-with-select"
                       style="width: 150px;">
              <el-option v-for="(items,index) in projectlist"
                         :key="index"
                         :label="items.name"
                         :value="items.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品名"
                        class="el-form-items">
            <el-select v-model="form.ProductName"
                       filterable
                       clearable
                       placeholder="请选择"
                       class="input-with-select"
                       style="width: 100px;">
              <el-option v-for="(items,index) in ProductNamelist"
                         :key="index"
                         :label="items.cate_spec"
                         :value="items.cate_spec">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="规格"
                        class="el-form-items">
            <el-select v-model="form.SpecificationName"
                       filterable
                       clearable
                       placeholder="请选择"
                       class="input-with-select"
                       style="width: 100px;">
              <el-option v-for="(items,index) in SpecificationNamelist"
                         :key="index"
                         :label="items.size"
                         :value="items.size">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期"
                        class="el-form-items">
            <el-date-picker v-model="form.sales_date1"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;">
            </el-date-picker>
            <el-date-picker v-model="form.sales_date2"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;margin-left:5px">
            </el-date-picker>
          </el-form-item>
          <div style="float: left;margin-top:7px">
            <el-button icon="el-icon-search"
                       type="primary"
                       size="mini"
                       :loading="updateLoading"
                       @click="search"
                       style="padding: 6px 10px;"></el-button>
          </div>
          <div style="float:right;margin-top:7px">
            <el-button type="primary"
                       icon="iconfont icon-jia"
                       size="mini"
                       style="margin: 0 10px 10px 0;padding: 6px 12px;"
                       @click="synchroSaleOrders" :loading="updateLoading"> 同步审单</el-button>
          </div>
          <div style="float:right;margin-top:7px">
            <el-button type="primary"
                       icon="iconfont icon-jia"
                       size="mini"
                       style="margin: 0 10px 10px 0;padding: 6px 12px;"
                       @click="getActuals" :loading="updateLoading"> 获取实提</el-button>
          </div>
        </el-form>
      </el-row>
      <el-row>
        <el-form :model="productselectdata"
                 ref="addorder-form"
                 :show-message="false">
          <el-table :data="productselectdata.orderlist"
                    ref="multipleTable"
                    style="width: 100%"
                    row-key="id"
                    v-loading="loading"
                    border
                    lazy
                    :highlight-current-row="true"
                    height="calc(100vh - 190px)"
                    :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                    id="table"
                    show-summary
                    :summary-method="getSummaries"
                    class="table">
                    <el-table-column  width="50" align="center" >
                      <template v-slot="scope">
                       <span v-if="scope.row.SellBillNumber!==null"><el-radio v-model="radio" :label="scope.row.id" @change="handleSelectionChange(scope.$index,scope.row.SellBillNumber,scope.row.OriginName)">{{""}}</el-radio></span>
                      </template>
                    </el-table-column>
            <el-table-column prop="SellBilledDate"
                             label="开单日期"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellBillNumber"
                             label="销售单号"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <el-link type="primary"
                         :underline="false">
                  <div >{{ scope.row. SellBillNumber}}</div>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column prop="CustomerName"
                             label="客户"
                             align="center"
                             width="210"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="ProjectName"
                             label="工程"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="ProductName"
                             label="品名"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SpecificationName"
                             label="规格"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="MaterialName"
                             label="材质"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="OriginName"
                             label="产地"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="FQtys"
                             label="销售件数"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellQuantity"
                             label="销售数量"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="FPaidWeight"
                             label="实提数量"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellPrice"
                             label="销售单价"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellAmount"
                             label="销售金额"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellEntireAmout"
                             label="销售整单金额"
                             align="center"
                             width="100"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellPayInAmount"
                             label="收款金额"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellNoPayInAmount"
                             label="收款差额"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellClearedAmount"
                             label="销售结算金额"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="CostSellExpenseAmount"
                             label="销售费用"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="Memo"
                             label="销售明细备注"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="AuditMemo"
                             label="销售审单备注"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="PurchaseBillNumber"
                             label="采购单号"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SellerName"
                             label="业务员"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="LastModifyByName"
                             label="修改人"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="TeamName"
                             label="业务组"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="StateName"
                             label="审批"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="AuditName"
                             label="审单"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SettlementCenterName"
                             label="销售代理结算"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="ProxyPrice"
                             label="销售代理单价"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="ProxyAmount"
                             label="销售代理金额"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="BillMemo"
                             label="主单备注"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
          </el-table>
        </el-form>
        <div style="margin-top:10px;"
             v-show="total!=0">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[30, 50, 100,200]"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-row>
    </el-card>
    <el-dialog title="同步审单"
               :visible.sync="dialogVisibles"
               width="20%"
               append-to-body
               :close-on-click-modal="false"
               >
      <el-form :model="formorder"
                :rules="rules"
               ref="ruleForm">
        <el-form-item label="公司"
                      class="el-form-items" prop="company" style="margin-bottom: 20px;">
          <el-select v-model="formorder.company"
                     filterable
                     placeholder="请选择"
                     class="input-with-select"
                     style="width: 200px;">
            <el-option v-for="(items,index) in companylist"
                       :key="index"
                       :label="items.name"
                       :value="items.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户"
                      class="el-form-items" prop="custom" style="margin-bottom: 20px;">
          <el-select v-model="formorder.custom"
                     filterable
                     placeholder="请选择"
                     class="input-with-select"
                     style="width: 200px;">
            <el-option v-for="(item,index) in customlist"
                       :key="index"
                       :label="item.name"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型"
                      class="el-form-items" prop="type" style="margin-bottom: 20px;">
          <el-select v-model="formorder.type"
                     filterable
                     placeholder="请选择"
                     class="input-with-select"
                     style="width: 200px;">
            <el-option v-for="(item,index) in typelist"
                       :key="index"
                       :label="item.name"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer" style="width: 200px;">
        <el-button type="primary"
                   @click="synchroSaleOrder">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="获取实提"
               :visible.sync="dialogVisibless"
               width="20%"
               append-to-body
               :close-on-click-modal="false"
               >
      <el-form :model="formorders">
        <el-form-item label="公司"
                      class="el-form-items" prop="company" style="margin-bottom: 20px;">
          <el-select v-model="formorders.company"
                     filterable
                     placeholder="请选择"
                     class="input-with-select"
                     style="width: 200px;">
            <el-option v-for="(items,index) in companylist"
                       :key="index"
                       :label="items.name"
                       :value="items.name">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer" style="width: 200px;">
        <el-button type="primary"
                   @click="getActual">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ProjectEnumList, CustomerEnumList, getSteelSizeList, searchSellPurchaseList, getActual, synchroSaleOrder } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'SelllistIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogVisibles: false,
      dialogVisibless: false,
      currentPage: 1,
      total: 0,
      perpage: 30,
      pagesize: 30,
      productselectdata: {
        orderlist: []
      },
      business: [],
      myHeaders: '',
      form: {
        number: null,
        ProjectName: null,
        CustomerName: null,
        SupplierName: null,
        ProductName: null,
        SpecificationName: null,
        sales_date1: null,
        sales_date2: null
      },
      projectlist: [],
      customerenumlist: [],
      sellbilllist: [],
      ProductNamelist: [],
      SpecificationNamelist: [],
      spvo: [],
      supplierdata: [],
      CompanyNamelist: [
        {
          id: 1,
          name: '广东泊明智钢供应链服务有限公司'
        },
        {
          id: 2,
          name: '聚钢资源管理服务（广州）有限公司'
        },
        {
          id: 3,
          name: '汇钢资源管理服务（广州）有限公司'
        },
        {
          id: 4,
          name: '广州蔚蓝新材料科技有限公司'
        }
      ],
      teamlist: [
        {
          id: 1,
          name: '大宗-现款'
        }, {
          id: 2,
          name: '大宗-出数'
        }, {
          id: 3,
          name: '大宗-账期'
        }, {
          id: 4,
          name: '工程-短账期'
        }, {
          id: 5,
          name: '工程-长账期'
        }, {
          id: 6,
          name: '商务-现款'
        }, {
          id: 7,
          name: '商务-出数'
        }, {
          id: 8,
          name: '商务-账期'
        }
      ],
      typepricelist: [
        { id: 1, name: '客户' },
        { id: 2, name: '供应商' }
      ],
      companylist: [
        { id: 1, name: '泊明' },
        { id: 2, name: '蔚蓝' }
      ],
      customlist: [
        { id: 1, name: '大宗' },
        { id: 2, name: '工地' }
      ],
      typelist: [
        { id: 1, name: '理计' },
        { id: 2, name: '磅计' }
      ],
      formorder: {
        company: '泊明',
        custom: null,
        type: null
      },
      formorders: {
        company: '泊明'
      },
      isshow: false,
      radio: '',
      SellBillNumber: null,
      OriginName: null,
      rules: {
        company: [
          { required: true, message: '请选择公司', trigger: 'change' }
        ],
        custom: [
          { required: true, message: '请选客户', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.loadnav()
    this.loadtoken()
    this.loadgetdate()
    this.loadgetSteelSizeList()
    this.loadsupplierList()
    this.loadgetCustomerEnumList()
    this.loadgetProjectEnumList()
    this.search()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetdate () {
      this.form.sales_date1 = this.getDay(-1)
      this.form.sales_date2 = this.getDay(0)
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'selllist') {
        this.topname = '销售管理'
        this.navnames = '销售单'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetCustomerEnumList () {
      CustomerEnumList().then(res => {
        if (res.data.status === 'success') {
          this.customerenumlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetProjectEnumList () {
      ProjectEnumList({ type: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.projectlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetSteelSizeList () {
      getSteelSizeList({ type: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.ProductNamelist = res.data.catespeclist
          this.SpecificationNamelist = res.data.sizelist
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadsupplierList () {
      getSteelSizeList({ type: 2 }).then(res => {
        if (res.data.status === 'success') {
          this.supplierdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    search () {
      this.updateLoading = true
      this.loading = true
      searchSellPurchaseList({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.spvo = res.data.item
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      searchSellPurchaseList({ data: this.form, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.spvo = res.data.item
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      searchSellPurchaseList({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.spvo = res.data.item
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === 12) {
          sums[index] = this.spvo.sellnumber
        } else if (index === 14) {
          sums[index] = this.spvo.SellAmount
        } else if (index === 15) {
          sums[index] = this.spvo.SellAmount
        } else if (index === 17) {
          sums[index] = this.spvo.SellNoPayInAmount
        } else if (index === 25) {
          sums[index] = this.spvo.Purchasenumber
        } else if (index === 27) {
          sums[index] = this.spvo.PurchaseAmount
        } else if (index === 28) {
          sums[index] = this.spvo.PurchaseAmount
        } else if (index === 30) {
          sums[index] = this.spvo.PurchaseNoOutAmount
        } else if (index === 35) {
          sums[index] = this.spvo.SellPurchaseProfitExpense
        }
      })
      return sums
    },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay (day) {
      var today = new Date()
      var targetdaymilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targetdaymilliseconds)
      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth (month) {
      var m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    },
    handleSelectionChange (index, SellBillNumber, OriginName) {
      this.SellBillNumber = SellBillNumber
      this.OriginName = OriginName
    },
    synchroSaleOrders () {
      this.dialogVisibles = true
    },
    synchroSaleOrder () {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          return false
        } else {
          if (this.SellBillNumber) {
            const loading = this.$loading({
              lock: true,
              text: '正在同步中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.95)'
            })
            synchroSaleOrder({ SellBillNumber: this.SellBillNumber, data: this.formorder }).then(res => {
              if (res.data.status === 'success') {
                this.search()
                this.updateLoading = false
                this.dialogVisibles = false
                loading.close()
                this.$message.success(res.data.message)
              } else {
                this.updateLoading = false
                this.dialogVisibles = false
                loading.close()
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              this.updateLoading = false
              this.dialogVisibles = false
              loading.close()
              console.log('保存失败', err)
            })
          } else {
            this.$message.error('请选择内容')
          }
        }
      })
    },
    getActuals () {
      this.dialogVisibless = true
    },
    getActual () {
      if (this.SellBillNumber) {
        if (this.OriginName === '桂鑫') {
          this.updateLoading = true
          const loading = this.$loading({
            lock: true,
            text: '正在获取中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.95)'
          })
          getActual({ SellBillNumber: this.SellBillNumber, type: this.formorders.company }).then(res => {
            if (res.data.status === 'success') {
              this.search()
              this.updateLoading = false
              loading.close()
              this.dialogVisibless = false
              this.$message.success(res.data.message)
            } else {
              this.updateLoading = false
              loading.close()
              this.dialogVisibless = false
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            this.updateLoading = false
            this.dialogVisibless = false
            loading.close()
            console.log('保存失败', err)
          })
        } else {
          this.$message.error('请选择桂鑫订单')
        }
      } else {
        this.$message.error('请选择内容')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  padding: 0 10px 0 0;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  font-size: 16px;
  color: #000;
  line-height: 25px;
}
.offer {
  line-height: 25px;
  font-size: 16px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 0px 0;
}
/deep/.el-table th {
  padding: 0px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 16px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
/deep/.table .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 2px;
}
/deep/.xwyc-table .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 2px;
}
/deep/.table .el-form-item__content {
  line-height: 25px;
  position: relative;
  font-size: 14px;
}
/deep/.table .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 25px;
}
/deep/.el-table__header .el-checkbox { // 找到表头那一行，然后把里面的复选框隐藏掉
    display:none!important;
  }
</style>
