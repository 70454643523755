<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <el-form :model="form"
                 ref="customer-form">
          <el-form-item label="单号"
                        class="el-form-items">
            <el-input placeholder="请输入销售单号"
                      v-model="form.SellBillNumber"
                      clearable
                      class="input-with-select"
                      style="width: 200px;">
            </el-input>
          </el-form-item>
          <el-form-item label="客户"
                        class="el-form-items">
            <el-select v-model="form.CustomerName"
                       filterable
                       clearable
                       placeholder="请选择客户"
                       class="input-with-select"
                       style="width: 200px;"
                       @change="changecustomer"
                       @clear="clearcustomer">
              <el-option v-for="(item,index) in oppAccountNameData"
                         :key="index"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工程"
                        class="el-form-items">
            <el-select v-model="form.ProjectName"
                       filterable
                       clearable
                       placeholder="请选择项目"
                       class="input-with-select"
                       style="width: 200px;"
                       @change="changeproject"
                       @clear="clearproject">
              <el-option v-for="(items,index) in projectlist"
                         :key="index"
                         :label="items.name"
                         :value="items.name">
              </el-option>
            </el-select>
            <el-button type="primary"
                       style=" margin-left:10px ;"
                       plain
                       size="mini"
                       @click="refreshdata"
                       :loading="updateLoadingrefreshdata">刷新数据</el-button>
          </el-form-item>
          <el-form-item label="日期"
                        class="el-form-items">
            <el-date-picker v-model="form.sales_date1"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;">
            </el-date-picker>
            <el-date-picker v-model="form.sales_date2"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;margin-left:5px">
            </el-date-picker>
          </el-form-item>
          <div style="float: left;margin-top:5px">
            <el-button icon="el-icon-search"
                       type="primary"
                       size="mini"
                       :loading="updateLoading"
                       @click="search"
                       style="padding: 6px 10px;"></el-button>
          </div>
          <div style="float:right;margin-top:5px">
            <el-button type="primary"
                       icon="iconfont icon-jia"
                       size="mini"
                       style="margin: 0 10px 10px 0;padding: 6px 12px;"
                       @click="importorder"> 导入</el-button>
          </div>
          <div style="float:right;margin-top:5px">
            <el-dropdown class="el-buttons">
              <el-button size="mini"
                         type="success"
                         icon="el-icon-printer"
                         style="margin: 0 10px 10px 0;padding: 6px 12px;"> 打印</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="deliverynote">打印标准</el-dropdown-item>
                <el-dropdown-item @click.native="printspecial">打印特殊</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </el-form>
      </el-row>
      <el-row>
        <el-table :data="orderlist"
                  ref="multipleTable"
                  style="width: 100%"
                  row-key="id"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  height="calc(100vh - 180px)"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                  :row-class-name="tableRowClassName"
                  @selection-change="handleSelectionChange"
                  @row-click="handleRowClick"
                  @select="handleSelect"
                  @select-all="handleSelectAll"
                  @cell-click="tabClick">
          <el-table-column type="selection"
                           width="40"
                           :reserve-selection="true"
                           :selectable='isCheckbox'>
          </el-table-column>
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="50">
          </el-table-column>
          <el-table-column prop="SellBilledDate"
                           label="销售日期"
                           align="center"
                           width="90">
          </el-table-column>
          <el-table-column prop="SellBillNumber"
                           label="销售单号"
                           align="center"
                           width="120"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="CompanyName"
                           label="公司"
                           align="center"
                           width="210"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="CustomerName"
                           label="客户"
                           align="center"
                           width="210"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="ProjectName"
                           label="工程"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="OriginName"
                           label="产地"
                           align="center"
                           width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="ProductName"
                           label="品名"
                           align="center"
                           width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="MaterialName"
                           label="材质"
                           align="center"
                           width="90"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="SpecificationName"
                           label="规格"
                           align="center"
                           width="70"
                           :show-overflow-tooltip="true">
          </el-table-column>

          <el-table-column prop="SellQuantity"
                           label="销售数量"
                           align="center"
                           width="70"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="SellPrice"
                           label="销售单价"
                           align="center"
                           width="70"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="SellAmount"
                           label="销售金额"
                           align="center"
                           width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="SellEntireAmout"
                           label="销售整单金额"
                           align="center"
                           width="90"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="SellUnitNumber"
                           label="销售件数"
                           align="center"
                           width="70"
                           :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.index === tabClickIndex && tabClickLabel === '销售件数'">
                <el-input v-model="orderlist[scope.$index].SellUnitNumber"
                          placeholder="请输入销售件数"
                          size="mini"
                          @blur="inputBlur(scope.$index,'SellUnitNumber')" />
              </span>
              <span v-else>{{ scope.row.SellUnitNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="Memo"
                           label="销售备注"
                           align="center"
                           width="100"
                           :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.index === tabClickIndex && tabClickLabel === '销售备注'">
                <el-input v-model="orderlist[scope.$index].Memo"
                          placeholder="请输入销售备注"
                          size="mini"
                          @blur="inputBlur(scope.$index,'Memo')" />
              </span>
              <span v-else>{{ scope.row.Memo }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="BillCenterVehicleDataCount"
                           label="车辆轨迹"
                           align="center"
                           width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>

        </el-table>
        <div style="margin-top:10px;"
             v-show="total!=0">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[29, 50, 100,200]"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-row>
    </el-card>
    <el-dialog title="导入"
               :visible.sync="dialogVisibles"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formorder"
               ref="form">
        <el-form-item label="日期">
          <el-date-picker v-model="formorder.sales_date1"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 140px;float: left;">
          </el-date-picker>
          <el-date-picker v-model="formorder.sales_date2"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 140px;float: left;margin-left:5px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工程">
          <el-select v-model="formorder.ProjectName"
                     filterable
                     clearable
                     placeholder="请选择项目"
                     class="input-with-select"
                     style="width: 200px;">
            <el-option v-for="(items,index) in projectlist"
                       :key="index"
                       :label="items.name"
                       :value="items.name">
            </el-option>
          </el-select>
          <el-button type="primary"
                     style=" margin-left:10px ;"
                     plain
                     size="mini"
                     @click="refreshdata"
                     :loading="updateLoadingrefreshdata">刷新数据</el-button>
        </el-form-item>
        <el-form-item label="订单信息"
                      prop="business_license"
                      style="margin-top: 20px;">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="businessRemove"
                     :on-success="filesuccess"
                     :limit="1"
                     accept=".xlsx"
                     :file-list="business"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="importFile">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="标准送货单"
               :visible.sync="dialogdeliverynote"
               append-to-body
               :close-on-click-modal="false"
               width="80%"
               top="10px"
               @close="closeprint">
      <div style="width:100%;padding-bottom:10px; margin-bottom: 10px;"
           align="center">
        <el-form ref="formprojectsa"
                 :model="formproject">
          <el-row>
            <el-form-item label="委托单位"
                          class="el-form-items">
              <el-select v-model="formproject.sell_name"
                         placeholder="请选择"
                         class="input-with-select"
                         style="width: 280px;">
                <el-option v-for="itemc in companyname"
                           :key="itemc.id"
                           :label="itemc.name"
                           :value="itemc.name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收货单位"
                          class="el-form-items">
              <el-input v-model="formproject.customer_name"
                        class="input-with-select"
                        style="width: 280px;"></el-input>
            </el-form-item>
            <el-form-item label="车牌号"
                          class="el-form-items">
              <el-input v-model="formproject.edition_number"
                        class="input-with-select"
                        style="width: 230px;"></el-input>
            </el-form-item>
            <el-form-item label="单号"
                          class="el-form-items">
              <el-input v-model="formproject.order_No"
                        class="input-with-select"
                        style="width: 240px;"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="项目名称"
                          class="el-form-items">
              <el-input v-model="formproject.project_name"
                        class="input-with-select"
                        style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="送货地址"
                          class="el-form-items">
              <el-input v-model="formproject.shipping_addr"
                        class="input-with-select"
                        style="width: 450px;"></el-input>
            </el-form-item>
            <el-form-item label="订货日期"
                          class="el-form-items">
              <el-date-picker v-model="formproject.sales_date"
                              value-format="yyyy-MM-dd"
                              type="date"
                              class="input-with-select"
                              style="width: 160px;float: left;margin-left:5px"> </el-date-picker>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <div class="clear"></div>
      <div style="width:720px;margin: 0 auto;"
           align="center">
        <div id="deliverynote"
             ref="deliverynote"
             style="width:100%;height:100%;"
             align="center">
          <div style="color: #000;  font-size: 24px;font-weight:700;">{{formproject.sell_name}}</div>
          <div style="color: #000;  font-size: 16px;font-weight:700;">货物签收单</div>
          <el-col :span="16"
                  class="print">
            <p align="left">收货单位：{{formproject.customer_name}}</p>
          </el-col>
          <el-col :span="8"
                  class="print">
            <p align="left">车牌号：{{formproject.edition_number}}</p>
          </el-col>
          <el-col :span="16"
                  class="print">
            <p align="left">订单日期：{{formproject.sales_date}}</p>
          </el-col>
          <el-col :span="8"
                  class="print">
            <p align="left">单号：{{formproject.order_No}}</p>
          </el-col>
          <el-col :span="16"
                  class="print">
            <p align="left">项目名称：{{formproject.project_name}}</p>
          </el-col>
          <el-col :span="8"
                  class="print">
            <p align="left">送货地址：{{formproject.shipping_addr}}</p>
          </el-col>
          <div style="padding-top: 15px;  clear: both;">
            <table align="center"
                   style="width:100%;height:100%;"
                   cellspacing="0"
                   cellpadding="0"
                   border="0"
                   class="xwyc-table">
              <thead>
                <tr>
                  <th align="center"
                      width="75">钢材品牌</th>
                  <th align="center"
                      width="180">品名规格</th>
                  <th align="center"
                      width="61">件数</th>
                  <th align="center"
                      width="120">实收数量/吨</th>
                  <th align="center">备注</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in productselectdata"
                    :key="index">
                  <td align="center">{{item.OriginName}}</td>
                  <td align="center">{{item.ProductName}} {{item.MaterialName}} {{item.SpecificationName}}</td>
                  <td align="center">{{item.SellUnitNumber}}</td>
                  <td align="center">{{item.SellQuantity}}</td>
                  <td align="center">{{item.Memo}}</td>
                </tr>
                <tr>
                  <td align="center">合计</td>
                  <td align="center"></td>
                  <td align="center">{{totalSellUnitNumber}}</td>
                  <td align="center">{{totalSellQuantity}}</td>
                  <td align="center"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="el-table__body-wrapper is-scrolling-none">
            <table class="el-table__body"
                   cellspacing="0"
                   cellpadding="0"
                   border="0"
                   style="width: 100%; height: auto; padding-bottom: 30px;">
              <tbody>
                <el-col :span="9"
                        class="print">
                  <p align="left">承运人签名：</p>
                </el-col>
                <el-col :span="9"
                        class="print">
                  <p align="left">收货人签名：</p>
                </el-col>
                <el-col :span="6"
                        class="print">
                  <p align="left">收货日期：</p>
                </el-col>
                <!-- <el-col :span="4">&nbsp;</el-col>
                <el-col :span="24"
                        class="print">
                  <p align="left">说明：</p>
                </el-col>
                <el-col :span="24"
                        class="print">
                  <p align="left">1.送货清单：收款联（白）、客户联（红）、记账联（黄）。</p>
                </el-col>
                <el-col :span="24">&nbsp;</el-col>-->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <el-row>
        <div style="margin-top:20px;text-align: center;">
          <el-button type="primary"
                     icon="el-icon-printer"
                     v-print="deliverynoteprint">打印</el-button>
        </div>
      </el-row>
    </el-dialog>
    <el-dialog title="特殊送货单"
               :visible.sync="dialogspecial"
               append-to-body
               :close-on-click-modal="false"
               width="80%"
               top="10px"
               @close="closeprint">
      <div style="width:100%;padding-bottom:10px; margin-bottom: 10px;"
           align="center">
        <el-form ref="formprojectsaa"
                 :model="formproject">
          <el-row>
            <el-form-item label="委托单位"
                          class="el-form-items">
              <el-select v-model="formproject.sell_name"
                         placeholder="请选择"
                         class="input-with-select"
                         style="width: 280px;">
                <el-option v-for="itemc in companyname"
                           :key="itemc.id"
                           :label="itemc.name"
                           :value="itemc.name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收货单位"
                          class="el-form-items">
              <el-input v-model="formproject.customer_name"
                        class="input-with-select"
                        style="width: 280px;"></el-input>
            </el-form-item>
            <el-form-item label="车牌号"
                          class="el-form-items">
              <el-input v-model="formproject.edition_number"
                        class="input-with-select"
                        style="width: 230px;"></el-input>
            </el-form-item>
            <el-form-item label="单号"
                          class="el-form-items">
              <el-input v-model="formproject.order_No"
                        class="input-with-select"
                        style="width: 240px;"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="项目名称"
                          class="el-form-items">
              <el-input v-model="formproject.project_name"
                        class="input-with-select"
                        style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="送货地址"
                          class="el-form-items">
              <el-input v-model="formproject.shipping_addr"
                        class="input-with-select"
                        style="width: 450px;"></el-input>
            </el-form-item>
            <el-form-item label="订货日期"
                          class="el-form-items">
              <el-date-picker v-model="formproject.sales_date"
                              value-format="yyyy-MM-dd"
                              type="date"
                              class="input-with-select"
                              style="width: 160px;float: left;margin-left:5px"> </el-date-picker>
            </el-form-item>
            <el-form-item label="是否显示"
                          class="el-form-items">
              <el-checkbox v-model="sellPricechecked">单价</el-checkbox>
              <el-checkbox v-model="sellAmountchecked">金额</el-checkbox>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <div class="clear"></div>
      <div style="width:720px;margin: 0 auto;"
           align="center">
        <div id="printspecial"
             ref="printspecial"
             style="width:100%;height:100%;"
             align="center">
          <div style="color: #000;  font-size: 24px;font-weight:700;">{{formproject.sell_name}}</div>
          <div style="color: #000;  font-size: 16px;font-weight:700;">货物签收单</div>
          <el-col :span="16"
                  class="print">
            <p align="left">收货单位：{{formproject.customer_name}}</p>
          </el-col>
          <el-col :span="8"
                  class="print">
            <p align="left">车牌号：{{formproject.edition_number}}</p>
          </el-col>
          <el-col :span="16"
                  class="print">
            <p align="left">订单日期：{{formproject.sales_date}}</p>
          </el-col>
          <el-col :span="8"
                  class="print">
            <p align="left">单号：{{formproject.order_No}}</p>
          </el-col>
          <el-col :span="16"
                  class="print">
            <p align="left">项目名称：{{formproject.project_name}}</p>
          </el-col>
          <el-col :span="8"
                  class="print">
            <p align="left">送货地址：{{formproject.shipping_addr}}</p>
          </el-col>
          <div style="padding-top: 15px;  clear: both;">
            <table align="center"
                   style="width:100%;height:100%;"
                   cellspacing="0"
                   cellpadding="0"
                   border="0"
                   class="xwyc-table">
              <thead>
                <tr>
                  <th align="center"
                      width="75">钢材品牌</th>
                  <th align="center"
                      width="180">品名规格</th>
                  <th align="center"
                      width="56">单位</th>
                  <th align="center"
                      width="86">数量</th>
                  <th align="center"
                      width="64">单价</th>
                  <th align="center"
                      width="113">金额</th>
                  <th align="center">备注</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in productselectdata"
                    :key="index">
                  <td align="center">{{item.OriginName}}</td>
                  <td align="center">{{item.ProductName}} {{item.MaterialName}} {{item.SpecificationName}}</td>
                  <td align="center">吨</td>
                  <td align="center">{{item.SellQuantity}}</td>
                  <td align="center"
                      v-if="sellPricechecked">{{item.SellPrice}}</td>
                  <td align="center"
                      v-else></td>
                  <td align="center"
                      v-if="sellAmountchecked">{{item.SellAmount}}</td>
                  <td align="center"
                      v-else></td>
                  <td align="center">{{item.Memo}}</td>
                </tr>
                <tr>
                  <td align="center">合计</td>
                  <td align="center"></td>
                  <td align="center"></td>
                  <td align="center">{{totalSellQuantity}}</td>
                  <td align="center"></td>
                  <td align="center"
                      v-if="sellAmountchecked">{{totalSellAmount}}</td>
                  <td align="center"
                      v-else></td>
                  <td align="center"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="el-table__body-wrapper is-scrolling-none">
            <table class="el-table__body"
                   cellspacing="0"
                   cellpadding="0"
                   border="0"
                   style="width: 100%; height: auto; padding-bottom: 30px;">
              <tbody>
                <el-col :span="9"
                        class="print">
                  <p align="left">承运人签名：</p>
                </el-col>
                <el-col :span="9"
                        class="print">
                  <p align="left">收货人签名：</p>
                </el-col>
                <el-col :span="6"
                        class="print">
                  <p align="left">收货日期：</p>
                </el-col>
                <!-- <el-col :span="4">&nbsp;</el-col>
                <el-col :span="24"
                        class="print">
                  <p align="left">说明：</p>
                </el-col>
                <el-col :span="24"
                        class="print">
                  <p align="left">1.送货清单：收款联（白）、客户联（红）、记账联（黄）。</p>
                </el-col>
                <el-col :span="24">&nbsp;</el-col>-->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <el-row>
        <div style="margin-top:20px;text-align: center;">
          <el-button type="primary"
                     icon="el-icon-printer"
                     v-print="specialprint">打印</el-button>
        </div>
      </el-row>
    </el-dialog>
    <el-dialog title="其他"
               :visible.sync="dialogprintother"
               append-to-body
               :close-on-click-modal="false"
               width="80%"
               top="10px">
      <div style="width:720px;margin: 0 auto;"
           align="center">
        <div id="printothers"
             ref="printothers"
             style="width:100%;height:100%;"
             align="center">
          <el-calendar v-model="printothervalue">
          </el-calendar>
        </div>
      </div>
      <el-row>
        <div style="margin-top:20px;text-align: center;">
          <el-button type="primary"
                     icon="el-icon-printer"
                     v-print="sprintother">打印</el-button>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { importOrderData, getOrderIfo, getAccountNameList, ProjectEnumList, getProjectEnumList, getCompanyName, updateInput, printRecord } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'OrderInfoIndex',
  data () {
    const self = this
    return {
      loading: true,
      updateLoading: false,
      dialogquotation: false,
      dialogdeliverynote: false,
      dialogVisibles: false,
      updateLoadingrefreshdata: false,
      dialogspecial: false,
      dialogprintother: false,
      currentPage: 1,
      total: 0,
      perpage: 29,
      pagesize: 29,
      orderlist: [],
      myHeaders: '',
      business: [],
      form: {
        SellBillNumber: null,
        CustomerName: null,
        ProjectName: null,
        StateName: null,
        AuditName: null,
        sales_date1: null,
        sales_date2: null
      },
      formorder: {
        sales_date1: null,
        sales_date2: null,
        ProjectName: null,
        url: null
      },
      customerlistname: [],
      customerlist: [],
      examinestatus: '',
      sellPricechecked: true,
      sellAmountchecked: true,
      companyname: [],
      statenamelist: [{
        id: 1,
        name: '已审批'
      }, {
        id: 2,
        name: '审批中'
      }],
      auditnamelist: [{
        id: 1,
        name: '已审单'
      }, {
        id: 3,
        name: '未审单'
      }],
      oppAccountNameData: [],
      projectlist: [],
      formproject: {
        sell_name: '广东泊明智钢供应链服务有限公司',
        customer_name: '',
        edition_number: '',
        sales_date: '',
        order_No: '',
        project_name: '',
        shipping_addr: ''
      },
      productselectdata: [],
      totalSellQuantity: '',
      totalSellAmount: '',
      totalSellUnitNumber: '',
      tabClickIndex: null, // 点击的单元格
      tabClickLabel: '', // 当前点击的列名
      printothervalue: new Date(),
      deliverynoteprint: {
        id: 'deliverynote', // 这里是要打印元素的ID
        popTitle: '', // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style>  #deliverynote { width: 100%;  } <style>', // 打印头部文字
        openCallback () { self.printrecord() } // 调用打印时的callback
      },
      specialprint: {
        id: 'printspecial', // 这里是要打印元素的ID
        popTitle: '', // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style>  #deliverynote { width: 100%;  } <style>', // 打印头部文字
        openCallback () { self.printrecord() } // 调用打印时的callback
      },
      sprintother: {
        id: 'printothers', // 这里是要打印元素的ID
        popTitle: '', // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style>  #deliverynote { width: 100%;  } <style>' // 打印头部文字
      }
    }
  },
  created () {
    this.loadgetdate()
    this.loadnav()
    this.loadgetCompanyName()
    this.loadgetAccountNameList()
    this.loadgetProjectEnumList()
    this.loadtoken()
    this.loadgetOrderIfo()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'orderinfo') {
        this.topname = '订单管理'
        this.navnames = '订单信息'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetdate () {
      this.salesdate = this.getDay(-7)
      this.form.sales_date1 = this.getDay(-7)
      this.form.sales_date2 = this.getDay(0)
      this.enddate = this.getDay(0)
    },
    loadgetCompanyName () {
      getCompanyName().then(res => {
        if (res.data.status === 'success') {
          this.companyname = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetAccountNameList () {
      getAccountNameList().then(res => {
        if (res.data.status === 'success') {
          this.oppAccountNameData = res.data.data.oppAccountName
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    loadgetProjectEnumList () {
      ProjectEnumList({ type: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.projectlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetOrderIfo () {
      getOrderIfo({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    importorder () {
      this.dialogVisibles = true
    },
    importFile () {
      if (this.formorder.sales_date1 === null) {
        this.$message.warning('请选择开始日期')
        return false
      }
      if (this.formorder.sales_date2 === null) {
        this.$message.warning('请选择结束日期')
        return false
      }
      if (this.formorder.ProjectName === null) {
        this.$message.warning('请选择工程')
        return false
      }
      if (this.formorder.url === null) {
        this.$message.warning('请选上传导入文件')
        return false
      }
      importOrderData(this.formorder).then(res => {
        if (res.data.status === 'success') {
          this.formorder.url = null
          this.business = []
          this.$message({
            message: '导入成功',
            type: 'success'
          })
          this.loadgetOrderIfo()
          this.dialogVisibles = false
        } else {
          this.formorder.url = null
          this.business = []
          this.dialogVisibles = false
          this.$message.error('导入失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.formorder.url = res.data.url
        this.business = this.business.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessRemove (file) {
      this.business.some((item, i) => {
        if (item.url === file.url) {
          this.business.splice(i, 1)
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeUpload (file) {
    },
    changecustomer (val) {
      ProjectEnumList({ name: val, type: 2 }).then(res => {
        if (res.data.status === 'success') {
          this.projectlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    clearcustomer () {
      this.form.ProjectName = null
    },
    changeproject (val) {
      ProjectEnumList({ name: val, type: 3 }).then(res => {
        if (res.data.status === 'success') {
          this.form.CustomerName = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    clearproject () {
      this.form.CustomerName = null
    },
    search () {
      this.updateLoading = true
      this.loading = true
      getOrderIfo({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      getOrderIfo({ data: this.form, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      getOrderIfo({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleSelectionChange (val) {
      const _this = this
      this.productselectdata = []
      this.productselectdata = val
      val.map((item) => {
        if (item.ProjectName && item.SellBilledDate) {
          _this.formproject.edition_number = item.BillCenterVehicleDataCount
          _this.formproject.sales_date = item.SellBilledDate
          _this.formproject.project_name = item.ProjectName
          _this.formproject.shipping_addr = item.ShippingAddress
          _this.formproject.customer_name = item.CustomerName
        }
      })
      this.totalSellQuantity = this.totalSumAll()
      this.totalSellAmount = this.totalSellAmounts()
      this.totalSellUnitNumber = this.totalcost()
    },
    // 手动点击勾选框触发的事件
    handleSelect (selection, row) {
      // selection,row  传递两个参数，row直接是对象
      if (row.className === 'normal') {
        row.className = 'tableSelectedRowBgColor'
      } else {
        row.className = 'normal'
      }
    },
    // select-all 手动点击全选触发的事件
    handleSelectAll (selection) {
      if (selection.length > 0) {
        selection.forEach((item) => {
          if (item.className === 'normal') {
            item.className = 'tableSelectedRowBgColor'
          }
        })
      } else {
        // 空数组初始化className
        this.purchaslist.forEach((item) => {
          if (item.className === 'tableSelectedRowBgColor') {
            item.className = 'normal'
          }
        })
      }
    },
    // 选中背景色
    tableRowClassName ({ row, rowIndex }) {
      // 把每一行的索引放进row
      row.index = rowIndex
      return row.className
    },
    tableRowClassNames ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    // 点击行触发，切换复选框状态
    handleRowClick (row, column, event) {
      /* if (row.className === 'normal') {
        row.className = 'tableSelectedRowBgColor'
        this.$refs.multipleTable.toggleRowSelection(row, true)
      } else {
        row.className = 'normal'
        this.$refs.multipleTable.toggleRowSelection(row, false)
      } */
    },
    isCheckbox (row, index) {
      /* if (row.payment_status === '已付款' || row.payment_status === '待付款' || row.sealed === 1 || row.supplier === '自有库存') { // 判断是否可选的条件
        return 0
      } else {
        return 1
      } */
      return 1
    },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay (day) {
      var today = new Date()
      var targetdaymilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targetdaymilliseconds)
      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth (month) {
      var m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    },
    refreshdata () {
      this.updateLoadingrefreshdata = true
      getProjectEnumList().then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.changeoppAccountName()
          this.loadgetProjectEnumList()
          this.updateLoadingrefreshdata = false
        } else {
          this.$message.error(res.data.message)
          this.updateLoadingrefreshdata = false
        }
      }).catch(err => {
        this.updateLoadingrefreshdata = false
        console.log('保存失败', err)
      })
    },
    // 打印标准
    deliverynote () {
      if (this.productselectdata.length === 0) {
        this.$message.error('请选择打印明细')
        return false
      } else {
        this.dialogdeliverynote = true
      }
    },
    // 打印特殊
    printspecial () {
      if (this.productselectdata.length === 0) {
        this.$message.error('请选择打印明细')
        return false
      } else {
        this.dialogspecial = true
      }
    },
    totalSellAmounts () {
      let totalSellAmounts = 0
      this.productselectdata.map((item) => {
        const taxtotalss = Number(item.SellAmount)
        totalSellAmounts += taxtotalss
      })
      return totalSellAmounts.toFixed(2)
    },
    totalSumAll () {
      let totalSumAll = 0
      this.productselectdata.map((item) => {
        const taxtotal = Number(item.SellQuantity)
        totalSumAll += taxtotal
      })
      return totalSumAll.toFixed(2)
    },
    totalcost () {
      let costtotas = 0
      this.productselectdata.map((item) => {
        const costtota = Number(item.SellUnitNumber)
        costtotas += costtota
      })
      if (costtotas === 0) {
        return null
      } else {
        return costtotas
      }
    },
    // 添加明细原因 row 当前行 column 当前列
    tabClick (row, column, cell, event) {
      switch (column.label) {
        case '销售件数':
          this.tabClickIndex = row.index
          this.tabClickLabel = column.label
          break
        case '销售备注':
          this.tabClickIndex = row.index
          this.tabClickLabel = column.label
          break
        default:
      }
    },
    inputBlur (event, namea) {
      this.tabClickIndex = null
      this.tabClickLabel = ''
      if (namea === 'Memo') {
        this.contents = this.orderlist[event].Memo
      }
      if (namea === 'SellUnitNumber') {
        this.contents = this.orderlist[event].SellUnitNumber
      }
      updateInput({ id: this.orderlist[event].id, contents: this.contents, namea: namea }).then(res => {
        if (res.data.status === 'success') {
          this.loadgetOrderIfo()
          this.toggleSelection()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    toggleSelection () {
      this.productselectdata.forEach(row => {
        this.orderlist.find(item => {
          if (row.id === item.id) {
            this.$refs.multipleTable.toggleRowSelection(item, false)
          }
        })
      })
      this.productselectdata = []
      this.formproject = {
        sell_name: '广东泊明智钢供应链服务有限公司',
        customer_name: '',
        edition_number: '',
        sales_date: '',
        order_No: '',
        project_name: '',
        shipping_addr: ''
      }
    },
    printrecord () {
      printRecord({ data: this.formproject, product: this.productselectdata }).then(res => {
        if (res.data.status === 'success') {
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    closeprint () {

    },
    printother () {
      this.dialogprintother = true
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  padding: 0 10px 0 0;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 0px 0;
}
/deep/.el-table th {
  padding: 0px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
</style>
