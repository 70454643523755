<template>
  <div class="manage-container">
    <el-card class="filter-card"
             shadow="hover">
      <!-- 数据筛选表单 -->
      <el-form ref="customer-form"
               :model="customerform"
               label-width="80px"
               :rules="customerformRules">
        <el-form-item label="公司名"
                      prop="id"
                      class="el-form-items">
          <el-select v-model="customerform.id"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in customerdatasearch"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:-60px ;"
                      class="el-form-items">
          <el-button size="primary"
                     @click="searchData"
                     icon="el-icon-search"
                     :loading="updateLoading">搜索</el-button>
          <el-button type="primary"
                     icon="el-icon-plus"
                     @click="dialogFormVisible = true">新建客户</el-button>
        </el-form-item>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>
    <el-card class="box-card"
             shadow="hover">
      <el-table :data="customerdata"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 180px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading">
        <el-table-column label="序号"
                         type="index"
                         align="center"
                         width="50">
        </el-table-column>
        <el-table-column label="客户名称"
                         prop="name"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="简称"
                         prop="abbreviation"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="曾用名"
                         prop="beforename"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="关联企业"
                         prop="associated"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="公司类型"
                         prop="type"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <!-- <el-table-column label="办公地址"
                         prop="addr"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="员工规模"
                         prop="scale"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="业务规模"
                         prop="business_scale"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>-->
        <el-table-column label="六六"
                         prop="liuliu"
                         align="center"
                         :filters="[{ text: '是', value: '是' }, { text: '否', value: '否' }]"
                         :filter-method="liuliufilterTags"
                         filter-placement="bottom-end"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="钢小二"
                         prop="gangxiaoer"
                         align="center"
                         :filters="[{ text: '是', value: '是' }, { text: '否', value: '否' }]"
                         :filter-method="gangxiaoerfilterTags"
                         filter-placement="bottom-end"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column label="所属部门"
                         prop="deptname"
                         align="center"
                         :filters="[{ text: '市场', value: '市场' }, { text: '运营', value: '运营' }, { text: '创融', value: '创融' }]"
                         :filter-method="filterTag"
                         filter-placement="bottom-end"
                         width="120"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="类型"
                         prop="mername"
                         align="center"
                         :filters="[{ text: '客户', value: '客户' }, { text: '客户,供应商', value: '客户,供应商' }]"
                         :filter-method="filterTags"
                         filter-placement="bottom-end"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户经理"
                         prop="manager"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="营业执照"
                         prop="business_license_url"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="success"
                       plain
                       @click="businessPdf(scope.row)"
                       icon="el-icon-view">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="开票信息"
                         prop="billing_information_url"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="primary"
                       plain
                       @click="billingPdf(scope.row)"
                       icon="el-icon-view">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         width="180"
                         label="操作">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="新增客户"
                 :visible.sync="dialogFormVisible"
                 append-to-body>
        <el-form ref="customerinfo-form"
                 :model="customerinfo"
                 label-width="120px"
                 :rules="formRules">
          <el-form-item label="客户名称"
                        prop="name">
            <el-input v-model="customerinfo.name"></el-input>
          </el-form-item>
          <el-form-item label="所在部门"
                        prop="deptid">
            <el-select v-model="customerinfo.deptid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in deptoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型"
                        prop="mid">
            <el-select v-model="customerinfo.mid"
                       placeholder="请选择"
                       multiple
                       filterable
                       remote
                       reserve-keyword>
              <el-option v-for="item in mertypeoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="简称"
                        prop="abbreviation">
            <el-input v-model="customerinfo.abbreviation"></el-input>
          </el-form-item>
          <el-form-item label="曾用名"
                        prop="beforename">
            <el-input v-model="customerinfo.beforename"></el-input>
          </el-form-item>
          <el-form-item label="关联企业"
                        prop="associated">
            <el-select v-model="customerinfo.associated"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in customerdata"
                         :key="item.id"
                         :label="item.name"
                         :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司类型"
                        prop="type">
            <el-input v-model="customerinfo.type"></el-input>
          </el-form-item>
          <el-form-item label="办公地址"
                        prop="addr">
            <el-input v-model="customerinfo.addr"></el-input>
          </el-form-item>
          <el-form-item label="员工规模"
                        prop="scale">
            <el-input v-model="customerinfo.scale"></el-input>
          </el-form-item>
          <el-form-item label="业务规模"
                        prop="business_scale">
            <el-input v-model="customerinfo.business_scale"></el-input>
          </el-form-item>
          <el-form-item label="六六"
                        prop="liuliu">
            <el-select v-model="customerinfo.liuliu"
                       placeholder="请选择">
              <el-option v-for="item in liuliuoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="钢小二"
                        prop="gangxiaoer">
            <el-select v-model="customerinfo.gangxiaoer"
                       placeholder="请选择">
              <el-option v-for="item in gangxiaoeroptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户经理"
                        prop="username">
            <el-select v-model="customerinfo.userid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in useroptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注"
                        prop="rank">
            <el-input v-model="customerinfo.rank"></el-input>
          </el-form-item>
          <el-form-item label="营业执照"
                        prop="business_license">
            <el-upload class="upload"
                       action="https://bmapi.gangerp.com/api/upFile"
                       :headers="myHeaders"
                       :on-remove="businessRemove"
                       :on-success="filesuccess"
                       multiple
                       :file-list="business"
                       :before-upload="beforeUpload">
              <el-button size="small"
                         type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="开票信息"
                        prop="billing_information">
            <el-upload class="upload"
                       action="https://bmapi.gangerp.com/api/upFile"
                       :headers="myHeaders"
                       :on-remove="billingRemove"
                       :on-success="billingsuccess"
                       multiple
                       :file-list="billing"
                       :before-upload="beforeUpload">
              <el-button size="small"
                         type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateCustomer">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改客户"
                 :visible.sync="dialogFormVisibles"
                 append-to-body>
        <el-form ref="customerinfos-form"
                 :model="customerinfos"
                 label-width="120px"
                 :rules="formRuless">
          <el-form-item label="客户名称"
                        prop="name">
            <el-input v-model="customerinfos.name"></el-input>
          </el-form-item>
          <el-form-item label="所在部门"
                        prop="deptid">
            <el-select v-model="customerinfos.deptid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in deptoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型"
                        prop="mid">
            <el-select v-model="customerinfos.mid"
                       placeholder="请选择"
                       multiple
                       filterable
                       remote
                       reserve-keyword>
              <el-option v-for="item in mertypeoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="简称"
                        prop="abbreviation">
            <el-input v-model="customerinfos.abbreviation"></el-input>
          </el-form-item>
          <el-form-item label="曾用名"
                        prop="beforename">
            <el-input v-model="customerinfos.beforename"></el-input>
          </el-form-item>
          <el-form-item label="关联企业"
                        prop="associated">
            <el-select v-model="customerinfos.associated"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in customerdata"
                         :key="item.id"
                         :label="item.name"
                         :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司类型"
                        prop="type">
            <el-input v-model="customerinfos.type"></el-input>
          </el-form-item>
          <el-form-item label="办公地址"
                        prop="addr">
            <el-input v-model="customerinfos.addr"></el-input>
          </el-form-item>
          <el-form-item label="员工规模"
                        prop="scale">
            <el-input v-model="customerinfos.scale"></el-input>
          </el-form-item>
          <el-form-item label="业务规模"
                        prop="business_scale">
            <el-input v-model="customerinfos.business_scale"></el-input>
          </el-form-item>
          <el-form-item label="六六"
                        prop="liuliu">
            <el-select v-model="customerinfos.liuliu"
                       placeholder="请选择">
              <el-option v-for="item in liuliuoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="钢小二"
                        prop="gangxiaoer">
            <el-select v-model="customerinfos.gangxiaoer"
                       placeholder="请选择">
              <el-option v-for="item in gangxiaoeroptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户经理"
                        prop="username">
            <el-select v-model="customerinfos.userid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in useroptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注"
                        prop="rank">
            <el-input v-model="customerinfos.rank"></el-input>
          </el-form-item>
          <el-form-item label="营业执照"
                        prop="business_license">
            <el-upload class="upload"
                       action="https://bmapi.gangerp.com/api/upFile"
                       :limit='1'
                       :headers="myHeaders"
                       :on-remove="businessaRemove"
                       :on-success="fileasuccess"
                       :file-list="businessa"
                       :before-upload="beforeUpload">
              <el-button size="small"
                         type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="开票信息"
                        prop="billing_information">
            <el-upload class="upload"
                       action="https://bmapi.gangerp.com/api/upFile"
                       :limit='1'
                       :headers="myHeaders"
                       :on-remove="billingaRemove"
                       :on-success="billingasuccess"
                       :file-list="billinga"
                       :before-upload="beforeUpload">
              <el-button size="small"
                         type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateCustomers">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getCustomerList, addNewsCustomer, getdelCustomer, getEditCustomer, upDataCustomer, getDepartmentList, getCustomerSearch, getMerchantsType } from '@/api/global'
import { getUserList } from '@/api/user'
import globalBus from '@/utils/global-bus'
const Base64 = require('js-base64').Base64

export default {
  name: 'CustomerIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      customerdatasearch: [],
      customerform: {
        id: ''
      },
      customerinfo: {
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        type: '',
        addr: '',
        scale: '',
        business_scale: '',
        liuliu: '',
        gangxiaoer: '',
        business_license: '',
        billing_information: '',
        business_license_url: '',
        billing_information_url: '',
        rank: ''
      },
      myHeaders: '',
      business: [],
      billing: [],
      businessa: [],
      billinga: [],
      updateProfileLoading: false,
      updateLoading: false,
      customerformRules: {
        id: [{ required: true, message: '请输选择公司名', trigger: 'blur' }]
      },
      formRules: {
        name: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      customerdata: [],
      search: '',
      dialogFormVisible: false,
      dialogFormVisibles: false,
      customerinfos: {
        id: '',
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        type: '',
        addr: '',
        scale: '',
        business_scale: '',
        liuliu: '',
        business_license: '',
        billing_information: '',
        business_license_url: '',
        billing_information_url: '',
        gangxiaoer: '',
        rank: ''
      },
      formRuless: {
        name: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      deptoptions: [],
      useroptions: [],
      mertypeoptions: [],
      liuliuoptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }],
      gangxiaoeroptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }]
    }
  },
  created () {
    this.loadtoken()
    this.loadnav()
    this.loaddept()
    this.loadmerchantstype()
    this.loaduser()
    this.loadcustomerList()
  },
  mounted () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loaduser () {
      getUserList().then(res => {
        if (res.data.status === 'success') {
          this.useroptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'customer') {
        this.topname = '客商管理'
        this.navnames = '客户管理'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loaddept () {
      getDepartmentList().then(res => {
        if (res.data.status === 'success') {
          this.deptoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadmerchantstype () {
      getMerchantsType().then(res => {
        if (res.data.status === 'success') {
          this.mertypeoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadcustomerList () {
      getCustomerList().then(res => {
        if (res.data.status === 'success') {
          this.customerdata = res.data.data
          this.customerdatasearch = res.data.data
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    searchData () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['customer-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updateLoading = true
        getCustomerSearch(this.customerform).then(res => {
          this.loading = false
          this.updateLoading = false
          this.customerdata = res.data.data
        }).catch(err => {
          this.updateLoading = false
          this.loading = false
          console.log('失获取数据败', err)
        })
      })
    },
    onUpdateCustomer () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['customerinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addNewsCustomer(this.customerinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.customerinfo.name = ''
          this.customerinfo.deptid = ''
          this.customerinfo.userid = ''
          this.loadcustomerList()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateCustomers () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['customerinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataCustomer(this.customerinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisibles = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.customerinfos.name = ''
            this.customerinfos.deptid = ''
            this.customerinfos.userid = ''
            this.loadcustomerList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    handleEdit (row) {
      getEditCustomer({ id: row.id }).then(res => {
        this.dialogFormVisibles = true
        this.customerinfos = res.data.data
        this.businessa = res.data.dataa
        this.billinga = res.data.datab
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getdelCustomer({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadcustomerList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    filterTag (value, row) {
      return row.deptname === value
    },
    filterTags (value, row) {
      return row.mername === value
    },
    liuliufilterTags (value, row) {
      return row.liuliu === value
    },
    gangxiaoerfilterTags (value, row) {
      return row.gangxiaoer === value
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.customerinfo.business_license = res.data.name
        this.customerinfo.business_license_url = res.data.url
        this.business = this.business.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessRemove (file) {
      this.business.some((item, i) => {
        if (item.url === file.url) {
          this.business.splice(i, 1)
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    billingsuccess (res) {
      if (res.status === 'success') {
        this.customerinfo.billing_information = res.data.name
        this.customerinfo.billing_information_url = res.data.url
        this.billing = this.billing.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    billingRemove (file) {
      this.billing.some((item, i) => {
        if (item.url === file.url) {
          this.billing.splice(i, 1)
          return true
        }
      })
    },
    fileasuccess (res) {
      if (res.status === 'success') {
        this.customerinfos.business_license = res.data.name
        this.customerinfos.business_license_url = res.data.url
        this.businessa = this.businessa.concat(res.data)
        console.log(this.businessa)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessaRemove (file) {
      this.businessa.some((item, i) => {
        if (item.url === file.url) {
          this.businessa.splice(i, 1)
          return true
        }
      })
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    billingasuccess (res) {
      if (res.status === 'success') {
        this.customerinfos.billing_information = res.data.name
        this.customerinfos.billing_information_url = res.data.url
        this.billinga = this.billinga.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    billingaRemove (file) {
      this.billinga.some((item, i) => {
        if (item.url === file.url) {
          this.billinga.splice(i, 1)
          return true
        }
      })
    },
    beforeUpload (file) {
    },
    businessPdf (row) {
      if (!row.business_license_url && typeof (row.business_license_url) !== 'undefined' && row.url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        const routeData = this.$router.resolve({
          name: 'pdf',
          query: {
            url: Base64.encode(row.business_license_url)
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
    billingPdf (row) {
      if (!row.billing_information_url && typeof (row.billing_information_url) !== 'undefined' && row.url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        const routeData = this.$router.resolve({
          name: 'pdf',
          query: {
            url: Base64.encode(row.billing_information_url)
          }
        })
        window.open(routeData.href, '_blank')
      }
    }
  }
}
</script>

<style scoped lang="less">
.filter-card {
  margin-bottom: 10px;
}
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
</style>
