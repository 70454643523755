<template>
  <div class="manage-container">
    <el-card class="filter-card"
             shadow="hover">
      <!-- 数据筛选表单 -->
      <el-form ref="voucher-form"
               :model="voucherform"
               label-width="80px"
               :rules="brandsformRules">
        <el-form-item label="品牌名"
                      prop="id"
                      class="form-items">
          <el-select v-model="voucherform.id"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="searchData">
            <el-option v-for="item in brandsdata"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!--<el-form-item style="margin-left:-80px ;"
                      class="form-items">
          <el-button size="primary"
                     @click="dialogFormBrands = true"
                     icon="el-icon-plus">新增品牌</el-button>
        </el-form-item>-->
        <el-form-item style="margin-left:-80px ;"
                      class="form-items">
          <el-button size="primary"
                     icon="el-icon-plus"
                     type="success"
                     @click="dialogFormBrand = true">新增主要供应商</el-button>
        </el-form-item>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>
    <el-card class="box-card"
             shadow="hover">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="layui-elem-quote"> 基本信息</div>
          </template>
          <el-row>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">品牌名：</span>{{brandsinfoss.name}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">优势区域：</span>{{brandsinfoss.advantage_area}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">生产工艺：</span>{{brandsinfoss.production_process}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">配送方式：</span>{{brandsinfoss.shipping}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">产量：</span>{{brandsinfoss.yield}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">广东投放量：</span>{{brandsinfoss.guangdong_investment}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">产品规格：</span>{{brandsinfoss.production_specs}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">库存区域：</span>{{brandsinfoss.inventory_area}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">负公差情况：</span>{{brandsinfoss.circumstances}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">磅差处理政策：</span>{{brandsinfoss.handle}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">主要供应商：</span>{{brandsinfoss.major_distributors_name}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">主要客户：</span>{{brandsinfoss.main_customers}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">项目结构：</span>{{brandsinfoss.project_structure}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">备注：</span>{{brandsinfoss.rank}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <el-button type="warning"
                           size="mini"
                           plain
                           @click="brandsedit(brandsinfoss.id)">修改</el-button>
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <div class="layui-elem-quote"> 主要供应商</div>
          </template>
          <div class="demo-block"
               v-for="(item,index) in supplierlist"
               :key="index">
            <el-row>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">供应商名：</span>{{item.name}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content "><span class="grid-content-text">经销类型：</span>{{item.type}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">月销协议量：</span>{{item.agreement_sales}} 吨</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">优势区域：</span>{{item.advantage_area}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">付款类型：</span>{{item.settlement_method}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">配送方式：</span>{{item.delivery_mode}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content"><span class="grid-content-text">优点：</span>{{item.advantage}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content"><span class="grid-content-text">缺点：</span>{{item.shortcoming}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content"><span class="grid-content-text">厂家政策：</span>{{item.factory_policy}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple"><span class="grid-content-text">备注：</span>{{item.rank}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-button type="warning"
                             size="mini"
                             plain
                             @click="brandedit(item.sid)">修改</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-dialog title="新增品牌"
               :visible.sync="dialogFormBrands"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="brandsinfo-form"
               :model="brandsinfo"
               label-width="120px"
               :rules="formRules">
        <el-form-item label="品牌名"
                      prop="name">
          <el-select v-model="brandsinfo.name"
                     filterable
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in brandlist"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产量"
                      prop="yield">
          <el-input v-model="brandsinfo.yield"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="广东投放量"
                      prop="guangdong_investment">
          <el-input v-model="brandsinfo.guangdong_investment"></el-input>
        </el-form-item>
        <el-form-item label="生产规格"
                      prop="production_specs">
          <el-input v-model="brandsinfo.production_specs"></el-input>
        </el-form-item>
        <el-form-item label="优势区域"
                      prop="advantage_area">
          <el-input v-model="brandsinfo.advantage_area"></el-input>
        </el-form-item>
        <el-form-item label="库存区域"
                      prop="inventory_area">
          <el-input v-model="brandsinfo.inventory_area"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="主要供应商"
                      prop="major_distributors">
          <el-select v-model="brandsinfo.major_distributors"
                     filterable
                     multiple
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in supplierdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主要客户"
                      prop="main_customers">
          <el-select v-model="brandsinfo.main_customers"
                     filterable
                     multiple
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in customerdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目结构"
                      prop="project_structure">
          <el-input v-model="brandsinfo.project_structure"></el-input>
        </el-form-item>
        <el-form-item label="生产工艺"
                      prop="production_process">
          <el-input v-model="brandsinfo.production_process"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="负公差情况"
                      prop="circumstances">
          <el-input v-model="brandsinfo.circumstances"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="磅差处理政策"
                      prop="handle">
          <el-input v-model="brandsinfo.handle"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="配送方式"
                      prop="shipping">
          <el-input v-model="brandsinfo.shipping"></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="brandsinfo.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updatePhotoLoading "
                     @click="onUpdataBrands">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改品牌"
               :visible.sync="dialogFormBrandss"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="brandsinfos-form"
               :model="brandsinfos"
               label-width="120px"
               :rules="formRuless">
        <el-form-item label="品牌名"
                      prop="name">
          <el-select v-model="brandsinfos.name"
                     filterable
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in brandlist"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产量"
                      prop="yield">
          <el-input v-model="brandsinfos.yield"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="广东投放量"
                      prop="guangdong_investment">
          <el-input v-model="brandsinfos.guangdong_investment"></el-input>
        </el-form-item>
        <el-form-item label="生产规格"
                      prop="production_specs">
          <el-input v-model="brandsinfos.production_specs"></el-input>
        </el-form-item>
        <el-form-item label="优势区域"
                      prop="advantage_area">
          <el-input v-model="brandsinfos.advantage_area"></el-input>
        </el-form-item>
        <el-form-item label="库存区域"
                      prop="inventory_area">
          <el-input v-model="brandsinfos.inventory_area"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="主要供应商"
                      prop="major_distributors">
          <el-select v-model="brandsinfos.major_distributors"
                     filterable
                     multiple
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in supplierdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主要客户"
                      prop="main_customers">
          <el-select v-model="brandsinfos.main_customers"
                     filterable
                     multiple
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in customerdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目结构"
                      prop="project_structure">
          <el-input v-model="brandsinfos.project_structure"></el-input>
        </el-form-item>
        <el-form-item label="生产工艺"
                      prop="production_process">
          <el-input v-model="brandsinfos.production_process"></el-input>
        </el-form-item>
        <el-form-item label="负公差情况"
                      prop="circumstances">
          <el-input v-model="brandsinfos.circumstances"></el-input>
        </el-form-item>
        <el-form-item label="磅差处理政策"
                      prop="handle">
          <el-input v-model="brandsinfos.handle"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="配送方式"
                      prop="shipping">
          <el-select v-model="brandsinfos.shipping"
                     placeholder="请选择"
                     multiple>
            <el-option v-for="item in delivery_modeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="brandsinfos.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updatePhotoLoading"
                     @click="onUpdataBrandss">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="新增主要供应商"
               :visible.sync="dialogFormBrand"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="brandinfo-form"
               :model="brandinfo"
               label-width="120px"
               :rules="formRulesbrand">
        <el-form-item label="供应商名称"
                      prop="sid">
          <el-select v-model="brandinfo.sid"
                     filterable
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in supplierdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌名"
                      prop="name">
          <el-select v-model="brandinfo.name"
                     filterable
                     placeholder="请输入品牌"
                     style="width:100%"
                     disabled>
            <el-option v-for="item in brandlist"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经销类型"
                      prop="type">
          <el-select v-model="brandinfo.type"
                     placeholder="请选择">
            <el-option v-for="item in typeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厂家政策"
                      prop="factory_policy">
          <el-input type="textarea"
                    v-model="brandinfo.factory_policy"></el-input>
        </el-form-item>
        <el-form-item label="月销协议量"
                      prop="agreement_sales">
          <el-input v-model="brandinfo.agreement_sales"
                    style="width:230px"></el-input> 吨
        </el-form-item>
        <el-form-item label="优势区域"
                      prop="advantage_area">
          <el-input type="textarea"
                    v-model="brandinfo.advantage_area"></el-input>
        </el-form-item>
        <el-form-item label="优点"
                      prop="advantage">
          <el-input v-model="brandinfo.advantage"></el-input>
        </el-form-item>
        <el-form-item label="缺点"
                      prop="shortcoming">
          <el-input v-model="brandinfo.shortcoming"></el-input>
        </el-form-item>
        <el-form-item label="付款类型"
                      prop="settlement_method">
          <el-select v-model="brandinfo.settlement_method"
                     placeholder="请选择">
            <el-option v-for="item in settlement_methodoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配送方式"
                      prop="settlement_method">
          <el-select v-model="brandinfo.delivery_mode"
                     placeholder="请选择"
                     multiple>
            <el-option v-for="item in delivery_modeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="brandinfo.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateBrand">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改主要供应商"
               :visible.sync="dialogFormBrandsss"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="brandinfos-form"
               :model="brandinfos"
               label-width="120px"
               :rules="formRulesbrands">
        <el-form-item label="供应商名称"
                      prop="sid">
          <el-select v-model="brandinfos.sid"
                     filterable
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in supplierdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌名"
                      prop="name">
          <el-select v-model="brandinfos.name"
                     filterable
                     placeholder="请输入品牌"
                     style="width:100%"
                     disabled>
            <el-option v-for="item in brandlist"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经销类型"
                      prop="type">
          <el-select v-model="brandinfos.type"
                     placeholder="请选择">
            <el-option v-for="item in typeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厂家政策"
                      prop="factory_policy">
          <el-input type="textarea"
                    v-model="brandinfos.factory_policy"></el-input>
        </el-form-item>
        <el-form-item label="月销协议量"
                      prop="agreement_sales">
          <el-input v-model="brandinfos.agreement_sales"
                    style="width:230px"></el-input> 吨
        </el-form-item>
        <el-form-item label="优势区域"
                      prop="advantage_area">
          <el-input type="textarea"
                    v-model="brandinfos.advantage_area"></el-input>
        </el-form-item>
        <el-form-item label="优点"
                      prop="advantage">
          <el-input v-model="brandinfos.advantage"></el-input>
        </el-form-item>
        <el-form-item label="缺点"
                      prop="shortcoming">
          <el-input v-model="brandinfos.shortcoming"></el-input>
        </el-form-item>
        <el-form-item label="付款类型"
                      prop="settlement_method">
          <el-select v-model="brandinfos.settlement_method"
                     placeholder="请选择">
            <el-option v-for="item in settlement_methodoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配送方式"
                      prop="settlement_method">
          <el-select v-model="brandinfos.delivery_mode"
                     placeholder="请选择"
                     multiple>
            <el-option v-for="item in delivery_modeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="brandinfos.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateBrands">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getBrands, addBrands, getSupplierList, getCustomerList, getBrandsList, upDataBrands, getBrandList, getEditBrand, addNewsBrand, upDataBrand } from '@/api/global'
import globalBus from '@/utils/global-bus'
import axios from 'axios'

export default {
  name: 'BrandsinfoIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      activeNames: ['1'],
      voucherform: {
        id: 1
      },
      top: '15px',
      supplierdata: [],
      supplierlist: [],
      customerdata: [],
      updatePhotoLoading: false,
      updateProfileLoading: false,
      brandsrdata: [],
      brandsinfoss: '',
      brandsinfo: {
        name: '',
        yield: '',
        guangdong_investment: '',
        production_specs: '',
        advantage_area: '',
        inventory_area: '',
        major_distributors: '',
        main_customers: '',
        project_structure: '',
        production_process: '',
        circumstances: '',
        shipping: '',
        handle: '',
        rank: ''
      },
      brandsformRules: {
        id: [{ required: true, message: '请输选择公司名', trigger: 'blur' }]
      },
      formRules: {
        name: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      dialogFormBrands: false,
      dialogFormBrandss: false,
      brandsinfos: {
        id: '',
        name: '',
        yield: '',
        guangdong_investment: '',
        production_specs: '',
        advantage_area: '',
        inventory_area: '',
        major_distributors: '',
        main_customers: '',
        project_structure: '',
        production_process: '',
        circumstances: '',
        shipping: '',
        handle: '',
        rank: ''
      },
      formRuless: {
        name: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      brandsdata: [],
      brandlist: [],
      brandlistdata: [],
      dialogFormBrand: false,
      dialogFormBrandsss: false,
      brandinfo: {
        sid: '',
        name: '',
        type: '',
        factory_policy: '',
        agreement_sales: '',
        advantage_area: '',
        advantage: '',
        shortcoming: '',
        price: '',
        delivery_mode: '',
        settlement_method: '',
        rank: ''
      },
      brandinfos: {
        id: '',
        sid: '',
        name: '',
        type: '',
        factory_policy: '',
        agreement_sales: '',
        advantage_area: '',
        advantage: '',
        shortcoming: '',
        price: '',
        delivery_mode: '',
        settlement_method: '',
        rank: ''
      },
      typeoptions: [{
        value: '一级代理商',
        label: '一级代理商'
      }, {
        value: '二级代理商',
        label: '二级代理商'
      }, {
        value: '销货库存商',
        label: '销货库存商'
      }],
      settlement_methodoptions: [{
        value: '款到发货',
        label: '款到发货'
      }, {
        value: '出数付款',
        label: '出数付款'
      }],
      delivery_modeoptions: [{
        value: '仓库自提',
        label: '仓库自提'
      }, {
        value: '仓库包送',
        label: '仓库包送'
      }, {
        value: '钢厂直送',
        label: '钢厂直送'
      }],
      formRulesbrand: {
        sid: [
          { required: true, message: '请选择供应商', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入品牌名', trigger: 'blur' }
        ]
      },
      formRulesbrands: {
        sid: [
          { required: true, message: '请选择供应商', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入品牌名', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadbrand()
    this.loadnav()
    this.loadsupplierList()
    this.loadcustomerList()
    this.loadbrandsList()
    this.loadbrandsinfo()
  },
  mounted () {
  },
  methods: {
    loadbrand () {
      const _self = this
      axios.post('https://api.66gangtie.com/api/bmGetBrand').then(function (response) {
        if (response.data.status === 'success') {
          _self.brandlist = response.data.data
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'brands') {
        this.topname = '客商管理'
        this.navnames = '品牌分析'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadsupplierList () {
      getSupplierList().then(res => {
        if (res.data.status === 'success') {
          this.supplierdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadcustomerList () {
      getCustomerList().then(res => {
        if (res.data.status === 'success') {
          this.customerdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadbrandsList () {
      getBrandsList().then(res => {
        if (res.data.status === 'success') {
          this.brandsdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadbrandsinfo () {
      getBrands({ id: this.voucherform.id }).then(res => {
        this.brandsinfoss = res.data.data
        this.brandinfo.name = res.data.data.name
        this.supplierlist = res.data.newarr
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    searchData () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['voucher-form'].validate(valid => {
        if (!valid) {
          return false
        }
        getBrands({ id: this.voucherform.id }).then(res => {
          this.brandsinfoss = res.data.data
          this.brandinfo.name = res.data.data.name
          this.supplierlist = res.data.newarr
        }).catch(err => {
          console.log('修改失败', err)
        })
      })
    },
    onUpdataBrands () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['brandsinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addBrands(this.brandsinfo).then(res => {
        if (res.data.status === 'success') {
          this.brandsinfo = ''
          this.dialogFormBrands = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.brandsdata = res.data.data
          this.voucherform.id = res.data.id
          this.loadbrandsinfo()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    brandsedit (id) {
      this.dialogFormBrandss = true
      getBrands({ id: id }).then(res => {
        this.brandsinfos = res.data.data
        this.brandsinfos.major_distributors = res.data.major_distributors
        this.brandsinfos.main_customers = res.data.main_customers
        this.brandsinfos.shipping = res.data.shipping
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    onUpdataBrandss () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['brandsinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataBrands(this.brandsinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormBrandss = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadbrandsinfo()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('修改失败', err)
        })
      })
    },
    // 主营品牌
    loadbrandList () {
      getBrandList({ sid: this.voucherform.id }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.brandlistdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    brandedit (id) {
      this.dialogFormBrandsss = true
      getEditBrand({ id: id, name: this.brandsinfoss.name }).then(res => {
        if (res.data.status === 'success') {
          this.brandinfos = res.data.data
        } else {
          this.brandinfos.name = this.brandsinfoss.name
          this.brandinfos.sid = id
          this.brandinfos.id = ''
          this.brandinfos.dealer_name = ''
          this.brandinfos.type = ''
          this.brandinfos.factory_policy = ''
          this.brandinfos.agreement_sales = ''
          this.brandinfos.advantage_area = ''
          this.brandinfos.advantage = ''
          this.brandinfos.shortcoming = ''
          this.brandinfos.price = ''
          this.brandinfos.delivery_mode = ''
          this.brandinfos.settlement_method = ''
          this.brandinfos.rank = ''
        }
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    onUpdateBrand () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['brandinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfobrand()
      })
    },
    sendinfobrand () {
      this.updatePhotoLoading = true
      addNewsBrand(this.brandinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormBrand = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadbrandsinfo()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateBrands () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['brandinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataBrand(this.brandinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormBrandsss = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadbrandsinfo()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
.form-items {
  float: left;
  margin-right: 5px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
.grid-content {
  font-size: 14px;
  padding: 10px 0px;
}
.layui-elem-quote {
  padding: 3px;
  line-height: 10px;
  border-left: 3px solid #4596fc;
  border-radius: 0 2px 2px 0;
  font-weight: 700 !important;
}
/deep/.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0px 0;
}
/deep/.el-collapse-item__header {
  line-height: 48px;
  color: #303133;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.demo-block {
  border-bottom: 1px solid #ebebeb;
  transition: 0.2s;
}
.grid-content-text {
  font-weight: 600;
}
</style>
