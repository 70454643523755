<template>
  <div class="settings-container">
    <el-card class="box-card"
             shadow="hover">
      <div slot="header"
           class="clearfix">
        <!-- 面包屑路径导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>个人设置</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- /面包屑路径导航 -->
      </div>
      <el-row>
        <el-col :span="15">
          <el-form ref="userinfo-form"
                   :model="userinfo"
                   label-width="120px"
                   :rules="formRules">
            <el-form-item label="姓名">
              {{ userinfo.name }}
            </el-form-item>
            <el-form-item label="手机">
              {{ userinfo.mobile }}
            </el-form-item>
            <el-form-item label="旧密码"
                          prop="oldpassword">
              <el-input v-model="userinfo.oldpassword"
                        show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码"
                          prop="password">
              <el-input v-model="userinfo.password"
                        show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码"
                          prop="confirmpassword">
              <el-input v-model="userinfo.confirmpassword"
                        show-password></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         :loading="updateProfileLoading"
                         @click="onUpdateUser">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :offset="2"
                :span="4">
          <div class="avatar">
            <label for="file">
              <el-avatar shape="square"
                         :size="150"
                         fit="cover"
                         :src="headimgurl"></el-avatar>
              <p>点击修改头像</p>
            </label>
            <input id="file"
                   type="file"
                   ref="file"
                   hidden
                   @change="onFileChange">
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog title="修改头像"
               :visible.sync="dialogVisible"
               append-to-body
               @opened="onDialogOpened"
               @closed="onDialogClosed">
      <div class="preview-image-wrap">
        <img class="preview-image"
             :src="previewImage"
             ref="preview-image">
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   :loading="updatePhotoLoading"
                   @click="onUpdatePhoto">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserProfile, updateUserPhoto, modifyPassword } from '@/api/user'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import globalBus from '@/utils/global-bus'

export default {
  name: 'SettingsIndex',
  components: {},
  props: {},
  data () {
    return {
      userinfo: {
        email: '',
        id: null,
        oldpassword: '',
        confirmpassword: '',
        password: '',
        mobile: '',
        name: '',
        headimgurl: ''
      }, // 用户资料
      headimgurl: '',
      dialogVisible: false, // 控制上传图片裁切预览的显示状态
      previewImage: '', // 预览图片
      cropper: null, // 裁切器示例
      updatePhotoLoading: false, // 更新用户头像 loading 状态
      updateProfileLoading: false, // 更新基本信息的 loading 状态
      formRules: {
        oldpassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,14}$/, message: '密码由数字+字母形式组合', trigger: 'blur' }

        ],
        confirmpassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadUser()
  },
  mounted () { },
  methods: {
    onUpdateUser () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['userinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    loadUser () {
      getUserProfile().then(res => {
        this.userinfo = res.data.data
        if (res.data.data.headimgurl === undefined || res.data.data.headimgurl <= 0) {
          this.headimgurl = 'https://66gangtie.oss-cn-shanghai.aliyuncs.com/web/uploads/20210106/1657895623edb24ea.jpg'
        } else {
          this.headimgurl = 'https://bmapi.gangerp.com/' + res.data.data.headimgurl
        }
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      modifyPassword(this.userinfo).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    // 处于图片预览
    onFileChange () {
      const file = this.$refs.file
      const blobData = window.URL.createObjectURL(file.files[0])
      this.previewImage = blobData
      this.dialogVisible = true
      this.$refs.file.value = ''
    },
    // 对话框关闭，销毁裁切器
    onDialogClosed () {
      // this.cropper.destroy()
      this.updatePhotoLoading = false
    },
    // 打开图片剪辑实例
    onDialogOpened () {
      const image = this.$refs['preview-image']
      if (this.cropper) {
        this.cropper.replace(this.previewImage)
        return false
      }
      this.cropper = new Cropper(image, {
        viewMode: 1,
        dragMode: 'none',
        aspectRatio: 1,
        cropBoxResizable: false
      })
    },
    // 上传头像
    onUpdatePhoto () {
      this.updatePhotoLoading = true
      // 获取裁切的图片对象
      this.cropper.getCroppedCanvas().toBlob(file => {
        const fd = new FormData()
        fd.append('headImgUrl', file)
        // 请求更新用户头像请求提交 fd
        this.blobToBase64(file).then(res => {
          // 转化后的base64
          updateUserPhoto({ headImgUrl: res }).then(res => {
            // console.log(res.data)
            // 关闭对话框
            this.dialogVisible = false
            // 更新视图展示
            // 直接把裁切结果的文件对象转为 blob 数据本地预览
            this.headimgurl = window.URL.createObjectURL(file)
            // 关闭确定按钮的 loading
            this.updatePhotoLoading = false
            this.$message({
              type: 'success',
              message: '更新头像成功'
            })
            // 更新顶部登录用户的信息
            globalBus.$emit('personals', { status: 1, headimgurl: this.headimgurl })
            // 把服务端返回的图片进行展示有点慢
            // this.user.photo = res.data.data.photo
          })
        })
      })
    },
    // 将blob转为base64
    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          resolve(e.target.result)
        }
        // readAsDataURL
        fileReader.readAsDataURL(blob)
        fileReader.onerror = () => {
          reject(new Error('文件流异常'))
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
</style>
