<template>
  <div>
    <iframe style="width:100%;height:100%;"
            :src="pathUrl"
            frameborder="0"
            scrolling="no"
            id="bdIframe">
    </iframe>
  </div>
</template>

<script>
import axios from 'axios'
const Base64 = require('js-base64').Base64
export default {
  name: 'pdf-component',
  data () {
    return {
      pathUrl: ''
    }
  },
  mounted () {
    const oIframe = document.getElementById('bdIframe')
    const deviceWidth = document.documentElement.clientWidth
    const deviceHeight = document.documentElement.clientHeight
    oIframe.style.width = (Number(deviceWidth) - 30) + 'px'
    oIframe.style.height = (Number(deviceHeight) - 30) + 'px'
    const url = this.$route.query.url
    const urls = Base64.decode(url)
    this.geturlData(urls)
  },
  methods: {
    // 接收文件流转换地址再显示
    geturlData (url) {
      axios.get(url, {
        responseType: 'blob'
      })
        .then(res => {
          const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
          const objectUrl = URL.createObjectURL(blob)
          this.pathUrl = '../../static/pdf/web/viewer.html?file=' + objectUrl
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>
</style>
