<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <el-form :model="form"
                 ref="customer-form">
          <el-form-item label="单号"
                        class="el-form-items">
            <el-input placeholder="请输入销售单号"
                      v-model="form.number"
                      clearable
                      class="input-with-select"
                      style="width: 150px;">
            </el-input>

          </el-form-item>
          <el-form-item label="日期"
                        class="el-form-items">
            <el-date-picker v-model="form.sales_date1"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;">
            </el-date-picker>
            <el-date-picker v-model="form.sales_date2"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;margin-left:5px">
            </el-date-picker>
          </el-form-item>
          <div style="float: left;margin-top:7px">
            <el-button icon="el-icon-search"
                       type="primary"
                       size="mini"
                       :loading="updateLoading"
                       @click="search"
                       style="padding: 6px 10px;"></el-button>
          </div>
        </el-form>
      </el-row>
      <el-row>
        <el-form :model="productselectdata"
                 ref="addorder-form"
                 :show-message="false">
          <el-table :data="productselectdata.orderlist"
                    ref="multipleTable"
                    style="width: 100%"
                    row-key="id"
                    v-loading="loading"
                    border
                    lazy
                    :highlight-current-row="true"
                    height="calc(100vh - 200px)"
                    :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                    id="table"
                    class="table">
            <el-table-column label="序号"
                             type="index"
                             align="center"
                             width="50">
            </el-table-column>
            <el-table-column prop="orderid"
                             label="合同编号"
                             align="center"
                             :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <el-link :type="scope.row.Purchasetypeedit"
                         :underline="false">
                  <div @click="handleprinter(scope.row.orderid)">{{ scope.row. orderid}}</div>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column prop="number"
                             label="单据号"
                             align="center"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="type"
                             label="单据类型"
                             align="center"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="CompanyName"
                             label="我方公司名"
                             align="center"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="status"
                             label="签署状态"
                             align="center"
                             :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.status===0" style="color: #E6A23C;">签署中</span>
                <span v-else-if="scope.row.status===1"
                      style="color:#67C23A;font-weight:500;">已完成</span>
              </template>
            </el-table-column>
            <el-table-column prop="applicant"
                             label="创建人"
                             align="center"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="applicant_time"
                             label="创建时间"
                             align="center"
                             :show-overflow-tooltip="true">
            </el-table-column>
          </el-table>
        </el-form>
        <div style="margin-top:10px;"
             v-show="total!=0">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[30, 50, 100,200]"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-row>
    </el-card>
    <el-dialog title="签章显示"
               :visible.sync="dialogVisiblesprinter"
               width="70%"
               top="10px"
               append-to-body
               :close-on-click-modal="false">
      <el-tabs v-model="activeName"
               @tab-click="handleClick">
        <el-tab-pane label="销售合同"
                     name="first">
          <div style="width:100%;margin: 0 auto; background-color:white;"
               id="pdfDom">
            <div style="width:90%;margin: 0 auto;">
              <div id="deliverynote"
                   ref="deliverynote"
                   style="width:100%;height:100%; padding: 30px 0;">
                <div
                     style="height: 31px; overflow: visible; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-size: 24px; font-style: normal; font-weight: bold; text-decoration: none; text-align: center; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; line-height: normal; margin-bottom: 30px;">
                  钢材购销合同（批次）</div>
                <div style="width: 70%;float: left; margin-bottom: 15px; "
                     class="print">买方（甲方）：{{sellvo.CustomerName}}
                </div>
                <div style="width: 30%;float: left;margin-bottom: 15px;"
                     class="print">合同编号：
                </div>
                <div style="width: 70%;float: left;margin-bottom: 15px;"
                     class="print">卖方（乙方）：{{sellvo.CompanyName}}
                </div>
                <div style="width: 30%;float: left;margin-bottom: 15px;"
                     class="print"> 签订地点：广州市越秀区
                </div>
                <div style="width: 100%;float: left;"
                     class="print">甲乙双方经友好协商，根据《中华人民共和国民法典》等法律、法规之规定，本着自愿、平等、互惠的原则，经充分协商共同达成如下钢材购销合同，其合同条款如下：
                </div>
                <div style="width: 100%;float: left;"
                     class="print">一、标的物名称、规格、材质、生产厂家、数量及价格：
                </div>
                <div style="width: 100%;float: left;"
                     class="print">买方向卖方购买钢材，以实际发货数量为准，具体明细如下：
                </div>
                <div style="padding-top: 15px;   clear: both;">
                  <table align="center"
                         style="width:100%;height:100%;"
                         cellspacing="0"
                         cellpadding="0"
                         border="0"
                         class="xwyc-table">
                    <thead>
                      <tr>
                        <th align="center"
                            width="90">产地</th>
                        <th align="center"
                            width="90">品名</th>
                        <th align="center"
                            width="120">规格（mm）</th>
                        <th align="center"
                            width="100">材质</th>
                        <th align="center"
                            width="100">数量（吨）</th>
                        <th align="center"
                            width="90">单价</th>
                        <th align="center"
                            width="120">金额</th>
                        <th align="center"
                            width="100">提货仓库</th>
                        <th align="center">备注</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in sellbilllist"
                          :key="index">
                        <td align="center">{{item.OriginName}}</td>
                        <td align="center">{{item.ProductName}}</td>
                        <td align="center">{{item.SpecificationName}}</td>
                        <td align="center">{{item.MaterialName}}</td>
                        <td align="center">{{item.SellQuantity}}</td>
                        <td align="center">{{item.SellPrice}}</td>
                        <td align="center">{{item.SellAmount}}</td>
                        <td align="center">{{item.SourceWarehouse}}</td>
                        <td align="center">{{item.Memo}}</td>
                      </tr>
                      <tr>
                        <td colspan="4"
                            align="center">合计</td>
                        <td align="center">{{sellvo.sellnumber}}</td>
                        <td align="center"></td>
                        <td align="center"
                            colspan="3">{{sellvo.SellEntireAmout}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-row style="margin-top: 10px;">
                  <div style="width: 100%;float: left;"
                       class="print">
                    甲乙双方经友好协商，根据《中华人民共和国民法典》等法律、法规之规定，本着自愿、平等、互惠的原则，经充分协商共同达成如下钢材购销合同，其合同条款如下：
                  </div>
                  <div style="width: 100%;float: left;"
                       class="print">
                    二、付款及开票：先款后货，卖方收到全额货款后才安排发货，自发货之日起3天内，供需双方根据买方实提数量及双方确认的单价进行结算，多退少补。卖方应及时开具合格的增值税发票给买方。出实提数付款，根据买方实际提货数量、当天结算；收齐买方货款后，卖方应及时开具合格的增值税发票给买方。
                  </div>
                  <div style="width: 100%;float: left;"
                       class="print">
                    <dt>三、钢材标准及验收要求：</dt>
                    <dt>1.质量标准按照上表中对应材料的国家标准执行。如经初检不合格，双方联合复检；复检合格，买方继续使用并承
                      担复检费用，复检不合格卖方负责退换货事宜及相关费用。</dt>
                    <dt>2.合理磅差为不超过3‰，超出部分双方协商解决。如磅差有异议，货物签收前协商解决，签收后重量不能更改。</dt>
                    <dt>3.买方收货前确认货物外观、规格型号无异议，货物一旦签收后，非质量问题不能退换货。</dt>
                  </div>
                  <div style="width: 100%;float: left; margin-bottom: 15px;"
                       class="print">
                    <dt>四、其它事项：</dt>
                    <dt>1.本合同传真件有效。买卖双方业务往来的传真件、双方认可的复印件，销售单、调拨单，结算单、对账单等往来
                      书面单据与本合同具有同等法律效力。</dt>
                    <dt>2.争议解决：本合同履行过程中，双方如发生争议，应友好协商解决，若协商不成，双方均有权向合同签订所在地
                      人民法院提起诉讼。</dt>
                  </div>
                  <div style="width: 100%;float: left;"
                       class="print">双方确认如下：</div>
                  <div style="width: 55%;float: left; margin-bottom: 8px; "
                       class="print">买方（章）：{{sellvo.CustomerName}}
                  </div>
                  <div style="width: 44%;float: left;margin-bottom: 8px;"
                       class="print">卖方（章）：{{sellvo.CompanyName}}
                  </div>
                  <div style="width: 55%;float: left;margin-bottom: 8px;"
                       class="print">签约代表：
                  </div>
                  <div style="width: 44%;float: left;margin-bottom: 8px;"
                       class="print"> 签约代表：{{sellvo.CreateByName}}
                  </div>
                  <div style="width: 55%;float: left;margin-bottom: 8px;"
                       class="print">联系电话：
                  </div>
                  <div style="width: 44%;float: left;margin-bottom: 8px;"
                       class="print"> 联系电话：
                  </div>
                  <div style="width: 55%;float: left;"
                       class="print">签订日期：{{sellvo.SellBilledDate}}</div>
                  <div style="width: 44%;float: left;"
                       class="print">签订日期：{{sellvo.SellBilledDate}}</div>
                  <div style="position: absolute; right: 23%; top: 72%; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="sellvo.status===1 && sellvo.img==='boming'">
                    <img src="~@/assets/image/boming.png"
                         width="100%">
                  </div>
                  <div style="position: absolute; right: 23%; top: 72%; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="sellvo.status===1 && sellvo.img==='jugang'">
                    <img src="~@/assets/image/jugang.png"
                         width="100%">
                  </div>
                  <div style="position: absolute; right: 23%; top: 72%; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="sellvo.status===1 && sellvo.img==='huigang'">
                    <img src="~@/assets/image/huigang.png"
                         width="100%">
                  </div>
                  <div style="position: absolute; right: 23%; top: 72%; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="sellvo.status===1 && sellvo.img==='weilan'">
                    <img src="~@/assets/image/weilan.png"
                         width="100%">
                  </div>
                </el-row>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="结算单"
                     name="second">
          <div style="width:100%;margin: 0 auto; background-color:white;"
               id="pdfDomsettlement">
            <div style="width:90%;margin: 0 auto;">
              <div id="deliverynote"
                   ref="deliverynote"
                   style="width:100%;height:100%;padding: 30px 0;">
                <div
                     style="overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 28px; font-style: normal; font-weight: bold; text-decoration: none; text-align: center; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; ">
                  结算表
                </div>
                <div style="position: relative;">
                  <div
                       style="position: absolute; right: 0%; bottom: -12px; overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-size: 16px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;">
                    开单日期: {{settlementvo.SellBilledDate}}</div>
                </div>
                <div style="padding-top: 15px;   clear: both;">
                  <table align="center"
                         style="width:100%;height:100%;"
                         cellspacing="0"
                         cellpadding="0"
                         border="0"
                         class="xwyc-table">
                    <thead>
                      <tr>
                        <th align="center"
                            width="90">产地</th>
                        <th align="center"
                            width="90">品名</th>
                        <th align="center"
                            width="90">规格</th>
                        <th align="center"
                            width="90">材质</th>
                        <th align="center"
                            width="90">数量</th>
                        <th align="center"
                            width="90">单价</th>
                        <th align="center"
                            width="120">金额</th>
                        <th align="center"
                            width="90">提货仓库</th>
                        <th align="center"
                            width="150">车号</th>
                        <th align="center">地址/备注</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in settlementlist"
                          :key="index">
                        <td align="center">{{item.OriginName}}</td>
                        <td align="center">{{item.ProductName}}</td>
                        <td align="center">{{item.SpecificationName}}</td>
                        <td align="center">{{item.MaterialName}}</td>
                        <td align="center">{{item.SellQuantity}}</td>
                        <td align="center">{{item.SellPrice}}</td>
                        <td align="center">{{item.SellAmount}}</td>
                        <td align="center">{{item.SourceWarehouse}} </td>
                        <td align="center">{{item.BillCenterVehicleDataCount}} </td>
                        <td align="center">{{item.Memo}}</td>
                      </tr>
                      <tr>
                        <td colspan="4"
                            align="center">合计</td>
                        <td align="center">{{settlementvo.sellnumber}}</td>
                        <td align="center"></td>
                        <td align="center">{{settlementvo.SellEntireAmout}}</td>
                        <td align="center"
                            colspan="3"></td>
                      </tr>
                      <tr>
                        <td colspan="6"
                            align="center">前期结余</td>
                        <td colspan="4"
                            align="center"
                            @click="tabClick(1,'前期结余')">
                          <span v-if="tabClickLabel === '前期结余'">
                            <el-input v-model="settlementvo.balance"
                                      ref="input"
                                      class="inputs"
                                      @blur="inputBlurstockrank(settlementvo.SellBillNumber,settlementvo.balance,'前期结余')"></el-input>
                          </span>
                          <span v-else>{{settlementvo.balance}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="6"
                            align="center">预付金额</td>
                        <td colspan="4"
                            align="center"
                            @click="tabClick(2,'预付金额')">
                          <span v-if="tabClickLabel === '预付金额'">
                            <el-input v-model="settlementvo.prepaidamount"
                                      ref="input"
                                      class="inputs"
                                      @blur="inputBlurstockrank(settlementvo.SellBillNumber,settlementvo.prepaidamount,'预付金额')"></el-input>
                          </span>
                          <span v-else>{{settlementvo.prepaidamount}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="6"
                            align="center">应付金额</td>
                        <td colspan="4"
                            align="center"
                            @click="tabClick(3,'应付金额')">
                          <span v-if="tabClickLabel === '应付金额'">
                            <el-input v-model="settlementvo.payableamount"
                                      ref="input"
                                      class="inputs"
                                      @blur="inputBlurstockrank(settlementvo.SellBillNumber,settlementvo.payableamount,'应付金额')"></el-input>
                          </span>
                          <span v-else>{{settlementvo.payableamount}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div @click="tabClick(4,'其他金额')">
                    <div style="margin-top: 50px; padding-bottom: 90px;">
                      <div style="width: 54%;float: left; margin-bottom: 18px;  padding-left: 10px;"
                           class="print">买方：{{settlementvo.CustomerName}}
                      </div>
                      <div style="width: 44%;float: left;margin-bottom: 18px;"
                           class="print">卖方：{{settlementvo.CompanyName}}
                      </div>
                      <div style="width: 54%;float: left;margin-bottom: 98px;padding-left: 10px;"
                           class="print">日期：{{settlementvo.AuditDate}}
                      </div>
                      <div style="width: 44%;float: left;margin-bottom: 98px;"
                           class="print"> 日期：{{settlementvo.AuditDate}}
                      </div>
                    </div>
                  </div>
                </div>
                <div style="position: relative;">
                  <div style="position: absolute; right: 25%; bottom: -30px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="settlementvo.status===1 && settlementvo.img==='boming'">
                    <img src="~@/assets/image/boming.png"
                         width="100%">
                  </div>
                  <div style="position: absolute; right: 25%; bottom: -30px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="settlementvo.status===1 && settlementvo.img==='huigang'">
                    <img src="~@/assets/image/huigang.png"
                         width="100%">
                  </div>
                  <div style="position: absolute; right: 25%; bottom: -30px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="settlementvo.status===1 && settlementvo.img==='jugang'">
                    <img src="~@/assets/image/jugang.png"
                         width="100%">
                  </div>
                  <div style="position: absolute; right: 25%; bottom: -30px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="settlementvo.status===1 && settlementvo.img==='weilan'">
                    <img src="~@/assets/image/weilan.png"
                         width="100%">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="销售单"
                     name="third">
          <div style="width:100%;margin: 0 auto; background-color:white;"
               id="pdfDomsale">
            <div style="width:90%;margin: 0 auto;">
              <div style="width:100%;height:100%; padding-top: 30px; padding-bottom: 400px;">
                <div
                     style="overflow: visible; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 28px; font-style: normal; font-weight: bold; text-decoration: none; text-align: center; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; margin-bottom: 30px; ">
                  钢材采购确认表
                </div>
                <div style="position: relative;">
                  <div
                       style="position: absolute; left: 0%; bottom: -12px; overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 18px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;">
                    我司现采购钢材一批，明细如下。请贵司协助办理相关提货或送货手续，谢谢!</div>
                  <div
                       style="position: absolute; right: 0%; bottom: -12px; overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 18px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;">
                    编号: {{salevo.SellBillNumber}}</div>
                </div>
                <div style="padding-top: 15px;   clear: both;">
                  <table align="center"
                         style="width:100%;height:100%;"
                         cellspacing="0"
                         cellpadding="0"
                         border="0"
                         class="xwyc-table">
                    <thead>
                      <tr>
                        <th align="center"
                            width="90">产地</th>
                        <th align="center"
                            width="90">品名</th>
                        <th align="center"
                            width="120">规格（mm）</th>
                        <th align="center"
                            width="90">材质</th>
                        <th align="center"
                            width="90">数量（吨）</th>
                        <th align="center"
                            width="120">含税单价</th>
                        <th align="center"
                            width="120">金额（元）</th>
                        <th align="center"
                            width="90">仓库</th>
                        <th align="center">提货信息/收货信息</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in salelist"
                          :key="index">
                        <td align="center">{{item.OriginName}}</td>
                        <td align="center">{{item.ProductName}}</td>
                        <td align="center">{{item.SpecificationName}}</td>
                        <td align="center">{{item.MaterialName}}</td>
                        <td align="center">{{item.SellQuantity}}</td>
                        <td align="center">{{item.SellPrice}}</td>
                        <td align="center">{{item.SellAmount}}</td>
                        <td align="center">{{item.SourceWarehouse}} </td>
                        <td align="center">{{item.Memo}}</td>
                      </tr>
                      <tr>
                        <td colspan="4"
                            align="center">合计</td>
                        <td align="center">{{salevo.sellnumber}}</td>
                        <td align="center"></td>
                        <td align="center">{{salevo.SellEntireAmout}}</td>
                        <td align="center"
                            colspan="2"></td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="position: relative;">
                    <div style="width: 100%;float: left;margin-top: 8px;"
                         class="print">
                      备注:
                    </div>
                    <div style="width: 100%;float: left; margin-bottom: 8px; "
                         class="print">
                      <dt>1、根据合同约定，运输费、装卸费均由需方承担。以上如是钢厂直送的，单价已包含直送运输费，但不包含装卸费(吊机费)。</dt>
                      <dt>2、结算、付款等按照合同约定执行。</dt>
                      <dt>3、车牌号、司机名、身份证信息为买方自提情况下买方指定提货人信息，仓库为买方自提时的提货地点。</dt>
                    </div>

                    <div style="width: 55%;float: left; margin-bottom: 8px; "
                         class="print">下单时间:{{salevo.SellBilledDate}}
                    </div>
                    <div style="width: 44%;float: left;margin-bottom: 8px;"
                         class="print">交货地点:
                    </div>
                    <div style="width: 100%;float: left; margin-bottom: 15px;"
                         class="print">
                      <dt>经各自核对后，买卖双方均确认以上内容无误。</dt>
                    </div>
                    <div style="width: 54%;float: left; margin-bottom: 8px; "
                         class="print">卖方单位（供货单位）:{{salevo.CompanyName}}
                    </div>
                    <div style="width: 44%;float: left;margin-bottom: 8px;"
                         class="print">买方单位（收货单位）：{{salevo.CustomerName}}
                    </div>
                    <div style="width: 55%;float: left;margin-bottom: 8px;"
                         class="print">经办人：{{salevo.SellerName}}
                    </div>
                    <div style="width: 44%;float: left;margin-bottom: 8px;"
                         class="print"> 经办人：
                    </div>
                    <div style="width: 55%;float: left; padding-bottom: 60px;"
                         class="print">日期：{{salevo.SellBilledDate}}</div>
                    <div style="width: 44;float: left; padding-bottom: 60px;"
                         class="print">日期：{{salevo.SellBilledDate}}</div>
                  </div>
                </div>
                <div style="position: relative;">
                  <div style="position: absolute; left: 18%; top: 150px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="salevo.status===1 && salevo.img==='boming'">
                    <img src="~@/assets/image/boming.png"
                         width="100%">
                  </div>
                  <div style="position: absolute; left: 18%; top: 150px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="salevo.status===1 && salevo.img==='huigang'">
                    <img src="~@/assets/image/huigang.png"
                         width="100%">
                  </div>
                  <div style="position: absolute; left: 18%; top: 150px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="salevo.status===1 && salevo.img==='jugang'">
                    <img src="~@/assets/image/jugang.png"
                         width="100%">
                  </div>
                  <div style="position: absolute;left: 18%; top: 150px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                       v-if="salevo.status===1 && salevo.img==='weilan'">
                    <img src="~@/assets/image/weilan.png"
                         width="100%">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="采购单"
                     name="fourth">
          <div v-for="(items,indexs) in purchaselist"
               :key="indexs">
            <div style="width:100%;margin: 0 auto; background-color:white;"
                 :id="items.pdfDompurchase">
              <div style="width:90%;margin: 0 auto;">
                <div id="deliverynote"
                     ref="deliverynote"
                     style="width:100%;height:100%;padding: 30px 0;">
                  <div
                       style="overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 28px; font-style: normal; font-weight: bold; text-decoration: none; text-align: center; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; ">
                    钢材采购确认表
                  </div>
                  <div style="position: relative;">
                    <div
                         style="position: absolute; right: 0%; bottom: -12px; overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;">
                      No:{{items.purchasevo.PurchaseBillNumber}}</div>
                  </div>
                  <div style="padding-top: 15px;   clear: both;">
                    <table align="center"
                           style="width:100%;height:100%;"
                           cellspacing="0"
                           cellpadding="0"
                           border="0"
                           class="xwyc-table">
                      <thead>
                        <tr>
                          <th align="center"
                              width="90">产地</th>
                          <th align="center"
                              width="90">品名</th>
                          <th align="center"
                              width="120">规格（mm）</th>
                          <th align="center"
                              width="90">材质</th>
                          <th align="center"
                              width="90">数量（吨）</th>
                          <th align="center"
                              width="120">含税单价</th>
                          <th align="center"
                              width="120">金额（元）</th>
                          <th align="center"
                              width="90">仓库</th>
                          <th align="center">提货信息/收货信息</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item,index) in items.list"
                            :key="index">
                          <td align="center">{{item.OriginName}}</td>
                          <td align="center">{{item.ProductName}}</td>
                          <td align="center">{{item.SpecificationName}}</td>
                          <td align="center">{{item.MaterialName}}</td>
                          <td align="center">{{item.PurchaseQuantity}}</td>
                          <td align="center">{{item.PurchasePrice}}</td>
                          <td align="center">{{item.PurchaseAmount}}</td>
                          <td align="center">{{item.SourceWarehouse}} </td>
                          <td align="center">{{item.PurchaseBillItemMemo}}</td>
                        </tr>
                        <tr>
                          <td colspan="4"
                              align="center">总计</td>
                          <td align="center">{{items.purchasevo.purchasenumber}}</td>
                          <td align="center"></td>
                          <td align="center">{{items.purchasevo.PurchaseEntireAmout}}</td>
                          <td align="center"
                              colspan="2"></td>
                        </tr>
                        <tr>
                          <td colspan="12"
                              style="height: 100px;">
                            <div style="width: 54%;float: left; margin-bottom: 8px;  padding-left: 10px;"
                                 class="print">买方：{{items.purchasevo.CompanyName}}
                            </div>
                            <div style="width: 44%;float: left;margin-bottom: 8px;"
                                 class="print">卖方：{{items.purchasevo.SupplierName}}
                            </div>
                            <div style="width: 54%;float: left;margin-bottom: 8px;padding-left: 10px;"
                                 class="print">经办人：{{items.purchasevo.CreateByName}}
                            </div>
                            <div style="width: 44%;float: left;margin-bottom: 8px;"
                                 class="print">经办人：
                            </div>
                            <div style="width: 54%;float: left;margin-bottom: 8px;padding-left: 10px;"
                                 class="print">传真：020-83887426
                            </div>
                            <div style="width: 44%;float: left;margin-bottom: 8px;"
                                 class="print"> 传真：
                            </div>
                            <div style="width: 54%;float: left;margin-bottom: 8px;padding-left: 10px;"
                                 class="print">日期：{{items.purchasevo.SellBilledDate}}
                            </div>
                            <div style="width: 44%;float: left;margin-bottom: 8px;"
                                 class="print"> 日期：{{items.purchasevo.SellBilledDate}}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style="position: relative;">
                    <div style="position: absolute; left: 9%; bottom: -20px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                         v-if="items.purchasevo.status===1 && items.purchasevo.img==='boming'">
                      <img src="~@/assets/image/boming.png"
                           width="100%">
                    </div>
                    <div style="position: absolute; left: 9%; bottom: -20px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                         v-if="items.purchasevo.status===1 && items.purchasevo.img==='huigang'">
                      <img src="~@/assets/image/huigang.png"
                           width="100%">
                    </div>
                    <div style="position: absolute; left: 9%; bottom: -20px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                         v-if="items.purchasevo.status===1 && items.purchasevo.img==='jugang'">
                      <img src="~@/assets/image/jugang.png"
                           width="100%">
                    </div>
                    <div style="position: absolute; left: 9%; bottom: -20px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                         v-if="items.purchasevo.status===1 && items.purchasevo.img==='weilan'">
                      <img src="~@/assets/image/weilan.png"
                           width="100%">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import { getElectronic, searchElectronic } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'SellpurchaselistIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogVisibles: false,
      dialogVisiblesprinter: false,
      currentPage: 1,
      total: 0,
      perpage: 30,
      pagesize: 30,
      productselectdata: {
        orderlist: []
      },
      business: [],
      myHeaders: '',
      form: {
        number: null,
        sales_date1: null,
        sales_date2: null
      },
      projectlist: [],
      customerenumlist: [],
      sellbilllist: [],
      sellvo: {},
      purchaselist: [],
      purchasevo: {},
      spvo: {},
      settlementlist: [],
      settlementvo: {},
      salelist: [],
      salevo: {},
      activeName: 'first'
    }
  },
  created () {
    this.loadnav()
    this.loadtoken()
    this.loadgetdate()
    this.search()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetdate () {
      this.form.sales_date1 = this.getDay(-1)
      this.form.sales_date2 = this.getDay(0)
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'electronic') {
        this.topname = '销售管理'
        this.navnames = '电子签明细'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    search () {
      this.updateLoading = true
      this.loading = true
      searchElectronic({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.spvo = res.data.item
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      searchElectronic({ data: this.form, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.spvo = res.data.item
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      searchElectronic({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.spvo = res.data.item
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleprinter (val) {
      this.dialogVisiblesprinter = true
      getElectronic({ orderid: val }).then(res => {
        if (res.data.status === 'success') {
          this.sellbilllist = res.data.sellbilllist
          this.sellvo = res.data.sellvo
          this.purchaselist = res.data.purchaselist
          this.settlementlist = res.data.settlementlist
          this.settlementvo = res.data.settlementvo
          this.salelist = res.data.salelist
          this.salevo = res.data.salevo
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    handleClick () { },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay (day) {
      var today = new Date()
      var targetdaymilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targetdaymilliseconds)
      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth (month) {
      var m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    }
  }
}
</script>
<style lang="less" scoped>
.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  padding: 0 10px 0 0;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  font-size: 16px;
  color: #000;
  line-height: 25px;
}
.offer {
  line-height: 25px;
  font-size: 16px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 0px 0;
}
/deep/.el-table th {
  padding: 0px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 16px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
/deep/.table .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 2px;
}
/deep/.xwyc-table .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 2px;
}
/deep/.table .el-form-item__content {
  line-height: 25px;
  position: relative;
  font-size: 14px;
}
/deep/.table .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 25px;
}
/deep/.el-link.el-link--warning:hover {
  color: #791aa6;
}
/deep/.el-link.el-link--warning {
  color: #791aa6;
}
/deep/.el-link.el-link--primary:hover {
  color: #0000ee;
}
/deep/.el-link.el-link--primary {
  color: #0000ee;
}
</style>
