import request from '@/utils/request'

// 获取收款信息
export const getCollectionList = data => {
  return request({
    method: 'POST',
    url: '/api/getCollectionList',
    data
  })
}
// 获取部门客户收款信息
export const getDeptCollectionList = data => {
  return request({
    method: 'POST',
    url: '/api/getDeptCollectionList',
    data
  })
}
// 获取个人客户收款信息
export const getPersonalList = data => {
  return request({
    method: 'POST',
    url: '/api/getPersonalList',
    data
  })
}
//   获取付款信息
export const getPaymentList = data => {
  return request({
    method: 'POST',
    url: '/api/getPaymentList',
    data
  })
}
//  获取户名
export const getAccountNameList = data => {
  return request({
    method: 'POST',
    url: '/api/getAccountNameList',
    data
  })
}
// 获取部门
export const getDepartmentList = data => {
  return request({
    method: 'POST',
    url: '/api/getDepartmentList',
    data
  })
}
export const addNewsDepartment = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsDepartment',
    data
  })
}
export const getdelDepartment = data => {
  return request({
    method: 'POST',
    url: '/api/getDelDepartment',
    data
  })
}
export const getEditDepartment = data => {
  return request({
    method: 'POST',
    url: '/api/getEditDepartment',
    data
  })
}
export const upDataDepartment = data => {
  return request({
    method: 'POST',
    url: '/api/upDataDepartment',
    data
  })
}
// 获取客户
export const getCustomerList = data => {
  return request({
    method: 'POST',
    url: '/api/getCustomerList',
    data
  })
}
export const getCustomerListAll = data => {
  return request({
    method: 'POST',
    url: '/api/getCustomerListAll',
    data
  })
}
export const getCustomerSearch = data => {
  return request({
    method: 'POST',
    url: '/api/getCustomerSearch',
    data
  })
}
export const addNewsCustomer = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsCustomer',
    data
  })
}
export const getdelCustomer = data => {
  return request({
    method: 'POST',
    url: '/api/getDelCustomer',
    data
  })
}
export const getEditCustomer = data => {
  return request({
    method: 'POST',
    url: '/api/getEditCustomer',
    data
  })
}
export const upDataCustomer = data => {
  return request({
    method: 'POST',
    url: '/api/upDataCustomer',
    data
  })
}
export const getMerchantsType = data => {
  return request({
    method: 'POST',
    url: '/api/getMerchantsType',
    data
  })
}
// 角色
export const getRoleList = data => {
  return request({
    method: 'POST',
    url: '/api/getRoleList',
    data
  })
}
export const addNewsRole = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsRole',
    data
  })
}
export const getDelRole = data => {
  return request({
    method: 'POST',
    url: '/api/getDelRole',
    data
  })
}
export const getEditRole = data => {
  return request({
    method: 'POST',
    url: '/api/getEditRole',
    data
  })
}
export const upDataRole = data => {
  return request({
    method: 'POST',
    url: '/api/upDataRole',
    data
  })
}
export const getAuthList = data => {
  return request({
    method: 'POST',
    url: '/api/getAuthList',
    data
  })
}
export const getSelectAuth = data => {
  return request({
    method: 'POST',
    url: '/api/getSelectAuth',
    data
  })
}
export const upSelectAuth = data => {
  return request({
    method: 'POST',
    url: '/api/upSelectAuth',
    data
  })
}
// 栏目
export const getNavList = data => {
  return request({
    method: 'POST',
    url: '/api/getNavList',
    data
  })
}
// 采购往来
export const getPurchaseContacts = data => {
  return request({
    method: 'POST',
    url: '/api/getPurchaseContacts',
    data
  })
}
// 收款管理
export const createdCapitaList = data => {
  return request({
    method: 'POST',
    url: '/api/createdCapitaList',
    data
  })
}
// 获取收付款
export const getCapitaList = data => {
  return request({
    method: 'POST',
    url: '/api/getCapitaList',
    data
  })
}
// 编辑收付款
export const editPayOrCollectionTypet = data => {
  return request({
    method: 'POST',
    url: '/api/editPayOrCollectionTypet',
    data
  })
}
export const ProjectEnumList = data => {
  return request({
    method: 'POST',
    url: '/api/ProjectEnumList',
    data
  })
}
export const CustomerEnumList = data => {
  return request({
    method: 'POST',
    url: '/api/CustomerEnumList',
    data
  })
}
// 删除收付款
export const deletedCapitaList = data => {
  return request({
    method: 'POST',
    url: '/api/deletedCapitaList',
    data
  })
}
export const getAccountListNotIN = data => {
  return request({
    method: 'POST',
    url: '/api/getAccountListNotIN',
    data
  })
}
// 财务管理
export const createVoucher = data => {
  return request({
    method: 'POST',
    url: '/api/createVoucher',
    data
  })
}
export const getVoucherList = data => {
  return request({
    method: 'POST',
    url: '/api/getVoucherList',
    data
  })
}
export const downloadVoucher = data => {
  return request({
    method: 'POST',
    url: '/api/downloadVoucher',
    data
  })
}
// 客户业务
export const getBusinessList = data => {
  return request({
    method: 'POST',
    url: '/api/getBusinessList',
    data
  })
}
export const addNewsBusiness = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsBusiness',
    data
  })
}
export const getEditBusiness = data => {
  return request({
    method: 'POST',
    url: '/api/getEditBusiness',
    data
  })
}
export const upDataBusiness = data => {
  return request({
    method: 'POST',
    url: '/api/upDataBusiness',
    data
  })
}
export const getDelBusiness = data => {
  return request({
    method: 'POST',
    url: '/api/getDelBusiness',
    data
  })
}
// 客户项目
export const getProjectList = data => {
  return request({
    method: 'POST',
    url: '/api/getProjectList',
    data
  })
}
export const addNewsProject = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsProject',
    data
  })
}
export const getEditProject = data => {
  return request({
    method: 'POST',
    url: '/api/getEditProject',
    data
  })
}
export const upDataProject = data => {
  return request({
    method: 'POST',
    url: '/api/upDataProject',
    data
  })
}
export const getDelProject = data => {
  return request({
    method: 'POST',
    url: '/api/getDelProject',
    data
  })
}
// 供应商
export const getSupplierList = data => {
  return request({
    method: 'POST',
    url: '/api/getSupplierList',
    data
  })
}
export const getSupplierSearch = data => {
  return request({
    method: 'POST',
    url: '/api/getSupplierSearch',
    data
  })
}
export const addNewsSupplier = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsSupplier',
    data
  })
}
export const getEditSupplier = data => {
  return request({
    method: 'POST',
    url: '/api/getEditSupplier',
    data
  })
}
export const upDataSupplier = data => {
  return request({
    method: 'POST',
    url: '/api/upDataSupplier',
    data
  })
}
export const getDelSupplier = data => {
  return request({
    method: 'POST',
    url: '/api/getDelSupplier',
    data
  })
}
export const getSupplierInfo = data => {
  return request({
    method: 'POST',
    url: '/api/getSupplierInfo',
    data
  })
}
// 主营品牌
export const getMainBrandList = data => {
  return request({
    method: 'POST',
    url: '/api/getMainBrandList',
    data
  })
}
export const getEditMainBrand = data => {
  return request({
    method: 'POST',
    url: '/api/getEditMainBrand',
    data
  })
}
export const addNewsMainBrand = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsMainBrand',
    data
  })
}
export const upDataMainBrand = data => {
  return request({
    method: 'POST',
    url: '/api/upDataMainBrand',
    data
  })
}
export const getDelMainBrand = data => {
  return request({
    method: 'POST',
    url: '/api/getDelMainBrand',
    data
  })
}
// 合同
export const getContractList = data => {
  return request({
    method: 'POST',
    url: '/api/getContractList',
    data
  })
}
export const addNewsContract = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsContract',
    data
  })
}
export const getEditContract = data => {
  return request({
    method: 'POST',
    url: '/api/getEditContract',
    data
  })
}
export const upDataContract = data => {
  return request({
    method: 'POST',
    url: '/api/upDataContract',
    data
  })
}
export const getDelContract = data => {
  return request({
    method: 'POST',
    url: '/api/getDelContract',
    data
  })
}
export const delContractFile = data => {
  return request({
    method: 'POST',
    url: '/api/delContractFile',
    data
  })
}
export const getEnclosureList = data => {
  return request({
    method: 'POST',
    url: '/api/getEnclosureList',
    data
  })
}
export const searchContract = data => {
  return request({
    method: 'POST',
    url: '/api/searchContract',
    data
  })
}
// 同步钢小儿收款
export const PaymentInEdit = data => {
  return request({
    method: 'POST',
    url: '/api/PaymentInEdit',
    data
  })
}
// 客商统计
export const getMerchantsList = data => {
  return request({
    method: 'POST',
    url: '/api/getMerchantsList',
    data
  })
}
export const getMerchants = data => {
  return request({
    method: 'POST',
    url: '/api/getMerchants',
    data
  })
}
export const addMerchants = data => {
  return request({
    method: 'POST',
    url: '/api/addMerchants',
    data
  })
}
export const upDataMerchants = data => {
  return request({
    method: 'POST',
    url: '/api/upDataMerchants',
    data
  })
}
export const getDelMerchants = data => {
  return request({
    method: 'POST',
    url: '/api/getDelMerchants',
    data
  })
}
// 查看当月凭证新增供应商，客户
export const getUnExitCustomerAndSupplier = data => {
  return request({
    method: 'POST',
    url: '/api/getUnExitCustomerAndSupplier',
    data
  })
}
// 导入凭证相关资料
export const importFinanceData = data => {
  return request({
    method: 'POST',
    url: '/api/importFinanceData',
    data
  })
}
// 品牌
export const getBrandsList = data => {
  return request({
    method: 'POST',
    url: '/api/getBrandsList',
    data
  })
}
export const addBrands = data => {
  return request({
    method: 'POST',
    url: '/api/addBrands',
    data
  })
}
export const getBrands = data => {
  return request({
    method: 'POST',
    url: '/api/getBrands',
    data
  })
}
export const getDelBrands = data => {
  return request({
    method: 'POST',
    url: '/api/getDelBrands',
    data
  })
}
export const upDataBrands = data => {
  return request({
    method: 'POST',
    url: '/api/upDataBrands',
    data
  })
}
export const getBrandList = data => {
  return request({
    method: 'POST',
    url: '/api/getBrandList',
    data
  })
}
export const getEditBrand = data => {
  return request({
    method: 'POST',
    url: '/api/getEditBrand',
    data
  })
}
export const addNewsBrand = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsBrand',
    data
  })
}
export const upDataBrand = data => {
  return request({
    method: 'POST',
    url: '/api/upDataBrand',
    data
  })
}
export const getDelBrand = data => {
  return request({
    method: 'POST',
    url: '/api/getDelBrand',
    data
  })
}
// 桂鑫订单
export const getChequnarOrderList = data => {
  return request({
    method: 'POST',
    url: '/api/getChequnarOrderList',
    data
  })
}
export const upDataSprice = data => {
  return request({
    method: 'POST',
    url: '/api/upDataSprice',
    data
  })
}
export const SellBillPurchaseBillList = data => {
  return request({
    method: 'POST',
    url: '/api/SellBillPurchaseBillList',
    data
  })
}
export const getSearchData = data => {
  return request({
    method: 'POST',
    url: '/api/getSearchData',
    data
  })
}
export const getSearchguixin = data => {
  return request({
    method: 'POST',
    url: '/api/getSearchguixin',
    data
  })
}
export const chequnaLogin = data => {
  return request({
    method: 'POST',
    url: '/api/chequnaLogin',
    data
  })
}
export const getI56yunCode = data => {
  return request({
    method: 'POST',
    url: '/api/getI56yunCode',
    data
  })
}
export const getPurchaseBillList = data => {
  return request({
    method: 'POST',
    url: '/api/getPurchaseBillList',
    data
  })
}
export const getChequnarWeight = data => {
  return request({
    method: 'POST',
    url: '/api/getChequnarWeight',
    data
  })
}
export const getCompanyList = data => {
  return request({
    method: 'POST',
    url: '/api/getCompanyList',
    data
  })
}
export const getslList = data => {
  return request({
    method: 'POST',
    url: '/api/getslList',
    data
  })
}
export const GenerateContract = data => {
  return request({
    method: 'POST',
    url: '/api/GenerateContract',
    data
  })
}
export const upContract = data => {
  return request({
    method: 'POST',
    url: '/api/upContract',
    data
  })
}
export const UpGuarantee = data => {
  return request({
    method: 'POST',
    url: '/api/UpGuarantee',
    data
  })
}
export const getWeilanChequnarOrderList = data => {
  return request({
    method: 'POST',
    url: '/api/getWeilanChequnarOrderList',
    data
  })
}
export const WeilanSellBillPurchaseBillList = data => {
  return request({
    method: 'POST',
    url: '/api/WeilanSellBillPurchaseBillList',
    data
  })
}
export const getNoContractOppInfo = data => {
  return request({
    method: 'POST',
    url: '/api/getNoContractOppInfo',
    data
  })
}
export const getTimeOffCustomer = data => {
  return request({
    method: 'POST',
    url: '/api/getTimeOffCustomer',
    data
  })
}
export const getProjects = data => {
  return request({
    method: 'POST',
    url: '/api/getProjects',
    data
  })
}
export const getJobwork = data => {
  return request({
    method: 'POST',
    url: '/api/getJobwork',
    data
  })
}
export const selectProjects = data => {
  return request({
    method: 'POST',
    url: '/api/selectProjects',
    data
  })
}
export const addJobwork = data => {
  return request({
    method: 'POST',
    url: '/api/addJobwork',
    data
  })
}
export const editJobwork = data => {
  return request({
    method: 'POST',
    url: '/api/editJobwork',
    data
  })
}
export const updataobwork = data => {
  return request({
    method: 'POST',
    url: '/api/updataobwork',
    data
  })
}
export const delJobwork = data => {
  return request({
    method: 'POST',
    url: '/api/delJobwork',
    data
  })
}
export const searchCustomer = data => {
  return request({
    method: 'POST',
    url: '/api/searchCustomer',
    data
  })
}
export const huisynchronization = data => {
  return request({
    method: 'POST',
    url: '/api/huisynchronization',
    data
  })
}
export const getProjectEnumList = data => {
  return request({
    method: 'POST',
    url: '/api/getProjectEnumList',
    data
  })
}
// 导入订单相关资料
export const importOrderData = data => {
  return request({
    method: 'POST',
    url: '/api/importOrderData',
    data
  })
}
export const getOrderIfo = data => {
  return request({
    method: 'POST',
    url: '/api/getOrderIfo',
    data
  })
}
export const getCompanyName = data => {
  return request({
    method: 'POST',
    url: '/api/getCompanyName',
    data
  })
}
export const updateInput = data => {
  return request({
    method: 'POST',
    url: '/api/updateInput',
    data
  })
}
export const printRecord = data => {
  return request({
    method: 'POST',
    url: '/api/printRecord',
    data
  })
}
export const getOfferItem = data => {
  return request({
    method: 'POST',
    url: '/api/getOfferItem',
    data
  })
}
export const addOfferItem = data => {
  return request({
    method: 'POST',
    url: '/api/addOfferItem',
    data
  })
}
export const editOfferItem = data => {
  return request({
    method: 'POST',
    url: '/api/editOfferItem',
    data
  })
}
export const updateOfferItem = data => {
  return request({
    method: 'POST',
    url: '/api/updateOfferItem',
    data
  })
}
export const delOfferItem = data => {
  return request({
    method: 'POST',
    url: '/api/delOfferItem',
    data
  })
}
export const getOfferItemPrice = data => {
  return request({
    method: 'POST',
    url: '/api/getOfferItemPrice',
    data
  })
}
export const saveOfficeItemPrice = data => {
  return request({
    method: 'POST',
    url: '/api/saveOfficeItemPrice',
    data
  })
}
export const getOfferManage = data => {
  return request({
    method: 'POST',
    url: '/api/getOfferManage',
    data
  })
}
export const addOfferManage = data => {
  return request({
    method: 'POST',
    url: '/api/addOfferManage',
    data
  })
}
export const editOfferManage = data => {
  return request({
    method: 'POST',
    url: '/api/editOfferManage',
    data
  })
}
export const updateOfferManage = data => {
  return request({
    method: 'POST',
    url: '/api/updateOfferManage',
    data
  })
}
export const delOfferManage = data => {
  return request({
    method: 'POST',
    url: '/api/delOfferManage',
    data
  })
}
export const importPurchaseSellData = data => {
  return request({
    method: 'POST',
    url: '/api/importPurchaseSellData',
    data
  })
}
export const searchPurchaseSell = data => {
  return request({
    method: 'POST',
    url: '/api/searchPurchaseSell',
    data
  })
}
export const searchSalesman = data => {
  return request({
    method: 'POST',
    url: '/api/searchSalesman',
    data
  })
}
export const addSalesman = data => {
  return request({
    method: 'POST',
    url: '/api/addSalesman',
    data
  })
}
export const editSalesman = data => {
  return request({
    method: 'POST',
    url: '/api/editSalesman',
    data
  })
}
export const updateSalesman = data => {
  return request({
    method: 'POST',
    url: '/api/updateSalesman',
    data
  })
}
export const delSalesman = data => {
  return request({
    method: 'POST',
    url: '/api/delSalesman',
    data
  })
}
export const SalesmanList = data => {
  return request({
    method: 'POST',
    url: '/api/SalesmanList',
    data
  })
}
export const searchDataManagement = data => {
  return request({
    method: 'POST',
    url: '/api/searchDataManagement',
    data
  })
}
export const updateDataManagement = data => {
  return request({
    method: 'POST',
    url: '/api/updateDataManagement',
    data
  })
}
export const getSteelSizeList = data => {
  return request({
    method: 'POST',
    url: '/api/getSteelSizeList',
    data
  })
}
export const updateStatisticalPrice = data => {
  return request({
    method: 'POST',
    url: '/api/updateStatisticalPrice',
    data
  })
}
export const addProjectManagement = data => {
  return request({
    method: 'POST',
    url: '/api/addProjectManagement',
    data
  })
}
export const updateProjectManagement = data => {
  return request({
    method: 'POST',
    url: '/api/updateProjectManagement',
    data
  })
}
export const searchProjectManagement = data => {
  return request({
    method: 'POST',
    url: '/api/searchProjectManagement',
    data
  })
}
export const editProjectManagement = data => {
  return request({
    method: 'POST',
    url: '/api/editProjectManagement',
    data
  })
}
export const delProjectManagement = data => {
  return request({
    method: 'POST',
    url: '/api/delProjectManagement',
    data
  })
}
export const BusinessGroupDataAnalysis = data => {
  return request({
    method: 'POST',
    url: '/api/BusinessGroupDataAnalysis',
    data
  })
}
export const getBrandPurchaseList = data => {
  return request({
    method: 'POST',
    url: '/api/getBrandPurchaseList',
    data
  })
}
export const getCustomerSalesReport = data => {
  return request({
    method: 'POST',
    url: '/api/getCustomerSalesReport',
    data
  })
}
export const getNonCustomerSalesReport = data => {
  return request({
    method: 'POST',
    url: '/api/getNonCustomerSalesReport',
    data
  })
}
export const addPurchaseSellListIsolation = data => {
  return request({
    method: 'POST',
    url: '/api/addPurchaseSellListIsolation',
    data
  })
}
export const getPurchaseSellListIsolation = data => {
  return request({
    method: 'POST',
    url: '/api/getPurchaseSellListIsolation',
    data
  })
}
export const getPurchaseSellContacts = data => {
  return request({
    method: 'POST',
    url: '/api/getPurchaseSellContacts',
    data
  })
}
export const getWeiLanPurchaseSellContacts = data => {
  return request({
    method: 'POST',
    url: '/api/getWeiLanPurchaseSellContacts',
    data
  })
}
export const searchSellPurchaseList = data => {
  return request({
    method: 'POST',
    url: '/api/searchSellPurchaseList',
    data
  })
}
export const SyncSellPurchaseList = data => {
  return request({
    method: 'POST',
    url: '/api/SyncSellPurchaseList',
    data
  })
}
export const getSellPurchaseList = data => {
  return request({
    method: 'POST',
    url: '/api/getSellPurchaseList',
    data
  })
}
export const getPurchaseList = data => {
  return request({
    method: 'POST',
    url: '/api/getPurchaseList',
    data
  })
}
export const SellExamine = data => {
  return request({
    method: 'POST',
    url: '/api/SellExamine',
    data
  })
}
export const PurchaseExamine = data => {
  return request({
    method: 'POST',
    url: '/api/PurchaseExamine',
    data
  })
}
export const getSettlementList = data => {
  return request({
    method: 'POST',
    url: '/api/getSettlementList',
    data
  })
}
export const getSaleList = data => {
  return request({
    method: 'POST',
    url: '/api/getSaleList',
    data
  })
}
export const updateSettlement = data => {
  return request({
    method: 'POST',
    url: '/api/updateSettlement',
    data
  })
}
export const SyncExamine = data => {
  return request({
    method: 'POST',
    url: '/api/SyncExamine',
    data
  })
}
export const CheckSeal = data => {
  return request({
    method: 'POST',
    url: '/api/CheckSeal',
    data
  })
}
export const searchSellPurchaseWeiLanList = data => {
  return request({
    method: 'POST',
    url: '/api/searchSellPurchaseWeiLanList',
    data
  })
}
export const SyncSellPurchaseWeiLanList = data => {
  return request({
    method: 'POST',
    url: '/api/SyncSellPurchaseWeiLanList',
    data
  })
}
export const getSellPurchaseWeiLanList = data => {
  return request({
    method: 'POST',
    url: '/api/getSellPurchaseWeiLanList',
    data
  })
}
export const getPurchaseWeiLanList = data => {
  return request({
    method: 'POST',
    url: '/api/getPurchaseWeiLanList',
    data
  })
}
export const SellExamineWeiLan = data => {
  return request({
    method: 'POST',
    url: '/api/SellExamineWeiLan',
    data
  })
}
export const PurchaseExamineWeiLan = data => {
  return request({
    method: 'POST',
    url: '/api/PurchaseExamineWeiLan',
    data
  })
}
export const getSettlementWeiLanList = data => {
  return request({
    method: 'POST',
    url: '/api/getSettlementWeiLanList',
    data
  })
}
export const getSaleWeiLanList = data => {
  return request({
    method: 'POST',
    url: '/api/getSaleWeiLanList',
    data
  })
}
export const updateSettlementWeiLan = data => {
  return request({
    method: 'POST',
    url: '/api/updateSettlementWeiLan',
    data
  })
}
export const SyncExamineWeiLan = data => {
  return request({
    method: 'POST',
    url: '/api/SyncExamineWeiLan',
    data
  })
}
export const CheckSealWeiLan = data => {
  return request({
    method: 'POST',
    url: '/api/CheckSealWeiLan',
    data
  })
}
export const getExamineList = data => {
  return request({
    method: 'POST',
    url: '/api/getExamineList',
    data
  })
}
export const exportReportExamine = data => {
  return request({
    method: 'POST',
    url: '/api/exportReportExamine',
    data
  })
}
export const searchElectronic = data => {
  return request({
    method: 'POST',
    url: '/api/searchElectronic',
    data
  })
}
export const getElectronic = data => {
  return request({
    method: 'POST',
    url: '/api/getElectronic',
    data
  })
}
export const getActual = data => {
  return request({
    method: 'POST',
    url: '/api/getActual',
    data
  })
}
export const searchSaleOrder = data => {
  return request({
    method: 'POST',
    url: '/api/searchSaleOrder',
    data
  })
}
export const synchroSaleOrder = data => {
  return request({
    method: 'POST',
    url: '/api/synchroSaleOrder',
    data
  })
}
export const searchPurchaseList = data => {
  return request({
    method: 'POST',
    url: '/api/searchPurchaseList',
    data
  })
}
export const synchroPurchaseOrder = data => {
  return request({
    method: 'POST',
    url: '/api/synchroPurchaseOrder',
    data
  })
}
