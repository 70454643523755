<template>
  <el-container>
    <el-header style="height: 80px">
      <div class="logo"><img src="~@/assets/image/bglogo.png" /></div>
    </el-header>
    <el-main>
      <div class="login-form-wrap">
        <div class="login-head">
          <div class="bglogo"></div>
          <el-form class="login-form"
                   ref="login-form"
                   :model="user"
                   :rules="formRules">
            <el-form-item>
              <div class="title">66钢铁信息管理系统</div>
            </el-form-item>
            <el-form-item prop="account">
              <el-input v-model="user.account"
                        placeholder="请输入手机号或邮箱"
                        prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="user.password"
                        show-password
                        placeholder="请输入密码"
                        prefix-icon="el-icon-lock"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input class="code"
                        v-model="user.code"
                        placeholder="请输入验证码"
                        prefix-icon="el-icon-key"></el-input>

              <div class="codebutton"
                   @click="refreshCode">
                <s-identify :identifyCode="identifyCode"></s-identify>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button class="login-btn"
                         type="primary"
                         :loading="loginLoading"
                         @click="onLogin">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-main>
    <el-footer style="height: 120px">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <div class="copyright-box">
              <div id="csdn-copyright-footer"
                   class="column dark">
                <ul class="footer-column-t">
                  <li>
                    <img src=""
                         alt="">
                    <span> 020-83887466-8056</span>
                  </li>
                  <li>
                    <img src=""
                         alt="">
                    <a href="mailto:gzgwzg@yeah.net"
                       target="_blank"> gzgwzg@yeah.net</a>
                  </li>
                  <li>
                    工作时间&nbsp;9:00-17:30
                  </li>
                  <li>
                    <a href="javascript:;"
                       @click="about"> 关于我们</a>
                  </li>
                </ul>
                <ul class="footer-column-b">
                  <li><a href="https://beian.miit.gov.cn/"
                       rel="noreferrer"
                       target="_blank">粤ICP备17117697号-1</a></li>
                  <li>©2017-2024 智钢数据服务（广州）有限公司</li>
                </ul>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>

<script>
import SIdentify from '@/components/identify'
import { login } from '@/api/user'
export default {
  name: 'Login',
  data () {
    const validateCode = (rule, value, callback) => {
      // console.log(value)
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else if (value !== this.identifyCode) {
        callback(new Error('验证码错误'))
      } else {
        callback()
      }
    }
    return {
      codestatus: false,
      user: {
        account: '',
        password: '',
        code: ''
      },
      makeCode: '',
      identifyCode: '',
      accuracy: 1,
      loginLoading: false,
      dialogTableVisible: false,
      formRules: {
        account: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        code: [
          { validator: validateCode, trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    's-identify': SIdentify
  },
  mounted () {
    // 绑定事件
    window.addEventListener('keydown', this.keyDown)
    this.makeIdentifyCode({ randomTypeLen: true })
  },
  methods: {
    onLogin () {
      this.$refs['login-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.login()
      })
    },
    login () {
      this.loginLoading = true
      login(this.user).then(res => {
        if (res.data.status === 'success') {
          this.loginLoading = false
          window.localStorage.setItem('user', JSON.stringify(res.data))
          // 跳转到首页
          this.$router.push({
            name: 'collection'
          })
        } else {
          this.$message.error(res.data.message)
          this.loginLoading = false
        }
      }).catch(err => {
        console.log('登录失败', err)
        // this.$message.error('登录失败，手机号或密码错误')
        this.loginLoading = false
      })
    },
    cedesuccess () {
      this.user.code = this.createCode()
    },
    cedeerror () {
      this.user.code = ''
    },
    createCode () {
      var code = ''
      var codeLength = 4
      var random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      for (var i = 0; i < codeLength; i++) {
        var index = Math.floor(Math.random() * 9)
        code += random[index]
      }
      return code
    },
    keyDown (e) {
      // 如果是回车则执行登录方法
      if (e.keyCode === 13) {
        this.onLogin()
      }
    },
    about () {
      this.dialogTableVisible = true
    },
    /**
     * 随机生成一个0~9之间的数
     */
    randomNum () {
      return Math.floor(Math.random() * 10)
    },

    /**
     * 随机生成一个字母
     * @param {boolean} isUupper 生成大写字母
     */
    randomAlphabet ({ isUupper = false } = {}) {
      // a的Unicode值为97,z的Unicode值为123
      const alphabet = String.fromCharCode(Math.floor(Math.random() * 25) + 97)
      if (!isUupper) {
        return alphabet
      } else {
        return alphabet.toUpperCase()
      }
    },

    /**
     * 生成图片验证码
     * @param {number} length 图片验证码位数
     * @param {boolean} typeMix 数字和字母混合
     * @param {string} pureNumber 纯数字('number')或者字母('alphabet')
     * @param {boolean} randomTypeLen 随机生成类型个数组合
     * @param {boolean} capsLookMix 字母大小写混合
     * @param {number} numLength 混合类型下的数字个数
     * @param {number} uupperLength 大写字母的个数
     */
    makeIdentifyCode ({ length = 4, typeMix = true, pureNumber = 'alphabet', randomTypeLen = false, capsLookMix = false, numLength = 2, uupperLength = 1 } = {}) {
      this.makeCode = ''
      // 数字和字母混合
      if (typeMix) {
        // 随机生成类型个数组合
        if (randomTypeLen) {
          // 字母大小写混合
          if (capsLookMix) {
            const numLength = Math.floor(Math.random() * length) + 1
            const uupperLength = numLength === length ? 0 : Math.floor(Math.random() * (length - numLength)) + 1
            for (let i = 0; i < numLength; i++) {
              this.makeCode += this.randomNum()
            }
            for (let i = 0; i < uupperLength; i++) {
              this.makeCode += this.randomAlphabet({ isUupper: true })
            }
            if (numLength + uupperLength < length) {
              for (let i = 0; i < length - numLength - uupperLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          } else {
            const numLength = Math.floor(Math.random() * length) + 1
            for (let i = 0; i < numLength; i++) {
              this.makeCode += this.randomNum()
            }
            if (numLength < length) {
              for (let i = 0; i < length - numLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          }
        } else {
          // 字母大小写混合
          if (capsLookMix) {
            const tempNumLength = numLength < 0 ? 2 : numLength > length ? 2 : numLength
            const tempUupperLength = uupperLength < 0 ? 1 : uupperLength > length - tempNumLength ? 1 : uupperLength
            for (let i = 0; i < tempNumLength; i++) {
              this.makeCode += this.randomNum()
            }
            for (let i = 0; i < tempUupperLength; i++) {
              this.makeCode += this.randomAlphabet({ isUupper: true })
            }
            if (tempNumLength + tempUupperLength < length) {
              for (let i = 0; i < length - tempNumLength - tempUupperLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          } else {
            const tempNumLength = numLength < 0 ? 2 : numLength > length ? 2 : numLength
            for (let i = 0; i < tempNumLength; i++) {
              this.makeCode += this.randomNum()
            }
            if (tempNumLength < length) {
              for (let i = 0; i < length - tempNumLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          }
        }
      } else {
        // 纯数字('number')
        if (pureNumber === 'number') {
          for (let i = 0; i < length; i++) {
            this.makeCode += this.randomNum()
          }
        }
        // 纯字母('alphabet')
        if (pureNumber === 'alphabet') {
          // 字母大小写混合
          if (capsLookMix) {
            const tempUupperLength = uupperLength < 0 ? 1 : uupperLength > length ? 1 : uupperLength
            for (let i = 0; i < tempUupperLength; i++) {
              this.makeCode += this.randomAlphabet({ isUupper: true })
            }
            if (tempUupperLength < length) {
              for (let i = 0; i < length - tempUupperLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          } else {
            for (let i = 0; i < length; i++) {
              this.makeCode += this.randomAlphabet()
            }
          }
        }
      }
      this.shuffle(this.makeCode)
    },

    /**
     * 图片验证码随机排序
     * @param {string} str 图片验证码
     */
    shuffle (str) {
      this.identifyCode = [...str].sort(() => Math.random() - 0.5).join('')
    },

    /**
     * 更换图片验证码
     */
    refreshCode () {
      this.makeIdentifyCode({ randomTypeLen: true })
    }
  }
}
</script>

<style lang="less" scoped>
.el-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  .login-form-wrap {
    max-width: 650px;
    background-color: #fff;
    border-radius: 6px;
    padding: 40px;
    .login-head {
      display: flex;
      justify-content: left;
      .bglogo {
        width: 400px;
        background: url('~@/assets/image/5cf4ca83a833d.png') no-repeat;
        background-size: contain;
      }
    }
    .login-form {
      padding-left: 10px;
      .login-btn {
        width: 100%;
      }
      .title {
        text-align: left;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
.el-header {
  padding-top: 15px;
  background-color: #ffffff;
  border-top: 3px solid #1e9fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 58.8%, 0.3);
  line-height: 80px;
}
.logo {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.el-footer {
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
  line-height: 120px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
}

.el-main {
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('~@/assets/image/5d24495b2940d.png') no-repeat;
  background-size: cover;
}
.copyright-box {
  width: 100%;
}
.column {
  margin: 0 auto;
  padding: 24px 34px 20px;
}
.column .footer-column-t {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.column .footer-column-t li {
  margin: 0 8px;
  color: #999aaa;
  font-size: 14px;
  height: 16px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column a {
  text-decoration: none;
  color: inherit;
}
.column .footer-column-b {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.column .footer-column-b li {
  font-size: 12px;
  color: #999aaa;
  height: 16px;
  line-height: 16px;
  margin: 4px 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column .footer-column-b li img {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 4px;
}
.about {
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
}
.code {
  width: 60%;
  float: left;
}
.codebutton {
  width: 35%;
  float: left;
  margin-left: 2%;
}
</style>
