<template>
  <div class="collection-container">
    <el-card class="filter-card"
             shadow="hover">
      <!-- 数据筛选表单 -->
      <el-form ref="form"
               :model="collectionform"
               label-width="80px">
        <el-form-item label="对方户名">
          <el-select v-model="collectionform.oppAccountName"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="(item,index) in oppAccountNameData"
                       :key="index"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="本方户名">
          <el-select v-model="collectionform.accountName"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="(item,index) in accountNameData"
                       :key="index"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="交易日期">
          <el-date-picker v-model="rangeDate"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left:-60px ;">
          <el-button type="primary"
                     icon="el-icon-search"
                     @click="searchData"
                     :loading="updateLoading">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>
    <el-card class="filter-card"
             shadow="hover">
      <el-table v-loading="loading"
                :data="collectionData"
                border
                show-summary
                :summary-method="getSummaries"
                height="calc(100vh - 260px)"
                style="width: 100%;font-size: 14px"
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName">
        <el-table-column prop="tradeTime"
                         label="交易时间"
                         align="center"
                         width="160">
        </el-table-column>
        <el-table-column prop="amount"
                         label="金额"
                         align="right"
                         width="130">
        </el-table-column>
        <el-table-column prop="oppAccountName"
                         label="对方户名"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="oppAccountNo"
                         label="对方账号"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="oppOpenBankName"
                         label="对方开户行"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="accountName"
                         label="本方户名"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="bankName"
                         label="本方银行"
                         align="center"
                         width="150"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="purpose"
                         label="用途"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="80">
        </el-table-column>
        <el-table-column prop="digest"
                         label="附言"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="80">
        </el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="80">
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getPersonalList, getAccountNameList } from '@/api/global'
import globalBus from '@/utils/global-bus'
import router from '@/router'
export default {
  data () {
    return {
      rangeDate: null,
      loading: true,
      pickerOptions: {
        disabledDate (time) {
          const curDate = (new Date()).getTime()
          const three = 36 * 30 * 24 * 3600 * 1000
          const threeMonths = curDate - three
          return time.getTime() > Date.now() || time.getTime() < threeMonths
        },
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近半年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 360)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      collectionform: {
        accountName: '',
        oppAccountName: ''
      },
      timer: '',
      accountNameData: [],
      oppAccountNameData: [],
      updateLoading: false,
      collectionData: [],
      navnames: '',
      topname: ''
    }
  },
  created () {
    // this.loadgetAccountNameList()
    // this.loadgetCollectionList()
    // this.loadnav()
    this.loadtiao()
  },
  mounted () {
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.searchData()
      }, 0)
    }, 120000)
  },
  destroyed () {
    window.clearInterval(this.timer)
  },
  methods: {
    loadtiao () {
      router.push('/collection')
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'personalcollection') {
        this.topname = '收款信息'
        this.navnames = '我的客户'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetCollectionList () {
      getPersonalList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.rangeDate = res.data.date
          this.collectionData = res.data.data.map(val => {
            if (val.amount) {
              val.amount = this.formatMoney(val.amount)
            }
            return val
          })
        } else {
          this.loading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    loadgetAccountNameList () {
      getAccountNameList().then(res => {
        if (res.data.status === 'success') {
          this.accountNameData = res.data.data.accountName
          this.oppAccountNameData = res.data.data.oppAccountName
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    searchData () {
      this.updateLoading = true
      getPersonalList({ startDate: this.rangeDate ? this.rangeDate[0] : null, endDate: this.rangeDate ? this.rangeDate[1] : null, accountName: this.collectionform.accountName, oppAccountName: this.collectionform.oppAccountName }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.updateLoading = false
          this.collectionData = res.data.data.map(val => {
            if (val.amount) {
              val.amount = this.formatMoney(val.amount)
            }
            return val
          })
        } else {
          this.loading = false
          this.updateLoading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('失获取数据败', err)
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
      // 延时执行   要不然会一直刷新
      window.setTimeout(() => {
        if (this.theight === this.height) {
          this.theight -= 0.5
        } else {
          this.theight = this.height
        }
      }, 1000)
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (column.property === 'amount') {
          const valuess = data.map(item => item[column.property])
          const values = []
          if (valuess.length > 0) {
            for (var i = 0; i < valuess.length; i++) {
              values.push(this.moneyToNumFiled(valuess[i]))
            }
          }
          if (!values.every(value => isNaN(value))) {
            const sumss = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            // 保留两位小数，末尾四舍五入
            const realVal = parseFloat(sumss).toFixed(2)
            // 金额显示形式
            sums[index] = this.formatMoney(realVal)
            // if (column.property === 'amount') {
            // sums[index] += ' 元'
            // }
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    formatMoney (value) {
      // 获取整数部分（这里是windy93的方法）
      var intPart = Number(value) - Number(value) % 1
      // 将整数部分逢三一断
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      var floatPart = '.00' // 预定义小数部分
      var value2Array = value.toString().split('.')
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        // 拿到小数部分
        floatPart = value2Array[1].toString()
        // 补0,实际上用不着
        if (floatPart.length === 1) {
          return intPartFormat + '.' + floatPart + '0'
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    },
    moneyToNumFiled (money) {
      var num = money.trim()
      var ss = num.toString()
      if (ss.length === 0) {
        return 0.00
      }
      num = Number(ss.replace(/,/g, ''))
      return num
    }
  }
}
</script>

<style lang="less" scoped>
.filter-card {
  margin-bottom: 10px;
}

.list-table {
  margin-bottom: 10px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.el-form-item {
  float: left;
  margin-right: 5px;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-card__body {
  padding: 20px 20px 60px 20px;
}
</style>
