<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <div style="float:right;margin-top:5px">
          <el-button type="primary"
                     icon="iconfont icon-jia"
                     size="mini"
                     style="margin: 0 10px 10px 0;padding: 6px 12px;"
                     @click="importorder"> 添加</el-button>
        </div>
      </el-row>
      <el-row>
        <el-table :data="offeritemlist"
                  style="width: 100%"
                  ref="multipleTable"
                  row-key="id"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  height="calc(100vh - 180px)"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="50">
          </el-table-column>
          <el-table-column prop="name"
                           label="项目名称"
                           align="center">
          </el-table-column>
          <el-table-column prop="addr"
                           label="项目地址"
                           align="center">
          </el-table-column>
          <el-table-column prop="brand"
                           label="品牌范围"
                           align="center">
          </el-table-column>
          <el-table-column align="center"
                           label="操作"
                           width="150">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="handleEdit(scope.row)"
                         icon="el-icon-edit">修改</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.row)"
                         icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <el-dialog :title="title"
               :visible.sync="dialogVisibles"
               append-to-body
               :close-on-click-modal="false">
      <el-form ref="form"
               :model="form"
               :rules="formRules">
        <el-form-item label="项目名称"
                      prop="name">
          <el-input v-model="form.name"
                    style="width:790px"></el-input>
        </el-form-item>
        <el-form-item label="项目地址"
                      prop="addr">
          <el-input v-model="form.addr"
                    style="width:790px"></el-input>
        </el-form-item>
        <el-form-item label="品牌要求"
                      prop="brands">
          <el-select v-model="form.brands"
                     multiple
                     filterable
                     remote
                     reserve-keyword
                     placeholder="请输入品牌"
                     :loading="brandloading"
                     style="width:790px">
            <el-option v-for="item in brandsdata"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="primary"
                   @click="importFile(type)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getBrandsList, addOfferItem, getOfferItem, editOfferItem, updateOfferItem, delOfferItem } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'offeritemIndex',
  data () {
    return {
      loading: true,
      brandloading: true,
      updateLoading: false,
      dialogVisibles: false,
      title: '',
      type: 1,
      brandsdata: [],
      brands: [],
      offeritemlist: [],
      form: {
        name: null,
        addr: null,
        brands: []
      },
      formRules: {
        name: [{ required: true, message: '请填写项目名', trigger: 'blur' }],
        addr: [{ required: true, message: '请填写项目地址', trigger: 'blur' }],
        brands: [{ required: true, message: '请选择品牌范围', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.loadnav()
    this.loadbrandsList()
    this.loadgetOfferItem()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'offeritem') {
        this.topname = '项目报价'
        this.navnames = '报价项目'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadbrandsList () {
      getBrandsList().then(res => {
        if (res.data.status === 'success') {
          this.brandsdata = res.data.data
          this.brandloading = false
        }
      }).catch(err => {
        this.brandloading = false
        console.log('保存失败', err)
      })
    },
    loadgetOfferItem () {
      getOfferItem().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.offeritemlist = res.data.data
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    importorder () {
      this.form = {
        name: null,
        addr: null,
        brands: []
      }
      this.dialogVisibles = true
      this.title = '添加'
      this.type = 1
    },
    importFile () {
      // 验证通过，提交表单
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false
        }
        if (this.type === 1) {
          this.addOfferItem()
        } else {
          this.updateOfferItem()
        }
      })
    },
    addOfferItem () {
      addOfferItem({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadgetOfferItem()
          this.dialogVisibles = false
        } else {
          this.$message.error(res.data.message)
          this.dialogVisibles = false
        }
      }).catch(err => {
        this.dialogVisibles = false
        console.log('失获取数据败', err)
      })
    },
    handleEdit (row) {
      editOfferItem({ id: row.id }).then(res => {
        if (res.data.status === 'success') {
          this.dialogVisibles = true
          this.title = '修改'
          this.type = 2
          this.form = res.data.data
        }
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    updateOfferItem () {
      updateOfferItem({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadgetOfferItem()
          this.dialogVisibles = false
        } else {
          this.$message.error(res.data.message)
          this.dialogVisibles = false
        }
      }).catch(err => {
        this.dialogVisibles = false
        console.log('失获取数据败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delOfferItem({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadgetOfferItem()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  padding: 0 10px 0 0;
}
/deep/.el-table .el-table__cell {
  padding: 3px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 3px 0;
}
/deep/.el-table th {
  padding: 3px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
.dialog-footer {
  margin-top: 25px;
  margin-left: 70px;
}
</style>
