<template>
  <div class="collection-container">

    <el-card class="filter-card"
             shadow="hover">
      <div class="search">
        <!-- 数据筛选表单 -->
        <el-form ref="form"
                 :model="collectionform"
                 label-width="80px">
          <el-form-item label="销售单号"
                        class="el-form-items"  style="width: 250px;">
            <el-input v-model="collectionform.FSNo"
                      placeholder="请输入内容"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="送货地址"
                        class="el-form-items"  style="width: 250px;">
            <el-input v-model="collectionform.FDetailedAddress"
                      placeholder="请输入内容"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="交易日期"
                        class="el-form-items" style="width: 400px;">
            <el-date-picker v-model="collectionform.rangeDate"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item style="margin-left:-60px ;"
                        class="el-form-items">
            <el-button type="primary"
                       size="mini"
                       icon="el-icon-search"
                       @click="searchData"
                       :loading="updateLoading">查询</el-button>
          </el-form-item>
        </el-form>
        <div class="right">
          <el-button type="success"
                     size="mini"
                     icon="el-icon-plus"
                     :loading="updateLoading"
                     @click="synchronizationweilan"
                     style="margin-top:7px ;">同步蔚蓝订单</el-button>
        </div>
        <div class="right">
          <el-button type="primary"
                     size="mini"
                     icon="el-icon-plus"
                     @click="synchronization"
                     style="margin-top:7px ;">同步泊明订单</el-button>
        </div>
        <div class="right">
          <el-button type="primary"
                     size="mini"
                     @click="dialogFormVisibletoken=true"
                     style="margin-top:7px ;">输入令牌</el-button>
        </div>
        <div class="right">
          <el-button type="primary"
                     size="mini"
                     @click="huisynchronization"
                     style="margin-top:7px ;">恢复同步状态</el-button>
        </div>
       <!-- <div class="right">
          <el-button type="primary"
                     size="mini"
                     @click="getActual"
                     style="margin-top:7px ;">获取实提数</el-button>
        </div>-->
        <!-- /数据筛选表单 -->
      </div>
      <div class="clear"></div>
      <el-table ref="multipleTable"
                v-loading="loading"
                :data="chequnarData"
                border
                height="calc(100vh - 205px)"
                style="width: 100%;font-size: 14px"
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                @selection-change="handleSelectionChange"
                @row-click="handleRowClick"
                @select="handleSelect"
                @select-all="handleSelectAll"
                show-summary
                :summary-method="getSummaries">
        <el-table-column type="selection"
                         width="48">
        </el-table-column>
        <el-table-column prop="FCDate"
                         label="创建时间"
                         align="center"
                         width="160px"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FSNo"
                         label="销售单号"
                         align="center"
                         width="150px"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FuturesOrderNo"
                         label="预定单号"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FSStockName"
                         label="交货仓库"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="100px">
        </el-table-column>
        <el-table-column prop="FProductName"
                         label="品名"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="150px">
        </el-table-column>
        <el-table-column prop="FPrice"
                         label="成交单价"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="80px">
        </el-table-column>
        <el-table-column prop="FSumWeightEntry"
                         label="理论总量"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="100px">
          <template slot-scope="scope">
            <span>{{scope.row.FSumWeightEntry}}吨</span>
          </template>
        </el-table-column>
        <el-table-column prop="FPaidWeight"
                         label="实提数"
                         align="center"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.FPaidWeight!==null">{{scope.row.FPaidWeight}}吨</span>
          </template>
        </el-table-column>
        <el-table-column prop="Sprice"
                         label="销售单价"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="80px">
        </el-table-column>
        <el-table-column prop="FDetailedAddress"
                         label="送货地址"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="customer"
                         label="客户"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="project"
                         label="工程"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="status"
                         label="订单同步状态"
                         align="center"
                         :filters="[{ text: '成功', value: '成功' }, { text: '失败', value: '失败' }, { text: '未同步', value: '未同步' }]"
                         :filter-method="filterTagstatus"
                         :show-overflow-tooltip="true"
                         width="120px">
          <template slot-scope="scope">
            <div v-if="scope.row.status==='成功'"><span class="active-column">{{scope.row.status}}</span> </div>
            <div v-else-if="scope.row.status==='失败'"><span class="active-column2">{{scope.row.status}}</span> </div>
            <div v-else><span class="active-column3">{{scope.row.status}}</span> </div>
          </template>
        </el-table-column>
        <el-table-column prop="team"
                         label="业务组"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="company"
                         label="销售公司"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="pcompany"
                         label="采购公司"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="同步泊明订单操作"
               :visible.sync="dialogFormVisible"
               append-to-body
               :fullscreen="true">
      <el-form :model="form"
               ref="collectionlist-form"
               label-width="120px"
               :rules="formRules">
        <el-form-item label="客户"
                      prop="customer"
                      class="el-form-items">
          <el-select v-model="form.customer"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="customerchange">
            <el-option v-for="item in customerData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务组"
                      prop="team"
                      class="el-form-items">
          <el-select v-model="form.team"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in teamData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售公司"
                      prop="company"
                      class="el-form-items">
          <el-select v-model="form.company"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changecompany">
            <el-option v-for="item in companyData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工程"
                      prop="project"
                      class="el-form-items">
          <el-select v-model="form.project"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changeproject">
            <el-option v-for="item in projectDatas"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购公司"
                      prop="companys"
                      class="el-form-items">
          <el-select v-model="form.companys"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in companyData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理结算"
                      prop="settlementcenter"
                      class="el-form-items">
          <el-select v-model="form.settlementcenter"
                     filterable
                     clearable
                     @change="changesettlementcenter"
                     @clear="clearsettlementcenter"
                     placeholder="请选择">
            <el-option v-for="item in settlementcenterData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收货联系"
                      prop="contact"
                      class="el-form-items">
          <el-input v-model="form.contact"
                    type="textarea"
                    placeholder="请输入内容"
                    style="width: 229px"></el-input>
        </el-form-item>
        <el-form-item label="主单备注"
                      prop="memo"
                      class="el-form-items">
          <el-input v-model="form.memo"
                    type="textarea"
                    size="mini"
                    placeholder="请输入内容"
                    style="width: 229px"></el-input>
        </el-form-item>
      </el-form>
      <div style="clear: both;"></div>
      <el-table v-loading="loading"
                :data="collectionlistData"
                border
                height="calc(100vh - 360px)"
                style="width: 100%;font-size: 14px"
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassNames"
                @cell-click="tableEdit"
                show-summary
                :summary-method="getSummaries">
        <el-table-column prop="FCDate"
                         label="创建时间"
                         align="center">
        </el-table-column>
        <el-table-column prop="FSNo"
                         label="销售单号"
                         align="center">
        </el-table-column>
        <el-table-column prop="FuturesOrderNo"
                         label="预定单号"
                         align="center"
                         width="150px">
        </el-table-column>
        <el-table-column prop="FSStockName"
                         label="交货仓库"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FProductName"
                         label="品名"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FPrice"
                         label="成交单价"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FSumWeightEntry"
                         label="理论总量"
                         align="center"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{scope.row.FSumWeightEntry}}吨</span>
          </template>
        </el-table-column>
        <el-table-column prop="Sprice"
                         label="销售单价"
                         align="center"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class='cell'>
              <div class='el-input'>
                <div class='el-input__inners'>{{scope.row.Sprice}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ProxyPrice"
                         label="销售代理单价"
                         align="center"
                         :show-overflow-tooltip="true"
                         v-if="isProxyPrice===true">
          <template slot-scope="scope">
            <div class='cell'>
              <div class='el-input'>
                <div class='el-input__inners'>{{scope.row.ProxyPrice}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="FDetailedAddress"
                         label="送货地址"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="onUpdateCollectionlist"
                   :loading="updateLoading">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="同步蔚蓝订单操作"
               :visible.sync="dialogFormVisibleweilan"
               append-to-body
               :fullscreen="true">
      <el-form :model="formweilan"
               ref="collectionlistweilan-form"
               label-width="120px"
               :rules="formRulesweilan">
        <el-form-item label="客户"
                      prop="customer"
                      class="el-form-items">
          <el-select v-model="formweilan.customer"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="customerchangeweilan">
            <el-option v-for="item in customerweilanData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务组"
                      prop="team"
                      class="el-form-items">
          <el-select v-model="formweilan.team"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in teamweilanData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售公司"
                      prop="company"
                      class="el-form-items">
          <el-select v-model="formweilan.company"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changecompanyweilan">
            <el-option v-for="item in companyweilanData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工程"
                      prop="project"
                      class="el-form-items">
          <el-select v-model="formweilan.project"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changeprojectweilan">
            <el-option v-for="item in projectweilanDatas"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购公司"
                      prop="companys"
                      class="el-form-items">
          <el-select v-model="formweilan.companys"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in companyweilanData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理结算"
                      prop="settlementcenter"
                      class="el-form-items">
          <el-select v-model="formweilan.settlementcenter"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in settlementcenterweilanData"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收货联系"
                      prop="contact"
                      class="el-form-items">
          <el-input v-model="formweilan.contact"
                    type="textarea"
                    placeholder="请输入内容"
                    style="width: 229px"></el-input>
        </el-form-item>
        <el-form-item label="主单备注"
                      prop="memo"
                      class="el-form-items">
          <el-input v-model="formweilan.memo"
                    type="textarea"
                    size="mini"
                    placeholder="请输入内容"
                    style="width: 229px"></el-input>
        </el-form-item>
      </el-form>
      <div style="clear: both;"></div>
      <el-table v-loading="loading"
                :data="collectionlistData"
                border
                height="calc(100vh - 360px)"
                style="width: 100%;font-size: 14px"
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassNames"
                @cell-click="tableEdit"
                show-summary
                :summary-method="getSummaries">
        <el-table-column prop="FCDate"
                         label="创建时间"
                         align="center">
        </el-table-column>
        <el-table-column prop="FSNo"
                         label="销售单号"
                         align="center">
        </el-table-column>
        <el-table-column prop="FuturesOrderNo"
                         label="预定单号"
                         align="center"
                         width="150px">
        </el-table-column>
        <el-table-column prop="FSStockName"
                         label="交货仓库"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FProductName"
                         label="品名"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FPrice"
                         label="成交单价"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="FSumWeightEntry"
                         label="理论总量"
                         align="center"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{scope.row.FSumWeightEntry}}吨</span>
          </template>
        </el-table-column>
        <el-table-column prop="Sprice"
                         label="销售单价"
                         align="center"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class='cell'>
              <div class='el-input'>
                <div class='el-input__inners'>{{scope.row.Sprice}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ProxyPrice"
                         label="销售代理单价"
                         align="center"
                         :show-overflow-tooltip="true"
                         v-if="isProxyPrice===true">
          <template slot-scope="scope">
            <div class='cell'>
              <div class='el-input'>
                <div class='el-input__inners'>{{scope.row.ProxyPrice}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="FDetailedAddress"
                         label="送货地址"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="onUpdateCollectionlistweilan"
                   :loading="updateLoading">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="登录过期了"
               :visible.sync="dialogFormVisibletoken"
               append-to-body>
      <el-form :model="formt"
               :rules="formtRule"
               ref="collectionlist-formtRule">
        <el-form-item label="令牌"
                      label-width="100px"
                      prop="code">
          <el-input v-model="formt.code"
                    autocomplete="off"
                    pplaceholder="请输入令牌"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisibletoken = false">取 消</el-button>
        <el-button type="primary"
                   @click="updatetoken">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getChequnarOrderList, upDataSprice, SellBillPurchaseBillList, getSearchData, chequnaLogin, getWeilanChequnarOrderList, WeilanSellBillPurchaseBillList, huisynchronization } from '@/api/global'
import globalBus from '@/utils/global-bus'
import { Message } from 'element-ui'
export default {
  name: 'ChequnarlistIndex',
  data () {
    return {
      top: '15px',
      rangeDate: null,
      loading: true,
      pickerOptions: {
        disabledDate (time) {
          const curDate = (new Date()).getTime()
          const three = 36 * 30 * 24 * 3600 * 1000
          const threeMonths = curDate - three
          return time.getTime() > Date.now() || time.getTime() < threeMonths
        },
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近半年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 360)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      customerData: [],
      companyData: [],
      teamData: [],
      projectData: [],
      projectDatas: [],
      customerweilanData: [],
      companyweilanData: [],
      teamweilanData: [],
      projectweilanData: [],
      projectweilanDatas: [],
      settlementcenterweilanData: [],
      settlementcenterData: [],
      updateLoading: false,
      chequnarData: [],
      collectionlistData: [],
      navnames: '',
      topname: '',
      form: {
        id: [],
        customer: '',
        company: '',
        team: '',
        project: '',
        companys: '',
        settlementcenter: null,
        contact: '',
        memo: ''
      },
      formweilan: {
        id: [],
        customer: '',
        company: '',
        team: '',
        project: '',
        companys: '',
        settlementcenter: null,
        contact: '',
        memo: ''
      },
      dialogFormVisible: false,
      dialogFormVisibleweilan: false,
      formRules: {
        customer: [
          { required: true, message: '请选择客户', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请选择销售公司', trigger: 'blur' }
        ],
        team: [
          { required: true, message: '请选择业务组', trigger: 'blur' }
        ]
      },
      formRulesweilan: {
        customer: [
          { required: true, message: '请选择客户', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请选择销售公司', trigger: 'blur' }
        ],
        team: [
          { required: true, message: '请选择业务组', trigger: 'blur' }
        ]
      },
      collectionform: {
        FSNo: '',
        FDetailedAddress: '',
        rangeDate: ''
      },
      dialogFormVisibletoken: false,
      formt: {
        code: ''
      },
      formtRule: {
        code: [
          { required: true, message: '请输入令牌', trigger: 'blur' }
        ]
      },
      chequnarDataSearch: [],
      isProxyPrice: false
    }
  },
  created () {
    this.loadgetChequnarOrderList()
    this.loadgetWeilanChequnarOrderList()
    this.loadnav()
  },
  mounted () {
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'chequnar') {
        this.topname = '桂鑫订单'
        this.navnames = '收款'
      }
      globalBus.$emit('navinfo', { status: 0, topname: this.topname, navname: this.navnames })
    },
    loadgetChequnarOrderList () {
      getChequnarOrderList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.chequnarData = res.data.data
          this.collectionform.rangeDate = res.data.date
          this.customerData = res.data.customer
          this.companyData = res.data.company
          this.teamData = res.data.team
          this.projectData = res.data.project
          this.projectDatas = res.data.project
          this.settlementcenterData = res.data.settlementcenter
          if (res.data.chequnar_status === 0) {
            this.dialogFormVisibletoken = true
          }
        } else {
          this.loading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    loadgetWeilanChequnarOrderList () {
      getWeilanChequnarOrderList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.customerweilanData = res.data.customer
          this.companyweilanData = res.data.company
          this.teamweilanData = res.data.team
          this.projectweilanData = res.data.project
          this.projectweilanDatas = res.data.project
          this.settlementcenterweilanData = res.data.settlementcenter
        } else {
          this.loading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    onUpdateCollectionlistweilan () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['collectionlistweilan-form'].validate(valid => {
        if (!valid) {
          return false
        }
        const _this = this
        _this.listcount = 0
        this.collectionlistData.forEach((item) => {
          if (item.status === '成功') {
            // eslint-disable-next-line no-const-assign
            _this.listcount++
          }
        })
        if (_this.listcount > 0) {
          Message.error('存在已同步过的订单明细，请重新选择')
          return false
        } else {
          this.sendinfoweilan()
        }
      })
    },
    sendinfoweilan () {
      const loading = this.$loading({
        lock: true,
        text: '正在同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      this.updateLoading = true
      WeilanSellBillPurchaseBillList(this.formweilan).then(res => {
        if (res.data.status === 'success') {
          this.loadgetChequnarOrderList()
          loading.close()
          Message.success(res.data.message)
          this.updateLoading = false
          this.dialogFormVisibleweilan = false
          this.formweilan = {
            id: [],
            customer: '',
            company: '',
            team: '',
            project: '',
            companys: '',
            settlementcenter: null,
            contact: '',
            memo: ''
          }
          this.collectionlistData = []
          this.clearsettlementcenter()
        } else {
          loading.close()
          Message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        loading.close()
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    onUpdateCollectionlist () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['collectionlist-form'].validate(valid => {
        if (!valid) {
          return false
        }
        const _this = this
        _this.listcount = 0
        this.collectionlistData.forEach((item) => {
          if (item.status === '成功') {
            // eslint-disable-next-line no-const-assign
            _this.listcount++
          }
        })
        if (_this.listcount > 0) {
          Message.error('存在已同步过的订单明细，请重新选择')
          return false
        } else {
          this.sendinfo()
        }
      })
    },
    sendinfo () {
      const loading = this.$loading({
        lock: true,
        text: '正在同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      this.updateLoading = true
      SellBillPurchaseBillList(this.form).then(res => {
        if (res.data.status === 'success') {
          this.loadgetChequnarOrderList()
          loading.close()
          Message.success(res.data.message)
          this.updateLoading = false
          this.dialogFormVisible = false
          this.form = {
            id: [],
            customer: '',
            company: '',
            team: '',
            project: '',
            companys: '',
            settlementcenter: null,
            contact: '',
            memo: ''
          }
          this.collectionlistData = []
          this.clearsettlementcenter()
        } else {
          loading.close()
          Message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        loading.close()
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    searchData () {
      this.loading = true
      getSearchData(this.collectionform).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.chequnarData = res.data.data
          if (res.data.chequnar_status === 0) {
            this.dialogFormVisibletoken = true
          }
        } else {
          this.loading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    changecompany (res) {
      this.form.companys = res
    },
    changecompanyweilan (res) {
      this.formweilan.companys = res
    },
    updatetoken () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['collectionlist-formtRule'].validate(valid => {
        if (!valid) {
          return false
        }
        chequnaLogin(this.formt).then(res => {
          if (res.data.status === 'success') {
            Message.success(res.data.message)
          } else {
            Message.error(res.data.message)
          }
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      })
    },
    handleSelectionChange (val) {
      this.form.id = []
      this.formweilan.id = []
      this.collectionlistData = []
      this.collectionlistData = val
      this.collectionlistData.forEach((item, index) => {
        // eslint-disable-next-line no-const-assign
        this.collectionlistData[index].Sprice = item.FPrice
      })
      for (var i = 0; i < val.length; i++) {
        this.form.id[i] = val[i].ID
        this.formweilan.id[i] = val[i].ID
      }
    },
    huisynchronization () {
      if (this.collectionlistData.length === 0) {
        this.$message.error('请选择内容')
        return false
      } else {
        huisynchronization(this.form).then(res => {
          if (res.data.status === 'success') {
            this.loadgetChequnarOrderList()
            Message.success(res.data.message)
          } else {
            Message.success(res.data.message)
          }
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      }
    },
    synchronization () {
      if (this.collectionlistData.length === 0) {
        this.$message.error('请选择内容')
        return false
      }
      this.dialogFormVisible = true
    },
    synchronizationweilan () {
      if (this.collectionlistData.length === 0) {
        this.$message.error('请选择内容')
        return false
      }
      this.dialogFormVisibleweilan = true
    },
    tableEdit (row, column, cell, event) {
      const _this = this
      if (column.label === '销售单价') {
        var beforeVal = event.target.textContent
        event.target.innerHTML = ''
        const str = `<div class='cell'>
            <div class='el-input'>
              <input type='text' placeholder='' class='el-input__inners'>
            </div>
        </div>`
        cell.innerHTML = str
        // 获取双击后生成的input  根据层级嵌套会有所变化
        const cellInput = cell.children[0].children[0].children[0]
        cellInput.value = beforeVal
        cellInput.focus() // input自动聚焦
        // 失去焦点后  将input移除
        cellInput.onblur = function () {
          const onblurCont = `<div class='cell'> <div class='el-input'><div class='el-input__inners'>${cellInput.value}</div></div></div>`
          cell.innerHTML = onblurCont // 换成原有的显示内容
          // 调用axios接口
          upDataSprice({ id: row.ID, sprice: cell.textContent, type: 1 }).then(res => {
            if (res.data.status === 'success') {
              _this.collectionlistData.forEach((item, index) => {
                if (item.ID === row.ID) {
                  // eslint-disable-next-line no-const-assign
                  _this.collectionlistData[index].ProxyPrice = cellInput.value
                }
              })
            }
          }).catch(err => {
            console.log('保存失败', err)
          })
        }
      } else if (column.label === '销售代理单价') {
        var beforeVala = event.target.textContent
        event.target.innerHTML = ''
        const str = `<div class='cell'>
            <div class='el-input'>
              <input type='text' placeholder='' class='el-input__inners'>
            </div>
        </div>`
        cell.innerHTML = str
        // 获取双击后生成的input  根据层级嵌套会有所变化
        const cellInput = cell.children[0].children[0].children[0]
        cellInput.value = beforeVala
        cellInput.focus() // input自动聚焦
        // 失去焦点后  将input移除
        cellInput.onblur = function () {
          const onblurCont = `<div class='cell'> <div class='el-input'><div class='el-input__inners'>${cellInput.value}</div></div></div>`
          cell.innerHTML = onblurCont // 换成原有的显示内容
          // 调用axios接口
          upDataSprice({ id: row.ID, sprice: cell.textContent, type: 2 }).then(res => {
            if (res.data.status === 'success') {

            }
          }).catch(err => {
            console.log('保存失败', err)
          })
        }
      }
    },
    // 手动点击勾选框触发的事件
    handleSelect (selection, row) {
      // selection,row  传递两个参数，row直接是对象
      if (row.className === 'normal') {
        row.className = 'tableSelectedRowBgColor'
      } else {
        row.className = 'normal'
      }
    },

    // select-all 手动点击全选触发的事件
    handleSelectAll (selection) {
      if (selection.length > 0) {
        selection.forEach((item) => {
          if (item.className === 'normal') {
            item.className = 'tableSelectedRowBgColor'
          }
        })
      } else {
        // 空数组初始化className
        this.chequnarData.forEach((item) => {
          if (item.className === 'tableSelectedRowBgColor') {
            item.className = 'normal'
          }
        })
      }
    },
    // 选中背景色
    tableRowClassName ({ row, rowIndex }) {
      return row.className
    },
    tableRowClassNames ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    // 点击行触发，切换复选框状态
    handleRowClick (row, column, event) {
      if (row.className === 'normal') {
        row.className = 'tableSelectedRowBgColor'
        this.$refs.multipleTable.toggleRowSelection(row, true)
      } else {
        row.className = 'normal'
        this.$refs.multipleTable.toggleRowSelection(row, false)
      }
    },
    filterTagstatus (value, row) {
      return row.status === value
    },
    customerchange (value) {
      this.projectDatas = []
      this.projectData.forEach((item, index) => {
        if (item.CustomerId === value) {
          this.projectDatas.push(item)
        }
      })
      if (this.projectDatas.length === 0) {
        this.projectDatas = []
        this.form.contact = ''
        this.form.project = ''
      }
    },
    customerchangeweilan (value) {
      this.projectweilanDatas = []
      this.projectweilanData.forEach((item, index) => {
        if (item.CustomerId === value) {
          this.projectweilanDatas.push(item)
        }
      })
      if (this.projectweilanDatas.length === 0) {
        this.projectweilanDatas = []
        this.formweilan.contact = ''
        this.formweilan.project = ''
      }
    },
    changeproject (value) {
      this.projectData.forEach((item, index) => {
        if (item.value === value) {
          this.form.contact = item.ContactPerson + ' ' + item.ContactPhone
          this.customerData.forEach((items, indexs) => {
            if (items.value === item.CustomerId) {
              this.form.customer = items.value
            }
          })
        }
      })
    },
    changeprojectweilan (value) {
      this.projectweilanData.forEach((item, index) => {
        if (item.value === value) {
          this.formweilan.contact = item.ContactPerson + ' ' + item.ContactPhone
          this.customerweilanData.forEach((items, indexs) => {
            if (items.value === item.CustomerId) {
              this.formweilan.customer = items.value
            }
          })
        }
      })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
      // 延时执行   要不然会一直刷新
      window.setTimeout(() => {
        if (this.theight === this.height) {
          this.theight -= 0.5
        } else {
          this.theight = this.height
        }
      }, 1000)
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计'
          return
        }
        if (column.property === 'FSumWeightEntry') {
          const valuess = data.map(item => item[column.property])
          const values = []
          if (valuess.length > 0) {
            for (var i = 0; i < valuess.length; i++) {
              values.push(this.moneyToNumFiled(valuess[i]))
            }
          }
          if (!values.every(value => isNaN(value))) {
            const sumss = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            // 保留两位小数，末尾四舍五入
            const realVal = parseFloat(sumss).toFixed(2)
            // 金额显示形式
            sums[index] = this.formatMoney(realVal)
            if (column.property === 'FSumWeightEntry') {
              sums[index] += ' 吨'
            }
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    formatMoney (value) {
      // 获取整数部分（这里是windy93的方法）
      var intPart = Number(value) - Number(value) % 1
      // 将整数部分逢三一断
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      var floatPart = '.00' // 预定义小数部分
      var value2Array = value.toString().split('.')
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        // 拿到小数部分
        floatPart = value2Array[1].toString()
        // 补0,实际上用不着
        if (floatPart.length === 1) {
          return intPartFormat + '.' + floatPart + '0'
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    },
    moneyToNumFiled (money) {
      var num = money.trim()
      var ss = num.toString()
      if (ss.length === 0) {
        return 0.00
      }
      num = Number(ss.replace(/,/g, ''))
      return num
    },
    changesettlementcenter (val) {
      const _this = this
      this.collectionlistData.forEach((item, index) => {
        // eslint-disable-next-line no-const-assign
        _this.collectionlistData[index].ProxyPrice = item.FPrice
      })
      this.isProxyPrice = true
    },
    clearsettlementcenter () {
      const _this = this
      this.collectionlistData.forEach((item, index) => {
        // eslint-disable-next-line no-const-assign
        _this.collectionlistData[index].ProxyPrice = null
      })
      this.isProxyPrice = false
      this.form.settlementcenter = null
      this.formweilan.settlementcenter = null
    }
  }
}
</script>

<style lang="less" scoped>
.filter-card {
  margin-bottom: 10px;
}

.list-table {
  margin-bottom: 10px;
}
/deep/ .tableSelectedRowBgColor td {
  background-color: rgb(253, 246, 236) !important;
}
.article-cover {
  width: 60px;
  background-size: cover;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
/deep/ .el-table {
  font-size: 14px;
  color: #161616;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-card__body {
  padding: 10px 10px 50px 10px;
}
.right {
  float: right;
  padding-left: 10px;
}
.active-column {
  color: #06b42c;
  font-weight: 600;
}
.active-column2 {
  color: #d30c0c;
  font-weight: 600;
}
.active-column3 {
  color: #e6a23c;
  font-weight: 600;
}
/deep/.el-input__inners {
  background-image: none;
  border-radius: 0px;
  border: 1px solid #dcdfe6;
  border-top-color: rgb(220, 223, 230);
  border-right-color: rgb(220, 223, 230);
  border-bottom-color: rgb(220, 223, 230);
  border-left-color: rgb(220, 223, 230);
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  padding: 0 25px;
  width: 100%;
}
.el-form-items {
  margin-bottom: 5px;
}
/deep/ .el-form-items .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  border-top-color: rgb(220, 223, 230);
  border-right-color: rgb(220, 223, 230);
  border-bottom-color: rgb(220, 223, 230);
  border-left-color: rgb(220, 223, 230);
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 36px;
  line-height: 35px;
  outline: 0;
  padding: 0 15px;
  padding-right: 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .el-form-items .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.search {
  width: 100%;
  float: left;
  margin-bottom: 5px;
}
.clear {
  clear: both;
}
/deep/.el-table th {
  padding: 0px 0;
}
/deep/.el-table td {
  padding: 5px 0;
  font-size: 12px;
}
</style>
