<template>
  <div class="collection-container">

    <el-card class="filter-card"
             shadow="hover">
      <div class="search">
        <!-- 数据筛选表单 -->
        <el-form ref="voucher-form"
                 :model="voucherform"
                 label-width="80px"
                 :rules="formRules">
          <el-form-item label="公司名"
                        prop="title"
                        class="el-form-items">
            <el-select v-model="voucherform.title"
                       filterable
                       clearable
                       placeholder="请选择">
              <el-option v-for="item in accountNameData"
                         :key="item"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期"
                        prop="month"
                        class="el-form-items">
            <el-date-picker v-model="voucherform.month"
                            type="month"
                            placeholder="选择月"
                            value-format="yyyy-MM">
            </el-date-picker>
          </el-form-item>
          <el-form-item style="margin-left:-60px ;"
                        class="el-form-items">
            <el-button type="primary"
                       size="mini"
                       @click="searchData"
                       icon="el-icon-search">搜索</el-button>
            <el-button type="primary"
                       size="mini"
                       icon="el-icon-plus"
                       @click="createData"
                       :loading="updateLoading">创建</el-button>
            <el-button type="danger"
                       size="mini"
                       @click="check">检查新供应商客户</el-button>
            <el-button type="danger"
                       size="mini"
                       @click="dialogVisibles = true">导入</el-button>
          </el-form-item>
        </el-form>
        <!-- /数据筛选表单 -->
      </div>
      <div class="clear"></div>
      <el-table v-loading="loading"
                :data="voucherData"
                border
                height="calc(100vh - 260px)"
                style="width: 100%;font-size: 14px"
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName">
        <el-table-column prop="file_name"
                         label="凭证名称"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="right"
                         label="操作"
                         width="180">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-download">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="新客商"
               :visible.sync="dialogVisible"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <span>新客户</span>
      <ul>
        <li v-for="item in customer"
            :key="item">{{item}}</li>
      </ul>
      <span>新供应商</span>
      <ul>
        <li v-for="item in supplier"
            :key="item">{{item}}</li>
      </ul>
    </el-dialog>
    <el-dialog title="导入"
               :visible.sync="dialogVisibles"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="form"
               ref="form"
               :rules="formRulessubjectcode"
               label-width="80px">
        <el-form-item label="我方抬头"
                      prop="title">
          <el-select v-model="form.title"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in accountNameData"
                       :key="item"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资料类型"
                      prop="subjectcode">
          <el-select v-model="form.subjectcode"
                     placeholder="请选择科目编码">
            <el-option v-for="(item,index) in types"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期"
                      prop="month">
          <el-date-picker v-model="form.month"
                          type="month"
                          value-format="yyyy-MM"
                          placeholder="选择月">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="凭证资料"
                      prop="business_license"
                      style="margin-top: 20px;">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="businessRemove"
                     :on-success="filesuccess"
                     multiple
                     :file-list="business"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="importFile">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAccountNameList, createVoucher, getVoucherList, downloadVoucher, getUnExitCustomerAndSupplier, importFinanceData } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      rangeDate: null,
      updateLoading: false,
      loading: false,
      accountNameData: [],
      voucherform: {
        title: '',
        month: ''
      },
      voucherData: [],
      formRules: {
        title: [{ required: true, message: '请输选择公司名', trigger: 'blur' }],
        month: [{ required: true, message: '请输选择日期', trigger: 'blur' }]
      },
      formRulessubjectcode: {
        title: [{ required: true, message: '请输选择我方抬头', trigger: 'blur' }],
        subjectcode: [{ required: true, message: '请输选择资料类型', trigger: 'blur' }],
        month: [{ required: true, message: '请输选择日期', trigger: 'blur' }]
      },
      customer: [],
      supplier: [],
      form: {
        title: '',
        subjectcode: '',
        month: '',
        url: ''
      },
      myHeaders: '',
      business: [],
      businessa: [],
      types: [{
        value: '供应商编码',
        label: '供应商编码'
      }, {
        value: '客户编码',
        label: '客户编码'
      }, {
        value: '简称全称',
        label: '简称全称'
      }, {
        value: '销项吨数',
        label: '销项吨数'
      }, {
        value: '销项发票',
        label: '销项发票'
      }, {
        value: '进项吨数',
        label: '进项吨数'
      }, {
        value: '进项发票',
        label: '进项发票'
      }, {
        value: '蔚蓝中行交易信息',
        label: '蔚蓝中行交易信息'
      }]
    }
  },
  created () {
    this.loadgetAccountNameList()
    this.loadnav()
    this.loadtoken()
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'voucher') {
        this.topname = '财务管理'
        this.navnames = '财务凭证'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetAccountNameList () {
      getAccountNameList().then(res => {
        if (res.data.status === 'success') {
          this.accountNameData = res.data.data.accountName
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    searchData () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['voucher-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.loading = true
        getVoucherList(this.voucherform).then(res => {
          this.loading = false
          this.voucherData = res.data.data
        }).catch(err => {
          this.loading = false
          console.log('失获取数据败', err)
        })
      })
    },
    createData () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['voucher-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.loading = true
        createVoucher(this.voucherform).then(res => {
          if (res.data.status === 'success') {
            getVoucherList(this.voucherform).then(res => {
              this.voucherData = res.data.data
            }).catch(err => {
              this.loading = false
              console.log('失获取数据败', err)
            })
            this.loading = false
          } else {
            this.loading = false
            console.log('获取数据失败')
          }
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      })
    },
    handleClose (done) {
      this.dialogVisible = false
    },
    handleEdit (row) {
      downloadVoucher({
        id: row.id
      }).then(res => {
        if (res.data.status === 'success') {
          this.downloadFile(res.data.url)
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    downloadFile (url) {
      var iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = url
      document.body.appendChild(iframe)
    },
    check () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['voucher-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.dialogVisible = true
        getUnExitCustomerAndSupplier(this.voucherform).then(res => {
          this.customer = res.data.data.customer
          this.supplier = res.data.data.supplier
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      })
    },
    importFile () {
      // 验证通过，提交表单
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false
        }
        importFinanceData(this.form).then(res => {
          if (res.data.status === 'success') {
            this.form.subjectcode = ''
            this.form.url = ''
            this.$message({
              message: '导入成功',
              type: 'success'
            })
          } else {
            this.$message.error('导入失败')
          }
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      })
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.form.url = res.data.url
        this.business = this.business.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessRemove (file) {
      this.business.some((item, i) => {
        if (item.url === file.url) {
          this.business.splice(i, 1)
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeUpload (file) {
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.filter-card {
  margin-bottom: 10px;
}

.list-table {
  margin-bottom: 10px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-card__body {
  padding: 10px 10px 10px 10px;
}
.el-form-items {
  margin-bottom: 5px;
}
/deep/ .el-form-items .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  border-top-color: rgb(220, 223, 230);
  border-right-color: rgb(220, 223, 230);
  border-bottom-color: rgb(220, 223, 230);
  border-left-color: rgb(220, 223, 230);
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 36px;
  line-height: 35px;
  outline: 0;
  padding-right: 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .el-form-items .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.search {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.clear {
  clear: both;
}
/deep/.el-table th {
  padding: 5px 0;
}
/deep/.el-table td {
  padding: 5px 0;
  font-size: 12px;
}
</style>
