<template>
  <div style="width:100%;height:100%;">
    <iframe id="wps-iframe"
            :src="wordurl"
            scrolling="no"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            classname="web-office-iframe"
            height="auto"
            width="100%"
            style="width:100%;height:100%;"></iframe>
    <div class="pg"><button id="download"
              @click="download"
              class="toolbarButton download hiddenMediumView"
              title="下载"
              tabindex="34"
              data-l10n-id="download">
      </button>
    </div>
  </div>
</template>

<script>
const Base64 = require('js-base64').Base64
export default {
  data () {
    return {
      wordurl: ''
    }
  },
  created () {
    // this.wordurl = 'https://view.officeapps.live.com/op/view.aspx?src=' + this.$route.params.url
    this.wordurl = 'https://file.gangerp.com/onlinePreview?url=' + Base64.encode(this.$route.params.url)
  },
  methods: {
    download () {
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      a.download = this.$route.params.name
      a.href = this.$route.params.url
      a.dispatchEvent(event)
    }
  }
}
</script>
<style lang="less" scoped>
.pg {
  position: absolute;
  top: 75px;
  z-index: 99999;
  right: 190px;
}
.web-office-iframe {
  position: absolute;
}
.toolbarButton,
.secondaryToolbarButton,
.overlayButton {
  border: 0 none;
  background: none;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  background-clip: border-box;
  width: 32px;
  height: 25px;
}
.toolbarButton,
.dropdownToolbarButton,
.secondaryToolbarButton,
.overlayButton {
  min-width: 16px;
  padding: 2px 6px 0;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  line-height: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
.toolbarButton:hover,
.toolbarButton:focus,
.dropdownToolbarButton,
.overlayButton,
.secondaryToolbarButton:hover,
.secondaryToolbarButton:focus {
  background-color: rgba(0, 0, 0, 0.12);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.05)),
    to(rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0)
  );
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-top-color: rgba(0, 0, 0, 0.35);
  border-right-color: rgba(0, 0, 0, 0.35);
  border-bottom-color: rgba(0, 0, 0, 0.35);
  border-left-color: rgba(0, 0, 0, 0.35);
  border-color: rgba(0, 0, 0, 0.32) rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.42);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset,
    0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 0 rgba(255, 255, 255, 0.05);
}
.toolbarButton,
.dropdownToolbarButton,
.secondaryToolbarButton,
.overlayButton {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  line-height: 14px;
  cursor: default;
}
.toolbarButton::before,
.secondaryToolbarButton::before {
  position: absolute;
  display: inline-block;
  top: 4px;
  left: 7px;
}
.toolbarButton.download::before,
.secondaryToolbarButton.download::before {
  content: url(https://file.gangerp.com/pdfjs/web/images/toolbarButton-download.png);
}
</style>
