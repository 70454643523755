<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <el-form :model="form"
                 ref="customer-form">
          <el-form-item label="客户"
                        class="el-form-items">
            <el-input placeholder="请输入客户"
                      v-model="form.name"
                      clearable
                      class="input-with-select"
                      style="width: 200px;">
            </el-input>
          </el-form-item>
          <el-form-item label="项目名"
                        class="el-form-items">
            <el-input placeholder="请输入项目名"
                      v-model="form.project_name"
                      clearable
                      class="input-with-select"
                      style="width: 200px;">
            </el-input>
          </el-form-item>
          <div style="float: left;margin-top:5px">
            <el-button icon="el-icon-search"
                       type="primary"
                       size="mini"
                       :loading="updateLoading"
                       @click="search"
                       style="padding: 6px 10px;"></el-button>
          </div>
          <div style="float:right;margin-top:5px">
            <el-button type="primary"
                       icon="iconfont icon-jia"
                       size="mini"
                       style="margin: 0 10px 10px 0;padding: 6px 12px;"
                       @click="importorder"> 添加</el-button>
          </div>
        </el-form>
      </el-row>
      <el-row>
        <el-table :data="orderlist"
                  ref="multipleTable"
                  style="width: 100%"
                  row-key="id"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  height="calc(100vh - 180px)"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="50">
          </el-table-column>
          <el-table-column prop="abbreviation"
                           label="客户简称"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="name"
                           label="客户全称"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="owner_name"
                           label="业主方名称"
                           align="center">
          </el-table-column>
          <el-table-column prop="project_name"
                           label="工程项目名称"
                           align="center">
          </el-table-column>
          <el-table-column align="center"
                           width="180"
                           label="操作">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="handleEdit(scope.row.id)"
                         icon="el-icon-edit">修改</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.row.id)"
                         icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;"
             v-show="total!=0">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[29, 50, 100,200]"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-row>
    </el-card>
    <el-dialog :title="title"
               :visible.sync="dialogVisibles"
               width="60%"
               append-to-body
               :close-on-click-modal="false"
               top="10px">
      <el-form :model="projectdata"
               ref="salesman-form"
               :rules="formRules">
        <table border="0"
               cellspacing="0"
               style="width: 100%;"
               class="table">
          <tr>
            <th colspan="8"
                class="bg">项目情况</th>
          </tr>
          <tr>
            <td align="center"
                width="12%"
                class="bg">客户简称</td>
            <td width="15%"
                class="pl">
              <el-form-item prop="abbreviation">
                <el-input v-model="projectdata.abbreviation"
                          placeholder="请输入内容"></el-input></el-form-item>
            </td>
            <td align="center"
                width="12%"
                class="bg">客户全称</td>
            <td colspan="2"
                width="28%"
                class="pl"><el-form-item prop="name"><el-input v-model="projectdata.name"
                          placeholder="请输入内容"></el-input></el-form-item></td>
            <td align="center"
                width="10%"
                class="bg">业主方名称</td>
            <td colspan="2"
                width="25%"
                class="pl"><el-input v-model="projectdata.owner_name"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">工程项目名称</td>
            <td colspan="4"
                class="pl"><el-form-item prop="project_name"><el-input v-model="projectdata.project_name"
                          placeholder="请输入内容"></el-input></el-form-item></td>
            <td align="center"
                class="bg">施工单位</td>
            <td colspan="2"
                class="pl"><el-input v-model="projectdata.construction_unit"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">项目施工许可证</td>
            <td colspan="4"
                class="pl"><el-input v-model="projectdata.construction_permit"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg">建设规模</td>
            <td colspan="2"
                class="pl"><el-input v-model="projectdata.construction_scale"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">项目地址</td>
            <td colspan="4"
                class="pl"><el-input v-model="projectdata.project_adress"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg">建设工期</td>
            <td colspan="2"
                class="pl"><el-input v-model="projectdata.construction_period"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">
              合同编号：</td>
            <td colspan="7"
                align="center"
                class="bg"><el-input v-model="projectdata.contract_number"
                        placeholder="请输入内容"></el-input>
            </td>
          </tr>
          <tr>
            <td align="center"
                class="bg">钢材品牌采购范围</td>
            <td colspan="2"
                class="pl"><el-input v-model="projectdata.brand_range"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg">赊销额度</td>
            <td class="pl"><el-input v-model="projectdata.sell_credit_quota"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg">所需钢材数量</td>
            <td colspan="2"
                class="pl"><el-input v-model="projectdata.steel_quantity"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">结算账期</td>
            <td colspan="7"
                class="pl"><el-input v-model="projectdata.settlement_period"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">基本价格条款</td>
            <td colspan="7"
                class="pl"><el-input v-model="projectdata.price_terms"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">违约金/提前回款优惠/冲账原则</td>
            <td colspan="7"
                class="pl"><el-input type="textarea"
                        :rows="3"
                        placeholder="请输入内容"
                        v-model="projectdata.preferential">
              </el-input></td>
          </tr>
          <tr>
            <td rowspan="3"
                align="center"
                class="bg">异常情况处理条款</td>
            <td colspan="7"
                class="pl">
              <div style="float: left;width: 8%; padding-left:8px;">磅差方面：</div>
              <div style="float:left;width:90%;"><el-input v-model="projectdata.pound_difference"
                          placeholder="请输入内容"></el-input></div>
            </td>
          </tr>
          <tr>
            <td colspan="7"
                class="pl">
              <div style="float: left;width: 11%; padding-left:8px;">质量检测方面：</div>
              <div style="float: left;width: 88%;"><el-input v-model="projectdata.inspection"
                          placeholder="请输入内容"></el-input></div>
            </td>
          </tr>
          <tr>
            <td colspan="7"
                class="pl">
              <div style="float: left;width: 14%; padding-left:8px;">其他异常处理约定：</div>
              <div style="float:left;width:85%;"><el-input v-model="projectdata.other_abnormal"
                          placeholder="请输入内容"></el-input></div>
            </td>
          </tr>
          <tr>
            <td align="center"
                class="bg">发票要求</td>
            <td colspan="7"
                class="pl"><el-input v-model="projectdata.invoice_requirement"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td rowspan="3"
                align="center"
                class="bg">单证授权有效印鉴</td>
            <td align="center"
                class="bg">货物签收人</td>
            <td align="center"
                class="bg">姓名</td>
            <td class="pl"><el-input v-model="projectdata.goods_signee"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg"
                width="12%">签字样式</td>
            <td class="pl"><el-input v-model="projectdata.signature"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg"
                width="10%">是否双签</td>
            <td class="pl"><el-input v-model="projectdata.double_signature"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">收货确认表</td>
            <td align="center">印鉴样式/签字样式</td>
            <td colspan="5"
                class="pl"><el-input v-model="projectdata.receipt_signature"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">对账单</td>
            <td align="center">印鉴样式/签字样式</td>
            <td colspan="5"
                class="pl"><el-input v-model="projectdata.statement_signature"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">其他关注条款</td>
            <td colspan="7"
                class="pl"><el-input v-model="projectdata.other_follow_clause"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td colspan="8"
                class="pl bg"
                align="center"
                style="font-weight: bold;">项目运营相关</td>
          </tr>
          <tr>
            <td colspan="8"
                class="pl">
              <div style="float: left;width: 15%;">项目相关联系人联系方式</div>
              <div style="float: left;width: 85%;"><el-input v-model="projectdata.project_contacts"
                          placeholder="请输入内容"></el-input></div>
            </td>
          </tr>
          <tr>
            <td align="center"
                class="bg">项目负责人</td>
            <td colspan="3"
                class="pl"><el-input v-model="projectdata.project_leader"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg">收货对接人</td>
            <td colspan="3"
                class="pl"><el-input v-model="projectdata.receiving_recipient"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">采购对接人</td>
            <td colspan="3"
                class="pl"><el-input v-model="projectdata.purchase_recipient"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg">对账对接人</td>
            <td colspan="3"
                class="pl"><el-input v-model="projectdata.reconciliation_recipient"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td align="center"
                class="bg">钢筋班主</td>
            <td colspan="3"
                class="pl"><el-input v-model="projectdata.troupe"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                class="bg">邮寄地址及联系人</td>
            <td colspan="3"
                class="pl"><el-input v-model="projectdata.address_contact"
                        placeholder="请输入内容"></el-input></td>
          </tr>
          <tr>
            <td colspan="8">订单要求（品牌优先次序、操作要点、卸货区域、卸货时间、物流情况等）<el-input type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="projectdata.order_requiring">
              </el-input></td>
          </tr>
          <tr>
            <td colspan="8">项目运营流程（下单流程、收货流程、对账流程、付款流程等）<el-input type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="projectdata.operate_process">
              </el-input>
            </td>
          </tr>
          <tr>
            <td colspan="8">其他备注： <el-input type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="projectdata.other_remarks">
              </el-input></td>
          </tr>
          <tr>
            <td colspan="8"
                class="pl bg"
                align="center"
                style="font-weight: bold;">历次项目拜访纪要</td>
          </tr>
          <tr>
            <td align="center"
                class="bg">拜访时间</td>
            <td colspan="7"
                class="pl"
                style="padding-left: 15px;">拜访内容及纪要</td>
          </tr>
          <tr v-for="(item,index) in projectdata.visit"
              :key="index">
            <td align="center">
              <el-date-picker type="date"
                              placeholder="选择日期"
                              v-model="item.visit_time"
                              class="input-with-selects "
                              value-format="yyyy-MM-dd"></el-date-picker>
            </td>
            <td colspan="6"
                class="pl"><el-input v-model="item.visit_content"
                        placeholder="请输入内容"></el-input></td>
            <td align="center"
                w> <el-link :underline="false"
                       @click.native="changedel(index)"><i class="el-icon-close"
                   style="font-size:14px ;"></i></el-link></td>
          </tr>
        </table>
      </el-form>
      <div style="padding: 10px 0;"><el-link type="primary"
                 @click="addproduct">添加</el-link></div>
      <div style="text-align: center; margin-top: 20px;">
        <el-button type="primary"
                   @click="importFile(type)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addProjectManagement, updateProjectManagement, searchProjectManagement, editProjectManagement, delProjectManagement } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'ProjectmanagementIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogVisibles: false,
      currentPage: 1,
      total: 0,
      perpage: 29,
      pagesize: 29,
      orderlist: [],
      business: [],
      myHeaders: '',
      title: '',
      type: '',
      form: {
        project_name: null,
        name: null
      },
      formRules: {
        abbreviation: [
          { required: true, message: '请输入客户简称', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入客户全称', trigger: 'blur' }
        ],
        project_name: [
          { required: true, message: '请输入工程项目名称', trigger: 'blur' }
        ]
      },
      isshow: false,
      projectdata: {
        abbreviation: null,
        name: null,
        owner_name: null,
        project_name: null,
        construction_unit: null,
        construction_permit: null,
        construction_scale: null,
        project_adress: null,
        construction_period: null,
        contract_number: null,
        brand_range: null,
        sell_credit_quota: null,
        steel_quantity: null,
        settlement_period: null,
        price_terms: null,
        preferential: null,
        pound_difference: null,
        inspection: null,
        other_abnormal: null,
        invoice_requirement: null,
        goods_signee: null,
        signature: null,
        double_signature: null,
        receipt_signature: null,
        statement_signature: null,
        other_follow_clause: null,
        project_contacts: null,
        project_leader: null,
        receiving_recipient: null,
        purchase_recipient: null,
        reconciliation_recipient: null,
        troupe: null,
        address_contact: null,
        order_requiring: null,
        operate_process: null,
        other_remarks: null,
        visit: [{
          id: null,
          visit_time: null,
          visit_content: null
        }, {
          id: null,
          visit_time: null,
          visit_content: null
        }, {
          id: null,
          visit_time: null,
          visit_content: null
        }]
      },
      delvisit: []
    }
  },
  created () {
    this.loadnav()
    this.loadtoken()
    this.search()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'projectmanagement') {
        this.topname = '客商管理'
        this.navnames = '项目管理'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    importorder () {
      this.dialogVisibles = true
      this.type = 1
      this.title = '添加项目'
      this.projectdata = {
        abbreviation: null,
        name: null,
        owner_name: null,
        project_name: null,
        construction_unit: null,
        construction_permit: null,
        construction_scale: null,
        project_adress: null,
        construction_period: null,
        contract_number: null,
        brand_range: null,
        sell_credit_quota: null,
        steel_quantity: null,
        settlement_period: null,
        price_terms: null,
        preferential: null,
        pound_difference: null,
        inspection: null,
        other_abnormal: null,
        invoice_requirement: null,
        goods_signee: null,
        signature: null,
        double_signature: null,
        receipt_signature: null,
        statement_signature: null,
        other_follow_clause: null,
        project_contacts: null,
        project_leader: null,
        receiving_recipient: null,
        purchase_recipient: null,
        reconciliation_recipient: null,
        troupe: null,
        address_contact: null,
        order_requiring: null,
        operate_process: null,
        other_remarks: null,
        visit: [{
          id: null,
          visit_time: null,
          visit_content: null
        }, {
          id: null,
          visit_time: null,
          visit_content: null
        }, {
          id: null,
          visit_time: null,
          visit_content: null
        }]
      }
    },
    addproduct () {
      this.projectdata.visit = this.projectdata.visit.concat({
        id: null,
        visit_time: null,
        visit_content: null
      })
    },
    importFile () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['salesman-form'].validate(valid => {
        if (!valid) {
          return false
        }
        if (this.type === 1) {
          this.addsalesman()
        } else {
          this.updatesalesman()
        }
      })
    },
    addsalesman () {
      addProjectManagement({ data: this.projectdata }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.search()
          this.projectdata = {
            abbreviation: null,
            name: null,
            owner_name: null,
            project_name: null,
            construction_unit: null,
            construction_permit: null,
            construction_scale: null,
            project_adress: null,
            construction_period: null,
            contract_number: null,
            brand_range: null,
            sell_credit_quota: null,
            steel_quantity: null,
            settlement_period: null,
            price_terms: null,
            preferential: null,
            pound_difference: null,
            inspection: null,
            other_abnormal: null,
            invoice_requirement: null,
            goods_signee: null,
            signature: null,
            double_signature: null,
            receipt_signature: null,
            statement_signature: null,
            other_follow_clause: null,
            project_contacts: null,
            project_leader: null,
            receiving_recipient: null,
            purchase_recipient: null,
            reconciliation_recipient: null,
            troupe: null,
            address_contact: null,
            order_requiring: null,
            operate_process: null,
            other_remarks: null,
            visit: [{
              id: null,
              visit_time: null,
              visit_content: null
            }, {
              id: null,
              visit_time: null,
              visit_content: null
            }, {
              id: null,
              visit_time: null,
              visit_content: null
            }]
          }
          this.dialogVisibles = false
          this.updateLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    updatesalesman () {
      updateProjectManagement({ data: this.projectdata, delvisit: this.delvisit }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.search()
          this.dialogVisibles = false
          this.updateLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleEdit (id) {
      editProjectManagement({ id: id }).then(res => {
        this.dialogVisibles = true
        this.type = 2
        this.title = '修改项目'
        this.projectdata = res.data.data
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (id) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delProjectManagement({ id: id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.search()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    search () {
      this.updateLoading = true
      this.loading = true
      searchProjectManagement({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      searchProjectManagement({ data: this.form, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      searchProjectManagement({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    changedel (val) {
      this.delvisit.push(this.projectdata.visit[val])
      this.projectdata.visit.splice(val, 1)
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 0px;
}
/deep/.el-form-item__content {
  line-height: 35px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  width: 70px;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 0px 0;
}
/deep/.el-table th {
  padding: 0px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
table,
td {
  border: 1px solid #999;
  border-collapse: collapse;
}
table td {
  height: 35px;
  line-height: 35px;
}
table th {
  height: 35px;
  line-height: 35px;
}
.pl {
  padding-left: 0px;
}
.bg {
  background: #f4f4f4;
}

/deep/.table .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 0px;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 35px;
  line-height: 35px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/.table .el-textarea__inner {
  border: 1px solid #fff;
}
/deep/.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 138px;
}
</style>
