<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <el-form :model="form"
                 ref="customer-form">
          <el-form-item label="公司"
                        class="el-form-items">
            <el-select v-model="form.CompanyName"
                       filterable
                       clearable
                       placeholder="请选择公司"
                       class="input-with-select"
                       style="width: 150px;">
              <el-option v-for="(items,index) in CompanyNamelist"
                         :key="index"
                         :label="items.name"
                         :value="items.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户"
                        class="el-form-items">
            <el-select v-model="form.CustomerShortName"
                       filterable
                       clearable
                       placeholder="请选择客户"
                       class="input-with-select"
                       style="width: 150px;">
              <el-option v-for="(item,index) in customerenumlist"
                         :key="index"
                         :label="item.sn"
                         :value="item.sn">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工程"
                        class="el-form-items">
            <el-select v-model="form.ProjectName"
                       filterable
                       clearable
                       placeholder="请选择工程"
                       class="input-with-select"
                       style="width: 150px;">
              <el-option v-for="(items,index) in projectlist"
                         :key="index"
                         :label="items.name"
                         :value="items.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商"
                        class="el-form-items">
            <el-select v-model="form.SupplierName"
                       filterable
                       clearable
                       placeholder="请选择供应商"
                       class="input-with-select"
                       style="width: 150px;">
              <el-option v-for="(items,index) in supplierdata"
                         :key="index"
                         :label="items.sn"
                         :value="items.sn">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品名"
                        class="el-form-items">
            <el-select v-model="form.ProductName"
                       filterable
                       clearable
                       placeholder="请选择"
                       class="input-with-select"
                       style="width: 100px;">
              <el-option v-for="(items,index) in ProductNamelist"
                         :key="index"
                         :label="items.cate_spec"
                         :value="items.cate_spec">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="规格"
                        class="el-form-items">
            <el-select v-model="form.SpecificationName"
                       filterable
                       clearable
                       placeholder="请选择"
                       class="input-with-select"
                       style="width: 100px;">
              <el-option v-for="(items,index) in SpecificationNamelist"
                         :key="index"
                         :label="items.size"
                         :value="items.size">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期"
                        class="el-form-items">
            <el-date-picker v-model="form.sales_date1"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;">
            </el-date-picker>
            <el-date-picker v-model="form.sales_date2"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;margin-left:5px">
            </el-date-picker>
          </el-form-item>
          <div style="float: left;margin-top:7px">
            <el-button icon="el-icon-search"
                       type="primary"
                       size="mini"
                       :loading="updateLoading"
                       @click="search"
                       style="padding: 6px 10px;"></el-button>
          </div>
          <div style="float:left;margin-top:7px; margin-left: 10px;">
            <el-button type="success"
                       icon="iconfont icon-jia"
                       size="mini"
                       style="margin: 0 10px 10px 0;padding: 6px 12px;"
                       @click="statisticalprice"> 统计调价</el-button>
          </div>
          <div style="float:right;margin-top:7px">
            <el-button type="primary"
                       icon="iconfont icon-jia"
                       size="mini"
                       style="margin: 0 10px 10px 0;padding: 6px 12px;"
                       @click="importorder"> 导入</el-button>
          </div>
        </el-form>
      </el-row>
      <el-row>
        <el-form :model="productselectdata"
                 ref="addorder-form"
                 :show-message="false">
          <el-table :data="productselectdata.orderlist"
                    ref="multipleTable"
                    style="width: 100%"
                    row-key="id"
                    v-loading="loading"
                    border
                    lazy
                    :highlight-current-row="true"
                    height="calc(100vh - 210px)"
                    :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                    id="table"
                    @cell-click="tabClick"
                    show-summary
                    :summary-method="getSummaries"
                    class="table">
            <el-table-column label="序号"
                             type="index"
                             align="center"
                             width="50">
            </el-table-column>
            <el-table-column prop="SellBillNumber"
                             label="销售单号"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="BilledDate"
                             label="开单日期"
                             align="center"
                             width="90">
            </el-table-column>
            <el-table-column prop="CompanyName"
                             label="公司"
                             align="center"
                             width="210"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="CustomerShortName"
                             label="客户"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="ProjectName"
                             label="工程"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="OriginName"
                             label="产地"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="ProductName"
                             label="品名"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SpecificationName"
                             label="规格"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="MaterialName"
                             label="材质"
                             align="center"
                             width="90"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="Quantity"
                             label="开单数量"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="Price"
                             label="开单单价"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="AuditQuantity"
                             label="审单数量"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="AuditPrice"
                             label="审单单价"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="sales_changes"
                             label="销售变化"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <div align="center"
                     style="height: 25px;">
                  <span v-if="scope.row.id === tabClickIndex && scope.row.AuditPrice!==null && tabClickLabel === '销售变化'">
                    <el-form-item>
                      <el-input v-model="productselectdata.orderlist[scope.$index].sales_changes"
                                @change="changesales(scope.$index)"
                                ref="input">
                      </el-input>
                    </el-form-item>
                  </span>
                  <span v-else>{{productselectdata.orderlist[scope.$index].sales_changes}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="TotalAmount"
                             label="应收总额"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="WarehouseName"
                             label="仓库"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="SupplierName"
                             label="供应商"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="TransferedQuantity"
                             label="出库/采购数量"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="CostPrice"
                             label="采购单价"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="PurchaseAuditPrice"
                             label="采购审单单价"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="purchase_changes"
                             label="采购变化"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <div align="center"
                     style="height: 25px;">
                  <span v-if="scope.row.id === tabClickIndex && scope.row.PurchaseAuditPrice!==null && tabClickLabel === '采购变化'">
                    <el-form-item>
                      <el-input v-model="productselectdata.orderlist[scope.$index].purchase_changes"
                                @change="changepurchase(scope.$index)"
                                ref="input">
                      </el-input>
                    </el-form-item>
                  </span>
                  <span v-else>{{productselectdata.orderlist[scope.$index].purchase_changes}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="PutOutExpensePurchase"
                             label="应付采购费用"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="PutOutTotalAmount"
                             label="应付总额"
                             align="center"
                             width="120"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="TeamName"
                             label="业务组"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <div align="center">
                  <span v-if="scope.row.id === tabClickIndex && tabClickLabel === '业务组'">
                    <el-select v-model="productselectdata.orderlist[scope.$index].TeamName"
                               filterable
                               clearable
                               placeholder="选择"
                               class="el-selects"
                               ref="selectFiles"
                               @change="changeteam(scope.$index)">
                      <el-option v-for="(item,index) in teamlist"
                                 :key="index"
                                 :label="item.name"
                                 :value="item.name">
                      </el-option>
                    </el-select>
                  </span>
                  <span v-else>{{productselectdata.orderlist[scope.$index].TeamName}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="SellerName"
                             label="业务员"
                             align="center"
                             width="80"
                             :show-overflow-tooltip="true">
            </el-table-column>
          </el-table>
        </el-form>
        <div style="margin-top:10px;"
             v-show="total!=0">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[30, 50, 100,200]"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-row>
    </el-card>
    <el-dialog title="导入"
               :visible.sync="dialogVisibles"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formorder"
               ref="form">
        <el-form-item label="开单日期">
          <el-date-picker v-model="formorder.sales_date1"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 140px;float: left;">
          </el-date-picker>
          <el-date-picker v-model="formorder.sales_date2"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 140px;float: left;margin-left:5px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="采销明细"
                      prop="business_license"
                      style="margin-top: 20px;">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="businessRemove"
                     :on-success="filesuccess"
                     :limit="1"
                     accept=".xlsx"
                     :file-list="business"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="importFile">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="统计调价"
               :visible.sync="dialogstatisticalprice"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formstatisticalprice"
               ref="formstatisticalprice">
        <el-form-item label="类型">
          <el-select v-model="formstatisticalprice.type"
                     filterable
                     clearable
                     placeholder="请选择类型"
                     class="input-with-select"
                     style="width: 320px;"
                     @change="pricetype">
            <el-option v-for="(items,index) in typepricelist"
                       :key="index"
                       :label="items.name"
                       :value="items.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户"
                      v-if="isshow===false">
          <el-select v-model="formstatisticalprice.CustomerShortName"
                     filterable
                     clearable
                     placeholder="请选择客户"
                     class="input-with-select"
                     style="width: 320px;">
            <el-option v-for="(item,index) in customerenumlist"
                       :key="index"
                       :label="item.sn"
                       :value="item.sn">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额"
                      v-if="isshow===false">
          <el-input v-model="formstatisticalprice.sales_changes"
                    placeholder="请输入内容"
                    style="width: 320px;"
                    class="input-with-select"></el-input>
        </el-form-item>
        <el-form-item label="供应商"
                      v-if="isshow">
          <el-select v-model="formstatisticalprice.SupplierName"
                     filterable
                     clearable
                     placeholder="请选择供应商"
                     class="input-with-select"
                     style="width: 320px;">
            <el-option v-for="(items,index) in supplierdata"
                       :key="index"
                       :label="items.sn"
                       :value="items.sn">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额"
                      v-if="isshow">
          <el-input v-model="formstatisticalprice.purchase_changes"
                    placeholder="请输入内容"
                    style="width: 250px;"
                    class="input-with-select"></el-input>
        </el-form-item>
        <el-form-item label="开单日期">
          <el-date-picker v-model="formstatisticalprice.sales_date1"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 140px;float: left;">
          </el-date-picker>
          <el-date-picker v-model="formstatisticalprice.sales_date2"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 140px;float: left;margin-left:5px">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="updatestatisticalprice">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ProjectEnumList, CustomerEnumList, importPurchaseSellData, searchDataManagement, getSteelSizeList, updateDataManagement, updateStatisticalPrice } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'DatamanagementIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogVisibles: false,
      dialogstatisticalprice: false,
      currentPage: 1,
      total: 0,
      perpage: 30,
      pagesize: 30,
      productselectdata: {
        orderlist: []
      },
      business: [],
      myHeaders: '',
      form: {
        CustomerShortName: null,
        ProjectName: null,
        CompanyName: null,
        SupplierName: null,
        OriginName: null,
        ProductName: null,
        SpecificationName: null,
        sales_date1: null,
        sales_date2: null
      },
      formorder: {
        sales_date1: null,
        sales_date2: null,
        ProjectName: null,
        url: null
      },
      formstatisticalprice: {
        type: '客户',
        sales_changes: null,
        purchase_changes: null,
        CustomerShortName: null,
        SupplierName: null,
        sales_date1: null,
        sales_date2: null
      },
      QuantityAll: null,
      AuditQuantityAll: null,
      TotalAmountAll: null,
      TransferedQuantityAll: null,
      PutOutTotalAmountAll: null,
      PutOutExpensePurchaseAll: null,
      projectlist: [],
      customerenumlist: [],
      ProductNamelist: [],
      SpecificationNamelist: [],
      supplierdata: [],
      CompanyNamelist: [
        {
          id: 1,
          name: '广东泊明智钢供应链服务有限公司'
        },
        {
          id: 2,
          name: '聚钢资源管理服务（广州）有限公司'
        },
        {
          id: 3,
          name: '汇钢资源管理服务（广州）有限公司'
        },
        {
          id: 4,
          name: '广州蔚蓝新材料科技有限公司'
        }
      ],
      teamlist: [
        {
          id: 1,
          name: '大宗-现款'
        }, {
          id: 2,
          name: '大宗-出数'
        }, {
          id: 3,
          name: '大宗-账期'
        }, {
          id: 4,
          name: '工程-短账期'
        }, {
          id: 5,
          name: '工程-长账期'
        }, {
          id: 6,
          name: '商务-现款'
        }, {
          id: 7,
          name: '商务-出数'
        }, {
          id: 8,
          name: '商务-账期'
        }
      ],
      typepricelist: [
        { id: 1, name: '客户' },
        { id: 1, name: '供应商' }
      ],
      isshow: false,
      tabClickIndex: null, // 点击的单元格
      tabClickLabel: '' // 当前点击的列名
    }
  },
  created () {
    this.loadnav()
    this.loadtoken()
    this.loadgetdate()
    this.loadgetSteelSizeList()
    this.loadsupplierList()
    this.loadgetCustomerEnumList()
    this.loadgetProjectEnumList()
    this.search()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetdate () {
      this.salesdate = '2023-01-01'
      this.form.sales_date1 = '2023-01-01'
      this.form.sales_date2 = this.getDay(0)
      this.enddate = this.getDay(0)
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'datamanagement') {
        this.topname = '数据分析'
        this.navnames = '数据管理'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetCustomerEnumList () {
      CustomerEnumList().then(res => {
        if (res.data.status === 'success') {
          this.customerenumlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetProjectEnumList () {
      ProjectEnumList({ type: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.projectlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetSteelSizeList () {
      getSteelSizeList({ type: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.ProductNamelist = res.data.catespeclist
          this.SpecificationNamelist = res.data.sizelist
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadsupplierList () {
      getSteelSizeList({ type: 2 }).then(res => {
        if (res.data.status === 'success') {
          this.supplierdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    search () {
      this.updateLoading = true
      this.loading = true
      searchDataManagement({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.QuantityAll = res.data.QuantityAll
          this.AuditQuantityAll = res.data.AuditQuantityAll
          this.TotalAmountAll = res.data.TotalAmountAll
          this.TransferedQuantityAll = res.data.TransferedQuantityAll
          this.PutOutTotalAmountAll = res.data.PutOutTotalAmountAll
          this.PutOutExpensePurchaseAll = res.data.PutOutExpensePurchaseAll
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      searchDataManagement({ data: this.form, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.QuantityAll = res.data.QuantityAll
          this.AuditQuantityAll = res.data.AuditQuantityAll
          this.TotalAmountAll = res.data.TotalAmountAll
          this.TransferedQuantityAll = res.data.TransferedQuantityAll
          this.PutOutTotalAmountAll = res.data.PutOutTotalAmountAll
          this.PutOutExpensePurchaseAll = res.data.PutOutExpensePurchaseAll
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      searchDataManagement({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.QuantityAll = res.data.QuantityAll
          this.AuditQuantityAll = res.data.AuditQuantityAll
          this.TotalAmountAll = res.data.TotalAmountAll
          this.TransferedQuantityAll = res.data.TransferedQuantityAll
          this.PutOutTotalAmountAll = res.data.PutOutTotalAmountAll
          this.PutOutExpensePurchaseAll = res.data.PutOutExpensePurchaseAll
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    importorder () {
      this.dialogVisibles = true
    },
    importFile () {
      if (this.formorder.sales_date1 === null) {
        this.$message.warning('请选择开始日期')
        return false
      }
      if (this.formorder.sales_date2 === null) {
        this.$message.warning('请选择结束日期')
        return false
      }
      if (this.formorder.url === null) {
        this.$message.warning('请选上传导入文件')
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: '正在导入中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      importPurchaseSellData(this.formorder).then(res => {
        if (res.data.status === 'success') {
          this.formorder.url = null
          this.business = []
          loading.close()
          this.search()
          this.dialogVisibles = false
          this.$message({
            message: '导入成功',
            type: 'success'
          })
        } else {
          this.formorder.url = null
          this.business = []
          this.dialogVisibles = false
          loading.close()
          this.$message.error('导入失败')
        }
      }).catch(err => {
        loading.close()
        console.log('失获取数据败', err)
      })
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.formorder.url = res.data.url
        this.business = this.business.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessRemove (file) {
      this.business.some((item, i) => {
        if (item.url === file.url) {
          this.business.splice(i, 1)
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeUpload (file) {
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === 10) {
          sums[index] = this.QuantityAll
        } else if (index === 12) {
          sums[index] = this.AuditQuantityAll
        } else if (index === 15) {
          sums[index] = this.TotalAmountAll
        } else if (index === 18) {
          sums[index] = this.TransferedQuantityAll
        } else if (index === 22) {
          sums[index] = this.PutOutExpensePurchaseAll
        } else if (index === 23) {
          sums[index] = this.PutOutTotalAmountAll
        }
      })
      return sums
    },
    // 添加明细原因 row 当前行 column 当前列
    tabClick (row, column, cell, event) {
      switch (column.label) {
        case '销售变化':
          this.tabClickIndex = row.id
          this.tabClickLabel = column.label
          setTimeout(() => {
            this.$refs.input.focus()
          }, 5)
          break
        case '采购变化':
          this.tabClickIndex = row.id
          this.tabClickLabel = column.label
          setTimeout(() => {
            this.$refs.input.focus()
          }, 5)
          break
        case '业务组':
          this.tabClickIndex = row.id
          this.tabClickLabel = column.label
          setTimeout(() => {
            this.$refs.selectFiles.focus()
          }, 5)
          break
        default:
      }
    },
    changesales (val) {
      updateDataManagement({ type: 1, saleschanges: this.productselectdata.orderlist[val].sales_changes, id: this.productselectdata.orderlist[val].id, AuditQuantity: this.productselectdata.orderlist[val].AuditQuantity, AuditPrice: this.productselectdata.orderlist[val].AuditPrice, Price: this.productselectdata.orderlist[val].Price, Quantity: this.productselectdata.orderlist[val].Quantity, CostPrice: this.productselectdata.orderlist[val].CostPrice }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist[val].TotalAmount = res.data.TotalAmount
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    changepurchase (val) {
      updateDataManagement({ type: 2, purchasechanges: this.productselectdata.orderlist[val].purchase_changes, id: this.productselectdata.orderlist[val].id, TransferedQuantity: this.productselectdata.orderlist[val].TransferedQuantity, PurchaseAuditPrice: this.productselectdata.orderlist[val].PurchaseAuditPrice, Price: this.productselectdata.orderlist[val].Price, Quantity: this.productselectdata.orderlist[val].Quantity, CostPrice: this.productselectdata.orderlist[val].CostPrice }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist[val].PutOutTotalAmount = res.data.PutOutTotalAmount
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    changeteam (val) {
      updateDataManagement({ type: 3, TeamName: this.productselectdata.orderlist[val].TeamName, id: this.productselectdata.orderlist[val].id }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist[val].TeamName = res.data.TeamName
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    pricetype (val) {
      if (val === '客户') {
        this.isshow = false
      } else {
        this.isshow = true
      }
    },
    statisticalprice () {
      this.dialogstatisticalprice = true
    },
    updatestatisticalprice () {
      if (this.formstatisticalprice.type === '客户') {
        if (this.formstatisticalprice.CustomerShortName === null) {
          this.$message.error('请选择客户')
          return false
        }
        if (this.formstatisticalprice.sales_changes === null) {
          this.$message.error('请填写金额')
          return false
        }
        if (this.formstatisticalprice.sales_date1 === null) {
          this.$message.error('请选择开始日期')
          return false
        }
        if (this.formstatisticalprice.sales_date2 === null) {
          this.$message.error('请选择结束日期')
          return false
        }
      } else {
        if (this.formstatisticalprice.purchase_changes === null) {
          this.$message.error('请选择供应商')
          return false
        }
        if (this.formstatisticalprice.purchase_changes === null) {
          this.$message.error('请填写金额')
          return false
        }
        if (this.formstatisticalprice.sales_date1 === null) {
          this.$message.error('请选择开始日期')
          return false
        }
        if (this.formstatisticalprice.sales_date2 === null) {
          this.$message.error('请选择结束日期')
          return false
        }
      }
      updateStatisticalPrice({ data: this.formstatisticalprice }).then(res => {
        if (res.data.status === 'success') {
          this.dialogstatisticalprice = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.search()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay (day) {
      var today = new Date()
      var targetdaymilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targetdaymilliseconds)
      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth (month) {
      var m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  padding: 0 10px 0 0;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 0px 0;
}
/deep/.el-table th {
  padding: 0px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
/deep/.table .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 2px;
}
/deep/.table .el-form-item__content {
  line-height: 25px;
  position: relative;
  font-size: 14px;
}
/deep/.table .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 25px;
}
</style>
