<template>
  <div class="manage-container">
    <el-card class="filter-card"
             shadow="hover">
      <!-- 数据筛选表单 -->
      <el-form ref="voucher-form"
               :model="voucherform"
               label-width="80px">
        <el-form-item label="客户名称"
                      prop="nid"
                      class="form-items">
          <el-select v-model="voucherform.nid"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="searchnData">
            <el-option v-for="item in customerdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名"
                      prop="name"
                      class="form-items">
          <el-input v-model="voucherform.name"
                    @focus="onemptynid"
                    placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="业主单位"
                      prop="unit"
                      class="form-items">
          <el-input v-model="voucherform.unit"
                    @focus="onemptynid"
                    placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="承建商"
                      prop="contractor"
                      class="form-items">
          <el-input v-model="voucherform.contractor"
                    @focus="onemptynid"
                    placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="包工头"
                      prop="jobwork"
                      class="form-items">
          <el-input v-model="voucherform.jobwork"
                    @focus="onemptynid"
                    placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:-80px ;"
                      class="form-items">
          <el-button type="primary"
                     icon="el-icon-search"
                     @click="searchData"
                     :loading="updateLoading">搜索</el-button>
        </el-form-item>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>
    <el-card class="filter-card"
             shadow="hover">
      <div class="demo-block"
           v-for="(item,index) in projectdata"
           :key="index">
        <el-row>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">（{{index+1}}）项目名：</span>{{item.project_name}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">业主单位：</span>{{item.owner_unit}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content "><span class="grid-content-text">承建商：</span>{{item.contractor}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content "><span class="grid-content-text">包工头：</span>{{item.jobwork}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content "><span class="grid-content-text">收货人：</span>{{item.consignee}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">联系方式：</span>{{item.tel}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">计量方式：</span>{{item.metering_mode}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">收货要求：</span>{{item.demand}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">供货周期：</span>{{item.settlement}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">付款周期：</span>{{item.payment_cycle}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">合同量：</span>{{item.contract_number}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">建设工期：</span>{{item.construction_period}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">主供品牌：</span>{{item.brand}}</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">施工状态：</span><span class="red"
                    v-if="item.status=='1'">未完工</span> <span v-else>已完工</span></div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"><span class="grid-content-text">客户名称：</span>{{item.name}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="grid-content"><span class="grid-content-text">品牌范围：</span>{{item.using_brand}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="grid-content"><span class="grid-content-text">项目地址：</span>{{item.addr}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple"><span class="grid-content-text">备注：</span>{{item.rank}}</div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top:10px;"
           v-show="total!=0">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-sizes="[3,10,20, 30,,50, 100]"
                       :page-size="3"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getProjects, selectProjects, getCustomerList } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'ProjectsIndex',
  data () {
    return {
      updateLoading: false,
      voucherform: {
        nid: '',
        name: '',
        unit: '',
        contractor: '',
        jobwork: ''
      },
      customerdata: [],
      projectdata: [],
      issearch: '',
      currentPage: 1,
      total: 0,
      perpage: 2
    }
  },
  created () {
    this.loadnav()
    this.loadprojectList()
    this.loadcustomerList()
  },
  mounted () {
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'projects') {
        this.topname = '客商管理'
        this.navnames = '项目分析'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadprojectList () {
      getProjects({ page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.projectdata = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadcustomerList () {
      getCustomerList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.customerdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    onemptynid () {
      this.voucherform.nid = ''
    },
    searchData () {
      this.issearch = 1
      this.updateLoading = true
      selectProjects({ data: this.voucherform, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.projectdata = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.updateLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    searchnData () {
      this.voucherform.name = ''
      this.voucherform.unit = ''
      this.voucherform.contractor = ''
      this.voucherform.jobwork = ''
      this.issearch = 1
      this.updateLoading = true
      selectProjects({ data: this.voucherform, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.projectdata = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.updateLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      if (this.issearch === 1) {
        selectProjects({ data: this.voucherform, per: this.perpage }).then(res => {
          if (res.data.status === 'success') {
            this.projectdata = res.data.data
            this.currentPage = res.data.page
            this.total = res.data.total
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      } else {
        getProjects({ per: this.perpage }).then(res => {
          if (res.data.status === 'success') {
            this.currentPage = res.data.page
            this.total = res.data.total
            this.projectdata = res.data.data
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      }
    },
    handleCurrentChange (val) {
      if (this.issearch === 1) {
        selectProjects({ data: this.voucherform, page: val, per: this.perpage }).then(res => {
          if (res.data.status === 'success') {
            this.projectdata = res.data.data
            this.currentPage = res.data.page
            this.total = res.data.total
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      } else {
        getProjects({ page: val, per: this.perpage }).then(res => {
          if (res.data.status === 'success') {
            this.currentPage = res.data.page
            this.total = res.data.total
            this.projectdata = res.data.data
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
.form-items {
  float: left;
  margin-right: 5px;
}
.red {
  color: red;
  font-weight: 700;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
.grid-content {
  font-size: 14px;
  padding: 10px 0px;
}
.layui-elem-quote {
  padding: 3px;
  line-height: 10px;
  border-left: 3px solid #4596fc;
  border-radius: 0 2px 2px 0;
  font-weight: 700 !important;
}
/deep/.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0px 0;
}
/deep/.el-collapse-item__header {
  line-height: 48px;
  color: #303133;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.demo-block {
  border-bottom: 1px solid #ebebeb;
  transition: 0.2s;
}
.grid-content-text {
  font-weight: 600;
}
</style>
