<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <div style="padding: 50px;">

        <div class="infoBox mb30">
          <el-link type="primary"
                   :underline="false"
                   @click="ExamineList(0)">
            <div class="iconCrl mr15 one"
                 style="margin-top: 8px;"><i class="el-icon-s-order"
                 style="color: #fff;"></i></div>
            <div class="info">
              <div class="sp1"><span class="mr5">未处理</span>
              </div><span class="sp2">{{counts.weicount}}</span>
            </div>
          </el-link>
        </div>
        <div class="infoBox mb30">
          <el-link type="primary"
                   :underline="false"
                   @click="ExamineList(1)">
            <div class="iconCrl mr15 two"
                 style="margin-top: 8px;"><i class="el-icon-s-claim"
                 style="color: #fff;"></i></div>
            <div class="info">
              <div class="sp1"><span class="mr5">已处理</span>
              </div><span class="sp2">{{counts.yicount}}</span>
            </div>
          </el-link>
        </div>
      </div>
      <el-row style="margin-top: 30px;">
        <el-table :data="productselectdata.orderlist"
                  ref="multipleTable"
                  style="width: 100%"
                  row-key="id"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  height="calc(100vh - 280px)"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                  id="table"
                  class="table">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="50">
          </el-table-column>
          <el-table-column label="签章操作"
                           align="center"
                           width="100">
            <template slot-scope="scope">
              <div class="el-buttons"
                   style="text-align: center; margin-left: 30%;">
                <el-button size="mini"
                           :type="scope.row.Selltypeedit"
                           @click="handlepurchase(scope.row.SellBillNumber,scope.row.PurchaseBillNumber,scope.row.index,scope.row.type,scope.row.types)"
                           icon="el-icon-s-check"
                           :title="scope.row.SellExamineTitle"></el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status"
                           label="签章状态"
                           align="center"
                           :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.status===0">未签章</span>
              <span v-else-if="scope.row.status===1"
                    style="color:#67C23A;font-weight:500;">已签章</span>
            </template>
          </el-table-column>
          <el-table-column prop="number"
                           label="单号"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="type"
                           label="类型"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="types"
                           label="公司"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="applicant"
                           label="申请人"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="applicant_time"
                           label="申请时间"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;"
             v-show="total!=0">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[30, 50, 100,200]"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-row>
    </el-card>
    <el-dialog title="采购盖章"
               :visible.sync="dialogVisiblespurchase"
               width="70%"
               top="10px"
               append-to-body
               :close-on-click-modal="false">
      <div v-for="(items,indexs) in purchaselist"
           :key="indexs">
        <div style="width:100%;margin: 0 auto; background-color:white;"
             :id="items.pdfDompurchase">
          <div style="width:90%;margin: 0 auto;">
            <div id="deliverynote"
                 ref="deliverynote"
                 style="width:100%;height:100%;padding: 30px 0;">
              <div
                   style="overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 28px; font-style: normal; font-weight: bold; text-decoration: none; text-align: center; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; ">
                钢材采购确认表
              </div>
              <div style="position: relative;">
                <div
                     style="position: absolute; right: 0%; bottom: -12px; overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;">
                  No:{{items.purchasevo.PurchaseBillNumber}}</div>
              </div>
              <div style="padding-top: 15px;   clear: both;">
                <table align="center"
                       style="width:100%;height:100%;"
                       cellspacing="0"
                       cellpadding="0"
                       border="0"
                       class="xwyc-table">
                  <thead>
                    <tr>
                      <th align="center"
                          width="90">产地</th>
                      <th align="center"
                          width="90">品名</th>
                      <th align="center"
                          width="120">规格（mm）</th>
                      <th align="center"
                          width="90">材质</th>
                      <th align="center"
                          width="90">数量（吨）</th>
                      <th align="center"
                          width="120">含税单价</th>
                      <th align="center"
                          width="120">金额（元）</th>
                      <th align="center"
                          width="90">仓库</th>
                      <th align="center">提货信息/收货信息</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,index) in items.list"
                        :key="index">
                      <td align="center">{{item.OriginName}}</td>
                      <td align="center">{{item.ProductName}}</td>
                      <td align="center">{{item.SpecificationName}}</td>
                      <td align="center">{{item.MaterialName}}</td>
                      <td align="center">{{item.PurchaseQuantity}}</td>
                      <td align="center">{{item.PurchasePrice}}</td>
                      <td align="center">{{item.PurchaseAmount}}</td>
                      <td align="center">{{item.SourceWarehouse}} </td>
                      <td align="center">{{item.PurchaseBillItemMemo}}</td>
                    </tr>
                    <tr>
                      <td colspan="4"
                          align="center">总计</td>
                      <td align="center">{{items.purchasevo.purchasenumber}}</td>
                      <td align="center"></td>
                      <td align="center">{{items.purchasevo.PurchaseEntireAmout}}</td>
                      <td align="center"
                          colspan="2"></td>
                    </tr>
                    <tr>
                      <td colspan="12"
                          style="height: 100px;">
                        <div style="width: 54%;float: left; margin-bottom: 8px;  padding-left: 10px;"
                             class="print">买方：{{items.purchasevo.CompanyName}}
                        </div>
                        <div style="width: 44%;float: left;margin-bottom: 8px;"
                             class="print">卖方：{{items.purchasevo.SupplierName}}
                        </div>
                        <div style="width: 54%;float: left;margin-bottom: 8px;padding-left: 10px;"
                             class="print">经办人：{{items.purchasevo.CreateByName}}
                        </div>
                        <div style="width: 44%;float: left;margin-bottom: 8px;"
                             class="print">经办人：
                        </div>
                        <div style="width: 54%;float: left;margin-bottom: 8px;padding-left: 10px;"
                             class="print">传真：020-83887426
                        </div>
                        <div style="width: 44%;float: left;margin-bottom: 8px;"
                             class="print"> 传真：
                        </div>
                        <div style="width: 54%;float: left;margin-bottom: 8px;padding-left: 10px;"
                             class="print">日期：{{items.purchasevo.SellBilledDate}}
                        </div>
                        <div style="width: 44%;float: left;margin-bottom: 8px;"
                             class="print"> 日期：{{items.purchasevo.SellBilledDate}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="position: relative;">
                <div style="position: absolute; left: 9%; bottom: -20px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                     v-if="items.purchasevo.status===1 && items.purchasevo.img==='boming'">
                  <img src="~@/assets/image/boming.png"
                       width="100%">
                </div>
                <div style="position: absolute; left: 9%; bottom: -20px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                     v-if="items.purchasevo.status===1 && items.purchasevo.img==='huigang'">
                  <img src="~@/assets/image/huigang.png"
                       width="100%">
                </div>
                <div style="position: absolute; left: 9%; bottom: -20px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                     v-if="items.purchasevo.status===1 && items.purchasevo.img==='jugang'">
                  <img src="~@/assets/image/jugang.png"
                       width="100%">
                </div>
                <div style="position: absolute; left: 9%; bottom: -20px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                     v-if="items.purchasevo.status===1 && items.purchasevo.img==='weilan'">
                  <img src="~@/assets/image/weilan.png"
                       width="100%">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-row>
        <div style="margin-top:50px;text-align: center;">
          <el-button type="primary"
                     icon="el-icon-s-check"
                     size="mini"
                     style="margin: 0 10px 10px 0;padding: 6px 10px;"
                     @click="exportReportsale(indexs)">确定签章</el-button>
          <!--<el-button type="primary"
                     icon="el-icon-printer"
                     @click="onPrint">打印</el-button>-->
        </div>
      </el-row>
    </el-dialog>
    <el-dialog title="销售单盖章"
               :visible.sync="dialogVisiblessale"
               width="70%"
               top="10px"
               append-to-body
               :close-on-click-modal="false">
      <div style="width:100%;margin: 0 auto; background-color:white;"
           id="pdfDomsale">
        <div style="width:90%;margin: 0 auto;">
          <div style="width:100%;height:100%; padding-top: 30px; padding-bottom: 400px;">
            <div
                 style="overflow: visible; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 28px; font-style: normal; font-weight: bold; text-decoration: none; text-align: center; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; margin-bottom: 30px; ">
              钢材采购确认表
            </div>
            <div style="position: relative;">
              <div
                   style="position: absolute; left: 0%; bottom: -12px; overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 18px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;">
                我司现采购钢材一批，明细如下。请贵司协助办理相关提货或送货手续，谢谢!</div>
              <div
                   style="position: absolute; right: 0%; bottom: -12px; overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px;  font-size: 18px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;">
                编号: {{salevo.SellBillNumber}}</div>
            </div>
            <div style="padding-top: 15px;   clear: both;">
              <table align="center"
                     style="width:100%;height:100%;"
                     cellspacing="0"
                     cellpadding="0"
                     border="0"
                     class="xwyc-table">
                <thead>
                  <tr>
                    <th align="center"
                        width="90">产地</th>
                    <th align="center"
                        width="90">品名</th>
                    <th align="center"
                        width="120">规格（mm）</th>
                    <th align="center"
                        width="90">材质</th>
                    <th align="center"
                        width="90">数量（吨）</th>
                    <th align="center"
                        width="120">含税单价</th>
                    <th align="center"
                        width="120">金额（元）</th>
                    <th align="center"
                        width="90">仓库</th>
                    <th align="center">提货信息/收货信息</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in salelist"
                      :key="index">
                    <td align="center">{{item.OriginName}}</td>
                    <td align="center">{{item.ProductName}}</td>
                    <td align="center">{{item.SpecificationName}}</td>
                    <td align="center">{{item.MaterialName}}</td>
                    <td align="center">{{item.SellQuantity}}</td>
                    <td align="center">{{item.SellPrice}}</td>
                    <td align="center">{{item.SellAmount}}</td>
                    <td align="center">{{item.SourceWarehouse}} </td>
                    <td align="center">{{item.Memo}}</td>
                  </tr>
                  <tr>
                    <td colspan="4"
                        align="center">合计</td>
                    <td align="center">{{salevo.sellnumber}}</td>
                    <td align="center"></td>
                    <td align="center">{{salevo.SellEntireAmout}}</td>
                    <td align="center"
                        colspan="2"></td>
                  </tr>
                </tbody>
              </table>
              <div style="position: relative;">
                <div style="width: 100%;float: left;margin-top: 8px;"
                     class="print">
                  备注:
                </div>
                <div style="width: 100%;float: left; margin-bottom: 8px; "
                     class="print">
                  <dt>1、根据合同约定，运输费、装卸费均由需方承担。以上如是钢厂直送的，单价已包含直送运输费，但不包含装卸费(吊机费)。</dt>
                  <dt>2、结算、付款等按照合同约定执行。</dt>
                  <dt>3、车牌号、司机名、身份证信息为买方自提情况下买方指定提货人信息，仓库为买方自提时的提货地点。</dt>
                </div>

                <div style="width: 55%;float: left; margin-bottom: 8px; "
                     class="print">下单时间:{{salevo.SellBilledDate}}
                </div>
                <div style="width: 44%;float: left;margin-bottom: 8px;"
                     class="print">交货地点:
                </div>
                <div style="width: 100%;float: left; margin-bottom: 15px;"
                     class="print">
                  <dt>经各自核对后，买卖双方均确认以上内容无误。</dt>
                </div>
                <div style="width: 55%;float: left; margin-bottom: 8px; "
                     class="print">卖方单位（供货单位）:{{salevo.CompanyName}}
                </div>
                <div style="width: 44%;float: left;margin-bottom: 8px;"
                     class="print">买方单位（收货单位）：{{salevo.CustomerName}}
                </div>
                <div style="width: 55%;float: left;margin-bottom: 8px;"
                     class="print">经办人：{{salevo.SellerName}}
                </div>
                <div style="width: 44%;float: left;margin-bottom: 8px;"
                     class="print"> 经办人：
                </div>
                <div style="width: 55%;float: left; padding-bottom: 60px;"
                     class="print">日期：{{salevo.SellBilledDate}}</div>
                <div style="width: 44%;float: left; padding-bottom: 60px;"
                     class="print">日期：{{salevo.SellBilledDate}}</div>
              </div>
            </div>
            <div style="position: relative;">
              <div style="position: absolute; left: 18%; top: 150px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                   v-if="salevo.status===1 && salevo.img==='boming'">
                <img src="~@/assets/image/boming.png"
                     width="100%">
              </div>
              <div style="position: absolute; left: 18%; top: 150px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                   v-if="salevo.status===1 && salevo.img==='huigang'">
                <img src="~@/assets/image/huigang.png"
                     width="100%">
              </div>
              <div style="position: absolute; left: 18%; top: 150px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                   v-if="salevo.status===1 && salevo.img==='jugang'">
                <img src="~@/assets/image/jugang.png"
                     width="100%">
              </div>
              <div style="position: absolute;left: 18%; top: 150px; width: 160px;  overflow: visible; margin: 0px; padding: 0px; background-color: transparent; color: rgb(51, 51, 51); border-color: rgb(51, 51, 51); border-style: none; border-width: 1px; font-family: 宋体; font-size: 20px; font-style: normal; font-weight: 400; text-decoration: none; text-align: right; vertical-align: baseline; break-inside: auto; break-before: auto; break-after: auto; z-index: 11;"
                   v-if="salevo.status===1 && salevo.img==='weilan'">
                <img src="~@/assets/image/weilan.png"
                     width="100%">
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-row>
        <div style="margin-top:50px;text-align: center;">
          <el-button type="primary"
                     icon="el-icon-s-check"
                     size="mini"
                     style="margin: 0 10px 10px 0;padding: 6px 10px;"
                     @click="exportReportsale(indexs)">确定签章</el-button>
          <!--<el-button type="primary"
                     icon="el-icon-printer"
                     @click="onPrint">打印</el-button>-->
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { getExamineList, getPurchaseList, getSaleList, exportReportExamine, getPurchaseWeiLanList, getSaleWeiLanList } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'ExaminelistIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogVisiblespurchase: false,
      dialogVisiblessale: false,
      myHeaders: '',
      currentPage: 1,
      total: 0,
      perpage: 30,
      pagesize: 30,
      productselectdata: {
        orderlist: []
      },
      form: {
        number: null,
        ProjectName: null,
        CustomerName: null,
        SupplierName: null,
        ProductName: null,
        SpecificationName: null,
        sales_date1: null,
        sales_date2: null,
        status: 0
      },
      counts: {},
      purchaselist: [],
      purchasevo: {},
      salelist: [],
      salevo: {},
      indexs: null
    }
  },
  created () {
    this.loadnav()
    this.loadtoken()
    this.search()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetdate () {
      this.form.sales_date1 = this.getDay(-1)
      this.form.sales_date2 = this.getDay(0)
      this.formorder.sales_date1 = this.getDay(0)
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'examinelist') {
        this.topname = '销售管理'
        this.navnames = '签章列表'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    ExamineList (val) {
      this.form.status = val
      this.search()
    },
    search () {
      this.updateLoading = true
      this.loading = true
      getExamineList({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.counts = res.data.counts
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      getExamineList({ data: this.form, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.counts = res.data.counts
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      getExamineList({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.productselectdata.orderlist = res.data.data
          this.currentPage = res.data.page
          this.counts = res.data.counts
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handlepurchase (SellBillNumber, PurchaseBillNumber, index, type, types) {
      this.indexs = index
      if (types === '泊明') {
        if (type === '销售') {
          this.getSaleList(SellBillNumber, PurchaseBillNumber)
          this.dialogVisiblessale = true
        } else {
          this.getPurchaseList(SellBillNumber, PurchaseBillNumber)
          this.dialogVisiblespurchase = true
        }
      } else {
        if (type === '销售') {
          this.getSaleWeiLanList(SellBillNumber, PurchaseBillNumber)
          this.dialogVisiblessale = true
        } else {
          this.getPurchaseWeiLanList(SellBillNumber, PurchaseBillNumber)
          this.dialogVisiblespurchase = true
        }
      }
    },
    exportReportsale (index) {
      exportReportExamine({ SellBillNumber: this.productselectdata.orderlist[index].SellBillNumber, PurchaseBillNumber: this.productselectdata.orderlist[index].PurchaseBillNumber, type: this.productselectdata.orderlist[index].type, types: this.productselectdata.orderlist[index].types }).then(res => {
        if (res.data.status === 'success') {
          this.search()
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          if (this.productselectdata.orderlist[index].types === '泊明') {
            if (this.productselectdata.orderlist[index].type === '销售') {
              this.getSaleList(this.productselectdata.orderlist[index].SellBillNumber, this.productselectdata.orderlist[index].PurchaseBillNumber)
              this.dialogVisiblessale = true
            } else {
              this.getPurchaseList(this.productselectdata.orderlist[index].SellBillNumber, this.productselectdata.orderlist[index].PurchaseBillNumber)
              this.dialogVisiblespurchase = true
            }
          } else {
            if (this.productselectdata.orderlist[index].type === '销售') {
              this.getSaleWeiLanList(this.productselectdata.orderlist[index].SellBillNumber, this.productselectdata.orderlist[index].PurchaseBillNumber)
              this.dialogVisiblessale = true
            } else {
              this.getPurchaseWeiLanList(this.productselectdata.orderlist[index].SellBillNumber, this.productselectdata.orderlist[index].PurchaseBillNumber)
              this.dialogVisiblespurchase = true
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    getSaleList (val) {
      getSaleList({ SellBillNumber: val }).then(res => {
        if (res.data.status === 'success') {
          this.salelist = res.data.data
          this.salevo = res.data.vo
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    getPurchaseList (SellBillNumber, PurchaseBillNumber) {
      getPurchaseList({ SellBillNumber: SellBillNumber, PurchaseBillNumber: PurchaseBillNumber }).then(res => {
        if (res.data.status === 'success') {
          this.purchaselist = res.data.data
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    getSaleWeiLanList (val) {
      getSaleWeiLanList({ SellBillNumber: val }).then(res => {
        if (res.data.status === 'success') {
          this.salelist = res.data.data
          this.salevo = res.data.vo
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    getPurchaseWeiLanList (SellBillNumber, PurchaseBillNumber) {
      getPurchaseWeiLanList({ SellBillNumber: SellBillNumber, PurchaseBillNumber: PurchaseBillNumber }).then(res => {
        if (res.data.status === 'success') {
          this.purchaselist = res.data.data
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    }

  }
}
</script>

<style lang="less" scoped>
.infoBox {
  width: 50%;
  float: left;
}
.iconCrl {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  opacity: 0.7;
}
.one {
  background: #1890ff;
}
.two {
  background: #00c050;
}
.info {
  float: left;
}
.info .sp1 {
  color: #666;
  font-size: 14px;
  display: block;
}
.info .sp2 {
  font-weight: 400;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.85);
  display: block;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  padding: 0 10px 0 0;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 14px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 14px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  font-size: 16px;
  color: #000;
  line-height: 25px;
}
.offer {
  line-height: 25px;
  font-size: 16px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 5px 0;
}
/deep/.el-table th {
  padding: 5px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 16px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
/deep/.table .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 2px;
}
/deep/.xwyc-table .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 2px;
}
/deep/.table .el-form-item__content {
  line-height: 25px;
  position: relative;
  font-size: 14px;
}
/deep/.table .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 25px;
}
</style>
