<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <div style="float:right;margin-top:5px">
          <el-button type="primary"
                     icon="iconfont icon-jia"
                     size="mini"
                     style="margin: 0 10px 10px 0;padding: 6px 12px;"
                     @click="importorder"> 添加</el-button>
        </div>
      </el-row>
      <el-row>
        <el-table :data="offermanagelist"
                  style="width: 100%"
                  ref="multipleTable"
                  row-key="id"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  height="calc(100vh - 180px)"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="50">
          </el-table-column>
          <el-table-column prop="name"
                           label="报价项目名称"
                           align="center">
          </el-table-column>
          <el-table-column prop="addr"
                           label="报价项目地址"
                           align="center">
          </el-table-column>
          <el-table-column align="center"
                           label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="handleEdit(scope.row)"
                         icon="el-icon-edit">查看报价</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.row)"
                         icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <el-dialog :title="title"
               :visible.sync="dialogVisibles"
               append-to-body
               :close-on-click-modal="false"
               width="80%"
               top="10px">
      <el-form ref="form"
               :model="form">
        <el-form-item label="项目">
          <div class="el-inputnew">
            <el-select v-model="form.region"
                       placeholder="请选择项目"
                       @change="changeofferitem"
                       clearable
                       class="input-with-select">
              <el-option v-for="item in offeritemlist"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>
      <template>
        <el-table :data="sizeprice"
                  style="width: 100%"
                  ref="multipleTable"
                  row-key="id"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  height="calc(100vh - 245px)"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}">
          <el-table-column label=""
                           width="30">
            <template slot-scope="scope">
              <div align="center">
                <el-link :underline="false"
                         @click.native="changedel(scope.$index)"><i class="el-icon-close"
                     style="font-size:14px ;"></i></el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="50">
          </el-table-column>
          <el-table-column prop="type"
                           label="类型"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <el-select v-model="sizeprice[scope.$index].type"
                         placeholder="请选择">
                <el-option v-for="(item,index) in typelist"
                           :key="index"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="cate_spec"
                           label="品名"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <el-select v-model="sizeprice[scope.$index].cate_spec"
                         placeholder="请选择"
                         @change="changecatespec(scope.$index)">
                <el-option v-for="(item,index) in catespeclist"
                           :key="index"
                           :label="item.name"
                           :value="item.name">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="material"
                           label="规格"
                           align="center"
                           width="100">
          </el-table-column>
          <el-table-column prop="size"
                           label="型号"
                           align="center"
                           width="100">
            <template slot-scope="scope">
              <el-select v-model="sizeprice[scope.$index].size"
                         @change="changesize(scope.$index)"
                         placeholder="请选择">
                <el-option v-for="(item,index) in sizelist"
                           :key="index"
                           :label="item.name"
                           :value="item.name">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="number"
                           label="数量"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="sizeprice[scope.$index].number"
                        ref="input"></el-input>
            </template>
          </el-table-column>
          <el-table-column v-for="(item,index) in brandtitle"
                           :key="index"
                           :prop="item.brand"
                           :label="item.name"
                           :property="item.brand"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row[scope.column.property]"
                        ref="input"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div style="text-align: center; margin-top: 20px; float: left;">
        <el-button type="primary"
                   @click="addPrice"
                   :loading="updateLoading">增加内容</el-button>
      </div>
      <div style="text-align: center; margin-top: 20px;">
        <el-button type="primary"
                   @click="createPrice(type)"
                   :loading="updateLoading">生成报价</el-button>
      </div>
    </el-dialog>
    <el-dialog title="报价表"
               :visible.sync="dialogTableOfferManage"
               append-to-body
               :close-on-click-modal="false"
               width="80%"
               top="10px">

      <el-table :data=" offermanagedata"
                style="width: 100%"
                id="offermanageTables"
                v-loading="loading"
                border
                lazy
                :highlight-current-row="true"
                :header-cell-style="{background:'#f4f4f5',color:'#303133'}">
        <el-table-column label="序号"
                         type="index"
                         align="center"
                         width="50">
        </el-table-column>
        <el-table-column prop="cate_specs"
                         label="类别"
                         align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type===1">第一单</span>
            <span v-else-if="scope.row.type===2">第二单</span>
            <span v-else-if="scope.row.type===3">第三单</span>
            <span v-else-if="scope.row.type===4">第四单</span>
            <span v-else-if="scope.row.type===5">第五单</span>
            <span v-else-if="scope.row.type===6">第六单</span>
            <span v-else-if="scope.row.type===7">第七单</span>
            <span v-else-if="scope.row.type===8">第八单</span>
            <span v-else-if="scope.row.type===9">第九单</span>
            <span v-else-if="scope.row.type===10">第十单</span>
          </template>
        </el-table-column>
        <el-table-column prop="cate_spec"
                         label="品名"
                         align="center">
        </el-table-column>
        <el-table-column prop="materials"
                         label="规格型号"
                         align="center">
          <template slot-scope="scope">
            {{scope.row.material}}Φ{{scope.row.size}}
          </template>
        </el-table-column>
        <el-table-column prop="brand"
                         label="品牌"
                         align="center">
        </el-table-column>
        <el-table-column prop="unit_name"
                         label="单位"
                         align="center">
          <template>吨</template>
        </el-table-column>
        <el-table-column prop="number"
                         label="数量"
                         align="center"
                         width="180">
        </el-table-column>
        <el-table-column prop="price"
                         label="单价（元/吨）"
                         align="center"
                         width="180">
        </el-table-column>
        <el-table-column prop="total"
                         label="总价（元"
                         align="center"
                         width="180">
        </el-table-column>
      </el-table>
      <div style="text-align: center; margin-top: 20px;">
        <el-button type="primary"
                   @click="submitPrice"
                   :loading="updatePhotoLoading">确定报价</el-button>
      </div>
    </el-dialog>
    <el-dialog title="报价表"
               :visible.sync="dialogTableOfferManages"
               append-to-body
               :close-on-click-modal="false"
               width="80%"
               top="10px">
      <div>
        <el-table :data="offermanageitem"
                  style="width: 100%"
                  id="offermanageTable"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="50">
          </el-table-column>
          <el-table-column prop="cate_spec"
                           label="品名"
                           align="center">
          </el-table-column>
          <el-table-column prop="materials"
                           label="规格型号"
                           align="center">
          </el-table-column>
          <el-table-column prop="brand"
                           label="品牌"
                           align="center">
          </el-table-column>
          <el-table-column prop="unit_name"
                           label="单位"
                           align="center">
          </el-table-column>
          <el-table-column prop="number"
                           label="数量"
                           align="center"
                           width="180">
          </el-table-column>
          <el-table-column prop="price"
                           label="单价（元/吨）"
                           align="center"
                           width="180">
          </el-table-column>
          <el-table-column prop="total"
                           label="总价（元"
                           align="center"
                           width="180">
          </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 20px;">
          <el-button type="primary"
                     @click="exportExcel">下载报价</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import { getBrandsList, getOfferItem, getOfferItemPrice, saveOfficeItemPrice, addOfferManage, getOfferManage, editOfferManage, delOfferManage } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'offeritemIndex',
  data () {
    return {
      loading: true,
      brandloading: true,
      updateLoading: false,
      updatePhotoLoading: false,
      dialogVisibles: false,
      dialogTableOfferManage: false,
      dialogTableOfferManages: false,
      title: '',
      type: 1,
      brandsdata: [],
      brands: [],
      offeritemlist: [],
      form: {
        name: null,
        addr: null,
        brands: []
      },
      offeritemid: null,
      brandtitle: [],
      sizeprice: [],
      sizeprices: [],
      sizepricea: [],
      sizelists: [],
      offermanagedata: [],
      offermanagelist: [],
      offermanageitem: [],
      offermanagename: '',
      catespeclist: [
        { id: 1, name: '螺纹钢' },
        { id: 2, name: '盘螺' },
        { id: 3, name: '高线' }
      ],
      sizelist: [],
      typelist: [
        { id: 1, name: '第一单' },
        { id: 2, name: '第二单' },
        { id: 3, name: '第三单' },
        { id: 4, name: '第四单' },
        { id: 5, name: '第五单' },
        { id: 6, name: '第六单' },
        { id: 7, name: '第七单' },
        { id: 8, name: '第八单' },
        { id: 9, name: '第九单' },
        { id: 10, name: '第十单' }
      ]
    }
  },
  created () {
    this.loadnav()
    this.loadbrandsList()
    this.loadgetOfferItem()
    this.loadgetOfferManage()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'offermanage') {
        this.topname = '项目报价'
        this.navnames = '报价管理'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadbrandsList () {
      getBrandsList().then(res => {
        if (res.data.status === 'success') {
          this.brandsdata = res.data.data
          this.brandloading = false
        }
      }).catch(err => {
        this.brandloading = false
        console.log('保存失败', err)
      })
    },
    loadgetOfferManage () {
      getOfferManage().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.offermanagelist = res.data.data
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    loadgetOfferItem () {
      getOfferItem().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.offeritemlist = res.data.data
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    importorder () {
      this.form = {
        name: null,
        addr: null,
        brands: []
      }
      this.brandtitle = []
      this.sizeprice = []
      this.dialogVisibles = true
      this.title = '添加'
      this.type = 1
    },
    changeofferitem (val) {
      this.offeritemid = val
      getOfferItemPrice({ id: val }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.sizeprice = res.data.data.size_price
          this.sizepricea = res.data.data.size_price
          this.brandtitle = res.data.data.brand_title
          this.sizelists = res.data.data.sizelist
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    changedel (val) {
      this.sizeprice.splice(val, 1)
    },
    createPrice () {
      this.updateLoading = true
      const loading = this.$loading({
        lock: true,
        text: '正在生成报价...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      saveOfficeItemPrice({ id: this.offeritemid, size_price: this.sizeprice }).then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.length > 0) {
            this.dialogTableOfferManage = true
            this.offermanagedata = res.data.data
            console.log(this.offermanagedata)
          } else {
            this.$message.error(res.data.message[0])
          }
          this.updateLoading = false
          loading.close()
        } else {
          this.updateLoading = false
          this.$message.error(res.data.message[0])
          loading.close()
        }
      }).catch(err => {
        loading.close()
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    submitPrice () {
      this.updatePhotoLoading = true
      addOfferManage({ id: this.offeritemid, offermanagedata: this.offermanagedata }).then(res => {
        if (res.data.status === 'success') {
          this.loadgetOfferManage()
          this.offeritemid = null
          this.offermanagedata = []
          this.dialogTableOfferManage = false
          this.dialogVisibles = false
          this.updatePhotoLoading = false
        } else {
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        this.updatePhotoLoading = false
        console.log('保存失败', err)
      })
    },
    handleEdit (val) {
      editOfferManage({ id: val.id }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.dialogTableOfferManages = true
          this.offermanagename = res.data.name
          this.offermanageitem = res.data.data
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleDelete (val) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delOfferManage({ id: val.id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadgetOfferManage()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    changecatespec (val) {
      if (this.sizeprice[val].cate_spec === '螺纹钢' || this.sizeprice[val].cate_spec === '盘螺') {
        this.sizeprice[val].material = 'HRB400E'
      } else {
        this.sizeprice[val].material = 'HPB300'
      }
      this.sizelists.forEach(row => {
        if (this.sizeprice[val].cate_spec === row.cate_spec) {
          this.sizelist.push({
            id: row.id,
            name: row.size
          })
        }
      })
    },
    addPrice () {
      if (this.offeritemid === null) {
        this.$message.error('请选择项目')
        return false
      }
      const indexs = this.sizeprice.length
      const index = indexs - 1
      const addsizeprice = this.sizeprice[index]
      this.sizeprice.push({ addsizeprice })
    },
    changesize (val) {
      this.sizelist.forEach(row => {
        if (this.sizeprice[val].size === row.name) {
          this.sizeprice[val].sizeid = row.id
        }
      })
    },
    exportExcel () {
      var xlsxParam = { raw: true }
      // 从表生成工作簿对象
      var wb = XLSX.utils.table_to_book(
        document.querySelector('#offermanageTable'),
        xlsxParam
      )
      // 获取二进制字符串作为输出
      var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        FileSaver.saveAs(
          // Blob 对象表示一个不可变、原始数据的类文件对象。
          // Blob 表示的不一定是JavaScript原生格式的数据。
          // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
          // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
          new Blob([wbout], { type: 'application/octet-stream' }),
          // 设置导出文件名称
          this.offermanagename + '.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
      return wbout
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/.el-inputnew input {
  border: 1px solid #dcdfe6;
}
/deep/ .input-with-select .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  padding: 0 10px 0 0;
}
/deep/.el-table .el-table__cell {
  padding: 3px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
  line-height: 20px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 3px 0;
}
/deep/.el-table th {
  padding: 3px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
.dialog-footer {
  margin-top: 10px;
  margin-left: 70px;
}
/deep/ .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 20px;
}
/deep/ .tableSelectedRowBgColor td {
  background-color: rgb(253, 246, 236) !important;
}
/deep/ .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 0px;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
</style>
