import request from '@/utils/request'

// 用户登录
export const login = data => {
  return request({
    method: 'POST',
    url: '/api/login',
    // data 用来设置 POST 请求体
    data
  })
}
// 用户注册
export const register = data => {
  return request({
    method: 'POST',
    url: '/api/addNewUser',
    data
  })
}
// 获取用户信息
export const getUserProfile = data => {
  return request({
    method: 'POST',
    url: '/api/getUserProfile',
    data
  })
}
// 修改用户信息
export const senduserinfo = data => {
  return request({
    method: 'POST',
    url: '/api/sendUserInfo',
    data
  })
}
// 上传头像
export const updateUserPhoto = data => {
  return request({
    method: 'POST',
    url: '/api/updateUserPhoto',
    data
  })
}
// 修改密码
export const modifyPassword = data => {
  return request({
    method: 'POST',
    url: '/api/modifyPassword',
    data
  })
}
// 新增用户
export const addNewUser = data => {
  return request({
    method: 'POST',
    url: '/api/addNewUser',
    data
  })
}
// 获取所有用户
export const getUserList = data => {
  return request({
    method: 'POST',
    url: '/api/getUserList',
    data
  })
}
// 删除用户
export const getDellUser = data => {
  return request({
    method: 'POST',
    url: '/api/getDellUser',
    data
  })
}
// 修改用户
export const getEditUser = data => {
  return request({
    method: 'POST',
    url: '/api/getEditUser',
    data
  })
}
// 修改用户
export const upDataUser = data => {
  return request({
    method: 'POST',
    url: '/api/upDataUser',
    data
  })
}
