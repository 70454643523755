<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover">
      <div class="addbutton">
        <el-button type="primary"
                   size="small"
                   icon="el-icon-plus"
                   @click="dialogFormVisible = true">新增客户业务</el-button>
      </div>
      <el-table :data="businessdata.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 180px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading">
        <el-table-column label="序号"
                         type="index"
                         align="center"
                         width="50">
        </el-table-column>
        <el-table-column label="客户名称"
                         prop="name"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="类型"
                         prop="type"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="业务种类"
                         prop="btype"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="结算条款"
                         prop="settlement_clause"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="业务联系人"
                         prop="business_contact"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="联系方式"
                         prop="btel"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="开票联系人"
                         prop="billing_contact"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="联系方式"
                         prop="bctel"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="业务规模"
                         prop="business_scale"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="市场敏感度"
                         prop="market_sensitivity"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="主要供应商"
                         prop="main_supplier"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="主要车队"
                         prop="main_fleet"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="主要终端"
                         prop="main_terminal"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="备注"
                         prop="rank"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="right"
                         width="180">
          <template slot="header">
            <el-input v-model="search"
                      size="mini"
                      placeholder="输入关键字搜索" />
          </template>
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="新增客户业务"
                 :visible.sync="dialogFormVisible"
                 append-to-body>
        <el-form ref="businessinfo-form"
                 :model="businessinfo"
                 label-width="120px"
                 :rules="formRules">
          <el-form-item label="客户名称"
                        prop="nid">
            <el-select v-model="businessinfo.nid"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in customerdata"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务种类"
                        prop="btype">
            <el-select v-model="businessinfo.btype"
                       placeholder="请选择">
              <el-option v-for="item in btypeoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结算条款"
                        prop="settlement_clause">
            <el-input v-model="businessinfo.settlement_clause"></el-input>
          </el-form-item>
          <el-form-item label="业务联系人"
                        prop="business_contact"
                        class="el-form-items">
            <el-input v-model="businessinfo.business_contact"></el-input>
          </el-form-item>
          <el-form-item label="联系方式"
                        prop="btel"
                        class="el-form-items">
            <el-input v-model="businessinfo.btel"></el-input>
          </el-form-item>
          <el-form-item label="开票联系人"
                        prop="billing_contact"
                        class="el-form-items">
            <el-input v-model="businessinfo.billing_contact"></el-input>
          </el-form-item>
          <el-form-item label="联系方式"
                        prop="bctel"
                        class="el-form-items">
            <el-input v-model="businessinfo.bctel"></el-input>
          </el-form-item>
          <el-form-item label="业务规模"
                        prop="business_scale"
                        class="el-form-itemss">
            <el-input v-model="businessinfo.business_scale"></el-input>
          </el-form-item>
          <el-form-item label="市场敏感度"
                        prop="market_sensitivity">
            <el-input type="textarea"
                      v-model="businessinfo.market_sensitivity"></el-input>
          </el-form-item>
          <el-form-item label="主要供应商"
                        prop="main_supplier">
            <el-select v-model="businessinfo.main_supplier"
                       multiple
                       filterable
                       remote
                       placeholder="请输入主要供应商"
                       :loading="loading"
                       style="width:100%">
              <el-option v-for="item in customerdata"
                         :key="item.id"
                         :label="item.abbreviation"
                         :value="item.abbreviation">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主要车队"
                        prop="main_fleet">
            <el-input v-model="businessinfo.main_fleet"></el-input>
          </el-form-item>
          <el-form-item label="主要终端"
                        prop="main_terminal">
            <el-input v-model="businessinfo.main_terminal"></el-input>
          </el-form-item>
          <el-form-item label="备注"
                        prop="rank">
            <el-input v-model="businessinfo.rank"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateBusiness">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改客户业务"
                 :visible.sync="dialogFormVisibles"
                 append-to-body>
        <el-form ref="businessinfos-form"
                 :model="businessinfos"
                 label-width="120px"
                 :rules="formRuless">
          <el-form-item label="客户名称"
                        prop="nid">
            <el-select v-model="businessinfos.nid"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in customerdata"
                         :key="item.ide"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务种类"
                        prop="btype">
            <el-select v-model="businessinfos.btype"
                       placeholder="请选择">
              <el-option v-for="item in btypeoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结算条款"
                        prop="settlement_clause">
            <el-input v-model="businessinfos.settlement_clause"></el-input>
          </el-form-item>
          <el-form-item label="业务联系人"
                        prop="business_contact"
                        class="el-form-items">
            <el-input v-model="businessinfos.business_contact"></el-input>
          </el-form-item>
          <el-form-item label="联系方式"
                        prop="btel"
                        class="el-form-items">
            <el-input v-model="businessinfos.btel"></el-input>
          </el-form-item>
          <el-form-item label="开票联系人"
                        prop="billing_contact"
                        class="el-form-items">
            <el-input v-model="businessinfos.billing_contact"></el-input>
          </el-form-item>
          <el-form-item label="联系方式"
                        prop="bctel"
                        class="el-form-items">
            <el-input v-model="businessinfos.bctel"></el-input>
          </el-form-item>
          <el-form-item label="业务规模"
                        prop="business_scale"
                        class="el-form-itemss">
            <el-input v-model="businessinfos.business_scale"></el-input>
          </el-form-item>
          <el-form-item label="市场敏感度"
                        prop="market_sensitivity">
            <el-input type="textarea"
                      v-model="businessinfos.market_sensitivity"></el-input>
          </el-form-item>
          <el-form-item label="主要供应商"
                        prop="main_supplier">
            <el-select v-model="businessinfos.main_supplier"
                       multiple
                       filterable
                       remote
                       placeholder="请输入主要供应商"
                       :loading="loading"
                       style="width:100%">
              <el-option v-for="item in customerdata"
                         :key="item.id"
                         :label="item.abbreviation"
                         :value="item.abbreviation">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主要车队"
                        prop="main_fleet">
            <el-input v-model="businessinfos.main_fleet"></el-input>
          </el-form-item>
          <el-form-item label="主要终端"
                        prop="main_terminal">
            <el-input v-model="businessinfos.main_terminal"></el-input>
          </el-form-item>
          <el-form-item label="备注"
                        prop="rank">
            <el-input v-model="businessinfos.rank"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateBusinesss">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getBusinessList, addNewsBusiness, getEditBusiness, upDataBusiness, getDelBusiness, getCustomerList } from '@/api/global'
import globalBus from '@/utils/global-bus'

export default {
  name: 'BusinessIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      businessinfo: {
        id: '',
        nid: '',
        type: '',
        btype: '',
        settlement_clause: '',
        business_contact: '',
        btel: '',
        billing_contact: '',
        bctel: '',
        business_scale: '',
        market_sensitivity: '',
        main_supplier: '',
        main_fleet: '',
        main_terminal: '',
        rank: ''
      }, // 用户资料
      updatePhotoLoading: false, // 更新用户头像 loading 状态
      updateProfileLoading: false, // 更新基本信息的 loading 状态
      formRules: {
        nid: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ]
      },
      businessdata: [],
      dialogFormVisible: false,
      dialogFormVisibles: false,
      businessinfos: {
        id: '',
        nid: '',
        type: '',
        btype: '',
        settlement_clause: '',
        business_contact: '',
        btel: '',
        billing_contact: '',
        bctel: '',
        business_scale: '',
        market_sensitivity: '',
        main_supplier: '',
        main_fleet: '',
        main_terminal: '',
        rank: ''
      },
      formRuless: {
        nid: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ]
      },
      search: '',
      customerdata: [],
      btypeoptions: [{
        value: '工程',
        label: '工程'
      }, {
        value: '账期',
        label: '账期'
      }, {
        value: '先货后款',
        label: '先货后款'
      }, {
        value: '款到发货',
        label: '款到发货'
      }]
    }
  },
  created () {
    this.loadnav()
    this.loadcustomerList()
    this.loadbusinessList()
  },
  mounted () {
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'business') {
        this.topname = '客商管理'
        this.navnames = '客户业务'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadbusinessList () {
      getBusinessList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.businessdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    loadcustomerList () {
      getCustomerList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.customerdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    onUpdateBusiness () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['businessinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addNewsBusiness(this.businessinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadbusinessList()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateBusinesss () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['businessinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataBusiness(this.businessinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisibles = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadbusinessList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    handleEdit (row) {
      getEditBusiness({ id: row.id }).then(res => {
        this.dialogFormVisibles = true
        this.businessinfos = res.data.data
        this.businessinfos.main_supplier = res.data.main_supplier
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？' + row.name, '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDelBusiness({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadbusinessList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    filterTag (value, row) {
      return row.deptname === value
    },
    filterTags (value, row) {
      return row.mername === value
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
.el-form-itemss {
  clear: both;
}
</style>
