<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover">
      <el-button type="primary"
                 icon="el-icon-plus"
                 size="small"
                 style="margin-bottom: 10px;"
                 @click="addjobwork">新增包工头</el-button>
      <el-table :data="jobworkdata.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 200px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading">
        <el-table-column label="序号"
                         type="index"
                         align="center"
                         :width="50">
        </el-table-column>
        <el-table-column label="姓名"
                         prop="name"
                         align="center"
                         :width="130"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="联系方式"
                         prop="contact"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="简介"
                         prop="introduction"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="center"
                         label="操作"
                         :width="200">
          <template slot="header">
            <el-input v-model="search"
                      size="mini"
                      placeholder="输入关键字搜索" />
          </template>
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;"
           v-show="total!=0">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-sizes="[19,30, 50, 100]"
                       :page-size="19"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
      <el-dialog :title="title"
                 :visible.sync="dialogFormJobwork"
                 append-to-body
                 :close-on-click-modal="false">
        <el-form ref="jobwork-form"
                 :model="jobworkform"
                 label-width="120px"
                 :rules="formRule">
          <el-form-item label="姓名"
                        prop="name">
            <el-input v-model="jobworkform.name"></el-input>
          </el-form-item>
          <el-form-item label="简介"
                        prop="introduction">
            <el-input v-model="jobworkform.introduction"
                      type="textarea"
                      :rows="2"></el-input>
          </el-form-item>
          <div v-for="(item, index) in contactvaluelist"
               :key="index">
            <el-form-item label="联系方式"
                          prop="contact">
              <input type="text"
                     style="width:250px;"
                     class="el-input__inner"
                     v-model="item.rulevalue" />
              <el-button type="danger"
                         icon="el-icon-delete"
                         circle
                         @click="deleteContact(item.index)"
                         size="mini"
                         style="padding:6px;margin-left: 10px;"></el-button>
              <el-button type="primary"
                         icon="el-icon-edit"
                         circle
                         v-if="contactvaluelist.length - 1 === index"
                         @click="addBillValue"
                         size="mini"
                         style="padding:6px;"></el-button>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateLoading"
                       @click="onSubmit(type)">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import { getJobwork, addJobwork, editJobwork, updataobwork, delJobwork } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'JobworkIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogFormJobwork: false,
      title: '',
      type: '',
      businessa: [],
      currentPage: 1,
      total: 0,
      perpage: 19,
      search: '',
      jobworkdata: [],
      jobworkform: {
        id: '',
        name: '',
        contact: [],
        introduction: ''
      },
      contactvaluelist: [{
        id: 1,
        rulevalue: ''
      }],
      formRule: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadnav()
    this.loadgetJobwork()
  },
  mounted () {
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'jobwork') {
        this.topname = '客商管理'
        this.navnames = '包工头'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetJobwork () {
      getJobwork({ page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.jobworkdata = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    addjobwork () {
      this.title = '新增包工头信息'
      this.type = 1
      this.dialogFormJobwork = true
      this.jobworkform = {}
      this.contactvaluelist = [{
        id: 1,
        rulevalue: ''
      }]
    },
    onSubmit (type) {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['jobwork-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.jobworkform.contact = []
        this.contactvaluelist.forEach((val, key, array) => {
          if (val.rulevalue !== '') {
            this.jobworkform.contact.push(val.rulevalue)
          }
        })
        if (type === 1) {
          this.addJobwork()
        } else {
          this.updataobwork()
        }
      })
    },
    addJobwork () {
      this.updateLoading = true
      addJobwork(this.jobworkform).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogFormJobwork = false
          this.updateLoading = false
          this.loadgetJobwork()
        } else {
          this.$message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    updataobwork () {
      updataobwork(this.jobworkform).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogFormJobwork = false
          this.updateLoading = false
          this.loadgetJobwork()
        } else {
          this.$message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    addBillValue () {
      this.contactvaluelist.push({
        index: this.contactvaluelist.length,
        rulevalue: ''
      })
    },
    deleteContact (index) {
      if (this.contactvaluelist.length - 1 < 1) {
        this.$message.error('至少输入1项')
      } else {
        this.contactvaluelist.splice(index, 1)
      }
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      getJobwork({ per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.currentPage = res.data.page
          this.total = res.data.total
          this.jobworkdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.loading = true
      getJobwork({ page: val, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.currentPage = res.data.page
          this.total = res.data.total
          this.jobworkdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？' + row.name, '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delJobwork({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadgetJobwork()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleEdit (row) {
      this.dialogFormJobwork = true
      this.title = '修改包工头信息'
      this.type = 2
      editJobwork({ id: row.id }).then(res => {
        this.jobworkform = res.data.data
        this.contactvaluelist = res.data.contactlist
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.aa {
  margin-bottom: 10px;
}
/deep/.el-card__body {
  padding: 10px 10px 10px 10px;
}
.el-form-items {
  margin-bottom: 5px;
}
/deep/ .el-form-items .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  border-top-color: rgb(220, 223, 230);
  border-right-color: rgb(220, 223, 230);
  border-bottom-color: rgb(220, 223, 230);
  border-left-color: rgb(220, 223, 230);
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 35px;
  line-height: 35px;
  outline: 0;
  padding: 0 15px;
  padding-right: 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .el-form-items .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.search {
  width: 100%;
  float: left;
  margin-bottom: 5px;
}
.clear {
  clear: both;
}
/deep/.el-table th {
  padding: 0px 0;
}
/deep/.el-table td {
  padding: 5px 0;
  font-size: 12px;
}
</style>
