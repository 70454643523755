<template>
  <div class="collection-container">
    <el-card class="filter-card"
             shadow="hover">
      <div class="search">
        <!-- 数据筛选表单 -->
        <el-form ref="form"
                 :model="collectionform"
                 label-width="80px">
          <el-form-item label="对方户名"
                        class="el-form-items">
            <el-select v-model="collectionform.oppAccountName"
                       filterable
                       clearable
                       placeholder="请选择">
              <el-option v-for="(item,index) in oppAccountNameData"
                         :key="index"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="本方户名"
                        class="el-form-items">
            <el-select v-model="collectionform.accountName"
                       filterable
                       clearable
                       placeholder="请选择">
              <el-option v-for="(item,index) in accountNameData"
                         :key="index"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="交易日期"
                        class="el-form-items">
            <el-date-picker v-model="rangeDate"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item style="margin-left:-60px ;"
                        class="el-form-items">
            <el-button type="primary"
                       icon="el-icon-search"
                       size="mini"
                       @click="searchData"
                       :loading="searchLoading">查询</el-button>
          </el-form-item>
        </el-form>
        <div class="right">
          <el-button type="warning"
                     icon="el-icon-plus"
                     size="mini"
                     @click="synchronization()"
                     :loading="updateLoading"
                     style="margin-top:7px ;">同步钢小二收款</el-button>
        </div>
        <div class="right">
          <el-button type="primary"
                     icon="el-icon-plus"
                     size="mini"
                     @click="dialogFormVisible = true"
                     style="margin-top:7px ;">添加</el-button>
        </div>
        <!-- /数据筛选表单 -->
      </div>
      <div class="clear"></div>
      <el-table v-loading="loading"
                :data="collectionData"
                border
                show-summary
                :summary-method="getSummaries"
                height="calc(100vh - 205px)"
                style="width: 100%;font-size: 14px"
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="38">
        </el-table-column>
        <el-table-column prop="tradeTime"
                         label="交易时间"
                         align="center"
                         width="140">
        </el-table-column>
        <el-table-column prop="amount"
                         label="金额"
                         align="right"
                         width="120">
        </el-table-column>
        <el-table-column prop="oppAccountName"
                         label="对方户名"
                         align="center"
                         width="130"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="oppAccountNo"
                         label="对方账号"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="oppOpenBankName"
                         label="对方开户行"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="accountName"
                         label="本方户名"
                         align="center"
                         width="130"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="bankName"
                         label="本方银行"
                         align="center"
                         width="150"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="payOrCollectionType"
                         label="类型"
                         align="center"
                         :show-overflow-tooltip="true"
                         :filters="[{ text: '销售收款', value: '销售收款' }, { text: '供应退款', value: '供应退款' }]"
                         :filter-method="filterTag"
                         width="80">
        </el-table-column>
        <el-table-column prop="projectname"
                         label="工程"
                         align="center"
                         width="120"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="purpose"
                         label="用途"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="digest"
                         label="附言"
                         align="center"
                         :show-overflow-tooltip="true"
                         width="80">
        </el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         align="center"
                         width="120"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="status"
                         label="同步状态"
                         align="center"
                         :filters="[{ text: '成功', value: '成功' }, { text: '失败', value: '失败' }, { text: '未同步', value: '未同步' }]"
                         :filter-method="filterTagstatus"
                         :show-overflow-tooltip="true"
                         width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.status==='成功'"><span class="active-column">{{scope.row.status}}</span> </div>
            <div v-else-if="scope.row.status==='失败'"><span class="active-column2">{{scope.row.status}}</span> </div>
            <div v-else><span class="active-column3">{{scope.row.status}}</span> </div>
          </template>
        </el-table-column>
        <el-table-column align="right"
                         label="操作"
                         width="180">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="新增收款"
               :visible.sync="dialogFormVisible"
               append-to-body>
      <el-form :model="form"
               ref="collectionlist-form"
               label-width="120px"
               :rules="formRules">
        <el-form-item label="交易时间"
                      prop="tradeTime">
          <el-date-picker v-model="form.tradeTime"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="金额"
                      prop="amount">
          <el-input v-model="form.amount"></el-input>
        </el-form-item>
        <el-form-item label="对方户名"
                      prop="oppAccountName">
          <el-select v-model="form.oppAccountName"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changeoppAccountName">
            <el-option v-for="(item,index) in oppAccountNameData"
                       :key="index"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="对方银行账号"
                      prop="oppAccountNo">
          <el-input v-model="form.oppAccountNo"></el-input>
        </el-form-item>
        <el-form-item label="对方银行"
                      prop="oppOpenBankName">
          <el-input v-model="form.oppOpenBankName"></el-input>
        </el-form-item>
        <el-form-item label="我方户名"
                      prop="accountName">
          <el-select v-model="form.accountName"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changeaccountName">
            <el-option v-for="(item,index) in accountNameDatas"
                       :key="index"
                       :label="item.account"
                       :value="item.account">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="我方银行账号"
                      prop="accountNo">
          <el-select v-model="form.accountNo"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changeaccountnum">
            <el-option v-for="item in account_nums"
                       :key="item.id"
                       :label="item.account_num"
                       :value="item.account_num">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="我方银行"
                      prop="bankName">
          <el-input v-model="form.bankName"></el-input>
        </el-form-item>
        <el-form-item label="收付款类型"
                      prop="payOrCollectionType">
          <el-select v-model="form.payOrCollectionType"
                     clearable
                     placeholder="请选择">
            <el-option v-for="(item,index) in payoptions"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工程"
                      prop="projectid">
          <el-select v-model="forms.projectid"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changeproject">
            <el-option v-for="item in projectlist"
                       :key="item.id"
                       :label="item.name"
                       :value="item.valueid">
            </el-option>
          </el-select>
          <el-button type="primary"
                     style=" margin-left:10px ;"
                     plain
                     size="mini"
                     @click="refreshdata"
                     :loading="updateLoadingrefreshdata">刷新数据</el-button>
        </el-form-item>
        <el-form-item label="附言"
                      prop="digest">
          <el-input v-model="form.digest"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="onUpdateCollectionlist"
                   :loading="updateLoading">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改收款"
               :visible.sync="dialogFormVisibles"
               append-to-body
               destroy-on-close>
      <el-form :model="forms"
               ref="collectionlist-forms"
               label-width="120px"
               :rules="formRuless">
        <el-form-item label="交易时间"
                      prop="tradeTime">
          <el-date-picker v-model="forms.tradeTime"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="选择日期时间"
                          :disabled="true">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="金额"
                      prop="amount">
          <el-input v-model="forms.amount"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="对方户名"
                      prop="oppAccountName">
          <el-input v-model="forms.oppAccountName"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="对方银行账号"
                      prop="oppAccountNo">
          <el-input v-model="forms.oppAccountNo"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="对方银行"
                      prop="oppOpenBankName">
          <el-input v-model="forms.oppOpenBankName"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="我方户名"
                      prop="accountName">
          <el-input v-model="forms.accountName"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="我方银行账号"
                      prop="accountNo">
          <el-input v-model="forms.accountNo"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="我方银行"
                      prop="bankName">
          <el-input v-model="forms.bankName"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="收款类型"
                      prop="payOrCollectionType">
          <el-select v-model="forms.payOrCollectionType"
                     clearable
                     placeholder="请选择">
            <el-option v-for="item in payoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工程"
                      prop="projectid">
          <el-select v-model="forms.projectid"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="changeprojects">
            <el-option v-for="item in projectlist"
                       :key="item.id"
                       :label="item.name"
                       :value="item.valueid">
            </el-option>
          </el-select>
          <el-button type="primary"
                     style=" margin-left:10px ;"
                     plain
                     size="mini"
                     @click="refreshdata"
                     :loading="updateLoadingrefreshdata">刷新数据</el-button>
        </el-form-item>
        <el-form-item label="附言"
                      prop="digest">
          <el-input v-model="forms.digest"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="forms.remark"
                    :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="onUpdateCollectionlists"
                   :loading="updateLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCollectionList, getAccountNameList, createdCapitaList, getCapitaList, deletedCapitaList, editPayOrCollectionTypet, getAccountListNotIN, PaymentInEdit, ProjectEnumList, getProjectEnumList } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'CollectionlistIndex',
  data () {
    return {
      dialogFormVisiblegdbmzg: false,
      dialogFormVisiblelogin: true,
      rangeDate: null,
      loading: true,
      searchLoading: false,
      updateLoadingrefreshdata: false,
      pickerOptions: {
        disabledDate (time) {
          const curDate = (new Date()).getTime()
          const three = 36 * 30 * 24 * 3600 * 1000
          const threeMonths = curDate - three
          return time.getTime() > Date.now() || time.getTime() < threeMonths
        },
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近半年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 360)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      collectionform: {
        accountName: '',
        oppAccountName: ''
      },
      timer: '',
      accountNameData: [],
      oppAccountNameData: [],
      accountNameDatas: [],
      account_nums: [],
      updateLoading: false,
      collectionData: [],
      collectionlistData: [],
      projectlist: [],
      navnames: '',
      topname: '',
      codeimg: '',
      validCodeToken: '',
      form: {
        tradeType: '03',
        tradeTime: '',
        bankName: '',
        accountNo: '',
        oppAccountNo: '',
        amount: '',
        oppOpenBankName: '',
        accountName: '',
        oppAccountName: '',
        digest: '',
        projectid: '',
        projectname: '',
        payOrCollectionType: '销售收款',
        remark: ''
      },
      forms: {
        id: '',
        tradeType: '03',
        tradeTime: '',
        bankName: '',
        accountNo: '',
        oppAccountNo: '',
        amount: '',
        oppOpenBankName: '',
        accountName: '',
        oppAccountName: '',
        digest: '',
        projectid: '',
        projectname: '',
        payOrCollectionType: '销售收款',
        remark: ''
      },
      dialogFormVisible: false,
      dialogFormVisibles: false,
      payoptions: [{
        value: '销售收款',
        label: '销售收款'
      }, {
        value: '供应退款',
        label: '供应退款'
      }],
      codelist: [],
      formRules: {
        tradeTime: [
          { required: true, message: '请选择交易时间', trigger: 'blur' }
        ],
        bankName: [
          { required: true, message: '请输入我方银行', trigger: 'blur' }
        ],
        accountNo: [
          { required: true, message: '请输入我方银行账号', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '银行账号只能为数字', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ],
        accountName: [
          { required: true, message: '请输入我方户名', trigger: 'blur' }
        ],
        oppAccountName: [
          { required: true, message: '请输入对方户名', trigger: 'blur' }
        ],
        payOrCollectionType: [
          { required: true, message: '请选择收款类型', trigger: 'blur' }
        ]
      },
      formRuless: {
        tradeTime: [
          { required: true, message: '请选择交易时间', trigger: 'blur' }
        ],
        bankName: [
          { required: true, message: '请输入我方银行', trigger: 'blur' }
        ],
        accountNo: [
          { required: true, message: '请输入我方银行账号', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '银行账号只能为数字', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ],
        accountName: [
          { required: true, message: '请输入我方户名', trigger: 'blur' }
        ],
        oppAccountName: [
          { required: true, message: '请输入对方户名', trigger: 'blur' }
        ],
        payOrCollectionType: [
          { required: true, message: '请选择收款类型', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadgetAccountNameList()
    this.loadgetCollectionList()
    this.loadgetAccountListNotIN()
    this.loadnav()
  },
  mounted () {
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.searchData()
      }, 0)
    }, 120000)
  },
  destroyed () {
    window.clearInterval(this.timer)
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'collectionlist') {
        this.topname = '货款管理'
        this.navnames = '收款'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetCollectionList () {
      getCollectionList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.rangeDate = res.data.date
          this.collectionData = res.data.data.map(val => {
            if (val.amount) {
              val.amount = this.formatMoney(val.amount)
            }
            return val
          })
        } else {
          this.loading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    loadgetAccountNameList () {
      getAccountNameList().then(res => {
        if (res.data.status === 'success') {
          this.accountNameData = res.data.data.accountName
          this.oppAccountNameData = res.data.data.oppAccountName
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    loadgetAccountListNotIN () {
      getAccountListNotIN().then(res => {
        if (res.data.status === 'success') {
          this.accountNameDatas = res.data.data
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    searchData () {
      this.searchLoading = true
      getCollectionList({ startDate: this.rangeDate ? this.rangeDate[0] : null, endDate: this.rangeDate ? this.rangeDate[1] : null, accountName: this.collectionform.accountName, oppAccountName: this.collectionform.oppAccountName }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.searchLoading = false
          this.collectionData = res.data.data.map(val => {
            if (val.amount) {
              val.amount = this.formatMoney(val.amount)
            }
            return val
          })
        } else {
          this.loading = false
          this.searchLoading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        this.searchLoading = false
        console.log('失获取数据败', err)
      })
    },
    onUpdateCollectionlist () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['collectionlist-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      createdCapitaList({ item: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.searchData()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    handleEdit (row) {
      this.dialogFormVisibles = true
      getCapitaList({ id: row.id }).then(res => {
        this.forms = res.data.data
        this.projectlist = res.data.projectlist
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletedCapitaList({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.searchData()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    onUpdateCollectionlists () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['collectionlist-forms'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        editPayOrCollectionTypet({ item: this.forms }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisibles = false
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.searchData()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    changeaccountName (res) {
      this.form.bankName = ''
      this.form.accountNo = ''
      const accountnum = this.accountNameDatas.filter(item => item.account === res)
      this.account_nums = accountnum[0].account_num
    },
    changeaccountnum (res) {
      const banks = this.account_nums.filter(item => item.account_num === res)
      this.form.bankName = banks[0].bank
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
      // 延时执行   要不然会一直刷新
      window.setTimeout(() => {
        if (this.theight === this.height) {
          this.theight -= 0.5
        } else {
          this.theight = this.height
        }
      }, 1000)
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计'
          return
        }
        if (column.property === 'amount') {
          const valuess = data.map(item => item[column.property])
          const values = []
          if (valuess.length > 0) {
            for (var i = 0; i < valuess.length; i++) {
              values.push(this.moneyToNumFiled(valuess[i]))
            }
          }
          if (!values.every(value => isNaN(value))) {
            const sumss = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            // 保留两位小数，末尾四舍五入
            const realVal = parseFloat(sumss).toFixed(2)
            // 金额显示形式
            sums[index] = this.formatMoney(realVal)
            // if (column.property === 'amount') {
            // sums[index] += ' 元'
            // }
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    formatMoney (value) {
      // 获取整数部分（这里是windy93的方法）
      var intPart = Number(value) - Number(value) % 1
      // 将整数部分逢三一断
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      var floatPart = '.00' // 预定义小数部分
      var value2Array = value.toString().split('.')
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        // 拿到小数部分
        floatPart = value2Array[1].toString()
        // 补0,实际上用不着
        if (floatPart.length === 1) {
          return intPartFormat + '.' + floatPart + '0'
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    },
    moneyToNumFiled (money) {
      var num = money.trim()
      var ss = num.toString()
      if (ss.length === 0) {
        return 0.00
      }
      num = Number(ss.replace(/,/g, ''))
      return num
    },
    filterTag (value, row) {
      return row.payOrCollectionType === value
    },
    filterTagstatus (value, row) {
      return row.status === value
    },
    handleSelectionChange (val) {
      this.collectionlistData = val
    },
    refresh () {
      this.loadcode()
    },
    getQueryString (url, name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = url.match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return ''
    },
    synchronization () {
      if (this.collectionlistData.length === 0) {
        this.$message.error('请选择内容')
        return false
      }
      this.updateLoading = true
      const loading = this.$loading({
        lock: true,
        text: '正在同步数据中，请不要刷新...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      PaymentInEdit(this.collectionlistData).then(res => {
        if (res.data.status === 'success') {
          loading.close()
          this.updateLoading = false
          this.searchData()
        }
      }).catch(err => {
        console.log('保存失败', err)
        this.updateLoading = false
      })
    },
    changeproject (val) {
      const _this = this
      this.projectlist.forEach(function (item, index) {
        if (item.valueid === val) {
          _this.form.projectname = item.name
        }
      })
    },
    changeprojects (val) {
      const _this = this
      this.projectlist.forEach(function (item, index) {
        if (item.valueid === val) {
          _this.forms.projectname = item.name
        }
      })
    },
    changeoppAccountName (val) {
      ProjectEnumList({ name: val, type: 2 }).then(res => {
        if (res.data.status === 'success') {
          this.projectlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    refreshdata () {
      this.updateLoadingrefreshdata = true
      getProjectEnumList().then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.changeoppAccountName()
          this.updateLoadingrefreshdata = false
        } else {
          this.$message.error(res.data.message)
          this.updateLoadingrefreshdata = false
        }
      }).catch(err => {
        this.updateLoadingrefreshdata = false
        console.log('保存失败', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.filter-card {
  margin-bottom: 10px;
}

.list-table {
  margin-bottom: 10px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-card__body {
  padding: 10px 10px 50px 10px;
}
.right {
  float: right;
  padding-left: 10px;
}
.active-column {
  color: #06b42c;
  font-weight: 600;
}
.active-column2 {
  color: #d30c0c;
  font-weight: 600;
}
.active-column3 {
  color: #e6a23c;
  font-weight: 600;
}
.el-form-items {
  margin-bottom: 5px;
}
/deep/ .el-form-items .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  border-top-color: rgb(220, 223, 230);
  border-right-color: rgb(220, 223, 230);
  border-bottom-color: rgb(220, 223, 230);
  border-left-color: rgb(220, 223, 230);
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 36px;
  line-height: 35px;
  outline: 0;
  padding: 0 15px;
  padding-right: 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .el-form-items .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.search {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.clear {
  clear: both;
}
/deep/.el-table th {
  padding: 0px 0;
}
/deep/.el-table td {
  padding: 5px 0;
  font-size: 12px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border-color: #e4e7ed;
  color: #333;
  cursor: not-allowed;
}
</style>
