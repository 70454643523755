<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <el-form :model="form"
                 ref="customer-form">
          <el-form-item label="业务类型"
                        class="el-form-items">
            <el-select v-model="form.type"
                       filterable
                       clearable
                       placeholder="请选择客户"
                       class="input-with-select"
                       style="width: 200px;"
                       @change="changetype">
              <el-option v-for="(item,index) in typelist"
                         :key="index"
                         :label="item.name"
                         :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="labels"
                        class="el-form-items">
            <el-select v-model="form.UserName"
                       filterable
                       clearable
                       :placeholder="placeholders"
                       class="input-with-select"
                       style="width: 200px;"
                       multiple>
              <el-option v-for="(items,index) in userlist"
                         :key="index"
                         :label="items.UserName"
                         :value="items.UserName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期"
                        class="el-form-items">
            <el-date-picker v-model="form.sales_date1"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;">
            </el-date-picker>
            <el-date-picker v-model="form.sales_date2"
                            value-format="yyyy-MM-dd"
                            type="date"
                            class="input-with-select"
                            style="width: 140px;float: left;margin-left:5px">
            </el-date-picker>
          </el-form-item>
          <div style="float: left;margin-top:5px">
            <el-button icon="el-icon-search"
                       type="primary"
                       size="mini"
                       :loading="updateLoading"
                       @click="search"
                       style="padding: 6px 10px;"></el-button>
          </div>
        </el-form>
      </el-row>
      <el-row>
        <el-tabs v-model="activeName"
                 type="card"
                 @tab-click="handleClick">
          <el-tab-pane label="分析图"
                       name="first">
            <el-row :gutter="10"
                    style="  margin-top: 30px;">
              <el-col :xs="24"
                      :sm="24"
                      :md="24"
                      :lg="24"
                      :xl="24">
                <h4>{{labels}}订单量趋势</h4>
                <div ref="echarts"
                     style="height: 300px;"></div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="数据表"
                       name="second">
            <el-table :data="listnew"
                      ref="multipleTable"
                      style="width: 100%"
                      row-key="id"
                      v-loading="loading"
                      border
                      lazy
                      :highlight-current-row="true"
                      height="calc(100vh - 250px)"
                      :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                      show-summary
                      :summary-method="getSummariesss">
              <el-table-column label="序号"
                               type="index"
                               align="center"
                               width="50">
              </el-table-column>
              <el-table-column prop="username"
                               :label="typename"
                               align="center"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="AuditQuantitytotal"
                               label="订单数量"
                               align="center">
              </el-table-column>
              <el-table-column prop="TotalAmounttotal"
                               label="订单金额"
                               align="center"
                               :show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="详细数据表"
                       name="three">
            <el-table :data="orderlist"
                      ref="multipleTable"
                      style="width: 100%"
                      row-key="id"
                      v-loading="loading"
                      border
                      lazy
                      :highlight-current-row="true"
                      height="calc(100vh - 250px)"
                      :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                      show-summary
                      :summary-method="getSummaries">
              <el-table-column label="序号"
                               type="index"
                               align="center"
                               width="50">
              </el-table-column>
              <el-table-column prop="SellBillNumber"
                               label="销售单号"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="BilledDate"
                               label="开单日期"
                               align="center"
                               width="90">
              </el-table-column>
              <el-table-column prop="CompanyName"
                               label="公司"
                               align="center"
                               width="210"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="CustomerShortName"
                               label="客户"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="ProjectName"
                               label="工程"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="OriginName"
                               label="产地"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="ProductName"
                               label="品名"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="SpecificationName"
                               label="规格"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="MaterialName"
                               label="材质"
                               align="center"
                               width="90"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="Quantity"
                               label="开单数量"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="Price"
                               label="开单单价"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="AuditQuantity"
                               label="审单数量"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="AuditPrice"
                               label="审单单价"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="TotalAmount"
                               label="应收总额"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="WarehouseName"
                               label="仓库"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="SupplierName"
                               label="供应商"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="TransferedQuantity"
                               label="出库/采购数量"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="CostPrice"
                               label="采购单价"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="PurchaseAuditPrice"
                               label="采购审单单价"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="PutOutExpensePurchase"
                               label="应付采购费用"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="PutOutTotalAmount"
                               label="应付总额"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="TeamName"
                               label="业务组"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="SellerName"
                               :label="typename"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
            <div style="margin-top:10px;"
                 v-show="total!=0">
              <el-pagination @size-change="handleSizeChange"
                             @current-change="handleCurrentChange"
                             :current-page="currentPage"
                             :page-sizes="[25, 50, 100,200]"
                             :page-size="pagesize"
                             layout="total, sizes, prev, pager, next, jumper"
                             :total="total">
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="无对应业务员数据"
                       name="four">
            <el-table :data="notuserlist"
                      ref="multipleTable"
                      style="width: 100%"
                      row-key="id"
                      v-loading="loading"
                      border
                      lazy
                      :highlight-current-row="true"
                      height="calc(100vh - 250px)"
                      :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                      show-summary
                      :summary-method="getSummariess">
              <el-table-column label="序号"
                               type="index"
                               align="center"
                               width="50">
              </el-table-column>
              <el-table-column prop="SellBillNumber"
                               label="销售单号"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="BilledDate"
                               label="开单日期"
                               align="center"
                               width="90">
              </el-table-column>
              <el-table-column prop="CompanyName"
                               label="公司"
                               align="center"
                               width="210"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="CustomerShortName"
                               label="客户"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="ProjectName"
                               label="工程"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="OriginName"
                               label="产地"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="ProductName"
                               label="品名"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="SpecificationName"
                               label="规格"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="MaterialName"
                               label="材质"
                               align="center"
                               width="90"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="Quantity"
                               label="开单数量"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="Price"
                               label="开单单价"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="AuditQuantity"
                               label="审单数量"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="AuditPrice"
                               label="审单单价"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="TotalAmount"
                               label="应收总额"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="WarehouseName"
                               label="仓库"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="SupplierName"
                               label="供应商"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="TransferedQuantity"
                               label="出库/采购数量"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="CostPrice"
                               label="采购单价"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="PurchaseAuditPrice"
                               label="采购审单单价"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="PutOutExpensePurchase"
                               label="应付采购费用"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="PutOutTotalAmount"
                               label="应付总额"
                               align="center"
                               width="120"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="TeamName"
                               label="业务组"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="SellerName"
                               :label="typename"
                               align="center"
                               width="80"
                               :show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-card>
    <el-dialog title="导入"
               :visible.sync="dialogVisibles"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formorder"
               ref="form">
        <el-form-item label="开单日期">
          <el-date-picker v-model="formorder.sales_date1"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 140px;float: left;">
          </el-date-picker>
          <el-date-picker v-model="formorder.sales_date2"
                          value-format="yyyy-MM-dd"
                          type="date"
                          class="input-with-select"
                          style="width: 140px;float: left;margin-left:5px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="采销明细"
                      prop="business_license"
                      style="margin-top: 20px;">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="businessRemove"
                     :on-success="filesuccess"
                     :limit="1"
                     accept=".xlsx"
                     :file-list="business"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="importFile">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { importPurchaseSellData, searchPurchaseSell, CustomerEnumList, ProjectEnumList, SalesmanList } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'BusinessanalysisIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogVisibles: false,
      currentPage: 1,
      total: 0,
      perpage: 25,
      pagesize: 25,
      orderlist: [],
      business: [],
      myHeaders: '',
      form: {
        type: null,
        UserName: null,
        sales_date1: null,
        sales_date2: null
      },
      formorder: {
        sales_date1: null,
        sales_date2: null,
        ProjectName: null,
        url: null
      },
      totalSellQuantity: '',
      totalSellAmount: '',
      totalSellUnitNumber: '',
      customerenumlist: [],
      projectlist: [],
      activeName: 'first',
      infoList: null,
      visitDate: 'thirtyday',
      series: [],
      yAxisData: [],
      content: {},
      infoLists: null,
      seriesArray: [],
      listnew: [],
      typelist: [{
        id: 1,
        name: '业务员'
      }, {
        id: 2,
        name: '拓展员'
      }],
      userlist: [],
      labels: '业务员',
      placeholders: '请选择业务员',
      notuserlist: [],
      typename: null,
      QuantityAll: null,
      AuditQuantityAll: null,
      NotQuantityAll: null,
      NotAuditQuantityAll: null,
      AuditQuantitytotals: null,
      TotalAmounttotals: null,
      TotalAmountAll: null,
      TransferedQuantityAll: null,
      PutOutTotalAmountAll: null
    }
  },
  created () {
    this.loadgetdate()
    this.loadnav()
    this.loadtoken()
    this.loadgetCustomerEnumList()
    this.loadgetProjectEnumList()
    this.search()
  },
  computed: {
    echarts () {
      return 'echarts' + Math.ceil(Math.random() * 100)
    }
  },
  destroyed () {
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.wsFunc)
    if (!this.myChart) {
      return
    }
    this.myChart.dispose()
    this.myChart = null
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'businessanalysis') {
        this.topname = '数据分析'
        this.navnames = '业务分析'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetCustomerEnumList () {
      CustomerEnumList().then(res => {
        if (res.data.status === 'success') {
          this.customerenumlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetProjectEnumList () {
      ProjectEnumList({ type: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.projectlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetdate () {
      this.salesdate = '2023-01-01'
      this.form.sales_date1 = '2023-01-01'
      this.form.sales_date2 = this.getDay(0)
      this.enddate = this.getDay(0)
    },
    importorder () {
      this.dialogVisibles = true
    },
    importFile () {
      if (this.formorder.sales_date1 === null) {
        this.$message.warning('请选择开始日期')
        return false
      }
      if (this.formorder.sales_date2 === null) {
        this.$message.warning('请选择结束日期')
        return false
      }
      if (this.formorder.url === null) {
        this.$message.warning('请选上传导入文件')
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: '正在导入中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      importPurchaseSellData(this.formorder).then(res => {
        if (res.data.status === 'success') {
          this.formorder.url = null
          this.business = []
          loading.close()
          this.search()
          this.dialogVisibles = false
          this.$message({
            message: '导入成功',
            type: 'success'
          })
        } else {
          this.formorder.url = null
          this.business = []
          this.dialogVisibles = false
          loading.close()
          this.$message.error('导入失败')
        }
      }).catch(err => {
        loading.close()
        console.log('失获取数据败', err)
      })
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.formorder.url = res.data.url
        this.business = this.business.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessRemove (file) {
      this.business.some((item, i) => {
        if (item.url === file.url) {
          this.business.splice(i, 1)
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeUpload (file) {
    },
    search () {
      this.updateLoading = true
      this.loading = true
      searchPurchaseSell({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.listnew = res.data.listnew
          this.notuserlist = res.data.notuserlist
          this.typename = res.data.typename
          this.QuantityAll = res.data.QuantityAll
          this.AuditQuantityAll = res.data.AuditQuantityAll
          this.NotQuantityAll = res.data.NotQuantityAll
          this.NotAuditQuantityAll = res.data.NotAuditQuantityAll
          this.AuditQuantitytotals = res.data.AuditQuantitytotals
          this.TotalAmounttotals = res.data.TotalAmounttotals
          this.TotalAmountAll = res.data.TotalAmountAll
          this.TransferedQuantityAll = res.data.TransferedQuantityAll
          this.PutOutTotalAmountAll = res.data.PutOutTotalAmountAll
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loadgetIndexInfo(res.data.datas)
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      searchPurchaseSell({ data: this.form, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.listnew = res.data.listnew
          this.notuserlist = res.data.notuserlist
          this.typename = res.data.typename
          this.QuantityAll = res.data.QuantityAll
          this.AuditQuantityAll = res.data.AuditQuantityAll
          this.NotQuantityAll = res.data.NotQuantityAll
          this.NotAuditQuantityAll = res.data.NotAuditQuantityAll
          this.AuditQuantitytotals = res.data.AuditQuantitytotals
          this.TotalAmounttotals = res.data.TotalAmounttotals
          this.TotalAmountAll = res.data.TotalAmountAll
          this.TransferedQuantityAll = res.data.TransferedQuantityAll
          this.PutOutTotalAmountAll = res.data.PutOutTotalAmountAll
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loadgetIndexInfo(res.data.datas)
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      searchPurchaseSell({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.listnew = res.data.listnew
          this.notuserlist = res.data.notuserlist
          this.typename = res.data.typename
          this.QuantityAll = res.data.QuantityAll
          this.AuditQuantityAll = res.data.AuditQuantityAll
          this.NotQuantityAll = res.data.NotQuantityAll
          this.NotAuditQuantityAll = res.data.NotAuditQuantityAll
          this.AuditQuantitytotals = res.data.AuditQuantitytotals
          this.TotalAmounttotals = res.data.TotalAmounttotals
          this.TotalAmountAll = res.data.TotalAmountAll
          this.TransferedQuantityAll = res.data.TransferedQuantityAll
          this.PutOutTotalAmountAll = res.data.PutOutTotalAmountAll
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loadgetIndexInfo(res.data.datas)
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay (day) {
      var today = new Date()
      var targetdaymilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targetdaymilliseconds)
      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth (month) {
      var m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    },
    totalSellAmounts () {
      let totalSellAmounts = 0
      this.productselectdata.map((item) => {
        const taxtotalss = Number(item.SellAmount)
        totalSellAmounts += taxtotalss
      })
      return totalSellAmounts.toFixed(2)
    },
    totalSumAll () {
      let totalSumAll = 0
      this.productselectdata.map((item) => {
        const taxtotal = Number(item.SellQuantity)
        totalSumAll += taxtotal
      })
      return totalSumAll.toFixed(2)
    },
    totalcost () {
      let costtotas = 0
      this.productselectdata.map((item) => {
        const costtota = Number(item.SellUnitNumber)
        costtotas += costtota
      })
      if (costtotas === 0) {
        return null
      } else {
        return costtotas
      }
    },
    handleClick (tab, event) {

    },
    loadgetIndexInfo (infoList) {
      this.infoLists = JSON.parse(JSON.stringify(infoList)) || {}
      this.seriesArray = JSON.parse(JSON.stringify(this.infoLists.series)) || []
      this.yAxisData = [
        {
          type: 'value',
          name: '数量 吨',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#7F8B9C'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F5F7F9'
            }
          }
        },
        {
          type: 'value',
          name: '金额 元',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#7F8B9C'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F5F7F9'
            }
          }
          // axisLabel: {
          //     formatter: '{value} °C'
          // }
        }
      ]
      const vm = this
      vm.$nextTick(() => {
        vm.handleSetVisitChart()
        window.addEventListener('resize', this.wsFunc)
      })
    },
    wsFunc () {
      this.myChart.resize()
    },
    handleSetVisitChart () {
      const echarts = require('echarts')
      this.myChart = echarts.init(this.$refs.echarts)
      let option = null
      if (this.echartsTitle === 'circle') {
        option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            left: 'right',
            data: this.infoLists.bing_xdata || []
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: '70%',
              center: ['50%', '60%'],
              data: this.infoLists.bing_data || [],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
      } else {
        option = {
          tooltip: {
            trigger: 'axis'
          },
          // 工具栏，内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
          toolbox: {
            show: true,
            // toolbox的配置项
            feature: {
              // 将图标保存为图片
              saveAsImage: { show: true }
            }
          },
          legend: {
            data: this.infoLists.legend || []
          },
          color: ['#1495EB', '#00CC66', '#F9D249', '#ff9900', '#9860DF'],
          grid: {
            left: 16,
            right: 25,
            bottom: 10,
            top: 40,
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              axisLine: {
                lineStyle: {
                  color: '#D7DDE4'
                }
              },
              axisTick: {
                alignWithLabel: true,
                lineStyle: {
                  color: '#D7DDE4'
                }
              },
              splitLine: {
                show: false,
                lineStyle: {
                  color: '#F5F7F9'
                }
              },
              // axisPointer: {
              //     type: 'shadow'
              // },
              axisLabel: {
                interval: 0,
                rotate: 40,
                textStyle: {
                  color: '#7F8B9C'
                }
              },
              data: this.infoLists.xAxis
            }
          ],
          /* eslint-disable */
          yAxis: this.yAxisData.length ? this.yAxisData : {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#7F8B9C'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#F5F7F9'
              }
            },
            type: 'value'
          },
          series: this.seriesArray
        }
      }

      this.myChart.clear()
      // 基于准备好的dom，初始化echarts实例
      this.myChart.setOption(option, true)
    },
    handleResize () {
      this.myChart.resize()
    },
    changetype (val) {
      this.form.UserName = null
      this.labels = val
      this.placeholders = '请选择' + val
      SalesmanList({ type: val }).then(res => {
        if (res.data.status === 'success') {
          this.userlist = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === 10) {
          sums[index] = this.QuantityAll.toFixed(3)
        } else if (index === 12) {
          sums[index] = this.AuditQuantityAll.toFixed(3)
        }
      })
      return sums
    },
    getSummariess (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === 10) {
          sums[index] = this.NotQuantityAll.toFixed(3)
        } else if (index === 12) {
          sums[index] = this.NotAuditQuantityAll.toFixed(3)
        }
      })
      return sums
    },
    getSummariesss (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === 2) {
          sums[index] = this.AuditQuantitytotals.toFixed(3)
        } else if (index === 3) {
          sums[index] = this.TotalAmounttotals.toFixed(3)
        }
      })
      return sums
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  padding: 0 10px 0 0;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 0px 0;
}
/deep/.el-table th {
  padding: 0px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
</style>
