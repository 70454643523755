<template>
  <div class="manage-container">
    <el-card class="filter-card"
             shadow="hover">
      <!-- 数据筛选表单 -->
      <el-form ref="voucher-form"
               :model="voucherform"
               label-width="80px"
               :rules="supplierformRules">
        <el-form-item label="名称"
                      prop="id"
                      class="form-items">
          <el-select v-model="voucherform.id"
                     filterable
                     clearable
                     placeholder="请选择"
                     @change="searchData">
            <el-option v-for="item in supplierdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:-80px ;"
                      class="form-items">
          <el-button size="primary"
                     @click="dialogFormSupplier = true"
                     icon="el-icon-plus">新增供应商</el-button>
        </el-form-item>
        <el-form-item style="margin-left:-80px ;"
                      class="form-items">
          <el-button size="primary"
                     icon="el-icon-plus"
                     type="success"
                     @click="dialogFormBrand = true">新增主营品牌</el-button>
        </el-form-item>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>
    <el-card class="box-card"
             shadow="hover">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="layui-elem-quote"> 基本信息</div>
          </template>
          <el-row>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">名称：</span>{{supplierinfoss.name}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">简称：</span>{{supplierinfoss.abbreviation}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content"><span class="grid-content-text">曾用名：</span>{{supplierinfoss.beforename}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">关联企业：</span>{{supplierinfoss.associated}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">公司类型：</span>{{supplierinfoss.type}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">员工规模：</span>{{supplierinfoss.scale}} 人</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">六六：</span>{{supplierinfoss.liuliu}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">钢小二：</span>{{supplierinfoss.gangxiaoer}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">营业执照：</span>
                <el-link v-if="supplierinfoss.business_license_url==null">未上传文件</el-link>
                <el-link type="primary"
                         v-else
                         @click="businessPdf(supplierinfoss.business_license_url)">查看文件</el-link>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">开票信息：</span>
                <el-link v-if="supplierinfoss.billing_information_url==null">未上传文件</el-link>
                <el-link type="primary"
                         v-else
                         @click="billingPdf(supplierinfoss.billing_information_url)">查看文件</el-link>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">收款账号：</span>
                <el-link v-if="supplierinfoss.collection_account_url==null">未上传文件</el-link>
                <el-link type="primary"
                         v-else
                         @click="collectionPdf(supplierinfoss.collection_account_url)">查看文件</el-link>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">办公地址：</span>{{supplierinfoss.addr}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">备注：</span>{{supplierinfoss.rank}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <el-button type="warning"
                           size="mini"
                           plain
                           @click="supplieredit(supplierinfoss.id)">修改</el-button>
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <div class="layui-elem-quote"> 业务信息</div>
          </template>
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">跟进人：</span>{{supplierinfoss.follow_person}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">内勤跟进：</span>{{supplierinfoss.office_follow}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">主营品种：</span>{{supplierinfoss.varieties}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">优势资源：</span>{{supplierinfoss.resources}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">付款类型：</span>{{supplierinfoss.payment_type}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">付款注意事项：</span>{{supplierinfoss.payment_notes}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">实提数注意事项：</span>{{supplierinfoss.actual_number_notes}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">对账注意事项：</span>{{supplierinfoss.reconciliation_notes}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">对接人及联系方式：</span>{{supplierinfoss.face_contact}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">订单样式要求：</span>{{supplierinfoss.order_style}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">开票时间节点：</span>{{supplierinfoss.billing_time}}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"><span class="grid-content-text">开票需提供的资料：</span>{{supplierinfoss.billing_info}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple"><span class="grid-content-text">走访记录：</span>{{supplierinfoss.interview_record}}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <el-button type="warning"
                           size="mini"
                           plain
                           @click="supplieredit(supplierinfoss.id)">修改</el-button>
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title">
            <div class="layui-elem-quote"> 主营品牌</div>
          </template>
          <div class="demo-block"
               v-for="(item,index) in brandlistdata"
               :key="index">
            <el-row>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">品牌名：</span>{{item.name}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content "><span class="grid-content-text">经销类型：</span>{{item.type}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">月销协议量：</span>{{item.agreement_sales}} 吨</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">付款类型：</span>{{item.settlement_method}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">配送方式：</span>{{item.delivery_mode}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">优点：</span>{{item.advantage}}</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content"><span class="grid-content-text">缺点：</span>{{item.shortcoming}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content"><span class="grid-content-text">厂家政策：</span>{{item.factory_policy}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content"><span class="grid-content-text">优势区域：</span>{{item.advantage_area}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple"><span class="grid-content-text">备注：</span>{{item.rank}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-button type="warning"
                             size="mini"
                             plain
                             @click="brandedit(item.id)">修改</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-dialog title="新增供应商"
               :visible.sync="dialogFormSupplier"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="supplierinfo-form"
               :model="supplierinfo"
               label-width="150px"
               :rules="formRules">
        <el-form-item label="名称"
                      prop="name">
          <el-input v-model="supplierinfo.name"></el-input>
        </el-form-item>
        <el-form-item label="类型"
                      prop="mid">
          <el-select v-model="supplierinfo.mid"
                     placeholder="请选择"
                     multiple
                     filterable
                     remote
                     reserve-keyword>
            <el-option v-for="item in mertypeoptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简称"
                      prop="abbreviation">
          <el-input v-model="supplierinfo.abbreviation"></el-input>
        </el-form-item>
        <el-form-item label="曾用名"
                      prop="beforename">
          <el-input v-model="supplierinfo.beforename"></el-input>
        </el-form-item>
        <el-form-item label="关联企业"
                      prop="associated">
          <el-select v-model="supplierinfo.associatedid"
                     multiple
                     filterable
                     remote
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in supplierdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司类型"
                      prop="type">
          <el-select v-model="supplierinfo.type"
                     placeholder="请选择">
            <el-option v-for="item in ctypeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工规模"
                      prop="scale">
          <el-select v-model="supplierinfo.scale"
                     placeholder="请选择">
            <el-option v-for="item in scaleoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select> 人
        </el-form-item>
        <el-form-item label="主营品种"
                      prop="varieties">
          <el-input v-model="supplierinfo.varieties"></el-input>
        </el-form-item>
        <el-form-item label="优势资源"
                      prop="resources">
          <el-input v-model="supplierinfo.resources"></el-input>
        </el-form-item>
        <el-form-item label="跟进人"
                      prop="follow_person">
          <el-input v-model="supplierinfo.follow_person"></el-input>
        </el-form-item>
        <el-form-item label="内勤跟进"
                      prop="office_follow">
          <el-input v-model="supplierinfo.office_follow"></el-input>
        </el-form-item>
        <el-form-item label="对接人及联系方式"
                      prop="face_contact">
          <el-input v-model="supplierinfo.face_contact"></el-input>
        </el-form-item>
        <el-form-item label="办公地址"
                      prop="addr">
          <el-input v-model="supplierinfo.addr"></el-input>
        </el-form-item>
        <el-form-item label="订单样式要求"
                      prop="order_style">
          <el-select v-model="supplierinfo.order_style"
                     placeholder="请选择">
            <el-option v-for="item in order_styleoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款类型"
                      prop="payment_type">
          <el-input v-model="supplierinfo.payment_type"></el-input>
        </el-form-item>
        <el-form-item label="付款注意事项"
                      prop="payment_notes">
          <el-input v-model="supplierinfo.payment_notes"></el-input>
        </el-form-item>
        <el-form-item label="实提数注意事项"
                      prop="actual_number_notes">
          <el-input v-model="supplierinfo.actual_number_notes"></el-input>
        </el-form-item>
        <el-form-item label="对账注意事项"
                      prop="reconciliation_notes">
          <el-input v-model="supplierinfo.reconciliation_notes"></el-input>
        </el-form-item>
        <el-form-item label="开票时间节点"
                      prop="billing_time">
          <el-input v-model="supplierinfo.billing_time"></el-input>
        </el-form-item>
        <el-form-item label="开票需提供的资料"
                      prop="billing_info">
          <el-input v-model="supplierinfo.billing_info"></el-input>
        </el-form-item>
        <el-form-item label="六六"
                      prop="liuliu">
          <el-select v-model="supplierinfo.liuliu"
                     placeholder="请选择">
            <el-option v-for="item in liuliuoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="钢小二"
                      prop="gangxiaoer">
          <el-select v-model="supplierinfo.gangxiaoer"
                     placeholder="请选择">
            <el-option v-for="item in gangxiaoeroptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="走访记录"
                      prop="interview_record">
          <el-input v-model="supplierinfo.interview_record"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="supplierinfo.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="营业执照"
                      prop="business_license">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="businessRemove"
                     :on-success="filesuccess"
                     multiple
                     :file-list="business"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="开票信息"
                      prop="billing_information">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="billingRemove"
                     :on-success="billingsuccess"
                     multiple
                     :file-list="billing"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="收款账号"
                      prop="collection_account">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :headers="myHeaders"
                     :on-remove="collectionRemove"
                     :on-success="collectionsuccess"
                     multiple
                     :file-list="collection"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateCustomer">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改供应商"
               :visible.sync="dialogFormSuppliers"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="supplierinfos-form"
               :model="supplierinfos"
               label-width="150px"
               :rules="formRuless">
        <el-form-item label="名称"
                      prop="name">
          <el-input v-model="supplierinfos.name"></el-input>
        </el-form-item>
        <el-form-item label="类型"
                      prop="mid">
          <el-select v-model="supplierinfos.mid"
                     placeholder="请选择"
                     multiple
                     filterable
                     remote
                     reserve-keyword>
            <el-option v-for="item in mertypeoptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简称"
                      prop="abbreviation">
          <el-input v-model="supplierinfos.abbreviation"></el-input>
        </el-form-item>
        <el-form-item label="曾用名"
                      prop="beforename">
          <el-input v-model="supplierinfos.beforename"></el-input>
        </el-form-item>
        <el-form-item label="关联企业"
                      prop="associated">
          <el-select v-model="supplierinfos.associatedid"
                     multiple
                     filterable
                     remote
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in supplierdata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司类型"
                      prop="type">
          <el-select v-model="supplierinfos.type"
                     placeholder="请选择">
            <el-option v-for="item in ctypeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工规模"
                      prop="scale">
          <el-select v-model="supplierinfos.scale"
                     placeholder="请选择">
            <el-option v-for="item in scaleoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select> 人
        </el-form-item>
        <el-form-item label="主营品种"
                      prop="varieties">
          <el-input v-model="supplierinfos.varieties"></el-input>
        </el-form-item>
        <el-form-item label="优势资源"
                      prop="resources">
          <el-input v-model="supplierinfos.resources"></el-input>
        </el-form-item>
        <el-form-item label="跟进人"
                      prop="follow_person">
          <el-input v-model="supplierinfos.follow_person"></el-input>
        </el-form-item>
        <el-form-item label="内勤跟进"
                      prop="office_follow">
          <el-input v-model="supplierinfos.office_follow"></el-input>
        </el-form-item>
        <el-form-item label="对接人及联系方式"
                      prop="face_contact">
          <el-input v-model="supplierinfos.face_contact"></el-input>
        </el-form-item>
        <el-form-item label="办公地址"
                      prop="addr">
          <el-input v-model="supplierinfos.addr"></el-input>
        </el-form-item>
        <el-form-item label="订单样式要求"
                      prop="order_style">
          <el-select v-model="supplierinfos.order_style"
                     placeholder="请选择">
            <el-option v-for="item in order_styleoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款类型"
                      prop="payment_type">
          <el-input v-model="supplierinfos.payment_type"></el-input>
        </el-form-item>
        <el-form-item label="付款注意事项"
                      prop="payment_notes">
          <el-input v-model="supplierinfos.payment_notes"></el-input>
        </el-form-item>
        <el-form-item label="实提数注意事项"
                      prop="actual_number_notes">
          <el-input v-model="supplierinfos.actual_number_notes"></el-input>
        </el-form-item>
        <el-form-item label="对账注意事项"
                      prop="reconciliation_notes">
          <el-input v-model="supplierinfos.reconciliation_notes"></el-input>
        </el-form-item>
        <el-form-item label="开票时间节点"
                      prop="billing_time">
          <el-input v-model="supplierinfos.billing_time"></el-input>
        </el-form-item>
        <el-form-item label="开票需提供的资料"
                      prop="billing_info">
          <el-input v-model="supplierinfos.billing_info"></el-input>
        </el-form-item>
        <el-form-item label="六六"
                      prop="liuliu">
          <el-select v-model="supplierinfos.liuliu"
                     placeholder="请选择">
            <el-option v-for="item in liuliuoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="钢小二"
                      prop="gangxiaoer">
          <el-select v-model="supplierinfos.gangxiaoer"
                     placeholder="请选择">
            <el-option v-for="item in gangxiaoeroptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="走访记录"
                      prop="interview_record">
          <el-input v-model="supplierinfos.interview_record"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="supplierinfos.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="营业执照"
                      prop="business_license">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :limit='1'
                     :headers="myHeaders"
                     :on-remove="businessaRemove"
                     :on-success="fileasuccess"
                     :file-list="businessa"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="开票信息"
                      prop="billing_information">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :limit='1'
                     :headers="myHeaders"
                     :on-remove="billingaRemove"
                     :on-success="billingasuccess"
                     :file-list="billinga"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="收款账号"
                      prop="collection_accoun">
          <el-upload class="upload"
                     action="https://bmapi.gangerp.com/api/upFile"
                     :limit='1'
                     :headers="myHeaders"
                     :on-remove="collectionaRemove"
                     :on-success="collectionasuccess"
                     :file-list="collectiona"
                     :before-upload="beforeUpload">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateCustomers">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="新增主营品牌"
               :visible.sync="dialogFormBrand"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="brandinfo-form"
               :model="brandinfo"
               label-width="120px"
               :rules="formRulesbrand">
        <el-form-item label="供应商名称"
                      prop="sid">
          <el-select v-model="brandinfo.sid"
                     filterable
                     placeholder="请选择"
                     style="width:100%">
            <el-option v-for="item in supplierdata"
                       :key="item.ide"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌名"
                      prop="name">
          <el-select v-model="brandinfo.name"
                     filterable
                     placeholder="请输入品牌"
                     :loading="loading"
                     style="width:100%">
            <el-option v-for="item in brandlist"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经销类型"
                      prop="type">
          <el-select v-model="brandinfo.type"
                     placeholder="请选择">
            <el-option v-for="item in typeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厂家政策"
                      prop="factory_policy">
          <el-input type="textarea"
                    v-model="brandinfo.factory_policy"></el-input>
        </el-form-item>
        <el-form-item label="月销协议量"
                      prop="agreement_sales">
          <el-input v-model="brandinfo.agreement_sales"
                    style="width:230px"></el-input> 吨
        </el-form-item>
        <el-form-item label="优势区域"
                      prop="advantage_area">
          <el-input type="textarea"
                    v-model="brandinfo.advantage_area"></el-input>
        </el-form-item>
        <el-form-item label="优点"
                      prop="advantage">
          <el-input v-model="brandinfo.advantage"></el-input>
        </el-form-item>
        <el-form-item label="缺点"
                      prop="shortcoming">
          <el-input v-model="brandinfo.shortcoming"></el-input>
        </el-form-item>
        <el-form-item label="付款类型"
                      prop="settlement_method">
          <el-select v-model="brandinfo.settlement_method"
                     placeholder="请选择"
                     multiple
                     remote>
            <el-option v-for="item in settlement_methodoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配送方式"
                      prop="settlement_method">
          <el-select v-model="brandinfo.delivery_mode"
                     placeholder="请选择"
                     multiple>
            <el-option v-for="item in delivery_modeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="brandinfo.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateBrand">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改主营品牌"
               :visible.sync="dialogFormBrands"
               append-to-body
               :top="top"
               :close-on-click-modal="false"
               width="70%">
      <el-form ref="brandinfos-form"
               :model="brandinfos"
               label-width="120px"
               :rules="formRulesbrands">
        <el-form-item label="品牌名"
                      prop="name">
          <el-select v-model="brandinfos.name"
                     filterable
                     placeholder="请输入品牌"
                     :loading="loading"
                     style="width:100%">
            <el-option v-for="item in brandlist"
                       :key="item.id"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经销类型"
                      prop="type">
          <el-select v-model="brandinfos.type"
                     placeholder="请选择">
            <el-option v-for="item in typeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厂家政策"
                      prop="factory_policy">
          <el-input type="textarea"
                    v-model="brandinfos.factory_policy"></el-input>
        </el-form-item>
        <el-form-item label="月销协议量"
                      prop="agreement_sales">
          <el-input v-model="brandinfos.agreement_sales"
                    style="width:230px"></el-input> 吨
        </el-form-item>
        <el-form-item label="优势区域"
                      prop="advantage_area">
          <el-input type="textarea"
                    v-model="brandinfos.advantage_area"></el-input>
        </el-form-item>
        <el-form-item label="优点"
                      prop="advantage">
          <el-input v-model="brandinfos.advantage"></el-input>
        </el-form-item>
        <el-form-item label="缺点"
                      prop="shortcoming">
          <el-input v-model="brandinfos.shortcoming"></el-input>
        </el-form-item>
        <el-form-item label="付款类型"
                      prop="settlement_method">
          <el-select v-model="brandinfos.settlement_method"
                     placeholder="请选择"
                     multiple
                     remote>
            <el-option v-for="item in settlement_methodoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配送方式"
                      prop="settlement_method">
          <el-select v-model="brandinfos.delivery_mode"
                     placeholder="请选择"
                     multiple>
            <el-option v-for="item in delivery_modeoptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注"
                      prop="rank">
          <el-input v-model="brandinfos.rank"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateProfileLoading"
                     @click="onUpdateBrands">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getSupplierList, getEditSupplier, addNewsSupplier, upDataSupplier, getMainBrandList, getEditMainBrand, addNewsMainBrand, upDataMainBrand, getDepartmentList, getMerchantsType } from '@/api/global'
import { getUserList } from '@/api/user'
import globalBus from '@/utils/global-bus'
import axios from 'axios'
const Base64 = require('js-base64').Base64

export default {
  name: 'SupplierinfoIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      activeNames: ['1'],
      voucherform: {
        id: 41
      },
      top: '15px',
      myHeaders: '',
      business: [],
      billing: [],
      businessa: [],
      billinga: [],
      collection: [],
      collectiona: [],
      updateProfileLoading: false, // 更新基本信息的 loading 状态
      supplierdata: [],
      search: '',
      deptoptions: [],
      useroptions: [],
      mertypeoptions: [],
      supplierinfoss: '',
      supplierdatasearch: [],
      supplierinfo: {
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        associatedid: '',
        varieties: '',
        resources: '',
        follow_person: '',
        office_follow: '',
        face_contact: '',
        scale: '',
        addr: '',
        order_style: '',
        payment_type: '',
        payment_notes: '',
        actual_number_notes: '',
        reconciliation_notes: '',
        billing_time: '',
        billing_info: '',
        liuliu: '',
        gangxiaoer: '',
        business_license: '',
        business_license_url: '',
        billing_information: '',
        billing_information_url: '',
        collection_account: '',
        collection_account_url: '',
        interview_record: '',
        rank: ''
      },
      supplierformRules: {
        id: [{ required: true, message: '请输选择公司名', trigger: 'blur' }]
      },
      formRules: {
        name: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      dialogFormSupplier: false,
      dialogFormSuppliers: false,
      supplierinfos: {
        id: '',
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        associatedid: '',
        varieties: '',
        resources: '',
        follow_person: '',
        office_follow: '',
        face_contact: '',
        scale: '',
        addr: '',
        order_style: '',
        payment_type: '',
        payment_notes: '',
        actual_number_notes: '',
        reconciliation_notes: '',
        billing_time: '',
        billing_info: '',
        liuliu: '',
        gangxiaoer: '',
        business_license: '',
        business_license_url: '',
        billing_information: '',
        billing_information_url: '',
        collection_account: '',
        collection_account_url: '',
        interview_record: '',
        rank: ''
      },
      formRuless: {
        name: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      liuliuoptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }],
      gangxiaoeroptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }],
      order_styleoptions: [{
        value: '对方样式',
        label: '对方样式'
      }, {
        value: '我方样式',
        label: '我方样式'
      }],
      scaleoptions: [{
        value: '0-10',
        label: '0-10'
      }, {
        value: '10-50',
        label: '10-50'
      }, {
        value: '50以上',
        label: '50以上'
      }],
      typeoptions: [{
        value: '一级代理商',
        label: '一级代理商'
      }, {
        value: '二级代理商',
        label: '二级代理商'
      }, {
        value: '销货库存商',
        label: '销货库存商'
      }],
      settlement_methodoptions: [{
        value: '款到发货',
        label: '款到发货'
      }, {
        value: '出数付款',
        label: '出数付款'
      }],
      delivery_modeoptions: [{
        value: '仓库自提',
        label: '仓库自提'
      }, {
        value: '仓库包送',
        label: '仓库包送'
      }, {
        value: '钢厂直送',
        label: '钢厂直送'
      }],
      ctypeoptions: [{
        value: '民营企业',
        label: '民营企业'
      }, {
        value: '国有企业',
        label: '国有企业'
      }],
      dialogFormBrand: false,
      dialogFormBrands: false,
      brandinfo: {
        sid: '',
        name: '',
        type: '',
        factory_policy: '',
        agreement_sales: '',
        advantage_area: '',
        advantage: '',
        shortcoming: '',
        price: '',
        settlement_method: '',
        delivery_mode: '',
        rank: ''
      },
      brandinfos: {
        id: '',
        sid: '',
        name: '',
        type: '',
        factory_policy: '',
        agreement_sales: '',
        advantage_area: '',
        advantage: '',
        shortcoming: '',
        price: '',
        settlement_method: '',
        delivery_mode: '',
        rank: ''
      },
      brandlist: [],
      brandlistdata: [],
      formRulesbrand: {
        sid: [
          { required: true, message: '请选择供应商', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入品牌名', trigger: 'blur' }
        ]
      },
      formRulesbrands: {
        sid: [
          { required: true, message: '请选择供应商', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入品牌名', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadnav()
    this.loadsupplierList()
    this.loadsupplierinfo()
    this.loaddept()
    this.loadmerchantstype()
    this.loaduser()
    this.loadbrand()
    this.loadbrandList()
    this.loadtoken()
  },
  mounted () {
  },
  methods: {
    loadbrand () {
      const _self = this
      axios.post('https://api.66gangtie.com/api/bmGetBrand').then(function (response) {
        if (response.data.status === 'success') {
          _self.brandlist = response.data.data
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loaddept () {
      getDepartmentList().then(res => {
        if (res.data.status === 'success') {
          this.deptoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadmerchantstype () {
      getMerchantsType().then(res => {
        if (res.data.status === 'success') {
          this.mertypeoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loaduser () {
      getUserList().then(res => {
        if (res.data.status === 'success') {
          this.useroptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'supplierinfo') {
        this.topname = '客商管理'
        this.navnames = '供应商分析'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadsupplierList () {
      getSupplierList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.supplierdata = res.data.data
          this.supplierdatasearch = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    loadsupplierinfo () {
      getEditSupplier({ id: this.voucherform.id }).then(res => {
        this.supplierinfoss = res.data.data
        this.businessa = res.data.dataa
        this.billinga = res.data.datab
        this.collectiona = res.data.datac
        this.brandinfo.sid = this.voucherform.id
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    searchData () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['voucher-form'].validate(valid => {
        if (!valid) {
          return false
        }
        getEditSupplier({ id: this.voucherform.id }).then(res => {
          console.log(res.data.data)
          this.supplierinfoss = res.data.data
          this.businessa = res.data.dataa
          this.billinga = res.data.datab
          this.brandinfo.sid = this.voucherform.id
          this.loadbrandList()
        }).catch(err => {
          console.log('修改失败', err)
        })
      })
    },
    businessPdf (url) {
      if (!url && typeof (url) !== 'undefined' && url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        window.open('https://file.gangerp.com/onlinePreview?url=' + Base64.encode(url), '_blank')
      }
    },
    billingPdf (url) {
      if (!url && typeof (url) !== 'undefined' && url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        window.open('https://file.gangerp.com/onlinePreview?url=' + Base64.encode(url), '_blank')
      }
    },
    collectionPdf (url) {
      if (!url && typeof (url) !== 'undefined' && url !== 0) {
        this.$message({
          message: '没有扫描件!',
          type: 'warning'
        })
      } else {
        window.open('https://file.gangerp.com/onlinePreview?url=' + Base64.encode(url), '_blank')
      }
    },
    supplieredit (id) {
      getEditSupplier({ id: id }).then(res => {
        this.dialogFormSuppliers = true
        this.supplierinfos = res.data.data
        this.businessa = res.data.dataa
        this.billinga = res.data.datab
        this.collectiona = res.data.datac
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    onUpdateCustomer () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['supplierinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addNewsSupplier(this.supplierinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormSupplier = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })

          this.supplierdata = res.data.data
          this.voucherform.id = res.data.id
          this.supplierinfo.name = ''
          this.supplierinfo.deptid = ''
          this.supplierinfo.userid = ''
          this.loadsupplierinfo()
          this.loadbrandList()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateCustomers () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['supplierinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        if (this.collectiona.length === 0) {
          this.supplierinfos.collection_account = null
          this.supplierinfos.collection_account_url = null
        }
        if (this.businessa.length === 0) {
          this.supplierinfos.business_license = null
          this.supplierinfos.business_license_url = null
        }
        if (this.billinga.length === 0) {
          this.supplierinfos.billing_information = null
          this.supplierinfos.billing_information_url = null
        }
        this.upDataSupplier()
      })
    },
    upDataSupplier () {
      this.updatePhotoLoading = true
      upDataSupplier(this.supplierinfos).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormSuppliers = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.supplierinfos.name = ''
          this.supplierinfos.deptid = ''
          this.supplierinfos.userid = ''
          this.loadsupplierinfo()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    filesuccess (res) {
      if (res.status === 'success') {
        this.supplierinfo.business_license = res.data.name
        this.supplierinfo.business_license_url = res.data.url
        this.business = this.business.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessRemove (file) {
      this.business.some((item, i) => {
        if (item.url === file.url) {
          this.business.splice(i, 1)
          return true
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    fileasuccess (res) {
      if (res.status === 'success') {
        this.supplierinfos.business_license = res.data.name
        this.supplierinfos.business_license_url = res.data.url
        this.businessa = this.businessa.concat(res.data)
        console.log(this.businessa)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    businessaRemove (file) {
      this.businessa.some((item, i) => {
        if (item.url === file.url) {
          this.businessa.splice(i, 1)
          return true
        }
      })
    },
    beforeaRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    billingsuccess (res) {
      if (res.status === 'success') {
        this.supplierinfo.billing_information = res.data.name
        this.supplierinfo.billing_information_url = res.data.url
        this.billing = this.billing.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    billingRemove (file) {
      this.billing.some((item, i) => {
        if (item.url === file.url) {
          this.billing.splice(i, 1)
          return true
        }
      })
    },
    billingasuccess (res) {
      if (res.status === 'success') {
        this.supplierinfos.billing_information = res.data.name
        this.supplierinfos.billing_information_url = res.data.url
        this.billinga = this.billinga.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    billingaRemove (file) {
      this.billinga.some((item, i) => {
        if (item.url === file.url) {
          this.billinga.splice(i, 1)
          return true
        }
      })
    },
    collectionsuccess (res) {
      if (res.status === 'success') {
        this.supplierinfo.collection_account = res.data.name
        this.supplierinfo.collection_account_url = res.data.url
        this.collection = this.collection.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    collectionRemove (file) {
      this.collection.some((item, i) => {
        if (item.url === file.url) {
          this.collection.splice(i, 1)
          return true
        }
      })
    },
    collectionasuccess (res) {
      if (res.status === 'success') {
        this.supplierinfos.collection_account = res.data.name
        this.supplierinfos.collection_account_url = res.data.url
        this.collectiona = this.collectiona.concat(res.data)
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    collectionaRemove (file) {
      this.collectiona.some((item, i) => {
        if (item.url === file.url) {
          this.collectiona.splice(i, 1)
          return true
        }
      })
    },
    beforeUpload (file) {
    },
    // 主营品牌
    loadbrandList () {
      getMainBrandList({ sid: this.voucherform.id }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.brandlistdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    brandedit (id) {
      getEditMainBrand({ id: id }).then(res => {
        this.dialogFormBrands = true
        this.brandinfos = res.data.data
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    onUpdateBrand () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['brandinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfobrand()
      })
    },
    sendinfobrand () {
      this.updatePhotoLoading = true
      addNewsMainBrand(this.brandinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormBrand = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.brandlistdata = res.data.data
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateBrands () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['brandinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataMainBrand(this.brandinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormBrands = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadbrandList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
.form-items {
  float: left;
  margin-right: 5px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
.grid-content {
  font-size: 14px;
  padding: 10px 0px;
}
.layui-elem-quote {
  padding: 3px;
  line-height: 10px;
  border-left: 3px solid #4596fc;
  border-radius: 0 2px 2px 0;
  font-weight: 700 !important;
}
/deep/.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0px 0;
}
/deep/.el-collapse-item__header {
  line-height: 48px;
  color: #303133;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.demo-block {
  border-bottom: 1px solid #ebebeb;
  transition: 0.2s;
}
.grid-content-text {
  font-weight: 600;
}
</style>
