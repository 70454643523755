import axios from 'axios'
import JSONbig from 'json-bigint'
import router from '@/router'
// 非组件模块可以这样加载使用 element 的 message 提示组件
import { Message, MessageBox } from 'element-ui'

// 实例一个axios
const request = axios.create({
  baseURL: 'https://bmapi.gangerp.com/',
  transformResponse: [
    function (data) {
      try {
        return JSONbig.parse(data)
      } catch (err) {
        console.log('转换失败', err)
        return data
      }
    }
  ]
})

// 请求拦截器
request.interceptors.request.use(
  function (config) {
    const user = JSON.parse(window.localStorage.getItem('user'))
    if (user) {
      config.headers.Authorization = `${user.token}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  function (response) {
    // 所有响应码为 2xx 的响应都会进入这里
    // response 是响应处理
    // 注意：一定要把响应结果 return，否则真正发请求的位置拿不到数据
    return response
  },
  function (error) {
    const { status } = error.response
    // 任何超出 2xx 的响应码都会进入这里
    if (status === 403) {
      // 跳转到登录页面
      // 清除本地存储中的用户登录状态
      window.localStorage.removeItem('user')
      router.push('/login')
      // Message.error('登录状态无效，请重新登录')
    } else if (status === 400) {
      // 客户端会员过期
      window.localStorage.removeItem('user')
      MessageBox.alert('会员停用或过期。', '消息提示', {
        confirmButtonText: '确定',
        callback: action => {
          window.localStorage.removeItem('user')
          router.push('/login')
        }
      })
    } else if (status === 401) {
      // 客户端权限
      /* MessageBox.alert('您没有权限。', '消息提示', {
        confirmButtonText: '确定',
        callback: action => {
          window.localStorage.removeItem('user')
          router.push('/login')
        }
      }) */
      Message.error('您没有权限操作。')
    } else if (status === 402) {
      // 客户端会员过期
      window.localStorage.removeItem('user')
      MessageBox.alert('会员停用或过期。', '消息提示', {
        confirmButtonText: '确定',
        callback: action => {
          window.localStorage.removeItem('user')
          router.push('/login')
        }
      })
    } else if (status === 418) {
      // 客户端会员在其他方登录
      // 跳转到登录页面
      // 清除本地存储中的用户登录状态
      window.localStorage.removeItem('user')
      router.push('/login')
    } else if (status >= 500) {
      Message.error('服务端内部异常，请稍后重试')
    }

    return Promise.reject(error)
  }
)

export default request
