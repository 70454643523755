<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <el-form :model="form"
                 ref="customer-form">
          <el-form-item label="客户"
                        class="el-form-items">
            <el-select v-model="form.CustomerShortName"
                       filterable
                       clearable
                       placeholder="请选择客户"
                       class="input-with-select"
                       style="width: 200px;">
              <el-option v-for="(item,index) in customerenumlist"
                         :key="index"
                         :label="item.sn"
                         :value="item.sn">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工程"
                        class="el-form-items">
            <el-select v-model="form.ProjectName"
                       filterable
                       clearable
                       placeholder="请选择工程"
                       class="input-with-select"
                       style="width: 200px;">
              <el-option v-for="(items,index) in projectlist"
                         :key="index"
                         :label="items.name"
                         :value="items.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务员"
                        class="el-form-items">
            <el-input placeholder="请输入业务员"
                      v-model="form.UserName"
                      clearable
                      class="input-with-select"
                      style="width: 200px;">
            </el-input>
          </el-form-item>
          <div style="float: left;margin-top:5px">
            <el-button icon="el-icon-search"
                       type="primary"
                       size="mini"
                       :loading="updateLoading"
                       @click="search"
                       style="padding: 6px 10px;"></el-button>
          </div>
          <div style="float:right;margin-top:5px">
            <el-button type="primary"
                       icon="iconfont icon-jia"
                       size="mini"
                       style="margin: 0 10px 10px 0;padding: 6px 12px;"
                       @click="importorder"> 添加</el-button>
          </div>
        </el-form>
      </el-row>
      <el-row>
        <el-table :data="orderlist"
                  ref="multipleTable"
                  style="width: 100%"
                  row-key="id"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  height="calc(100vh - 180px)"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="50">
          </el-table-column>
          <el-table-column prop="type"
                           label="类型"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="CustomerShortName"
                           label="客户"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="ProjectName"
                           label="工程"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="UserName"
                           label="业务员"
                           align="center">
          </el-table-column>
          <el-table-column prop="Extender"
                           label="拓展员"
                           align="center">
          </el-table-column>
          <el-table-column align="center"
                           width="180"
                           label="操作">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="handleEdit(scope.row.id)"
                         icon="el-icon-edit">修改</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.row.id)"
                         icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;"
             v-show="total!=0">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[29, 50, 100,200]"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-row>
    </el-card>
    <el-dialog :title="title"
               :visible.sync="dialogVisibles"
               width="40%"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formorder"
               ref="salesman-form"
               :rules="formRules">
        <el-form-item label="类型"
                      prop="type">
          <el-select v-model="formorder.type"
                     filterable
                     clearable
                     placeholder="请选择类型"
                     class="input-with-select"
                     style="width: 300px;"
                     @change="changetype">
            <el-option v-for="(item,index) in typelist"
                       :key="index"
                       :label="item.name"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名"
                      prop="CustomerShortName">
          <el-select v-model="formorder.CustomerShortName"
                     filterable
                     clearable
                     placeholder="请选择客户名"
                     class="input-with-select"
                     style="width: 300px;">
            <el-option v-for="(item,index) in customerenumlist"
                       :key="index"
                       :label="item.sn"
                       :value="item.sn">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工程名"
                      prop="ProjectName"
                      v-if="isshow">
          <el-select v-model="formorder.ProjectName"
                     filterable
                     clearable
                     placeholder="请选择工程名"
                     class="input-with-select"
                     style="width: 300px;">
            <el-option v-for="(items,index) in projectlist"
                       :key="index"
                       :label="items.name"
                       :value="items.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务员"
                      prop="UserName">
          <el-input placeholder="请输入业务员"
                    v-model="formorder.UserName"
                    clearable
                    class="input-with-select"
                    style="width: 300px;">
          </el-input>
        </el-form-item>
        <el-form-item label="拓展员"
                      prop="Extender"
                      v-if="isshow">
          <el-input placeholder="请输入拓展员"
                    v-model="formorder.Extender"
                    clearable
                    class="input-with-select"
                    style="width: 300px;">
          </el-input>
        </el-form-item>
      </el-form>
      <div style="margin-left: 50px; margin-top: 20px;">
        <el-button type="primary"
                   @click="importFile(type)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { searchSalesman, ProjectEnumList, CustomerEnumList, addSalesman, delSalesman, editSalesman, updateSalesman } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'SalesmanIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogVisibles: false,
      currentPage: 1,
      total: 0,
      perpage: 29,
      pagesize: 29,
      orderlist: [],
      business: [],
      myHeaders: '',
      title: '',
      type: '',
      form: {
        CustomerShortName: null,
        ProjectName: null,
        UserName: null
      },
      formorder: {
        CustomerShortName: null,
        ProjectName: null,
        UserName: null
      },
      formRules: {
        type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        CustomerShortName: [
          { required: true, message: '请选择客户名', trigger: 'change' }
        ],
        ProjectName: [
          { required: false, message: '请选择工程名', trigger: 'change' }
        ],
        UserName: [
          { required: true, message: '请输入业务员', trigger: 'blur' }
        ],
        Extender: [
          { required: false, message: '请输入拓展员', trigger: 'blur' }
        ]
      },
      projectlist: [],
      customerenumlist: [],
      typelist: [{
        id: 1,
        name: '大宗'
      }, {
        id: 2,
        name: '工程'
      }],
      isshow: false
    }
  },
  created () {
    this.loadnav()
    this.loadtoken()
    this.loadgetCustomerEnumList()
    this.loadgetProjectEnumList()
    this.search()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'salesman') {
        this.topname = '数据分析'
        this.navnames = '业务员管理'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loadgetCustomerEnumList () {
      CustomerEnumList().then(res => {
        if (res.data.status === 'success') {
          this.customerenumlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetProjectEnumList () {
      ProjectEnumList({ type: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.projectlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    importorder () {
      this.dialogVisibles = true
      this.type = 1
      this.title = '添加'
    },
    handleEdit (id) {
      editSalesman({ id: id }).then(res => {
        this.dialogVisibles = true
        this.type = 2
        this.title = '修改'
        this.formorder = res.data.data
        this.changetype(res.data.data.type)
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (id) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSalesman({ id: id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.search()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    importFile () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['salesman-form'].validate(valid => {
        if (!valid) {
          return false
        }
        if (this.type === 1) {
          this.addsalesman()
        } else {
          this.updatesalesman()
        }
      })
    },
    addsalesman () {
      addSalesman({ data: this.formorder }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.search()
          this.formorder = {
            type: null,
            CustomerShortName: null,
            ProjectName: null,
            UserName: null,
            Extender: null
          }
          this.dialogVisibles = false
          this.updateLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    updatesalesman () {
      updateSalesman({ data: this.formorder }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.search()
          this.dialogVisibles = false
          this.updateLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    search () {
      this.updateLoading = true
      this.loading = true
      searchSalesman({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      searchSalesman({ data: this.form, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      searchSalesman({ data: this.form, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    changetype (val) {
      if (val === '大宗') {
        this.isshow = false
        this.formRules.ProjectName[0].required = false
        this.formRules.Extender[0].required = false
      } else {
        this.isshow = true
        this.formRules.ProjectName[0].required = true
        this.formRules.Extender[0].required = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 18px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  width: 70px;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 0px 0;
}
/deep/.el-table th {
  padding: 0px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
</style>
