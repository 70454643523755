<template>
  <div id="app">
    <!-- 路由出口 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {}
  },
  methods: {},
  created () { }
}
</script>

<style lang="less">
body {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
}
</style>
