<template>
  <div>
    <el-card shadow="never"
             :body-style="{ padding: '10px' }">
      <el-row>
        <el-form :model="form"
                 ref="customer-form">
          <el-form-item label="工程"
                        class="el-form-items">
            <el-date-picker type="year"
                            placeholder="选择日期"
                            v-model="form.year"
                            class="input-with-selects "
                            value-format="yyyy"></el-date-picker>
          </el-form-item>
          <div style="float: left;margin-top:5px">
            <el-button icon="el-icon-search"
                       type="primary"
                       size="mini"
                       :loading="updateLoading"
                       @click="search"
                       style="padding: 6px 10px;"></el-button>
          </div>
        </el-form>
      </el-row>
      <el-row>
        <el-table :data="orderlist"
                  ref="multipleTable"
                  style="width: 100%"
                  row-key="id"
                  v-loading="loading"
                  border
                  lazy
                  :highlight-current-row="true"
                  height="calc(100vh - 180px)"
                  :header-cell-style="{background:'#f4f4f5',color:'#303133'}"
                  :span-method="objectSpanMethod">
          <el-table-column prop="type"
                           label="类别"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="team"
                           label="业务组"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column v-for="(head,index) in monthslist"
                           :key="index"
                           :label="head.months"
                           :property="head.months"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="total"
                           label="年度小计"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="team_percent"
                           label="组内占比"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="company_percent"
                           label="公司占比"
                           align="center"
                           :show-overflow-tooltip="true">
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { BusinessGroupDataAnalysis } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'BusinessgroupanalysisIndex',
  data () {
    return {
      loading: true,
      updateLoading: false,
      orderlist: [],
      form: {
        year: '2023'
      },
      monthslist: [],
      myHeaders: '',
      span: [],
      index: null

    }
  },
  created () {
    this.loadnav()
    this.loadtoken()
    this.search()
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'businessgroupanalysis') {
        this.topname = '数据分析'
        this.navnames = '业务组分析'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    search () {
      this.updateLoading = true
      this.loading = true
      BusinessGroupDataAnalysis({ year: '2023' }).then(res => {
        if (res.data.status === 'success') {
          this.orderlist = res.data.data
          this.monthslist = res.data.months
          this.getSpanArr(res.data.data)
          this.loading = false
          this.updateLoading = false
        } else {
          this.loading = false
          this.updateLoading = false
        }
      }).catch(err => {
        this.loading = false
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    // 获取需要合并的数据
    getSpanArr (data) {
      // data就是我们从后台拿到的数据
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.span.push(1)
          this.index = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].type === data[i - 1].type) {
            this.span[this.index] += 1
            this.span.push(0)
          } else {
            this.span.push(1)
            this.index = i
          }
        }
      }
    },
    // 表格合并行方法
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.span[rowIndex]
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: 1
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}
body {
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  height: auto;
}
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  html,
  body {
    height: inherit;
  }
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .input-with-select .el-input__inner {
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-item {
  margin-bottom: 18px;
}
.input-with-select {
  background-color: #fff;
}
.el-form-items {
  float: left;
  margin-right: 10px;
}
/deep/ .el-form-item__content {
  float: left;
}
/deep/ .el-form-item__label {
  width: 70px;
}
/deep/.el-table .el-table__cell {
  padding: 0px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: #303133;
}
/deep/ .el-table thead {
  color: #909399;
  font-weight: normal !important;
}
/deep/ .el-table {
  font-size: 12px;
  font-weight: normal !important;
  color: #303133;
}
/deep/ .el-row {
  margin: 0 auto;
}
.print {
  height: 30px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}
.offer {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
/deep/.el-table .cell {
  padding-right: 5px;
}
/deep/.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 5px;
}
/deep/.el-table td {
  padding: 0px 0;
}
/deep/.el-table th {
  padding: 0px 0;
}
.xwyc-table th,
.xwyc-table td,
.xwyc-table[lay-skin='line'],
.xwyc-table[lay-skin='row'] {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.xwyc-table th,
.xwyc-table td {
  padding: 1px 0;
  border-top: none;
  border-left: none;
  color: #000;
  font-size: 14px;
  font-weight: normal !important;
}
.xwyc-table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.el-buttons {
  float: left;
  margin-left: 5px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 2px 5px;
  font-size: 12px;
}
.clear {
  clear: both;
}
/deep/.el-calendar-table tr td:first-child {
  border-left: 1px solid #aaabac;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #aaabac;
}
/deep/.el-calendar-table td {
  border-bottom: 1px solid #aaabac;
  border-right: 1px solid #aaabac;
  vertical-align: top;
  transition: background-color 0.2s ease;
}
</style>
