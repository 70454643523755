<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover">
      <div class="search">
        <!-- 数据筛选表单 -->
        <el-form ref="form"
                 :model="collectionform"
                 label-width="80px">
          <el-form-item label="名称"
                        class="el-form-items">
            <el-input v-model="collectionform.name"
                      placeholder="请输入内容"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="销售日期"
                        class="el-form-items">
            <el-date-picker v-model="collectionform.rangeDate"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item style="margin-left:-60px ;"
                        class="el-form-items">
            <el-button type="primary"
                       size="mini"
                       icon="el-icon-search"
                       @click="searchData"
                       :loading="updateLoading">查询</el-button>
          </el-form-item>
        </el-form>
        <!-- /数据筛选表单 -->
      </div>
      <div class="clear"></div>
      <el-table :data="merchantsdata"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 200px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading">
        <el-table-column label="序号"
                         type="index"
                         align="center"
                         :width="50">
        </el-table-column>
        <el-table-column label="名称"
                         prop="name"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="简称"
                         prop="abbreviation"
                         align="center"
                         :width="150"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="关联企业"
                         prop="associated"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="六六"
                         prop="liuliu"
                         align="center"
                         :filters="[{ text: '是', value: '是' }, { text: '否', value: '否' }]"
                         :filter-method="liuliufilterTags"
                         filter-placement="bottom-end"
                         :width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="钢小二"
                         prop="gangxiaoer"
                         align="center"
                         :filters="[{ text: '是', value: '是' }, { text: '否', value: '否' }]"
                         :filter-method="gangxiaoerfilterTags"
                         filter-placement="bottom-end"
                         :width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column label="所属部门"
                         prop="deptname"
                         align="center"
                         :filters="[{ text: '市场', value: '市场' }, { text: '运营', value: '运营' }, { text: '创融', value: '创融' },{ text: '未选定', value: '未选定' }]"
                         :filter-method="filterTag"
                         filter-placement="bottom-end"
                         :width="120"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="类型"
                         prop="mername"
                         align="center"
                         :filters="[{ text: '客户', value: '客户' }, { text: '供应商', value: '供应商' },{ text: '客户,供应商', value: '客户,供应商' },{ text: '未选定', value: '未选定' }]"
                         :filter-method="filterTags"
                         filter-placement="bottom-end"
                         :width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户经理"
                         prop="manager"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="销售日期"
                         prop="sellbilleddate"
                         align="center"
                         width="130"
                         :filters="[{ text: '未出单', value: '未出单' }]"
                         :filter-method="filterTagss"
                         filter-placement="bottom-end"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户完善情况"
                         prop="customercount"
                         align="center"
                         :width="120"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="green"
                  v-if="scope.row.customercount>50">{{scope.row.customercount}}%</span>
            <span class=""
                  v-else>{{scope.row.customercount}}%</span>
          </template>
        </el-table-column>
        <el-table-column label="供应商完善情况"
                         prop="suppliercount"
                         align="center"
                         :width="130"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="green"
                  v-if="scope.row.suppliercount>50">{{scope.row.suppliercount}}%</span>
            <span class=""
                  v-else>{{scope.row.suppliercount}}%</span>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         label="操作">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;"
           v-show="total!=0">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-sizes="[19,30, 50, 100]"
                       :page-size="19"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
      <el-dialog title="新增客商"
                 :visible.sync="dialogFormMerchants"
                 append-to-body>
        <el-form ref="customerinfo-form"
                 :model="customerinfo"
                 label-width="120px"
                 :rules="formRules">
          <el-form-item label="名称"
                        prop="name">
            <el-input v-model="customerinfo.name"></el-input>
          </el-form-item>
          <el-form-item label="所在部门"
                        prop="deptid">
            <el-select v-model="customerinfo.deptid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in deptoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型"
                        prop="mid">
            <el-select v-model="customerinfo.mid"
                       placeholder="请选择"
                       multiple
                       filterable
                       remote
                       reserve-keyword>
              <el-option v-for="item in mertypeoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="简称"
                        prop="abbreviation">
            <el-input v-model="customerinfo.abbreviation"></el-input>
          </el-form-item>
          <el-form-item label="曾用名"
                        prop="beforename">
            <el-input v-model="customerinfo.beforename"></el-input>
          </el-form-item>
          <el-form-item label="关联企业"
                        prop="associated">
            <el-select v-model="customerinfo.associated"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in merchantsdata"
                         :key="item.id"
                         :label="item.name"
                         :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="六六"
                        prop="liuliu">
            <el-select v-model="customerinfo.liuliu"
                       placeholder="请选择">
              <el-option v-for="item in liuliuoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="钢小二"
                        prop="gangxiaoer">
            <el-select v-model="customerinfo.gangxiaoer"
                       placeholder="请选择">
              <el-option v-for="item in gangxiaoeroptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户经理"
                        prop="username">
            <el-select v-model="customerinfo.userid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in useroptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateCustomer">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改客商"
                 :visible.sync="dialogFormMerchantss"
                 append-to-body>
        <el-form ref="customerinfos-form"
                 :model="customerinfos"
                 label-width="120px"
                 :rules="formRuless">
          <el-form-item label="名称"
                        prop="name">
            <el-input v-model="customerinfos.name"></el-input>
          </el-form-item>
          <el-form-item label="所在部门"
                        prop="deptid">
            <el-select v-model="customerinfos.deptid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in deptoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型"
                        prop="mid">
            <el-select v-model="customerinfos.mid"
                       placeholder="请选择"
                       multiple
                       filterable
                       remote
                       reserve-keyword>
              <el-option v-for="item in mertypeoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="简称"
                        prop="abbreviation">
            <el-input v-model="customerinfos.abbreviation"></el-input>
          </el-form-item>
          <el-form-item label="曾用名"
                        prop="beforename">
            <el-input v-model="customerinfos.beforename"></el-input>
          </el-form-item>
          <el-form-item label="关联企业"
                        prop="associated">
            <el-select v-model="customerinfos.associated"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in merchantsdata"
                         :key="item.id"
                         :label="item.name"
                         :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="六六"
                        prop="liuliu">
            <el-select v-model="customerinfos.liuliu"
                       placeholder="请选择">
              <el-option v-for="item in liuliuoptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="钢小二"
                        prop="gangxiaoer">
            <el-select v-model="customerinfos.gangxiaoer"
                       placeholder="请选择">
              <el-option v-for="item in gangxiaoeroptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户经理"
                        prop="username">
            <el-select v-model="customerinfos.userid"
                       placeholder="请选择"
                       clearable
                       filterable>
              <el-option v-for="item in useroptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateCustomers">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getMerchantsList, addMerchants, getDelMerchants, getMerchants, upDataMerchants, getDepartmentList, getMerchantsType } from '@/api/global'
import { getUserList } from '@/api/user'
import globalBus from '@/utils/global-bus'

export default {
  name: 'MerchantsIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      rangeDate: null,
      pickerOptions: {
        disabledDate (time) {
          const curDate = (new Date()).getTime()
          const three = 36 * 30 * 24 * 3600 * 1000
          const threeMonths = curDate - three
          return time.getTime() > Date.now() || time.getTime() < threeMonths
        },
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近半年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 360)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      collectionform: {
        name: '',
        rangeDate: ''
      },
      customerdatasearch: [],
      customerform: {
        id: ''
      },
      customerinfo: {
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        liuliu: '',
        gangxiaoer: ''
      },
      updateProfileLoading: false,
      updateLoading: false,
      customerformRules: {
        id: [{ required: true, message: '请输选择公司名', trigger: 'blur' }]
      },
      formRules: {
        name: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      merchantsdata: [],
      search: '',
      dialogFormMerchants: false,
      dialogFormMerchantss: false,
      customerinfos: {
        id: '',
        name: '',
        deptid: '',
        userid: '',
        mid: '',
        abbreviation: '',
        beforename: '',
        associated: '',
        liuliu: '',
        gangxiaoer: ''
      },
      formRuless: {
        name: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mid: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      deptoptions: [],
      useroptions: [],
      mertypeoptions: [],
      liuliuoptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }],
      gangxiaoeroptions: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }, {
        value: '试用',
        label: '试用'
      }],
      percentage: 10,
      colors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      currentPage: 1,
      total: 0,
      perpage: 19
    }
  },
  created () {
    this.loadnav()
    this.loaddept()
    this.loadmerchantstype()
    this.loaduser()
    this.loadmerchantsList()
  },
  mounted () {
  },
  methods: {
    loaduser () {
      getUserList().then(res => {
        if (res.data.status === 'success') {
          this.useroptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadnav () {
      //  发布通知
      if (this.$route.name === 'merchants') {
        this.topname = '客商管理'
        this.navnames = '客商统计'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loaddept () {
      getDepartmentList().then(res => {
        if (res.data.status === 'success') {
          this.deptoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadmerchantstype () {
      getMerchantsType().then(res => {
        if (res.data.status === 'success') {
          this.mertypeoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadmerchantsList () {
      getMerchantsList({ per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.merchantsdata = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    onUpdateCustomer () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['customerinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addMerchants(this.customerinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormMerchants = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.customerinfo.name = ''
          this.customerinfo.deptid = ''
          this.customerinfo.userid = ''
          this.loadmerchantsList()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    searchData () {
      this.loading = true
      getMerchantsList({ data: this.collectionform, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.merchantsdata = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
        } else {
          this.loading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    onUpdateCustomers () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['customerinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataMerchants(this.customerinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormMerchantss = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.customerinfos.name = ''
            this.customerinfos.deptid = ''
            this.customerinfos.userid = ''
            this.loadmerchantsList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    handleEdit (row) {
      getMerchants({ id: row.id }).then(res => {
        this.dialogFormMerchantss = true
        this.customerinfos = res.data.data
        this.businessa = res.data.dataa
        this.billinga = res.data.datab
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDelMerchants({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormMerchants = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadmerchantsList()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    filterTag (value, row) {
      return row.deptname === value
    },
    filterTags (value, row) {
      return row.mername === value
    },
    filterTagss (value, row) {
      return row.sellbilleddate === value
    },
    liuliufilterTags (value, row) {
      return row.liuliu === value
    },
    gangxiaoerfilterTags (value, row) {
      return row.gangxiaoer === value
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      getMerchantsList({ data: this.collectionform, per: val }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.merchantsdata = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
        } else {
          this.loading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    },
    handleCurrentChange (val) {
      this.loading = true
      getMerchantsList({ data: this.collectionform, per: this.perpage, page: val }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.merchantsdata = res.data.data
          this.currentPage = res.data.page
          this.total = res.data.total
        } else {
          this.loading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.loading = false
        console.log('失获取数据败', err)
      })
    }
  }
}
</script>

<style scoped lang="less">
.filter-card {
  margin-bottom: 10px;
}
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
.el-form-items {
  float: left;
  margin-right: 5px;
}
.red {
  color: red;
  font-weight: 700;
}
.green {
  color: green;
  font-weight: 700;
}
/deep/ .el-button--mini,
.el-button--mini.is-round {
  padding: 5px 10px;
}
/deep/.el-card__body {
  padding: 10px 10px 10px 10px;
}
.el-form-items {
  margin-bottom: 5px;
}
/deep/ .el-form-items .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  border-top-color: rgb(220, 223, 230);
  border-right-color: rgb(220, 223, 230);
  border-bottom-color: rgb(220, 223, 230);
  border-left-color: rgb(220, 223, 230);
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 35px;
  line-height: 35px;
  outline: 0;
  padding: 0 15px;
  padding-right: 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .el-form-items .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.search {
  width: 100%;
  float: left;
  margin-bottom: 5px;
}
.clear {
  clear: both;
}
/deep/.el-table th {
  padding: 0px 0;
}
/deep/.el-table td {
  padding: 5px 0;
  font-size: 12px;
}
</style>
